import React, { useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import { MEMBERSHIP_INFORMATION, VIRTUAL_CARD_INFO } from '../../graphql/queries/Patients'

export default function VirtualCardNumberInfo(props) {



    var patient_id = parseInt(window.localStorage.getItem('xrefID'))
    if (props.patientID) {
        patient_id = parseInt(props.patientID)
    }

    const [member_id, setmemberid] = useState('')
    const { data: memberdata } = useQuery(MEMBERSHIP_INFORMATION, {
        variables: { patient_id }
    });


    const [cardnum, setcardnum] = useState('')
    const [expiration, setexpiration] = useState('')
    const [cvv, setcvv] = useState('')
    const { loading, error, data, refetch } = useQuery(VIRTUAL_CARD_INFO, {
        variables: { member_id }
    });


    const [cardnum1, set1] = useState('')
    const [cardnum2, set2] = useState('')
    const [cardnumsub2, setsub2] = useState('')
    const [cardnum3, set3] = useState('')
    const [cardnum4, set4] = useState('')

    const [expirationFormat, setformat] = useState('')

    useEffect(() => {
        if (patient_id) {
            if (memberdata) {
                if (memberdata.patientinfo != null) {
                    if (memberdata.patientinfo.member_id) {
                        setmemberid(memberdata.patientinfo.member_id)
                    }
                }

            }
            if (data != null) {
                if (data.patientcard != null) {
                    if (data.patientcard.data != null) {
                        if (data.patientcard.data.cardNum) {
                            setcardnum(data.patientcard.data.cardNum)
                            if (cardnum != "") {
                                set1(cardnum.substring(0, 4))
                                set2(cardnum.substring(4, 8))
                                setsub2(cardnum.substring(4, 6))
                                set3(cardnum.substring(8, 12))
                                set4(cardnum.substring(12, 16))
                            }
                        }
                        if (data.patientcard.data.expDate) {
                            setexpiration(data.patientcard.data.expDate)
                            if (expiration != "") {
                                setformat(expiration.substring(0, 10))
                            }
                        }
                        if (data.patientcard.data.cvv) {
                            if (data.patientcard.data.cvv.toString().length == 2) {
                                setcvv('0' + data.patientcard.data.cvv.toString())
                            } else if (data.patientcard.data.cvv.toString().length == 1) {
                                setcvv('00' + data.patientcard.data.cvv.toString())
                            } else if (data.patientcard.data.cvv.toString().length == 0) {
                                setcvv('00' + data.patientcard.data.cvv.toString())
                            } else {
                                setcvv(data.patientcard.data.cvv)
                            }
                        } 
                    }
                }

            }
        }
    })


    return (
        <div className="section shadow-box color-label">
            <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Virtual Card Number Information</h2>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Card number</label>
                        <span id="masked_card_id">{cardnum1} {cardnumsub2}** ****{cardnum4}</span>
                        <span id="long_card_id" style={{ display: 'none' }}>
                            {cardnum1} {cardnum2} {cardnum3} {cardnum4}
                        </span><a id="show_16_card" href="#" ><br />Show 16 digits</a>
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Card expiration</label>
                        {expirationFormat}</p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>CVC #</label>
                        <span id="cvc" style={{ display: 'none' }}>
                            {cvv}
                        </span><a id="show_cvc" href="#">Show CVC Number</a>
                    </p>
                </div>
            </div>
        </div>
    )
}