import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'


export default function EnrollSuccess() {

	const enrollmentData = JSON.parse(window.localStorage.getItem('enroll_success')) ? JSON.parse(window.localStorage.getItem('enroll_success')) : ''
	const member_id = window.localStorage.getItem('member_id')
	const verification_data = JSON.parse(window.localStorage.getItem('verification_data')) ? JSON.parse(window.localStorage.getItem('verification_data')) : ''
	

	var firstname = ''
	var lastname = ''
	var status = ''
	var memberid = ''
	var groupnumber = ''
	var bin = ''
	var pcn = ''
	var payerid = 'RISRX'
	if(enrollmentData){
		if(enrollmentData.first_name){
			firstname = enrollmentData.first_name
		}
		if(enrollmentData.last_name){
			lastname = enrollmentData.last_name
		}
	}
	if(verification_data){
		if(verification_data.response){
			if(verification_data.response.CardResponseInfo){
				if(verification_data.response.CardResponseInfo.Status){
					status = verification_data.response.CardResponseInfo.Status
				}
				if(verification_data.response.CardResponseInfo.MemberId){
					memberid = verification_data.response.CardResponseInfo.MemberId
				}
				if(verification_data.response.CardResponseInfo.Group){
					groupnumber = verification_data.response.CardResponseInfo.Group
				}
				if(verification_data.response.CardResponseInfo.Bin){
					bin = verification_data.response.CardResponseInfo.Bin
				}
				if(verification_data.response.CardResponseInfo.Pcn){
					pcn = verification_data.response.CardResponseInfo.Pcn
				}
			}
		}
	}

	

	  
	return (
		<div className="container" id="content">
			<div id="container">
				<h1 style={{ fontSize: '1.5em', lineHeight: '1.49em' }}>Thank You for Enrolling</h1>
				<p>In the next few days, a Welcome Letter will be mailed to the address provided under "Patient Information." The letter has information about the program and instructions for how to use it. </p>
				<p>Please save the below information for your records. You will need it in case you need to call about your enrollment in the program.</p>
				<p><b>Patient name:</b> {firstname}&nbsp;{lastname}</p>
				<p><b>Patient status:</b> {status} </p>
				<p><b>Member ID:</b> { member_id} </p>
				<p><b>Group Number:</b> { groupnumber}</p>
				<p><b>RxBIN:</b> {bin}</p>
				<p><b>PCN:</b> {pcn}</p>
				<p><b>Payer ID:</b> {payerid}</p>
				<p>If you have any questions, please call <span className="noWrap"> <strong>(855) 965-2472</strong></span>.</p>
				<p><Link to="../create-account">Click here</Link> to create an account. By creating an account, you will be able to track your claims and corresponding co-pay assistance, upload EOB's and ensure your demographic information is as up to date as possible. </p>
				<div className="clearfloat"></div>

			</div>
		</div>
	)
}