import React from 'react'

export default function ClaimsTableRow() {
    return (
        <>
            <tr className="status_approved">
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>XOLAIR</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>98607</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>Medical</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>09/13/2018</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>$16.86</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>$11.86</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="status-icon active">Approved</span></td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>Approved </td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>Allergy Partners <br />
                    39 Clarendon Blvd.  <br />
                    Suite 163  <br />
                    Dayton, GA 98461 </td>
            </tr>

            <tr className="status_approved">
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>XOLAIR</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>98604</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>Medical</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>09/13/2018</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>$16.86</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>$11.86</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="status-icon active">Approved</span></td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>-</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>Allergy Partners Of Metro De <br />
                    48 Rocky Hague Freeway<br />
                    Jacksonville, AK 49108<br /></td>
            </tr>
            <tr className="status_rejected">
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>XOLAIR</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>89798</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>Medical</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>09/13/2018</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>$5</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>-</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}><a className="status-icon rejected" title="Show Reject Reason">Rejected</a></td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>-</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>Claim submitted by and paid to
                    HCP not affiliated with your office</td>
            </tr>
            <tr className="status_rejected">
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>XOLAIR</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>89793</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>Medical</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>09/13/2018</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>$5</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>-</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}><a className="status-icon rejected" title="Show Reject Reason">Rejected</a></td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>-</td>
                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>Claim submitted by and paid to HCP not affiliated with your office</td>
            </tr>
        </>
    )
}