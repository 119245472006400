import React, { useEffect } from 'react'
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import UpdatePatientProfile from '../components/UpdatePatientProfile'
import AccountNavbar from '../components/AccountNavbar'
import MembershipInformation from '../components/MembershipInformation'
import CommercialMedicalInsurance from '../components/CommercialMedicalInsurance'
import PharmacyInsuranceInformation from '../components/PharmacyInsuranceInformation'
import PatientEnrollmentDetails from '../components/PatientEnrollmentDetails'


export default function UpdateProfile() {
  useEffect(() => {
    
    $(document).ready(function () {
      $('#save').on('click', function (event) {
        event.preventDefault();
        $('#save-popup').css('display', 'block');
      });

      $('.save-popup .close-button').on('click', function (event) {
        event.preventDefault();
        $('.save-popup').css('display', 'none');
      });
      $('#cancel').on('click', function (event) {
        event.preventDefault();
        $('.update_profile').submit();
      });
    });
    $(document).ready(function () {
      /*
      $('#ok').on('click', function (event) {
        event.preventDefault();
        $('.update_profile').submit();
      });
*/
      $('.ok-popup .close-button').on('click', function (event) {
        event.preventDefault();
        $('.ok-popup').css('display', 'none');
      });
      /*
      $('.save-updates').on('click', function (event) {
        event.preventDefault();
        $('.update_profile').submit();
      });
      */
    });

    $(document).ready(function () {
      $('.ok-popup').css('display', 'block');
      $('.ok-popup .close-button').on('click', function (event) {
        event.preventDefault();
        $('.ok-popup').css('display', 'none');
      });

    });
    
    $(document).ready(function () {
      $(".datepicker").datepicker({
        dateFormat: 'mm/dd/yy', showButtonPanel: true,
        changeMonth: true,
        changeYear: true,
        showOtherMonths: true,
        selectOtherMonths: true
      });
    });
  })


  return (
    <div className="container" id="content" style={{ fontSize: '1em' }}>
      <div className="ok-popup" style={{ display: 'none' }}>
        <div className="popup-box" style={{ padding: '20px' }}>
          <p>Updates to patient profile information may not be reflected immediately.</p>
          <button className="close-button">Close</button>
        </div>
      </div>
      <div id="container" className="rtx_prof">
        <div id="hcp_post_content">
          <AccountNavbar page="profile" />
          <div className="clearfloat"></div>
          <div className="space"></div>
          <UpdatePatientProfile url='profile' />
          <MembershipInformation />
          <CommercialMedicalInsurance />
          <PharmacyInsuranceInformation />
          <PatientEnrollmentDetails />
          <div className="clearfloat"></div>
        </div>
      </div>
    </div>
  )
}