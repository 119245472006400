import React, { useState, useEffect } from 'react'
import {useNavigate} from 'react-router-dom'
import { useLazyQuery, gql } from '@apollo/client';
import {EDIT_OFFICE_USER} from '../../graphql/queries/Providers'

export default function EditOfficeUser() {

    const edit_user_data = JSON.parse(window.localStorage.getItem('editUser')) ? JSON.parse(window.localStorage.getItem('editUser')) : JSON.stringify({
        id : 0,
        firstName: '',
        lastName: '',
        email: '',
        status: ''
    })
    const provider_id = parseInt(window.localStorage.getItem('providerID'))

    const [id, setid] = useState(edit_user_data.id.toString() ? edit_user_data.id.toString() : '')
    const [user_status, setuserstatus] = useState(edit_user_data.status ? edit_user_data.status : '')
    const [first_name, setfirstname] = useState(edit_user_data.firstName ? edit_user_data.firstName : '')
    const [last_name, setlastname] = useState(edit_user_data.lastName ? edit_user_data.lastName : '')
    const [username, setusername] = useState(edit_user_data.email ? edit_user_data.email : '')
    const [email, setemail] = useState(edit_user_data.email ? edit_user_data.email : '')

    const [ editUser, { loading, error, data }] = useLazyQuery(EDIT_OFFICE_USER, {
        variables: { provider_id, id, email, user_status }
    });

    useEffect(() => {
        if(data){
            console.log(data)

        }
    }, [data])

    function editOfficeUser(e){
        e.preventDefault()
        editUser({variables: { provider_id, id, email, user_status }})
        window.localStorage.removeItem('editUser')
        let path = `../manage-users`
        navigate(path)
    }

    let navigate = useNavigate()
    function cancelBtn(){
        let path = `../manage-users`
        navigate(path)
    }

    return (
        <div class="container" id="content" style={{ fontSize: '1em' }}>
            <div id="container">
                <div id="hcp_post_content">
                    <h1 style={{ fontSize: '1.5em', lineHeight: '1.49em' }}>Edit Office User</h1>
                    <div class="section shadow-box color-label">
                        <form onSubmit={e => editOfficeUser(e)} class="form-validate" acceptCharset="utf-8">
                            <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>User Details</h2>
                            <div class="col-sm-12 col-md-12 col-lg-6"><p class="field">
                                <label for="first_name" style={{ fontSize: '1em', lineHeight: '1.49em' }}>First name</label>{first_name}</p></div>
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <p class="field">
                                    <label for="last_name" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Last name</label>{last_name}</p></div>
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <p class="field">
                                    <label for="street" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Username</label>{username}</p></div>
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <p class="field required-field text">
                                    <label for="street" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span class="inner-label">Email Address<span class="required">*</span>:</span></label>
                                    <input readOnly type="text" name="address_2_enroller" onChange={e => setemail(e.target.value)} defaultValue={email}/>
                                </p></div>
                            <p>
                                <button type="submit" >Save</button><button type="reset" onClick={cancelBtn}>Cancel</button>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

