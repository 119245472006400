import React, { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client';
import { GET_PATIENT_PROVIDER_NPI, PROVIDER_INFORMATION } from '../graphql/queries/Patients'

export default function ProviderInformation() {

    const patient_id = parseInt(window.localStorage.getItem('xrefID'))

    const [firstName, setFirstName] = useState('');
    const [lastName, setLasttName] = useState('');
    const [practicename, setpracticename] = useState('');
    //const [npi, setnpi] = useState(0);
    const [address1, setaddress1] = useState('');
    const [address2, setaddress2] = useState('');
    const [city, setcity] = useState('');
    const [state, setstate] = useState('');
    const [zip, setzip] = useState('');
    const [phonenumber, setphonenumber] = useState('');

    const [npi, setnpi] = useState(0)

    const { data: getnpi } = useQuery(GET_PATIENT_PROVIDER_NPI, {
        variables: { patient_id }
    })

    useEffect(() => {
        if(getnpi != null){
            if(getnpi.patientinfo != null){
                if(getnpi.patientinfo.provider_data != null){
                    if(getnpi.patientinfo.provider_data.primary != null){
                        if(getnpi.patientinfo.provider_data.primary[0] != null){
                            if(getnpi.patientinfo.provider_data.primary[0].provider_npi != null){
                                setnpi(parseInt(getnpi.patientinfo.provider_data.primary[0].provider_npi))
                                
                            }
                        }
                    }
                }
            }
        }
    }, [getnpi])
    
   

    const { loading, error, data: data, refetch } = useQuery(PROVIDER_INFORMATION, {
        variables: { npi, patient_id }
    });

    useEffect(() => {
        if (npi != 0) {
            if (patient_id) {
                if (data != null) {
                    if(data.providernpi != null){
                        if(data.providernpi.provider_data != null){
                            if (data.providernpi.provider_data.first_name) {
                                setFirstName(data.providernpi.provider_data.first_name)
                            }
                            if (data.providernpi.provider_data.last_name) {
                                setLasttName(data.providernpi.provider_data.last_name)
                            }
                            if (data.providernpi.provider_data.practice_name) {
                                setpracticename(data.providernpi.provider_data.practice_name)
                            }
                            if (data.providernpi.provider_data.address.address_1) {
                                setaddress1(data.providernpi.provider_data.address.address_1)
                            }
                            if (data.providernpi.provider_data.address.address_2) {
                                setaddress2(data.providernpi.provider_data.address.address_2)
                            }
                            if (data.providernpi.provider_data.address.city) {
                                setcity(data.providernpi.provider_data.address.city)
                            }
                            if (data.providernpi.provider_data.address.state) {
                                setstate(data.providernpi.provider_data.address.state)
                            }
                            if (data.providernpi.provider_data.address.zip_code) {
                                setzip(data.providernpi.provider_data.address.zip_code)
                            }
                            if (data.providernpi.provider_data.phone_number) {
                                setphonenumber(data.providernpi.provider_data.phone_number)
                            }
        
                        }
                    }
                    
                } else {
                }
            } else {
            }
        }
    }, [data])


    return (
        <div className="section shadow-box color-label"><h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Provider Information</h2>


            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Full name</label>
                        {firstName} {lastName} </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Practice name </label>
                        {practicename}</p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>National Provider Identifier (NPI)</label>
                        {npi} </p></div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Address </label>
                        {address1}{address2 ? <><br />{address2}</> : <></>} <br />
                        {city}, {state} {zip} </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Phone number </label>
                        {phonenumber}</p></div>
            </div>
        </div>
    )
}
