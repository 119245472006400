import React from 'react'
import { useOutletContext } from 'react-router-dom'

export default function ReviewLap(){

    const { enrollment_data, setenrollmentdata } = useOutletContext()

    return (
        <div className="section shadow-box  color-label">
              <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Legally Authorized Person's Information</h2>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p>
                    <label htmlFor="firstName" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>First name</label>
                    {enrollment_data.lap.first_name}      </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p>
                    <label htmlFor="lastName" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Last name</label>
                    {enrollment_data.lap.last_name}      </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p>
                    <label htmlFor="street" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Address</label>
                    {enrollment_data.lap.address.address1}			  </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p>
                    <label htmlFor="street" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Address 2</label>
                    {enrollment_data.lap.address.address2}</p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p>
                    <label htmlFor="city" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>City</label>
                    {enrollment_data.lap.address.city}			  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p>
                    <label htmlFor="state" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>State</label><br />
                    {enrollment_data.lap.address.state}      </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p className=" text zipcode">
                    <label htmlFor="zipCode" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>ZIP code</label>
                    {enrollment_data.lap.address.zip_code}      </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p className=" text phone">
                    <label htmlFor="homePhone" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Phone number</label>
                    {enrollment_data.lap.phone}			  </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p className=" text phone">
                    <label htmlFor="homePhone" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Phone type</label>
                    Home			  </p>
                </div>
              </div>
            </div>
    )
}