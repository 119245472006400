import React, { useEffect, useState } from 'react'
import { Link, redirect } from 'react-router-dom'

export default function MessageTableRow(props) {

    const [fileURL, setURL] = useState('')


    /*
        useEffect(() => {
            const bucket = props.bucketName
            const folder = props.folderName
            const file = props.folderName + props.fileName
            // Simple POST request with a JSON body using fetch
            if (bucket && file) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'text/plain' },
                    body: JSON.stringify({ bucketName: bucket, fileName: file })
                };
                fetch('https://i1vv1q00j2.execute-api.us-east-1.amazonaws.com/default/get-file', requestOptions)
                    .then((response) => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error('Something went wrong');
                    })
                    .then((responseJson) => {
                        setURL(responseJson)
                        if(responseJson == 'no file found'){
                            setURL('')
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
    
            } else {
            }
    
        }, [])
        */

    function invalidFile() {
        alert('file not found')
    }

 
    function getFile() {
        const bucket = props.bucketName
        const folder = props.folderName
        const file = props.folderName + props.fileName
        // Simple POST request with a JSON body using fetch
        if (bucket && file) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'text/plain' },
                body: JSON.stringify({ bucketName: bucket, fileName: file })
            };
            fetch('https://w8efuoxgaj.execute-api.us-east-1.amazonaws.com/xolair/download', requestOptions)
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    window.open(responseJson, "_blank")
                    if (responseJson == 'no file found') {
                        alert('file not found')
                    }
                })
                .catch((error) => {
                    console.log(error)
                })

        } else {
        }
      
    }

    return (
        <tr>
            <td style={{ fontSize: '1em', lineHeight: '1.49em' }}><div onClick={getFile}>{props.docType}</div></td>
            <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{props.patientID}</td>
            <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{props.docSentDate}</td>
        </tr>
    )
}