import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';
import { PATIENT_COMMUNICATIONS } from '../graphql/queries/Patients'
import MessageTableRow from './MessageTableRow'

export default function MessagesTable(props) {


    var patient_id = parseInt(window.localStorage.getItem('xrefID'))
    if (props.patientID) {
        patient_id = parseInt(props.patientID)
    }
    var document_sent_from_date = ''
    var document_sent_to_date = ''
    if (props.fromdate) {
        document_sent_from_date = props.fromdate
    }
    if (props.todate) {
        document_sent_to_date = props.todate
    }
    var document_types = "EOB_FAX_BACK||LTR"
    
    

    const { loading, error, data, refetch } = useQuery(PATIENT_COMMUNICATIONS, {
        variables: { patient_id, document_sent_from_date, document_sent_to_date, document_types }
    });

    useEffect(() => {
        document_sent_from_date = props.fromdate
        document_sent_to_date = props.todate
        refetch({ patient_id, document_sent_from_date, document_sent_to_date, document_types });
    }, [props])


    return (
        <table className="account">
            <tbody>
                <tr className="list_header">
                    <th align="left" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Communication Type</th>
                    <th align="center" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Recipient</th>
                    <th align="center" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Date</th>
                </tr>
                {data && (data.patientcommunications != null) && data.patientcommunications.map(({ __typename, document_id, patient_id, member_id, document_type, document_file_name, document_received_date, document_sent_date, document_processed_date, communication_data, related_document_id }) => (
                    <MessageTableRow key={document_id} docType={document_type} patientID={patient_id} docSentDate={document_sent_date} bucketName={communication_data && communication_data.file_location != null ? (communication_data.file_location.s3_bucket != null ? communication_data.file_location.s3_bucket : '') : ''} folderName={communication_data && communication_data.file_location != null ? (communication_data.file_location.s3_folder != null ? communication_data.file_location.s3_folder : '') : ''} fileName={communication_data && communication_data.file_location != null ? (communication_data.file_location.file_name != null ? communication_data.file_location.file_name : '') : ''} />
                ))}
            </tbody>
        </table>
    )
}

