// import logo from "./logo.svg";
//import './App.css';
import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import HCPHeader from "./hcp/components/Header";
import HCPFooter from "./hcp/components/Footer";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Index";
import ChangePassword from "./pages/ChangePassword";
import CreateAccount from "./pages/CreateAccount";
import CreatePassword from "./pages/CreatePassword";
import PasswordReset from "./pages/PasswordReset";
import DoctorInformation from "./pages/DoctorInformation";
import EligibilityErrorDrug from "./pages/EligibilityErrorDrug";
import EligibilityErrorInfusion from "./pages/EligibilityErrorInfusion";
import EligibilityError from "./pages/EligibilityError";
import EligibilityLap from "./components/EligibilityLap";
import EligibilityPatient from "./components/EligibilityPatient";
import Eligibility from "./pages/Eligibility";
import EnrollAdditionalPatient from "./pages/EnrollAdditionalPatient";
import EnrollAttestationPatient from "./pages/EnrollAttestationPatient";
import EnrollDoctorInformationLap from "./pages/EnrollDoctorInformationLap";
import EnrollDoctorInformation from "./pages/EnrollDoctorInformation";
import EnrollFormLap from "./pages/EnrollFormLap";
import EnrollFormReviewLap from "./pages/EnrollFormReviewLap";
import EnrollFormReview from "./pages/EnrollFormReview";
import EnrollForm from "./pages/EnrollForm";
import EnrollSuccess from "./pages/EnrollSuccess";
import EnrollError from "./pages/EnrollError";
import Enroll from "./pages/Enroll";
import Forms from "./pages/Forms";
import Login from "./pages/Login";
import Messages from "./pages/Messages";
import MyAccount from "./pages/MyAccount";
import MyProfile from "./pages/MyProfile";
import PasswordRequestSuccess from "./pages/PasswordRequestSuccess";
import PasswordRequest from "./pages/PasswordRequest";
import Profile from "./pages/Profile";
import SubmitDocument from "./pages/SubmitDocument";
import TermsAndConditions from "./pages/TermsAndConditions";
import UpdateInsurance from "./pages/UpdateInsurance";
import UpdatePharmacy from "./pages/UpdatePharmacy";
import UpdateProfile from "./pages/UpdateProfile";
import UsingTheCard from "./pages/UsingTheCard";
import HCP from "./hcp/Routes";
import Layout from "./components/Layout";
import $ from "jquery";

function App() {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        /*
    window.addEventListener('storage', () => {
      window.localStorage.clear();
    })
    */
        function myFunction() {
            document.getElementById("user-box").classList.toggle("show");
        }

        // Close the dropdown if the user clicks outside of it
        window.onclick = function (event) {
            if (!event.target.matches(".user-box") && !event.target.matches(".user-mm")) {
                if ($(".user-box").css("display") == "block") {
                    $(".user-mm").toggleClass("show");
                    $(".user-box").slideToggle();
                }
            }
        };
    });
    useEffect(() => {
        window.addEventListener("error", (e) => {
            // console.log(e);

            if (e.message.includes("ResizeObserver")) {
                // console.log("Caught ResizeObserver Error");
                const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
                const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");

                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute("style", "display: none");
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute("style", "display: none");
                }
            }
        });
    }, []);

    return (
        <div className={pathname.includes("hcp") ? "home hcp" : "home patients"}>
            {pathname.includes("hcp") ? <HCPHeader /> : <Header />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/create-account" element={<CreateAccount />} />
                <Route path="/create-password" element={<CreatePassword />} />
                <Route path="/password-reset" element={<PasswordReset />} />
                <Route path="/eligibility" element={<Eligibility />} />
                <Route element={<Layout />}>
                    <Route path="/eligibility-error-drug" element={<EligibilityErrorDrug />} />
                    <Route path="/eligibility-error-infusion" element={<EligibilityErrorInfusion />} />
                    <Route path="/eligibility-error" element={<EligibilityError />} />
                    <Route path="/eligibility-lap-drug-infusion" element={<EligibilityLap pageType="drug-infusion" />} />
                    <Route path="/eligibility-lap-drug-only" element={<EligibilityLap pageType="drug" />} />
                    <Route path="/eligibility-lap-infusion-only" element={<EligibilityLap pageType="infusion" />} />
                    <Route path="/eligibility-patient-drug-infusion" element={<EligibilityPatient pageType="drug-infusion" />} />
                    <Route path="/eligibility-patient-drug-only" element={<EligibilityPatient pageType="drug" />} />
                    <Route path="/eligibility-patient-infusion-only" element={<EligibilityPatient pageType="infusion" />} />
                    <Route path="/enroll-additional-lap" element={<EnrollAdditionalPatient />} />
                    <Route path="/enroll-additional-patient" element={<EnrollAdditionalPatient />} />
                    <Route path="/enroll-attestation-lap" element={<EnrollAttestationPatient />} />
                    <Route path="/enroll-attestation-patient" element={<EnrollAttestationPatient />} />
                    <Route path="/enroll-doctor-information-lap" element={<EnrollDoctorInformationLap />} />
                    <Route path="/enroll-doctor-information" element={<EnrollDoctorInformation />} />
                    <Route path="/enroll-form-lap" element={<EnrollFormLap />} />
                    <Route path="/enroll-form-review-lap" element={<EnrollFormReviewLap />} />
                    <Route path="/enroll-form-review" element={<EnrollFormReview />} />
                    <Route path="/enroll-form" element={<EnrollForm />} />
                    <Route path="/enroll" element={<Enroll />} />
                </Route>
                <Route path="/enroll-success" element={<EnrollSuccess />} />
                <Route path="/enroll-error" element={<EnrollError />} />
                <Route path="/forms" element={<Forms />} />
                <Route path="/login" element={<Login />} />
                <Route path="/password-request-success" element={<PasswordRequestSuccess />} />
                <Route path="/password-request" element={<PasswordRequest />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/using-the-card" element={<UsingTheCard />} />
                <Route path="/hcp/*" element={<HCP />} />
                <Route element={<ProtectedRoute />}>
                    <Route path="/doctor-information" element={<DoctorInformation />} />
                    <Route path="/messages" element={<Messages />} />
                    <Route path="/my-account" element={<MyAccount />} />
                    <Route path="/my-profile" element={<MyProfile />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/submit-document" element={<SubmitDocument />} />
                    <Route path="/update-insurance" element={<UpdateInsurance />} />
                    <Route path="/update-pharmacy" element={<UpdatePharmacy />} />
                    <Route path="/update-profile" element={<UpdateProfile />} />
                </Route>
            </Routes>
            <Footer />
        </div>
    );
}

export default App;

const ProtectedRoute = () => {
    const token = localStorage.getItem("token");
    return token ? <Outlet /> : <Navigate to="/" replace />;
};
