import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

export default function ManageUsersTableRow(props) {
    let navigate = useNavigate();
    const [edit_user, setedit] = useState({
        id: props.userID,
        firstName: props.firstName,
        lastName: props.lastName,
        email: props.username,
        status: props.status,
    });

    function editUser() {
        window.localStorage.setItem("editUser", JSON.stringify(edit_user));
    }

    const [username, setusername] = useState("");

    function deacitvate() {
        setusername(props.username);
    }

    return (
        <>
            <tr>
                <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{props.firstName}</td>
                <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{props.lastName}</td>
                <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{props.username}</td>
                <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{props.email}</td>
                <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{props.createdOn}</td>
                <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{props.userType}</td>
                <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                    <span className={props.status == "Active" ? "status-icon active" : "status-icon inactive"}>{props.status}</span>
                </td>
                <td style={{ textAlign: "center", fontSize: "1em", lineHeight: "1.49em" }}>
                    <Link onClick={editUser} to="../edit-office-user">
                        Edit
                    </Link>
                    {props.status == "Active" ? (
                        <DeactivateBtn username={props.username} edit_user={edit_user} />
                    ) : props.status == "Inactive" || "inactive" ? (
                        <ActivateBtn username={props.username} edit_user={edit_user} />
                    ) : (
                        <></>
                    )}
                </td>
            </tr>
        </>
    );
}

function clearTemps() {
    window.localStorage.removeItem("selectedUser");
    window.localStorage.removeItem("tempID");
    window.localStorage.removeItem("tempEmail");
    window.localStorage.removeItem("tempStatus");
}

function DeactivateBtn(props) {
    const handleLocalStorage = () => {
        clearTemps();

        window.localStorage.setItem("selectedUser", props.username);
        window.localStorage.setItem("tempID", props.edit_user.id);
        window.localStorage.setItem("tempEmail", props.edit_user.email);
        window.localStorage.setItem("tempStatus", "inactive");
        window.dispatchEvent(new Event("storage"));
    };
    return (
        <>
            <br />
            <Link to="#" onClick={handleLocalStorage} className="deactivate_1">
                Deactivate
            </Link>
        </>
    );
}

function ActivateBtn(props) {
    const handleLocalStorage = () => {
        clearTemps();

        window.localStorage.setItem("selectedUser", props.username);
        window.localStorage.setItem("tempID", props.edit_user.id);
        window.localStorage.setItem("tempEmail", props.edit_user.email);
        window.localStorage.setItem("tempStatus", "Active");
        window.dispatchEvent(new Event("storage"));
    };
    return (
        <>
            <br />
            <Link to="#" onClick={handleLocalStorage} className="activate_2">
                Activate
            </Link>
        </>
    );
}
