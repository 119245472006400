import React, { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.min.css";
import ajaxLoader from "../../images/ajax-loader.gif";
import ReviewPatientInformation from "../../components/ReviewPatientInformation";
import ReviewCommercialMedicalInsurance from "../../components/ReviewCommercialMedicalInsurance";
import ReviewCommercialPharmacyInsurance from "../../components/ReviewCommercialPharmacyInsurance";
import RequestVirtualCardNumber from "../components/RequestVirtualCardNumber";
import ReviewProviderInformation from "../../components/ReviewProviderInformation";
import ReviewSiteOfCareInformation from "../../components/ReviewSiteOfCareInformation";
import { PATIENT_ENROLLMENT, UPDATE_PATIENT_ENROLLMENT } from "../../graphql/mutations/Patients";

export default function EnrollFormReview() {
    const {
        setTitle,
        setStep,
        email_opt_in,
        setemailopt,
        enrollment_data,
        setenrollmentdata,
        provider_data,
        setproviderdata,
        site_of_care,
        setsiteofcare,
        eligibility_answers,
        seteligibilityanswers,
    } = useOutletContext();

    var patient_id;
    var storedEnrollmentData;
    var storedproviderData;
    var eligibilityAnswers;
    var storedSiteOfCareData;
    var product;
    var enrolledBy;
    var additionalPatientServices;
    var existingMember;
    var requestCard;
    if (window.localStorage.getItem("enrolled_patient_id")) {
        patient_id = window.localStorage.getItem("enrolled_patient_id");
    }
    if (window.localStorage.getItem("enrollment_data")) {
        storedEnrollmentData = JSON.parse(window.localStorage.getItem("enrollment_data"));
    }
    if (window.localStorage.getItem("provider_data")) {
        storedproviderData = JSON.parse(window.localStorage.getItem("provider_data"));
    }
    if (window.localStorage.getItem("site_of_care")) {
        storedSiteOfCareData = JSON.parse(window.localStorage.getItem("site_of_care"));
    }
    if (window.localStorage.getItem("eligibility_answers")) {
        eligibilityAnswers = JSON.parse(window.localStorage.getItem("eligibility_answers"));
    }
    if (window.localStorage.getItem("product")) {
        product = window.localStorage.getItem("product");
    }
    if (window.localStorage.getItem("enrollment_type")) {
        enrolledBy = window.localStorage.getItem("enrollment_type");
    } else {
        enrolledBy = "patient";
    }
    if (window.localStorage.getItem("email_opt_in")) {
        additionalPatientServices = JSON.parse(window.localStorage.getItem("email_opt_in"));
    }
    if (window.localStorage.getItem("existing_member")) {
        existingMember = window.localStorage.getItem("existing_member");
    }
    if (window.localStorage.getItem("request_card")) {
        requestCard = window.localStorage.getItem("request_card");
    }

    var drug_patient = product == "drug" || product == "drug-admin" ? "Y" : "N";
    var drug_admin_patient = product == "admin" || product == "drug-admin" ? "Y" : "N";
    var enrollment_source = "provider";
    var request_card = requestCard ? (requestCard.toUpperCase() == "Y" ? "Y" : "N") : "N";
    var patient_data_json = '{"rxinfo":[]}';
    let date = new Date();
    let formattedDate =
        date.getFullYear() +
        "-" +
        date.toLocaleString("en-US", { month: "2-digit" }) +
        "-" +
        date.toLocaleString("en-US", { day: "2-digit" });
    var enrollment_date_drug = product == "drug" || product == "drug-admin" ? formattedDate : "";
    var enrollment_date_admin = product == "admin" || product == "drug-admin" ? formattedDate : "";
    // var enrollment_date_drug = formattedDate;
    // var enrollment_date_admin = formattedDate;

    var first_name = enrollment_data.first_name && typeof enrollment_data.first_name === "string" ? enrollment_data.first_name.trim() : "";
    var last_name = enrollment_data.last_name && typeof enrollment_data.last_name === "string" ? enrollment_data.last_name.trim() : "";
    var dob = enrollment_data.dob ? enrollment_data.dob : "";
    var gender = enrollment_data.gender ? enrollment_data.gender : "";
    var phone = enrollment_data.phone_number ? enrollment_data.phone_number : "";
    var phone_type = enrollment_data.phone_type ? enrollment_data.phone_type : "Home";
    var address1 =
        enrollment_data.address.address1 && typeof enrollment_data.address.address1 === "string"
            ? enrollment_data.address.address1.trim()
            : "";
    var address2 =
        enrollment_data.address.address2 && typeof enrollment_data.address.address2 === "string"
            ? enrollment_data.address.address2.trim()
            : "";
    var city = enrollment_data.address.city && typeof enrollment_data.address.city === "string" ? enrollment_data.address.city.trim() : "";
    var state = enrollment_data.address.state ? enrollment_data.address.state : "";
    var zip_code = enrollment_data.address.zip_code ? enrollment_data.address.zip_code : "";
    var mi_name = enrollment_data.insurance.medical_insurance.name ? enrollment_data.insurance.medical_insurance.name : "";
    var mi_plan_type = enrollment_data.insurance.medical_insurance.plan_type ? enrollment_data.insurance.medical_insurance.plan_type : "";
    var mi_member_number = enrollment_data.insurance.medical_insurance.member_number
        ? enrollment_data.insurance.medical_insurance.member_number
        : "";
    var mi_effective_date = enrollment_data.insurance.medical_insurance.effective_date
        ? enrollment_data.insurance.medical_insurance.effective_date
        : "";
    var mi_group_number = enrollment_data.insurance.medical_insurance.group_number
        ? enrollment_data.insurance.medical_insurance.group_number
        : "";
    var mi_bin = enrollment_data.insurance.medical_insurance.bin ? enrollment_data.insurance.medical_insurance.bin : "";
    var mi_pcn = enrollment_data.insurance.medical_insurance.pcn ? enrollment_data.insurance.medical_insurance.pcn : "";
    var rx_name = enrollment_data.insurance.rx_insurance.name ? enrollment_data.insurance.rx_insurance.name : "";
    var rx_plan_type = enrollment_data.insurance.rx_insurance.plan_type ? enrollment_data.insurance.rx_insurance.plan_type : "";
    var rx_member_number = enrollment_data.insurance.rx_insurance.member_number ? enrollment_data.insurance.rx_insurance.member_number : "";
    var rx_group_number = enrollment_data.insurance.rx_insurance.group_number ? enrollment_data.insurance.rx_insurance.group_number : "";
    var rx_bin = enrollment_data.insurance.rx_insurance.bin ? enrollment_data.insurance.rx_insurance.bin : "";
    var rx_pcn = enrollment_data.insurance.rx_insurance.pcn ? enrollment_data.insurance.rx_insurance.pcn : "";
    var lap_first_name = enrollment_data.lap.first_name ? enrollment_data.lap.first_name : "";
    var lap_last_name = enrollment_data.lap.last_name ? enrollment_data.lap.last_name : "";
    var lap_address1 = enrollment_data.lap.address.address1 ? enrollment_data.lap.address.address1 : "";
    var lap_address2 = enrollment_data.lap.address.address2 ? enrollment_data.lap.address.address2 : "";
    var lap_city = enrollment_data.lap.address.city ? enrollment_data.lap.address.city : "";
    var lap_state = enrollment_data.lap.address.state ? enrollment_data.lap.address.state : "";
    var lap_zip_code = enrollment_data.lap.address.zip_code ? enrollment_data.lap.address.zip_code : "";
    var lap_phone = enrollment_data.lap.address.phone ? enrollment_data.lap.address.phone : "";
    var email = enrollment_data.email ? enrollment_data.email : "";
    var email_opt = email_opt_in.opt ? (email_opt_in.opt == "yes" ? "y" : "n") : "n";
    var drug_answer_01 = product == "drug" || product == "drug-admin" ? (eligibility_answers.q1 ? eligibility_answers.q1 : "") : "";
    var drug_answer_02 = product == "drug" || product == "drug-admin" ? (eligibility_answers.q2 ? eligibility_answers.q2 : "") : "";
    var drug_answer_03 = product == "drug" || product == "drug-admin" ? (eligibility_answers.q3 ? eligibility_answers.q3 : "") : "";
    var drug_answer_04 = product == "drug" || product == "drug-admin" ? (eligibility_answers.q4 ? eligibility_answers.q4 : "") : "";

    var drug_answer_05_item = "";
    if (eligibility_answers.q5) {
        drug_answer_05_item = eligibility_answers.q5;
        if (eligibility_answers.q5b && drug_answer_05_item != "no") {
            drug_answer_05_item += `,${eligibility_answers.q5b}`;
        }
        if (eligibility_answers.q5c && drug_answer_05_item != "no") {
            drug_answer_05_item += `,${eligibility_answers.q5c}`;
        }
    }

    var drug_answer_05 = product == "drug" || product == "drug-admin" ? (eligibility_answers.q5 ? drug_answer_05_item : "") : "";

    var drug_answer_06 = product == "drug" || product == "drug-admin" ? (eligibility_answers.q6 ? eligibility_answers.q6 : "") : "";
    var drug_answer_07 = product == "drug" || product == "drug-admin" ? (eligibility_answers.q7 ? eligibility_answers.q7 : "") : "";
    var drug_answer_08 = product == "drug" || product == "drug-admin" ? (eligibility_answers.q8 ? eligibility_answers.q8 : "") : "";
    var drug_answer_09 = product == "drug" || product == "drug-admin" ? (eligibility_answers.q9 ? eligibility_answers.q9 : "") : "";
    var drug_answer_10 = product == "drug" || product == "drug-admin" ? (eligibility_answers.q10 ? eligibility_answers.q10 : "") : "";

    var admin_answer_01 = product == "admin" || product == "drug-admin" ? (eligibility_answers.q1 ? eligibility_answers.q1 : "") : "";
    var admin_answer_02 = product == "admin" || product == "drug-admin" ? (eligibility_answers.q2 ? eligibility_answers.q2 : "") : "";
    var admin_answer_03 = product == "admin" || product == "drug-admin" ? (eligibility_answers.q3 ? eligibility_answers.q3 : "") : "";
    var admin_answer_04 = product == "admin" || product == "drug-admin" ? (eligibility_answers.q4 ? eligibility_answers.q4 : "") : "";

    var admin_answer_05 = product == "admin" || product == "drug-admin" ? (eligibility_answers.q5 ? drug_answer_05_item : "") : "";
    var admin_answer_06 = product == "admin" || product == "drug-admin" ? (eligibility_answers.q6 ? eligibility_answers.q6 : "") : "";
    var admin_answer_07 = product == "admin" || product == "drug-admin" ? (eligibility_answers.q7 ? eligibility_answers.q7 : "") : "";
    var admin_answer_08 = product == "admin" || product == "drug-admin" ? (eligibility_answers.q8 ? eligibility_answers.q8 : "") : "";
    var admin_answer_09 = product == "admin" || product == "drug-admin" ? (eligibility_answers.q9 ? eligibility_answers.q9 : "") : "";
    var admin_answer_10 = product == "admin" || product == "drug-admin" ? (eligibility_answers.q10 ? eligibility_answers.q10 : "") : "";

    var provider_first_name = provider_data.first_name ? provider_data.first_name : "";
    var provider_last_name = provider_data.last_name ? provider_data.last_name : "";
    var provider_npi = provider_data.npi ? provider_data.npi : "";
    var provider_phone = provider_data.phones.phone ? provider_data.phones.phone : "";
    var provider_address1 = provider_data.address.address1 ? provider_data.address.address1 : "";
    var provider_address2 = provider_data.address.address2 ? provider_data.address.address2 : "";
    var provider_city = provider_data.address.city ? provider_data.address.city : "";
    var provider_state = provider_data.address.state ? provider_data.address.state : "";
    var provider_zip_code = provider_data.address.zip_code ? provider_data.address.zip_code : "";
    var secondary_practice_name = site_of_care.name ? site_of_care.name : "";
    var secondary_npi = site_of_care.npi ? site_of_care.npi : "";
    var secondary_phone = site_of_care.phone_number ? site_of_care.phone_number : "";
    var secondary_address1 = site_of_care.address.adress1 ? site_of_care.address.adress1 : "";
    var secondary_address2 = site_of_care.address.address2 ? site_of_care.address.address2 : "";
    var secondary_city = site_of_care.address.city ? site_of_care.address.city : "";
    var secondary_state = site_of_care.address.state ? site_of_care.address.state : "";
    var secondary_zip_code = site_of_care.address.zip_code ? site_of_care.address.zip_code : "";
    var primary_provider_id = provider_data.provider_id ? provider_data.provider_id : "";
    var primary_npi = provider_data.npi ? provider_data.npi : "";
    var secondary_provider_id = site_of_care.provider_id ? site_of_care.provider_id : "";
    var secondary_npi = site_of_care.npi ? site_of_care.npi : "";

    var patient_provider_data_json =
        `{"primary":{"first_name":"` +
        (provider_first_name ? provider_first_name : "") +
        `","npi":"` +
        (provider_npi ? provider_npi : "") +
        `","last_name":"` +
        (provider_last_name ? provider_last_name : "") +
        `","phones":{"phone":"` +
        (provider_phone ? provider_phone : "") +
        `"},"address":{"address1":"` +
        (provider_address1 ? provider_address1 : "") +
        `","address2":"` +
        (provider_address2 ? provider_address2 : "") +
        `","city":"` +
        provider_city +
        `","state":"` +
        (provider_state ? provider_state : "") +
        `","zip_code":"` +
        (provider_zip_code ? provider_zip_code : "") +
        `"}},"secondary":{"practice_name":"` +
        (secondary_practice_name ? secondary_practice_name : "") +
        `","npi":"` +
        (secondary_npi ? secondary_npi : "") +
        `","phones":{"phone":"` +
        (secondary_phone ? secondary_phone : "") +
        `"},"address":{"address1":"` +
        (secondary_address1 ? secondary_address1 : "") +
        `","address2":"` +
        (secondary_address2 ? secondary_address2 : "") +
        `","city":"` +
        (secondary_city ? secondary_city : "") +
        `","state":"` +
        (secondary_state ? secondary_state : "") +
        `","zip_code":"` +
        (secondary_zip_code ? secondary_zip_code : "") +
        `"}}}`;
    var provider_data_json =
        `{"primary":{"provider_id":"` +
        (primary_provider_id ? primary_provider_id : "") +
        `","npi":"` +
        (primary_npi ? primary_npi : "") +
        `"},"secondary":{"provider_id":"` +
        (secondary_provider_id ? secondary_provider_id : "") +
        `","npi":"` +
        (secondary_npi ? secondary_npi : "") +
        `"}}`;

    var program = product == "admin" ? "Xolair Administration" : "Xolair Drug";

    useEffect(() => {
        if (window.localStorage.getItem("enrollment_data")) {
            setenrollmentdata(storedEnrollmentData);
        }
        if (window.localStorage.getItem("provider_data")) {
            setproviderdata(storedproviderData);
        }
        if (window.localStorage.getItem("site_of_care")) {
            setsiteofcare(storedSiteOfCareData);
        }
        if (window.localStorage.getItem("eligibility_answers")) {
            seteligibilityanswers(eligibilityAnswers);
        }
        if (window.localStorage.getItem("email_opt_in")) {
            setemailopt(additionalPatientServices);
        }
    }, []);

    useEffect(() => {
        $(function () {
            $(".correct input[type=checkbox]").on("click", function () {
                if ($(this).is(":checked")) {
                    $("#submit").prop("disabled", false);
                    var field = $("#submit");
                    field.addClass("submit-patients");
                    field.removeClass("no-submit");
                } else {
                    $("#submit").prop("disabled", true);
                    var field = $("#submit");
                    field.removeClass("submit-patients");
                    field.addClass("no-submit");
                }
            });
            /*
      $('#submit').on('click', function (e) {
          document.getElementById("message").style.display = "block";
          e.preventDefault();
          console.log(enrollment_data)
          var $form = $(this);
          setTimeout(function () {
              $form.submit();
              return false;
          }, 10000);
      });
      */
        });

        setTitle("Apply for the XOLAIR Co-pay Program");
        setStep("6");
    });

    let navigate = useNavigate();
    function goBack() {
        navigate(-1);
    }

    const [enrollPatient, { called, error, data }] = useMutation(PATIENT_ENROLLMENT);
    const [existingEnrollment, { called: exisitngCalled, error: existingError, data: existingData }] =
        useMutation(UPDATE_PATIENT_ENROLLMENT);

    useEffect(() => {
        if (called) {
            document.getElementById("message").style.display = "block";
        }
    }, [called]);

    useEffect(() => {
        if (data) {
            if (data.patientenrollment != null) {
                if (data.patientenrollment.member_id) {
                    window.localStorage.setItem("member_id", data.patientenrollment.member_id);
                }
                if (data.patientenrollment.verification_data != null) {
                    window.localStorage.setItem("verification_data", data.patientenrollment.verification_data);
                }
                if (data.patientenrollment.enrollment_status) {
                    navigateEnroll(data.patientenrollment.enrollment_status);
                }
            } else {
                navigateEnroll("Error");
            }
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            navigateEnroll("Error");
        }
    }, [error]);

    useEffect(() => {
        if (exisitngCalled) {
            document.getElementById("message").style.display = "block";
        }
    }, [exisitngCalled]);

    useEffect(() => {
        if (existingData != null) {
            if (existingData.patientenrollmentupdate != null) {
                if (existingData.patientenrollmentupdate.member_id != null) {
                    window.localStorage.setItem("member_id", existingData.patientenrollmentupdate.member_id);
                }
                if (existingData.patientenrollmentupdate.enrollment_status) {
                    navigateEnroll(existingData.patientenrollmentupdate.enrollment_status);
                }
                if (existingData.patientenrollmentupdate.verification_data != null) {
                    window.localStorage.setItem("verification_data", existingData.patientenrollmentupdate.verification_data);
                }
            } else {
                navigateEnroll("Error");
            }
        }
    }, [existingData]);

    useEffect(() => {
        if (existingError) {
            navigateEnroll("Error");
        }
    }, [existingError]);

    function navigateEnroll(enrollmentStatus) {
        if (enrollmentStatus == "Active") {
            let path = `../enroll-success`;
            navigate(path);
        } else if (
            enrollmentStatus == "Pending" ||
            enrollmentStatus == "Duplicate" ||
            enrollmentStatus == "Error" ||
            enrollmentStatus == "Inactive"
        ) {
            let path = `../enroll-error`;
            navigate(path);
        }
    }

    function transformDateString(inputString) {
        // const iso8601Regex = /^\d{4}-\d{2}-\d{2}$/;
        // const slashDateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;
        // const hyphenDateFormatRegex = /^\d{2}-\d{2}-\d{4}$/;
        // const monthDayYearRegex = /^[A-Z][a-z]+ \d{1,2}, \d{4}$/;
        // const dayMonthYearRegex = /^\d{1,2} [A-Z][a-z]+ \d{4}$/;
        // const unixTimestampRegex = /^\d+$/;
        // const millisecondsTimestampRegex = /^\d{13}$/;
        const defaultToDateStrRegex = /^[A-Z][a-z]{2} [A-Z][a-z]{2} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[-+]\d{4} \([A-Za-z\s]+\)$/;
        // if (
        //     // iso8601Regex.test(inputString) ||
        //     // slashDateFormatRegex.test(inputString) ||
        //     // hyphenDateFormatRegex.test(inputString) ||
        //     // monthDayYearRegex.test(inputString) ||
        //     // dayMonthYearRegex.test(inputString) ||
        //     // unixTimestampRegex.test(inputString) ||
        //     // millisecondsTimestampRegex.test(inputString)
        // ) {
        //     return inputString; // In correct format YYYY-MM-DD
        if (!defaultToDateStrRegex.test(inputString)) {
            // if (!isValidDateFormat(dob)) {
            //     console.log("Bad DOB Format");
            // }
            return inputString;
        } else {
            const date = new Date(inputString);
            const offset = date.getTimezoneOffset();

            date.setMinutes(date.getMinutes() + offset);

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");

            return `${year}-${month}-${day}`;
        }
    }

    function isValidDateFormat(inputString) {
        const dateYYYYMMDDFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
        return dateYYYYMMDDFormatRegex.test(inputString);
    }

    function enroll(e) {
        e.preventDefault();
        if (!isValidDateFormat(dob)) {
            dob = transformDateString(dob);
        }
        // console.trace({
        //     variables: {
        //         patient_data: patient_data_json,
        //         provider_data: provider_data_json,
        //         patient_provider_data: patient_provider_data_json,
        //         current_enrollment_date_drug: enrollment_date_drug,
        //         current_enrollment_date_drug_admin: enrollment_date_admin,
        //         original_enrollment_date_drug: enrollment_date_drug,
        //         original_enrollment_date_drug_admin: enrollment_date_admin,
        //         enrollment_source: enrollment_source,
        //         drug_patient: drug_patient,
        //         drug_admin_patient: drug_admin_patient,
        //         request_card: request_card,
        //         original_enrollment_date: formattedDate,
        //         enrollment_date: formattedDate,
        //         first_name: first_name,
        //         last_name: last_name,
        //         date_of_birth: transformDateString(dob),
        //         gender: gender,
        //         phone: phone,
        //         phone_type: phone_type,
        //         email: email,
        //         address1: address1,
        //         address2: address2,
        //         city: city,
        //         state: state,
        //         zip_code: zip_code,
        //         enrolled_by: enrolledBy,
        //         email_opt_in_response: email_opt,
        //         lap_first_name: lap_first_name,
        //         lap_last_name: lap_last_name,
        //         lap_phone: lap_phone,
        //         lap_address1: lap_address1,
        //         lap_address2: lap_address2,
        //         lap_city: lap_city,
        //         lap_state: lap_state,
        //         lap_zip_code: lap_zip_code,
        //         mi_name: mi_name,
        //         mi_plan_type: mi_plan_type,
        //         mi_member_number: mi_member_number,
        //         mi_effective_date: mi_effective_date,
        //         mi_group_number: mi_group_number,
        //         mi_bin: mi_bin,
        //         mi_pcn: mi_pcn,
        //         rx_name: rx_name,
        //         rx_plan_type: rx_plan_type,
        //         rx_member_number: rx_member_number,
        //         rx_group_number: rx_group_number,
        //         rx_bin: rx_bin,
        //         rx_pcn: rx_pcn,
        //         drug_answer_01: drug_answer_01,
        //         drug_answer_02: drug_answer_02,
        //         drug_answer_03: drug_answer_03,
        //         drug_answer_04: drug_answer_04,
        //         drug_answer_05: drug_answer_05,
        //         drug_answer_06: drug_answer_06,
        //         drug_answer_07: drug_answer_07,
        //         drug_answer_08: drug_answer_08,
        //         drug_answer_09: drug_answer_09,
        //         drug_answer_10: drug_answer_10,
        //         admin_answer_01: admin_answer_01,
        //         admin_answer_02: admin_answer_02,
        //         admin_answer_03: admin_answer_03,
        //         admin_answer_04: admin_answer_04,
        //         admin_answer_05: admin_answer_05,
        //         admin_answer_06: admin_answer_06,
        //         admin_answer_07: admin_answer_07,
        //         admin_answer_08: admin_answer_08,
        //         admin_answer_09: admin_answer_09,
        //         admin_answer_10: admin_answer_10,
        //     },
        // });
        if (existingMember == "true") {
            existingEnrollment({
                variables: {
                    patient_id: patient_id,
                    program: program,
                    enrollment_date: formattedDate,
                    drug_answer_01: drug_answer_01,
                    drug_answer_02: drug_answer_02,
                    drug_answer_03: drug_answer_03,
                    drug_answer_04: drug_answer_04,
                    drug_answer_05: drug_answer_05,
                    drug_answer_06: drug_answer_06,
                    drug_answer_07: drug_answer_07,
                    drug_answer_08: drug_answer_08,
                    drug_answer_09: drug_answer_09,
                    drug_answer_10: drug_answer_10,
                    admin_answer_01: admin_answer_01,
                    admin_answer_02: admin_answer_02,
                    admin_answer_03: admin_answer_03,
                    admin_answer_04: admin_answer_04,
                    admin_answer_05: admin_answer_05,
                    admin_answer_06: admin_answer_06,
                    admin_answer_07: admin_answer_07,
                    admin_answer_08: admin_answer_08,
                    admin_answer_09: admin_answer_09,
                    admin_answer_10: admin_answer_10,
                },
            });
        } else {
            enrollPatient({
                variables: {
                    patient_data: patient_data_json,
                    provider_data: provider_data_json,
                    patient_provider_data: patient_provider_data_json,
                    current_enrollment_date_drug: enrollment_date_drug,
                    current_enrollment_date_drug_admin: enrollment_date_admin,
                    original_enrollment_date_drug: enrollment_date_drug,
                    original_enrollment_date_drug_admin: enrollment_date_admin,
                    enrollment_source: enrollment_source,
                    drug_patient: drug_patient,
                    drug_admin_patient: drug_admin_patient,
                    request_card: request_card,
                    original_enrollment_date: formattedDate,
                    enrollment_date: formattedDate,
                    first_name: first_name,
                    last_name: last_name,
                    date_of_birth: transformDateString(dob),
                    gender: gender,
                    phone: phone,
                    phone_type: phone_type,
                    email: email,
                    address1: address1,
                    address2: address2,
                    city: city,
                    state: state,
                    zip_code: zip_code,
                    enrolled_by: enrolledBy,
                    email_opt_in_response: email_opt,
                    lap_first_name: lap_first_name,
                    lap_last_name: lap_last_name,
                    lap_phone: lap_phone,
                    lap_address1: lap_address1,
                    lap_address2: lap_address2,
                    lap_city: lap_city,
                    lap_state: lap_state,
                    lap_zip_code: lap_zip_code,
                    mi_name: mi_name,
                    mi_plan_type: mi_plan_type,
                    mi_member_number: mi_member_number,
                    mi_effective_date: mi_effective_date,
                    mi_group_number: mi_group_number,
                    mi_bin: mi_bin,
                    mi_pcn: mi_pcn,
                    rx_name: rx_name,
                    rx_plan_type: rx_plan_type,
                    rx_member_number: rx_member_number,
                    rx_group_number: rx_group_number,
                    rx_bin: rx_bin,
                    rx_pcn: rx_pcn,
                    drug_answer_01: drug_answer_01,
                    drug_answer_02: drug_answer_02,
                    drug_answer_03: drug_answer_03,
                    drug_answer_04: drug_answer_04,
                    drug_answer_05: drug_answer_05,
                    drug_answer_06: drug_answer_06,
                    drug_answer_07: drug_answer_07,
                    drug_answer_08: drug_answer_08,
                    drug_answer_09: drug_answer_09,
                    drug_answer_10: drug_answer_10,
                    admin_answer_01: admin_answer_01,
                    admin_answer_02: admin_answer_02,
                    admin_answer_03: admin_answer_03,
                    admin_answer_04: admin_answer_04,
                    admin_answer_05: admin_answer_05,
                    admin_answer_06: admin_answer_06,
                    admin_answer_07: admin_answer_07,
                    admin_answer_08: admin_answer_08,
                    admin_answer_09: admin_answer_09,
                    admin_answer_10: admin_answer_10,
                },
            });
        }

        window.localStorage.removeItem("enrollment_type");
        window.localStorage.removeItem("provider_data");
        window.localStorage.removeItem("product");
        window.localStorage.removeItem("email_opt_in");
        window.localStorage.removeItem("site_of_care");
        window.localStorage.removeItem("eligibility_answers");
        window.localStorage.removeItem("enrollment_data");
        window.localStorage.removeItem("existing_member");
        window.localStorage.removeItem("enrolled_patient_id");
        window.localStorage.removeItem("autofillProvider");
        window.localStorage.removeItem("request_card");
        window.localStorage.removeItem("pharmacy_insurance_checked");
        window.localStorage.removeItem("medical_insurance_checked");
        const enrollmentSuccess = '{"first_name" : "' + first_name + '", "last_name": "' + last_name + '" }';
        window.localStorage.setItem("enroll_success", enrollmentSuccess);
    }

    return (
        <>
            <>
                <form action="enroll-form" className="apply-step-two-form" acceptCharset="utf-8">
                    <h1 style={{ fontSize: "1.5em", lineHeight: "1.49em" }}>Please Confirm This Information Is Correct</h1>
                    <ReviewPatientInformation />
                    <ReviewCommercialMedicalInsurance />
                    <ReviewCommercialPharmacyInsurance />
                    <RequestVirtualCardNumber />
                    <p>
                        <button type="submit" name="edit-form" value="edit">
                            Edit
                        </button>
                    </p>
                </form>
                <form action="enroll-doctor-information" className="apply-step-two-form" acceptCharset="utf-8">
                    <ReviewProviderInformation />
                    <ReviewSiteOfCareInformation />
                    <p>
                        <button type="submit" name="edit-form" value="edit">
                            Edit
                        </button>
                    </p>
                </form>
                <form onSubmit={(e) => enroll(e)} id="" className="apply-step-two-form" acceptCharset="utf-8">
                    <p></p>
                    <p className=" text phone">
                        {" "}
                        <span className="correct">
                            {" "}
                            <label className="checkbox" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <input type="checkbox" name="allcorrect" className="allcorrect" id="allcorrect" />
                                All information is correct<span className="checkmark"></span>
                            </label>
                        </span>
                    </p>
                    <p></p>
                    <p>
                        <button type="reset" onClick={goBack}>
                            Back
                        </button>{" "}
                        <button id="submit" className="no-submit" type="submit" disabled="disabled" value="confirm">
                            Confirm
                        </button>
                    </p>
                </form>
            </>
            <div className="loading" id="message">
                <div className="popup-box">
                    <img src={ajaxLoader} />
                </div>
            </div>
        </>
    );
}
