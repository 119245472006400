import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { PATIENT_INFO } from "../graphql/queries/Patients";
import $ from "jquery";

export default function PatientInfo() {
    var patient_id = parseInt(window.localStorage.getItem("xrefID"));

    const [firstName, setFirstName] = useState("");
    const [lastName, setLasttName] = useState("");
    const [memberid, setmemberid] = useState("");

    const { loading, error, data, refetch } = useQuery(PATIENT_INFO, {
        variables: { patient_id },
    });

    useEffect(() => {
        if (patient_id) {
            if (data != null) {
                console.log(data);
                if (data.patientinfo != null) {
                    if (data.patientinfo.enrollment_data != null) {
                        if (data.patientinfo.enrollment_data.first_name) {
                            setFirstName(data.patientinfo.enrollment_data.first_name);
                        }
                        if (data.patientinfo.enrollment_data.last_name) {
                            setLasttName(data.patientinfo.enrollment_data.last_name);
                        }
                    }
                    if (data.patientinfo.member_id) {
                        setmemberid(data.patientinfo.member_id);
                    }
                }
            }
        }
    }, [data]);

    return (
        <div className="section shadow-box  color-label">
            <div className="col-sm-12 col-md-12 col-lg-12">
                <h2>Patient</h2>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-4">
                <p className="field required-field text">
                    <label htmlFor="first_name">Name</label>
                    {firstName} {lastName}
                </p>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-4">
                <p className="field required-field text date">
                    <label htmlFor="birthDate">Member ID</label>
                    {memberid}
                </p>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-4">
                <p className="field required-field text date">
                    <label htmlFor="birthDate">Treatment Name</label>
                    XOLAIR
                </p>
            </div>
        </div>
    );
}
