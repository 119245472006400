/*
import React from 'react'

export default function PasswordRequest() {

	function requestPassword(e) {
		e.preventDefault()
	}

	return (
		<div className="container" id="content" style={{ fontSize: '1em' }}>
			<div id="container">
				<div id="hcp_post_content">
					<h2>Forgot My Password </h2>
					<div className="find_pass">
						<form action="password-request-success" className="form-validate" onSubmit={e => requestPassword(e)} acceptCharset="utf-8">
							<div className="col-sm-12 col-md-12 col-lg-12">
								<p style={{ marginBottom: '0' }}>Please enter your username:</p>
								<p className="field required-field text"><label className='normal' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Username<span className="required">*</span></span></label>
									<input type="text" name="" id="pass_1" autoComplete="off" />
								</p>
							</div>
							<p><br />
								<button type="submit" name="loginSubmit" autoComplete="off">Submit</button>
							</p>
						</form>
						<p>Forgot your username? Call <strong> (855) 965-2472</strong> for further assistance. </p>
					</div>
				</div>
				<div className="clearfloat"></div>
			</div>
		</div >
	)
}
*/
import React, { useState } from 'react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
export default function PasswordRequest() {

	const [username, setusername] = useState('')
	const [error, showerror] = useState(false)

	let navigate = useNavigate()

	function requestPassword(e) {
		e.preventDefault()
		if (username != '') {
			let apiPayload = JSON.stringify({
				login_id: username
			});
			const headers = { 'Content-Type': 'application/json;charset=UTF-8', "Access-Control-Allow-Origin": "*" };
			axios.post(`https://xremail.netswitchcard.com/request-reset/`, apiPayload, { headers: headers })
				.then(res => {
					console.log(res);
					console.log(res.status);
					if(res.status == '200'){
						let path = `../password-request-success`;
						navigate(path)
					} else {
						showerror(true)
					}

				})
		}

	}

	return (
		<div className="container" id="content" style={{ fontSize: '1em' }}>
			<div id="container">
				<div id="hcp_post_content">
					<h2>Forgot My Password </h2>
					<div className="find_pass">
						<form className="form-validate" onSubmit={e => requestPassword(e)} acceptCharset="utf-8">
							<div className="col-sm-12 col-md-12 col-lg-12">
								<p style={{ marginBottom: '0' }}>Please enter your username:</p>
								<p className="field required-field text"><label className='normal' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Username<span className="required">*</span></span></label>
									<input type="text" name="" id="pass_1" autoComplete="off" value={username} onChange={e => setusername(e.target.value)} />
								</p>
								{error ? <p className = 'red'>Password Request Error</p> : <></>}
							</div>
							<p><br />
								<button type="submit" name="loginSubmit" autoComplete="off">Submit</button>
							</p>
						</form>
						<p>Forgot your username? Call <strong> (855) 965-2472</strong> for further assistance. </p>
					</div>
				</div>
				<div className="clearfloat"></div>
			</div>
		</div >
	)
}