import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { MEMBERSHIP_INFORMATION, PATIENT_ENROLLMENT_DETAILS } from "../graphql/queries/Patients";

export default function PatientProfileDetails(props) {
    var patient_id = parseInt(window.localStorage.getItem("xrefID"));
    if (props.patientID) {
        patient_id = parseInt(props.patientID);
    }

    const [patientID, setPatientID] = useState("");

    const [bin, setbin] = useState("");
    const [pcn, setpcn] = useState("");
    const [group, setgroup] = useState("");
    const [memberid, setmemberid] = useState("");

    const { loading, error, data, refetch } = useQuery(MEMBERSHIP_INFORMATION, {
        variables: { patient_id },
    });

    const {
        loading: detailLoading,
        error: detailError,
        data: detailData,
        refetch: detailRefetch,
    } = useQuery(PATIENT_ENROLLMENT_DETAILS, {
        variables: { patient_id },
    });

    useEffect(() => {
        if (patient_id) {
            if (data) {
                if (data.patientinfo != null) {
                    if (data.patientinfo.verification_data != null) {
                        const jsondata = JSON.parse(data.patientinfo.verification_data);
                        if (jsondata.response != null) {
                            if (jsondata.response.CardResponseInfo != null) {
                                if (jsondata.response.CardResponseInfo.Bin) {
                                    setbin(jsondata.response.CardResponseInfo.Bin);
                                }
                                if (jsondata.response.CardResponseInfo.Pcn) {
                                    setpcn(jsondata.response.CardResponseInfo.Pcn);
                                }
                                if (jsondata.response.CardResponseInfo.Group) {
                                    setgroup(jsondata.response.CardResponseInfo.Group);
                                }
                            }
                        }
                    } else {
                        setbin("610020");
                        setpcn("PDMI");
                        setgroup("99995223");
                    }
                    if (data.patientinfo.member_id != null) {
                        setmemberid(data.patientinfo.member_id);
                    }
                }
            } else {
            }
        } else {
        }
    }, [data]);

    useEffect(() => {
        // console.log(detailLoading);

        if (
            bin.toString().length < 1 &&
            pcn.toString().length < 1 &&
            group.toString().length < 1 &&
            patient_id &&
            detailData?.patientinfo?.drug_patient.toString().toUpperCase() === "Y"
        ) {
            // detailData;

            if (
                detailData &&
                detailData?.patientinfo &&
                detailData?.patientinfo?.enrollment_status &&
                typeof detailData?.patientinfo?.enrollment_status === "string" &&
                detailData?.patientinfo?.enrollment_status?.toUpperCase() === "ACTIVE"
            ) {
                setbin("610020");
                setpcn("PDMI");
                setgroup("99995223");
            }
        }
    }, [detailData, detailLoading]);

    return (
        <div className="section shadow-box color-label">
            <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>Membership Information</h2>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            BIN:
                        </label>
                        {bin}{" "}
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            PCN:
                        </label>
                        {pcn}{" "}
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Group:
                        </label>
                        {group}{" "}
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Member ID:
                        </label>
                        {memberid}{" "}
                    </p>
                </div>
            </div>
            {/* {props.showMessage == "true" ? <Message /> : <></>} */}
        </div>
    );
}

function Message() {
    return (
        <p>
            <small>
                {/* For questions regarding specialty pharmacy claims processing, please call the pharmacy claims processor Change Healthcare
                help desk at 1-800-433-4893. */}
            </small>
        </p>
    );
}
