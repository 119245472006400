import React, { useEffect, useState } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { useLazyQuery, gql } from "@apollo/client";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.min.css";
import calBtn from "../images/calbtn.gif";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/date-styling.css";
import { func } from "prop-types";
import { FIND_ENROLLED_PATIENT, FIND_ENROLLED_PATIENT_BY_MEMBERID } from "../graphql/queries/Patients";

export default function EnrollAttestationPatient() {
    const { setTitle, setStep, enrollment_data, provider_data, site_of_care, product, setproduct, setenrollmentdata } = useOutletContext();
    const [eligibility_path, seteligibilitypath] = useState("");

    const [member_id, setmemberid] = useState("");
    const [dob1, setdob1] = useState("");

    const [first_name, setfirstname] = useState("");
    const [last_name, setlastname] = useState("");
    const [dob2, setdob2] = useState("");
    const [zip_code, setzip] = useState("");

    var enrolledProduct;

    const enrollmentType = window.localStorage.getItem("enrollment_type") ? window.localStorage.getItem("enrollment_type") : "";

    useEffect(() => {
        if (enrollmentType == "") {
            navigate("/enroll");
        }
    }, [enrollmentType]);

    const [searchByMemberID, { called: called1, loading: loading1, data: patientData1 }] = useLazyQuery(FIND_ENROLLED_PATIENT_BY_MEMBERID, {
        variables: { member_id: member_id, dob: dob1 },
    });
    const [searchByMemberInfo, { called: called2, loading: loading2, data: patientData2 }] = useLazyQuery(FIND_ENROLLED_PATIENT, {
        variables: {
            first_name: first_name,
            last_name: last_name,
            zip_code: zip_code,
            dob: dob2,
        },
    });

    let navigate = useNavigate();

    useEffect(() => {
        if (patientData1 != null) {
            if (patientData1.patientfindenrolled != null) {
                window.localStorage.setItem("existing_member", "true");
                if (patientData1.patientfindenrolled.patient_id) {
                    window.localStorage.setItem("enrolled_patient_id", patientData1.patientfindenrolled.patient_id);
                }
                if (patientData1.patientfindenrolled.drug_patient != null && patientData1.patientfindenrolled.drug_admin_patient != null) {
                    if (
                        (patientData1.patientfindenrolled.drug_patient == "Y" || patientData1.patientfindenrolled.drug_patient == "y") &&
                        (patientData1.patientfindenrolled.drug_admin_patient == "Y" || patientData1.patientfindenrolled.drug_admin_patient == "y")
                    ) {
                        enrolledProduct = "drug-admin";
                    } else if (patientData1.patientfindenrolled.drug_patient == "Y" || patientData1.patientfindenrolled.drug_patient == "y") {
                        enrolledProduct = "drug";
                    } else if (patientData1.patientfindenrolled.drug_admin_patient == "Y" || patientData1.patientfindenrolled.drug_admin_patient == "y") {
                        enrolledProduct = "admin";
                    }
                }
                if (patientData1.patientfindenrolled.enrollment_data != null) {
                    if (patientData1.patientfindenrolled.enrollment_data.first_name) {
                        enrollment_data.first_name = patientData1.patientfindenrolled.enrollment_data.first_name;
                    }
                    if (patientData1.patientfindenrolled.enrollment_data.last_name) {
                        enrollment_data.last_name = patientData1.patientfindenrolled.enrollment_data.last_name;
                    }
                    if (patientData1.patientfindenrolled.enrollment_data.gender) {
                        if (patientData1.patientfindenrolled.enrollment_data.gender == "M") {
                            enrollment_data.gender = "Male";
                        } else if (patientData1.patientfindenrolled.enrollment_data.gender == "F") {
                            enrollment_data.gender = "Female";
                        } else {
                            enrollment_data.gender = patientData1.patientfindenrolled.enrollment_data.gender;
                        }
                    }
                    if (patientData1.patientfindenrolled.enrollment_data.date_of_birth) {
                        enrollment_data.dob = patientData1.patientfindenrolled.enrollment_data.date_of_birth;
                    }
                    if (patientData1.patientfindenrolled.enrollment_data.address) {
                        if (patientData1.patientfindenrolled.enrollment_data.address.address_1) {
                            enrollment_data.address.address1 = patientData1.patientfindenrolled.enrollment_data.address.address_1;
                        }
                        if (patientData1.patientfindenrolled.enrollment_data.address.address_2) {
                            enrollment_data.address.address2 = patientData1.patientfindenrolled.enrollment_data.address.address_2;
                        }
                        if (patientData1.patientfindenrolled.enrollment_data.address.city) {
                            enrollment_data.address.city = patientData1.patientfindenrolled.enrollment_data.address.city;
                        }
                        if (patientData1.patientfindenrolled.enrollment_data.address.state) {
                            enrollment_data.address.state = patientData1.patientfindenrolled.enrollment_data.address.state;
                        }
                        if (patientData1.patientfindenrolled.enrollment_data.address.zip_code) {
                            enrollment_data.address.zip_code = patientData1.patientfindenrolled.enrollment_data.address.zip_code;
                        }
                    }
                    if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance != null) {
                        if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance != null) {
                            if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.name) {
                                enrollment_data.insurance.medical_insurance.name =
                                    patientData1.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.name;
                            }
                            if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.plan_type) {
                                enrollment_data.insurance.medical_insurance.plan_type =
                                    patientData1.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.plan_type;
                            }
                            if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.member_number) {
                                enrollment_data.insurance.medical_insurance.member_number =
                                    patientData1.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.member_number;
                            }
                            if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.group_number) {
                                enrollment_data.insurance.medical_insurance.group_number =
                                    patientData1.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.group_number;
                            }
                            if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.bin) {
                                enrollment_data.insurance.medical_insurance.bin =
                                    patientData1.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.bin;
                            }
                            if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.pcn) {
                                enrollment_data.insurance.medical_insurance.pcn =
                                    patientData1.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.pcn;
                            }
                        }
                        if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance != null) {
                            if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.name) {
                                enrollment_data.insurance.rx_insurance.name =
                                    patientData1.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.name;
                            }
                            if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.plan_type) {
                                enrollment_data.insurance.rx_insurance.plan_type =
                                    patientData1.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.plan_type;
                            }
                            if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.member_number) {
                                enrollment_data.insurance.rx_insurance.member_number =
                                    patientData1.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.member_number;
                            }
                            if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.group_number) {
                                enrollment_data.insurance.rx_insurance.group_number =
                                    patientData1.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.group_number;
                            }
                            if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.bin) {
                                enrollment_data.insurance.rx_insurance.bin =
                                    patientData1.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.bin;
                            }
                            if (patientData1.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.pcn) {
                                enrollment_data.insurance.rx_insurance.pcn =
                                    patientData1.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.pcn;
                            }
                        }
                    }
                    if (patientData1.patientfindenrolled.enrollment_data.lap != null) {
                        if (patientData1.patientfindenrolled.enrollment_data.lap.first_name) {
                            enrollment_data.lap.first_name = patientData1.patientfindenrolled.enrollment_data.lap.first_name;
                        }
                        if (patientData1.patientfindenrolled.enrollment_data.lap.last_name) {
                            enrollment_data.lap.last_name = patientData1.patientfindenrolled.enrollment_data.lap.last_name;
                        }
                        if (patientData1.patientfindenrolled.enrollment_data.lap.address != null) {
                            if (patientData1.patientfindenrolled.enrollment_data.lap.address.address_1) {
                                enrollment_data.lap.address.address1 = patientData1.patientfindenrolled.enrollment_data.lap.address.address_1;
                            }
                            if (patientData1.patientfindenrolled.enrollment_data.lap.address.address_2) {
                                enrollment_data.lap.address.address2 = patientData1.patientfindenrolled.enrollment_data.lap.address.address_2;
                            }
                            if (patientData1.patientfindenrolled.enrollment_data.lap.address.city) {
                                enrollment_data.lap.address.city = patientData1.patientfindenrolled.enrollment_data.lap.address.city;
                            }
                            if (patientData1.patientfindenrolled.enrollment_data.lap.address.state) {
                                enrollment_data.lap.address.state = patientData1.patientfindenrolled.enrollment_data.lap.address.state;
                            }
                            if (patientData1.patientfindenrolled.enrollment_data.lap.address.zip_code) {
                                enrollment_data.lap.address.zip_code = patientData1.patientfindenrolled.enrollment_data.lap.address.zip_code;
                            }
                        }
                    }
                }
                if (patientData1.patientfindenrolled.patient_provider_data != null) {
                    if (patientData1.patientfindenrolled.patient_provider_data.primary != null) {
                        if (patientData1.patientfindenrolled.patient_provider_data.primary != null) {
                            if (patientData1.patientfindenrolled.patient_provider_data.primary.first_name) {
                                provider_data.first_name = patientData1.patientfindenrolled.patient_provider_data.primary.first_name;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.primary.last_name) {
                                provider_data.last_name = patientData1.patientfindenrolled.patient_provider_data.primary.last_name;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.primary.address != null) {
                                if (patientData1.patientfindenrolled.patient_provider_data.primary.address.address_1) {
                                    provider_data.address.address1 = patientData1.patientfindenrolled.patient_provider_data.primary.address.address_1;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.primary.address.address_2) {
                                    provider_data.address.address2 = patientData1.patientfindenrolled.patient_provider_data.primary.address.address_2;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.primary.address.city) {
                                    provider_data.address.city = patientData1.patientfindenrolled.patient_provider_data.primary.address.city;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.primary.address.state) {
                                    provider_data.address.state = patientData1.patientfindenrolled.patient_provider_data.primary.address.state;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.primary.address.zip_code) {
                                    provider_data.address.zip_code = patientData1.patientfindenrolled.patient_provider_data.primary.address.zip_code;
                                }
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.primary.phone_number) {
                                provider_data.phones.phone = patientData1.patientfindenrolled.patient_provider_data.primary.phone_number;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.primary.npi) {
                                provider_data.npi = patientData1.patientfindenrolled.patient_provider_data.primary.npi;
                            }
                        }
                        if (patientData1.patientfindenrolled.patient_provider_data.primary[0] != null) {
                            if (patientData1.patientfindenrolled.patient_provider_data.primary[0].first_name) {
                                provider_data.first_name = patientData1.patientfindenrolled.patient_provider_data.primary[0].first_name;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.primary[0].last_name) {
                                provider_data.last_name = patientData1.patientfindenrolled.patient_provider_data.primary[0].last_name;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.primary[0].address != null) {
                                if (patientData1.patientfindenrolled.patient_provider_data.primary[0].address.address_1) {
                                    provider_data.address.address1 = patientData1.patientfindenrolled.patient_provider_data.primary[0].address.address_1;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.primary[0].address.address_2) {
                                    provider_data.address.address2 = patientData1.patientfindenrolled.patient_provider_data.primary[0].address.address_2;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.primary[0].address.city) {
                                    provider_data.address.city = patientData1.patientfindenrolled.patient_provider_data.primary[0].address.city;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.primary[0].address.state) {
                                    provider_data.address.state = patientData1.patientfindenrolled.patient_provider_data.primary[0].address.state;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.primary[0].address.zip_code) {
                                    provider_data.address.zip_code = patientData1.patientfindenrolled.patient_provider_data.primary[0].address.zip_code;
                                }
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.primary[0].phone_number) {
                                provider_data.phones.phone = patientData1.patientfindenrolled.patient_provider_data.primary[0].phone_number;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.primary[0].npi) {
                                provider_data.npi = patientData1.patientfindenrolled.patient_provider_data.primary[0].npi;
                            }
                        }
                    }
                    if (patientData1.patientfindenrolled.patient_provider_data.secondary != null) {
                        if (patientData1.patientfindenrolled.patient_provider_data.secondary != null) {
                            if (patientData1.patientfindenrolled.patient_provider_data.secondary.practice_name) {
                                site_of_care.name = patientData1.patientfindenrolled.patient_provider_data.secondary.practice_name;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.secondary.address != null) {
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary.address.address_1) {
                                    site_of_care.address.address1 = patientData1.patientfindenrolled.patient_provider_data.secondary.address.address_1;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary.address.address_2) {
                                    site_of_care.address.address2 = patientData1.patientfindenrolled.patient_provider_data.secondary.address.address_2;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary.address.city) {
                                    site_of_care.address.city = patientData1.patientfindenrolled.patient_provider_data.secondary.address.city;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary.address.state) {
                                    site_of_care.address.state = patientData1.patientfindenrolled.patient_provider_data.secondary.address.state;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary.address.zip_code) {
                                    site_of_care.address.zip_code = patientData1.patientfindenrolled.patient_provider_data.secondary.address.zip_code;
                                }
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.secondary.phone_number) {
                                site_of_care.phone_number = patientData1.patientfindenrolled.patient_provider_data.secondary.phone_number;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.secondary.npi) {
                                site_of_care.npi = patientData1.patientfindenrolled.patient_provider_data.secondary.npi;
                            }
                        }
                        if (patientData1.patientfindenrolled.patient_provider_data.secondary[0] != null) {
                            if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].practice_name) {
                                site_of_care.name = patientData1.patientfindenrolled.patient_provider_data.secondary[0].practice_name;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].address != null) {
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.address_1) {
                                    site_of_care.address.address1 = patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.address_1;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.address_2) {
                                    site_of_care.address.address2 = patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.address_2;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.city) {
                                    site_of_care.address.city = patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.city;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.state) {
                                    site_of_care.address.state = patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.state;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.zip_code) {
                                    site_of_care.address.zip_code = patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.zip_code;
                                }
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].phone_number) {
                                site_of_care.phone_number = patientData1.patientfindenrolled.patient_provider_data.secondary[0].phone_number;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].npi) {
                                site_of_care.npi = patientData1.patientfindenrolled.patient_provider_data.secondary[0].npi;
                            }
                        }
                    }
                }
                window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));
                window.localStorage.setItem("provider_data", JSON.stringify(provider_data));
                window.localStorage.setItem("site_of_care", JSON.stringify(site_of_care));
                window.localStorage.setItem("product", enrolledProduct);
                let path = "/enroll-additional-" + enrollmentType;
                navigate(path);
            } else {
                let path = "/enroll";
                navigate(path);
            }
        }
    }, [patientData1]);

    useEffect(() => {
        if (patientData2 != null) {
            if (patientData2.patientfindenrolled != null) {
                window.localStorage.setItem("existing_member", "true");
                if (patientData2.patientfindenrolled.patient_id) {
                    window.localStorage.setItem("enrolled_patient_id", patientData2.patientfindenrolled.patient_id);
                }
                if (patientData2.patientfindenrolled.drug_patient != null && patientData2.patientfindenrolled.drug_admin_patient != null) {
                    if (
                        (patientData2.patientfindenrolled.drug_patient == "Y" || patientData2.patientfindenrolled.drug_patient == "y") &&
                        (patientData2.patientfindenrolled.drug_admin_patient == "Y" || patientData2.patientfindenrolled.drug_admin_patient == "y")
                    ) {
                        enrolledProduct = "drug-admin";
                    } else if (patientData2.patientfindenrolled.drug_patient == "Y" || patientData2.patientfindenrolled.drug_patient == "y") {
                        enrolledProduct = "drug";
                    } else if (patientData2.patientfindenrolled.drug_admin_patient == "Y" || patientData2.patientfindenrolled.drug_admin_patient == "y") {
                        enrolledProduct = "admin";
                    }
                }
                if (patientData2.patientfindenrolled.enrollment_data != null) {
                    if (patientData2.patientfindenrolled.enrollment_data.first_name) {
                        enrollment_data.first_name = patientData2.patientfindenrolled.enrollment_data.first_name;
                    }
                    if (patientData2.patientfindenrolled.enrollment_data.last_name) {
                        enrollment_data.last_name = patientData2.patientfindenrolled.enrollment_data.last_name;
                    }
                    if (patientData2.patientfindenrolled.enrollment_data.gender) {
                        enrollment_data.gender = patientData2.patientfindenrolled.enrollment_data.gender;
                    }
                    if (patientData2.patientfindenrolled.enrollment_data.date_of_birth) {
                        enrollment_data.dob = patientData2.patientfindenrolled.enrollment_data.date_of_birth;
                    }
                    if (patientData2.patientfindenrolled.enrollment_data.address) {
                        if (patientData2.patientfindenrolled.enrollment_data.address.address_1) {
                            enrollment_data.address.address1 = patientData2.patientfindenrolled.enrollment_data.address.address_1;
                        }
                        if (patientData2.patientfindenrolled.enrollment_data.address.address_2) {
                            enrollment_data.address.address2 = patientData2.patientfindenrolled.enrollment_data.address.address_2;
                        }
                        if (patientData2.patientfindenrolled.enrollment_data.address.city) {
                            enrollment_data.address.city = patientData2.patientfindenrolled.enrollment_data.address.city;
                        }
                        if (patientData2.patientfindenrolled.enrollment_data.address.state) {
                            enrollment_data.address.state = patientData2.patientfindenrolled.enrollment_data.address.state;
                        }
                        if (patientData2.patientfindenrolled.enrollment_data.address.zip_code) {
                            enrollment_data.address.zip_code = patientData2.patientfindenrolled.enrollment_data.address.zip_code;
                        }
                    }
                    if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance != null) {
                        if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance != null) {
                            if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.name) {
                                enrollment_data.insurance.medical_insurance.name =
                                    patientData2.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.name;
                            }
                            if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.plan_type) {
                                enrollment_data.insurance.medical_insurance.plan_type =
                                    patientData2.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.plan_type;
                            }
                            if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.member_number) {
                                enrollment_data.insurance.medical_insurance.member_number =
                                    patientData2.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.member_number;
                            }
                            if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.group_number) {
                                enrollment_data.insurance.medical_insurance.group_number =
                                    patientData2.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.group_number;
                            }
                            if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.bin) {
                                enrollment_data.insurance.medical_insurance.bin =
                                    patientData2.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.bin;
                            }
                            if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.pcn) {
                                enrollment_data.insurance.medical_insurance.pcn =
                                    patientData2.patientfindenrolled.enrollment_data.commercial_insurance.medical_insurance.pcn;
                            }
                        }
                        if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance != null) {
                            if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.name) {
                                enrollment_data.insurance.rx_insurance.name =
                                    patientData2.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.name;
                            }
                            if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.plan_type) {
                                enrollment_data.insurance.rx_insurance.plan_type =
                                    patientData2.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.plan_type;
                            }
                            if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.member_number) {
                                enrollment_data.insurance.rx_insurance.member_number =
                                    patientData2.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.member_number;
                            }
                            if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.group_number) {
                                enrollment_data.insurance.rx_insurance.group_number =
                                    patientData2.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.group_number;
                            }
                            if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.bin) {
                                enrollment_data.insurance.rx_insurance.bin =
                                    patientData2.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.bin;
                            }
                            if (patientData2.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.pcn) {
                                enrollment_data.insurance.rx_insurance.pcn =
                                    patientData2.patientfindenrolled.enrollment_data.commercial_insurance.rx_insurance.pcn;
                            }
                        }
                    }
                    if (patientData2.patientfindenrolled.enrollment_data.lap != null) {
                        if (patientData2.patientfindenrolled.enrollment_data.lap.first_name) {
                            enrollment_data.lap.first_name = patientData2.patientfindenrolled.enrollment_data.lap.first_name;
                        }
                        if (patientData2.patientfindenrolled.enrollment_data.lap.last_name) {
                            enrollment_data.lap.last_name = patientData2.patientfindenrolled.enrollment_data.lap.last_name;
                        }
                        if (patientData2.patientfindenrolled.enrollment_data.lap.address != null) {
                            if (patientData2.patientfindenrolled.enrollment_data.lap.address.address_1) {
                                enrollment_data.lap.address.address1 = patientData2.patientfindenrolled.enrollment_data.lap.address.address_1;
                            }
                            if (patientData2.patientfindenrolled.enrollment_data.lap.address.address_2) {
                                enrollment_data.lap.address.address2 = patientData2.patientfindenrolled.enrollment_data.lap.address.address_2;
                            }
                            if (patientData2.patientfindenrolled.enrollment_data.lap.address.city) {
                                enrollment_data.lap.address.city = patientData2.patientfindenrolled.enrollment_data.lap.address.city;
                            }
                            if (patientData2.patientfindenrolled.enrollment_data.lap.address.state) {
                                enrollment_data.lap.address.state = patientData2.patientfindenrolled.enrollment_data.lap.address.state;
                            }
                            if (patientData2.patientfindenrolled.enrollment_data.lap.address.zip_code) {
                                enrollment_data.lap.address.zip_code = patientData2.patientfindenrolled.enrollment_data.lap.address.zip_code;
                            }
                        }
                    }
                }
                if (patientData2.patientfindenrolled.patient_provider_data != null) {
                    if (patientData2.patientfindenrolled.patient_provider_data.primary != null) {
                        if (patientData2.patientfindenrolled.patient_provider_data.primary != null) {
                            if (patientData2.patientfindenrolled.patient_provider_data.primary.first_name) {
                                provider_data.first_name = patientData2.patientfindenrolled.patient_provider_data.primary.first_name;
                            }
                            if (patientData2.patientfindenrolled.patient_provider_data.primary.last_name) {
                                provider_data.last_name = patientData2.patientfindenrolled.patient_provider_data.primary.last_name;
                            }
                            if (patientData2.patientfindenrolled.patient_provider_data.primary.address != null) {
                                if (patientData2.patientfindenrolled.patient_provider_data.primary.address.address_1) {
                                    provider_data.address.address1 = patientData2.patientfindenrolled.patient_provider_data.primary.address.address_1;
                                }
                                if (patientData2.patientfindenrolled.patient_provider_data.primary.address.address_2) {
                                    provider_data.address.address2 = patientData2.patientfindenrolled.patient_provider_data.primary.address.address_2;
                                }
                                if (patientData2.patientfindenrolled.patient_provider_data.primary.address.city) {
                                    provider_data.address.city = patientData2.patientfindenrolled.patient_provider_data.primary.address.city;
                                }
                                if (patientData2.patientfindenrolled.patient_provider_data.primary.address.state) {
                                    provider_data.address.state = patientData2.patientfindenrolled.patient_provider_data.primary.address.state;
                                }
                                if (patientData2.patientfindenrolled.patient_provider_data.primary.address.zip_code) {
                                    provider_data.address.zip_code = patientData2.patientfindenrolled.patient_provider_data.primary.address.zip_code;
                                }
                            }
                            if (patientData2.patientfindenrolled.patient_provider_data.primary.phone_number) {
                                provider_data.phones.phone = patientData2.patientfindenrolled.patient_provider_data.primary.phone_number;
                            }
                            if (patientData2.patientfindenrolled.patient_provider_data.primary.npi) {
                                provider_data.npi = patientData2.patientfindenrolled.patient_provider_data.primary.npi;
                            }
                        }
                        if (patientData1.patientfindenrolled.patient_provider_data.primary[0] != null) {
                            if (patientData1.patientfindenrolled.patient_provider_data.primary[0].first_name) {
                                provider_data.first_name = patientData1.patientfindenrolled.patient_provider_data.primary[0].first_name;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.primary[0].last_name) {
                                provider_data.last_name = patientData1.patientfindenrolled.patient_provider_data.primary[0].last_name;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.primary[0].address != null) {
                                if (patientData1.patientfindenrolled.patient_provider_data.primary[0].address.address_1) {
                                    provider_data.address.address1 = patientData1.patientfindenrolled.patient_provider_data.primary[0].address.address_1;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.primary[0].address.address_2) {
                                    provider_data.address.address2 = patientData1.patientfindenrolled.patient_provider_data.primary[0].address.address_2;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.primary[0].address.city) {
                                    provider_data.address.city = patientData1.patientfindenrolled.patient_provider_data.primary[0].address.city;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.primary[0].address.state) {
                                    provider_data.address.state = patientData1.patientfindenrolled.patient_provider_data.primary[0].address.state;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.primary[0].address.zip_code) {
                                    provider_data.address.zip_code = patientData1.patientfindenrolled.patient_provider_data.primary[0].address.zip_code;
                                }
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.primary[0].phone_number) {
                                provider_data.phones.phone = patientData1.patientfindenrolled.patient_provider_data.primary[0].phone_number;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.primary[0].npi) {
                                provider_data.npi = patientData1.patientfindenrolled.patient_provider_data.primary[0].npi;
                            }
                        }
                    }
                    if (patientData2.patientfindenrolled.patient_provider_data.secondary != null) {
                        if (patientData2.patientfindenrolled.patient_provider_data.secondary != null) {
                            if (patientData2.patientfindenrolled.patient_provider_data.secondary.practice_name) {
                                site_of_care.name = patientData2.patientfindenrolled.patient_provider_data.secondary.practice_name;
                            }
                            if (patientData2.patientfindenrolled.patient_provider_data.secondary.address != null) {
                                if (patientData2.patientfindenrolled.patient_provider_data.secondary.address.address_1) {
                                    site_of_care.address.address1 = patientData2.patientfindenrolled.patient_provider_data.secondary.address.address_1;
                                }
                                if (patientData2.patientfindenrolled.patient_provider_data.secondary.address.address_2) {
                                    site_of_care.address.address2 = patientData2.patientfindenrolled.patient_provider_data.secondary.address.address_2;
                                }
                                if (patientData2.patientfindenrolled.patient_provider_data.secondary.address.city) {
                                    site_of_care.address.city = patientData2.patientfindenrolled.patient_provider_data.secondary.address.city;
                                }
                                if (patientData2.patientfindenrolled.patient_provider_data.secondary.address.state) {
                                    site_of_care.address.state = patientData2.patientfindenrolled.patient_provider_data.secondary.address.state;
                                }
                                if (patientData2.patientfindenrolled.patient_provider_data.secondary.address.zip_code) {
                                    site_of_care.address.zip_code = patientData2.patientfindenrolled.patient_provider_data.secondary.address.zip_code;
                                }
                            }
                            if (patientData2.patientfindenrolled.patient_provider_data.secondary.phone_number) {
                                site_of_care.phone_number = patientData2.patientfindenrolled.patient_provider_data.secondary.phone_number;
                            }
                            if (patientData2.patientfindenrolled.patient_provider_data.secondary.npi) {
                                site_of_care.npi = patientData2.patientfindenrolled.patient_provider_data.secondary.npi;
                            }
                        }
                        if (patientData1.patientfindenrolled.patient_provider_data.secondary[0] != null) {
                            if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].practice_name) {
                                site_of_care.name = patientData1.patientfindenrolled.patient_provider_data.secondary[0].practice_name;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].address != null) {
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.address_1) {
                                    site_of_care.address.address1 = patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.address_1;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.address_2) {
                                    site_of_care.address.address2 = patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.address_2;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.city) {
                                    site_of_care.address.city = patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.city;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.state) {
                                    site_of_care.address.state = patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.state;
                                }
                                if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.zip_code) {
                                    site_of_care.address.zip_code = patientData1.patientfindenrolled.patient_provider_data.secondary[0].address.zip_code;
                                }
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].phone_number) {
                                site_of_care.phone_number = patientData1.patientfindenrolled.patient_provider_data.secondary[0].phone_number;
                            }
                            if (patientData1.patientfindenrolled.patient_provider_data.secondary[0].npi) {
                                site_of_care.npi = patientData1.patientfindenrolled.patient_provider_data.secondary[0].npi;
                            }
                        }
                    }
                }
                window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));
                window.localStorage.setItem("provider_data", JSON.stringify(provider_data));
                window.localStorage.setItem("product", enrolledProduct);
                let path = "/enroll-additional-" + enrollmentType;
                navigate(path);
            } else {
                let path = "/enroll";
                navigate(path);
            }
        }
    }, [patientData2]);

    $("input[name=dob1]").on("change", function () {
        var date = $("#datepicker1").val();
        var month = date.substring(0, 2);
        var day = date.substring(3, 5);
        var year = date.substring(6, 10);
        if (date != "") {
            setdob1(year + "-" + month + "-" + day);
        }
    });
    $("input[name=dob2]").on("change", function () {
        var date = $("#datepicker2").val();
        var month = date.substring(0, 2);
        var day = date.substring(3, 5);
        var year = date.substring(6, 10);
        if (date != "") {
            setdob2(year + "-" + month + "-" + day);
        }
    });

    useEffect(() => {
        $(document).ready(function () {
            $("#enroll-yes").click(function () {
                if ($(this).is(":checked")) {
                    $(".enroll-hidden").css("display", "block");
                    $(".no-box").css("display", "none");
                }
            });
            $("#enroll-no").click(function () {
                if ($(this).is(":checked")) {
                    $("form.enroll").attr("action", "enroll-options-patient");
                    $("form.enroll").submit();
                    $(".enroll-hidden").css("display", "none");
                }
            });
            $("#enroll-no-yet").click(function () {
                if ($(this).is(":checked")) {
                    $(".enroll-hidden").css("display", "none");
                    $(".no-box").css("display", "block");
                }
            });
            $(".showCard").click(function (event) {
                event.preventDefault();
                $(".cardImg").css("display", "block");
            });
            $(".cardImg .close-button").click(function (event) {
                event.preventDefault();
                $(".cardImg").css("display", "none");
            });
        });
        $(document).ready(function () {
            $("input[type='radio']").change(function (event) {
                var option = $('input[name="option"]:checked').val();
                // if ($("input.option1").is(":checked")) {
                if (option == "drug") {
                    $("form.enroll").attr("action", "eligibility-" + enrollmentType + "-drug-only");
                    seteligibilitypath("../eligibility-" + enrollmentType + "-drug-only");
                    setproduct("drug");
                }
                // if ($("input.option2").is(":checked")) {
                if (option == "infusion") {
                    $("form.enroll").attr("action", "eligibility-" + enrollmentType + "-infusion-only");
                    seteligibilitypath("../eligibility-" + enrollmentType + "-infusion-only");
                    setproduct("admin");
                }
                // if ($("input.option1").is(":checked") && $("input.option2").is(":checked")) {
                // if ($("input.option3").is(":checked")) {
                if (option == "both") {
                    $("form.enroll").attr("action", "eligibility-" + enrollmentType + "-drug-infusion");
                    seteligibilitypath("../eligibility-" + enrollmentType + "-drug-infusion");
                    setproduct("drug-admin");
                }
            });
        });
        $(document).ready(function () {
            $("#datepicker1").datepicker({
                dateFormat: "mm/dd/yy",
                showButtonPanel: true,
                changeMonth: true,
                changeYear: true,
                yearRange: "1920:+nn",
                showOtherMonths: true,
                selectOtherMonths: true,
            });
            $("#datepicker2").datepicker({
                dateFormat: "mm/dd/yy",
                showButtonPanel: true,
                changeMonth: true,
                changeYear: true,
                yearRange: "1920:+nn",
                showOtherMonths: true,
                selectOtherMonths: true,
            });
            $(".mandatory1 input").keypress(function (event) {
                $(".mandatory1").removeClass("field text");
                $(".mandatory1").addClass("field required-field text");
                $(".mandatory").removeClass("field required-field text");
                $(".mandatory").addClass("field text");
            });
            $(".mandatory input").keypress(function (event) {
                $(".mandatory").removeClass("field text");
                $(".mandatory").addClass("field required-field text");
                $(".mandatory1").removeClass("field required-field text");
                $(".mandatory1").addClass("field text");
            });
            $(".mandatory1 input").keydown(function (event) {
                $(".mandatory1").removeClass("field text");
                $(".mandatory1").addClass("field required-field text");
                $(".mandatory").removeClass("field required-field text");
                $(".mandatory").addClass("field text");
            });
            $(".mandatory input").keydown(function (event) {
                $(".mandatory").removeClass("field text");
                $(".mandatory").addClass("field required-field text");
                $(".mandatory1").removeClass("field required-field text");
                $(".mandatory1").addClass("field text");
            });
        });

        //from form-validations.js
        $(".enroll").submit(function (event) {
            $(".error").empty();
            var errorField = '<p><span class="prompt-error error">Please select at least one option below.</span></p>';
            $(".prompt-error").remove();

            var valid = true;
            var radios = $(".no-box");
            // $(".error").append("");
            radios.each(function (index) {
                var obj = $(this);

                var checked = $("input:checked", obj);
                //alert(checked.length);
                if (checked.length > 0) {
                    return;
                }
                // console.log($(".error").val());
                $(".error").append(errorField);
                valid = false;
                // }
            });

            //alert(valid);
            if (!valid) event.preventDefault();
        });
        setTitle("Apply for the XOLAIR Co-pay Program");
        setStep("2");
    });

    function searchPatient(e) {
        e.preventDefault();

        if (!(member_id == "" || dob1 == "")) {
            searchByMemberID({ member_id: member_id, dob: dob1 });
        } else if (!(first_name == "" || last_name == "" || zip_code == "" || dob2 == "")) {
            searchByMemberInfo({
                first_name: first_name,
                last_name: last_name,
                zip_code: zip_code,
                dob: dob2,
            });
        } else {
        }
    }

    function goBack() {
        navigate(-1);
    }

    function next(e) {
        e.preventDefault();
        window.localStorage.setItem("product", product);
        navigate(eligibility_path);
    }

    return (
        <>
            <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>
                Have you already enrolled in the XOLAIR Co-pay Program for drug assistance, administration assistance or both?
            </h2>
            <form onSubmit={(e) => next(e)} className="enroll" acceptCharset="utf-8">
                <p>
                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                        <input id="enroll-yes" name="enroll-attestation" type="radio" /> Yes
                        <span className="checkmark"></span>
                    </label>
                </p>
                <p>
                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                        <input id="enroll-no-yet" name="enroll-attestation" type="radio" /> No<span className="checkmark"></span>
                    </label>
                </p>
                <div className="no-box" style={{ display: "none" }}>
                    <p>Please select the type(s) of assistance you are applying for:</p>
                    <div className="error"></div>
                    <p>
                        <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            <input type="radio" className="option3" name="option" value="both" /> Apply for help with XOLAIR drug and administration
                            out-of-pocket costs.
                            <span class="checkmark"></span>
                        </label>
                    </p>
                    <p>
                        <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            <input type="radio" className="option1" name="option" value="drug" /> Apply for help with XOLAIR drug out-of-pocket costs.
                            <span className="checkmark "></span>
                        </label>
                    </p>
                    <p>
                        <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            <input type="radio" className="option2" name="option" value="infusion" /> Apply for help with XOLAIR administration out-of-pocket
                            costs.
                            <span className="checkmark "></span>
                        </label>
                    </p>
                    <div className="space"></div>
                    <p>
                        <button className="cancel" type="reset" value="cancel" onClick={goBack}>
                            Back
                        </button>
                        <button type="submit" name="submit" value="submit">
                            Next
                        </button>
                    </p>
                </div>
            </form>
            <form
                onSubmit={(e) => searchPatient(e)}
                className="enroll-hidden form-validate-attestation"
                style={{ display: "none" }}
                method={"get" /*post*/}
                acceptCharset="utf-8"
            >
                <p>Enter the following information:</p>
                <p className="error"></p>
                <div className="section">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="mandatory1 field text">
                            <label htmlFor="firstName" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <span className="inner-label">Member ID</span>
                            </label>
                            <input value={member_id} onChange={(e) => setmemberid(e.target.value)} type="text" id="short_card_id" name="short_card_id" />
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="mandatory1 field text">
                            <label htmlFor="birthDate" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <span className="inner-label">Patient's birth date</span>
                            </label>
                            <input id="datepicker1" className="datepicker" type="text" name="dob1" size="10" />
                            <img className="ui-datepicker-trigger" src={calBtn} alt="..." title="..." />
                        </p>
                    </div>
                    <p
                        style={{
                            textAlign: "center",
                            marginBottom: "20px",
                            minHeight: "inherit",
                        }}
                    >
                        <strong>OR</strong>
                    </p>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="mandatory field text">
                            <label htmlFor="firstName" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <span className="inner-label">Patient's first name</span>
                            </label>
                            <input value={first_name} onChange={(e) => setfirstname(e.target.value)} type="text" id="firstName" name="firstName" />
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="mandatory field text">
                            <label htmlFor="lastName" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <span className="inner-label">Patient's last name</span>
                            </label>
                            <input type="text" id="lastName" name="lastName" value={last_name} onChange={(e) => setlastname(e.target.value)} />
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="mandatory field text">
                            <label htmlFor="birthDate" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <span className="inner-label">Patient's birth date</span>
                            </label>
                            <input id="datepicker2" className="datepicker" type="text" name="dob2" size="10" />
                            <img className="ui-datepicker-trigger" src={calBtn} alt="..." title="..." />
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="mandatory field text">
                            <label htmlFor="zipCode" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <span className="inner-label">Patient's ZIP code</span>
                            </label>
                            <input type="text" id="zipCode" name="zipCode" value={zip_code} onChange={(e) => setzip(e.target.value)} />
                        </p>
                    </div>
                    <p>
                        <input type="hidden" name="re-enroll" value="yes" />
                        <button type="submit" name="apply-step-two-submit">
                            Submit
                        </button>
                    </p>
                    <p>
                        Your Member ID number was provided to you in your Welcome Letter. If you do not remember your Member ID number, you can call the Help
                        Desk at{" "}
                        <span className="noWrap">
                            <strong>(855) 965-2472</strong>
                        </span>
                        .
                    </p>
                </div>
            </form>
            <div className="clearfloat"></div>
        </>
    );
}
