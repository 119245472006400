import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation, gql } from '@apollo/client';
import { PATIENT_PROFILE_DETAILS } from '../graphql/queries/Patients'
import { UPDATE_PATIENT_PROFILE } from '../graphql/mutations/Patients'


export default function UpdatePatientProfile(props) {

    var patient_id = parseInt(window.localStorage.getItem('xrefID'))

    const url = props.url

    const [firstName, setFirstName] = useState('');
    const [lastName, setLasttName] = useState('');
    const [dob, setdob] = useState('');
    const [email, setemail] = useState('');
    const [address1, setaddress1] = useState('');
    const [address2, setaddress2] = useState('');
    const [city, setcity] = useState('');
    const [state, setstate] = useState('');
    const [zip_code, setzip] = useState('');
    const [phone, setphonenumber] = useState('');
    const [phone_type, setphonetype] = useState('');


    const { loading, error, data, refetch } = useQuery(PATIENT_PROFILE_DETAILS, {
        variables: { patient_id }
    });

    const [updateProfile, { data: newData, loading: loadingUpdate, error: errorUpdate }] = useMutation(UPDATE_PATIENT_PROFILE);

    useEffect(() => {
        if (patient_id) {
            if (data != null) {
                if (data.patientinfo != null) {
                    if (data.patientinfo.enrollment_data != null) {
                        if (data.patientinfo.enrollment_data.first_name) {
                            setFirstName(data.patientinfo.enrollment_data.first_name)
                        }
                        if (data.patientinfo.enrollment_data.last_name) {
                            setLasttName(data.patientinfo.enrollment_data.last_name)
                        }
                        if (data.patientinfo.enrollment_data.date_of_birth) {
                            var date = data.patientinfo.enrollment_data.date_of_birth
                            var year = date.substring(0, 4)
                            var month = date.substring(5, 7)
                            var day = date.substring(8, 10)
                            $(".datepicker").val(month + '/' + day + '/' + year)
                            setdob(data.patientinfo.enrollment_data.date_of_birth)
                        }
                        if (data.patientinfo.enrollment_data.address.address_1) {
                            setaddress1(data.patientinfo.enrollment_data.address.address_1)
                        }
                        if (data.patientinfo.enrollment_data.address.address_2) {
                            setaddress2(data.patientinfo.enrollment_data.address.address_2)
                        }
                        if (data.patientinfo.enrollment_data.address.city) {
                            setcity(data.patientinfo.enrollment_data.address.city)
                        }
                        if (data.patientinfo.enrollment_data.address.state) {
                            setstate(data.patientinfo.enrollment_data.address.state)
                        }
                        if (data.patientinfo.enrollment_data.address.zip_code) {
                            setzip(data.patientinfo.enrollment_data.address.zip_code)
                        }
                        if (data.patientinfo.enrollment_data.email) {
                            setemail(data.patientinfo.enrollment_data.email)
                        }
                        if (data.patientinfo.enrollment_data.phone) {
                            setphonenumber(data.patientinfo.enrollment_data.phone)
                        }
                        if (data.patientinfo.enrollment_data.phone_type) {
                            setphonetype(data.patientinfo.enrollment_data.phone_type)
                        }
                    }
                }
            }
        }
    }, [data])

    let navigate = useNavigate()
    function updateProfileInfo(e) {
        e.preventDefault()
        console.log(email)
        updateProfile({ variables: { patient_id, address1, address2, city, state, zip_code, dob, phone, phone_type, email } })
        let path = `../` + url
        navigate(path)
    }

    function cancelUpdate() {
        let path = `../` + url
        navigate(path)
    }

    function submit(e){
        e.preventDefault()
    }

    return (
        <>
            <div id="save-popup" className="save-popup">
                <div className="popup-box" style={{ padding: '20px' }}>
                    <p>Are you sure you want to save changes?</p>
                    <button id="ok" className="save close-button" onClick={e => updateProfileInfo(e)}>OK</button> <button className="cancel close-button" type="reset">Cancel</button>
                </div>
            </div>
            <div className="section shadow-box color-label">
                <div className="col-sm-12 col-md-12 col-lg-9" style={{ padding: '0' }}>
                    <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Patient Profile <span className="red" style={{ paddinLeft: '40px', textDecoration: 'underline' }}>Please update items marked in red.</span></h2>
                </div>
                <form onSubmit={e => submit(e)} encType="multipart/form-data" className="update_profile form-validate" acceptCharset="utf-8" >
                    <div className="col-sm-12 col-md-12 col-lg-2" style={{ float: 'right' }} >
                        <button className="save" id="save" >Save</button> <button className="cancel" id="cancel" type="reset" onClick={cancelUpdate}>Cancel</button>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="field required-field text">
                            <label htmlFor="first_name" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">First name<span className="required">*</span></span></label>
                            <input readOnly type="text" id="first_name" name="first_name" value={firstName} />
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="field required-field text">
                            <label htmlFor="last_name" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Last name<span className="required">*</span></span></label>
                            <input readOnly type="text" id="last_name" name="last_name" value={lastName} />
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <p className="field required-field text date">
                                <label htmlFor="birthDate" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Date of birth<span className="required">*</span></span></label>
                                <input readonly disabled='disabled' className="datepicker" type="text" name="birth_date" />
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <p className="field required-field text">
                                <label htmlFor="street" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Address<span className="required">*</span></span></label>
                                <input onChange={e => setaddress1(e.target.value)} type="text" id="address" name="address_1" value={address1} />
                            </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <p className="field text">
                                <label htmlFor="street" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Address 2</span></label>
                                <input onChange={e => setaddress2(e.target.value)} type="text" id="address2" name="address_2" value={address2} />
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <p className="field required-field text">
                                <label htmlFor="city" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">City<span className="required">*</span></span></label>
                                <input onChange={e => setcity(e.target.value)} type="text" id="city" name="city" value={city} />
                            </p></div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <p className="field required-field dropdown">
                                <label htmlFor="state" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">State<span className="required">*</span></span></label>
                                <span className="select_container">
                                    <select name="state" id="state" defaultValue="" value={state} onChange={e => setstate(e.target.value)}>
                                        <option label="" value="default">Select a state</option>
                                        <option label="Alabama" value="AL">Alabama</option>
                                        <option label="Alaska" value="AK">Alaska</option>
                                        <option label="American Samoa" value="AS">American Samoa</option>
                                        <option label="Arizona" value="AZ">Arizona</option>
                                        <option label="Arkansas" value="AR">Arkansas</option>
                                        <option label="California" value="CA">California</option>
                                        <option label="Colorado" value="CO">Colorado</option>
                                        <option label="Connecticut" value="CT">Connecticut</option>
                                        <option label="Delaware" value="DE">Delaware</option>
                                        <option label="Florida" value="FL">Florida</option>
                                        <option label="Georgia" value="GA">Georgia</option>
                                        <option label="Guam" value="GU">Guam</option>
                                        <option label="Hawaii" value="HI">Hawaii</option>
                                        <option label="Idaho" value="ID">Idaho</option>
                                        <option label="Illinois" value="IL">Illinois</option>
                                        <option label="Indiana" value="IN">Indiana</option>
                                        <option label="Iowa" value="IA">Iowa</option>
                                        <option label="Kansas" value="KS">Kansas</option>
                                        <option label="Kentucky" value="KY">Kentucky</option>
                                        <option label="Louisiana" value="LA">Louisiana</option>
                                        <option label="Maine" value="ME">Maine</option>
                                        <option label="Maryland" value="MD">Maryland</option>
                                        <option label="Massachusetts" value="MA">Massachusetts</option>
                                        <option label="Michigan" value="MI">Michigan</option>
                                        <option label="Minnesota" value="MN">Minnesota</option>
                                        <option label="Mississippi" value="MS">Mississippi</option>
                                        <option label="Missouri" value="MO">Missouri</option>
                                        <option label="Montana" value="MT">Montana</option>
                                        <option label="Nebraska" value="NE">Nebraska</option>
                                        <option label="Nevada" value="NV">Nevada</option>
                                        <option label="New Hampshire" value="NH">New Hampshire</option>
                                        <option label="New Jersey" value="NJ">New Jersey</option>
                                        <option label="New Mexico" value="NM">New Mexico</option>
                                        <option label="New York" value="NY">New York</option>
                                        <option label="North Carolina" value="NC">North Carolina</option>
                                        <option label="North Dakota" value="ND">North Dakota</option>
                                        <option label="Northern Mariana Islands" value="NM">Northern Mariana Islands</option>
                                        <option label="Ohio" value="OH">Ohio</option>
                                        <option label="Oklahoma" value="OK">Oklahoma</option>
                                        <option label="Oregon" value="OR">Oregon</option>
                                        <option label="Pennsylvania" value="PA">Pennsylvania</option>
                                        <option label="Puerto Rico" value="PR">Puerto Rico</option>
                                        <option label="Rhode Island" value="RI">Rhode Island</option>
                                        <option label="South Carolina" value="SC">South Carolina</option>
                                        <option label="South Dakota" value="SD">South Dakota</option>
                                        <option label="Tennessee" value="TN">Tennessee</option>
                                        <option label="Texas" value="TX">Texas</option>
                                        <option label="U.S. Virgin Islands" value="VI">U.S. Virgin Islands</option>
                                        <option label="Utah" value="UT">Utah</option>
                                        <option label="Vermont" value="VT">Vermont</option>
                                        <option label="Virginia" value="VA">Virginia</option>
                                        <option label="Washington" value="WA">Washington</option>
                                        <option label="Washington DC" value="DC">Washington DC</option>
                                        <option label="West Virginia" value="WV">West Virginia</option>
                                        <option label="Wisconsin" value="WI">Wisconsin</option>
                                        <option label="Wyoming" value="WY">Wyoming</option>	</select>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <p className="field required-field text zipcode">
                                <label htmlFor="zipCode" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">ZIP code<span className="required">*</span></span></label>
                                <input onChange={e => setzip(e.target.value)} type="text" id="zipCode" name="zip" value={zip_code} />
                            </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <p className="field required-field text">
                                <label htmlFor="homePhone_enroller" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label red">Email address</span></label>
                                <input onChange={e => setemail(e.target.value)} type="text" id="" name="email" value={email} />
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <p className="field required-field text">
                                <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Phone number<span className="required">*</span></span></label>
                                <input onChange={e => setphonenumber(e.target.value)} type="text" id="homePhone" name="phone_number" value={phone} />
                            </p></div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <p className="field required-field dropdown">
                                <label htmlFor="homePhone_enroller" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Phone type<span className="required">*</span></label>
                                <span className="select_container">
                                    <select value={phone_type} onChange={e => setphonetype(e.target.value)} id="Phone_type" name="phone_type_patient">
                                        <option value="">Select type</option>
                                        <option value="Home">Home</option>
                                        <option value="Mobile" selected="selected">Mobile</option>
                                    </select>
                                </span>
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}