import React from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.min.css";
import PatientInfo from "../../components/PatientInfo";
import DocumentSubmission from "../../components/DocumentSubmission";

export default function SubmitDocument() {
    const patient_id = parseInt(window.localStorage.getItem("xrefID"));

    let navigate = useNavigate();
    function cancelBtn() {
        let path = `../patient-informations`;
        navigate(path);
    }
    return (
        <div className="container" id="content">
            <div id="container" className="upload-content">
                <div id="hcp_post_content">
                    <h1 style={{ fontSize: "1.5em", lineHeight: "1.49em" }}>Upload Document</h1>
                    <PatientInfo />
                    <DocumentSubmission cancel="patient-informations" />
                </div>
            </div>
        </div>
    );
}
