import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';
import { COMMERCIAL_MEDICAL_INSURANCE_INFORMATION } from '../graphql/queries/Patients'

export default function CommercialMedicalInsurance(props) {

    var patient_id = parseInt(window.localStorage.getItem('xrefID'))
    if (props.patientID) {
        patient_id = parseInt(props.patientID)
    }

    const [patientID, setPatientID] = useState('');

    const [companyname, setcompanyname] = useState('');
    const [plantype, setplantype] = useState('');
    const [group, setgroup] = useState('');
    const [membernumber, setmembernumber] = useState('');
    const [effectivedate, seteffectivedate] = useState('');
    const [bin, setbin] = useState('');
    const [pcn, setpcn] = useState('')

    const { loading, error, data, refetch } = useQuery(COMMERCIAL_MEDICAL_INSURANCE_INFORMATION, {
        variables: { patient_id }
    });

    useEffect(() => {
        if (patient_id) {
            if (data != null) {
                if (data.patientinfo != null) {
                    if (data.patientinfo.enrollment_data != null) {
                        if (data.patientinfo.enrollment_data.commercial_insurance != null) {
                            if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance != null) {
                                if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.name) {
                                    setcompanyname(data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.name)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.plan_type) {
                                    setplantype(data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.plan_type)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.group_number) {
                                    setgroup(data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.group_number)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.member_number) {
                                    setmembernumber(data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.member_number)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.effective_date) {
                                    seteffectivedate(data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.effective_date)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.bin) {
                                    setbin(data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.bin)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.pcn) {
                                    setpcn(data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.pcn)
                                }
                            }
                        }
                    }
                }

            }
        }
    }, [data])

    let navigate = useNavigate()
    function updateInsurance(e) {
        e.preventDefault()
        let path = `../update-insurance`
        navigate(path)
    }

    function handleChange() {

    }

    return (
        <div className="section shadow-box color-label">
            <div className="col-sm-12 col-md-12 col-lg-9" style={{ paddingLeft: '0' }}>
                <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Commercial Medical Insurance Information</h2>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3">
                <form onSubmit={e => updateInsurance(e)} encType="multipart/form-data">
                    <input onChange={handleChange} type="hidden" name="action" value="update_insurance" />
                    <button className="update" type="submit" value="Next">Update Insurance Information</button>
                </form>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-3">
                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Company name</label>
                        {companyname}   </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Plan type</label>
                        {plantype}  </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Group number</label>
                        {group}  </p>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-3">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Member number</label>
                        {membernumber}  </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Effective date</label>
                        {effectivedate}  </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>BIN </label>
                        {bin}</p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3">
                    <p className="field required-field text phone">
                        <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>PCN</label>
                        {pcn}</p>
                </div>
            </div>
        </div>
    )
}