import React from "react";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.min.css";
import excel from "../../images/excel.png";

/*
$(document).ready(function () {
    $(".datepicker").datepicker({
        dateFormat: 'mm/dd/yy', showButtonPanel: true,
        changeMonth: true,
        changeYear: true,
        showOtherMonths: true,
        selectOtherMonths: true
    });
});
*/

export default function PatientListAll() {
    return (
        <div className="container" id="content" style={{ fontSize: "1em" }}>
            <div id="container">
                <div id="hcp_post_content">
                    <h1 style={{ fontSize: "1.5em", lineHeight: "1.49em" }}>Patient Search</h1>
                    <p>
                        Enter one or more criteria to search for your patients. "First Name" and "Last Name" provides partial text search.
                    </p>

                    <div className="required"></div>
                    <form action="patient-list-all" acceptCharset="utf-8" className="find_patient" id="search_form">
                        <div className="find_patient">
                            <p>
                                <input type="text" name="firstname" placeholder="First Name" />{" "}
                                <input type="text" name="lastname" placeholder="Last Name" />{" "}
                                <input className="datepicker" type="text" name="birth_date" placeholder="Date of Birth" />{" "}
                                <input type="text" name="member_id" placeholder="Member ID" />{" "}
                            </p>
                            <p>
                                <span className="select_container">
                                    <select name="product" style={{ maxWidth: "inherit", width: "100%" }}>
                                        <option value="" selected="selected">
                                            Product
                                        </option>
                                        <option value="1">XOLAIR</option>
                                    </select>
                                </span>
                            </p>
                            <p></p>
                        </div>
                        <p>
                            <button type="submit" name="list_all" id="grey-button">
                                Search
                            </button>{" "}
                            <button type="reset">Reset</button>
                        </p>
                    </form>
                    <div id="hcp_post_content">
                        <div>
                            <span className="tooltip" style={{ float: "right" }}>
                                <a href="#">
                                    <img src={excel} className="excel" />
                                </a>
                                <span className="tooltiptext">
                                    Click here to download entire patient list. Please note there is a 24 hour delay for newly enrolled
                                    patients to be included in this excel list
                                </span>
                            </span>
                            <table className="account" align="center" style={{ margin: "0" }} id="patient-list">
                                <thead>
                                    <tr className="list_header">
                                        <th align="left" width="150" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            Patient Name
                                        </th>
                                        <th align="left" width="150" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            Date of Birth
                                        </th>
                                        <th align="center" width="120" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            Member ID
                                        </th>
                                        <th align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            Product
                                        </th>
                                        <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            Address
                                        </th>
                                        <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            Enroll Date
                                        </th>
                                        <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            Original Enroll Date
                                        </th>
                                        <th align="center" style={{ textAlign: "center", fontSize: "1em", lineHeight: "1.49em" }}>
                                            Status
                                        </th>
                                        <th
                                            align="center"
                                            style={{ textAlign: "center", fontSize: "1em", lineHeight: "1.49em" }}
                                            width="150"
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="table-list">
                                    <tr>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <a href="patient-informations">Adams Angelina</a>
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            01/01/1971
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            XR6130492
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            XOLAIR
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            81 Oak Way
                                            <br />
                                            Suite 48
                                            <br />
                                            Arlington, VA 18985{" "}
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            08/01/2022
                                        </td>
                                        <td align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <span className="status-icon active">Active</span>
                                        </td>
                                        <td align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <p className="hcp">
                                                <a href="submit-document">Upload document</a>
                                            </p>
                                            <p className="hcp"></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <a href="patient-informations">Adams Angelina</a>
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            01/01/1971
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            XR6130492
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            XOLAIR Administration
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            81 Oak Way
                                            <br />
                                            Suite 48
                                            <br />
                                            Arlington, VA 18985{" "}
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            08/01/2020
                                        </td>
                                        <td align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <span className="status-icon active">Active</span>
                                        </td>
                                        <td align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <p className="hcp">
                                                <a href="submit-document">Upload document</a>
                                            </p>
                                            <p className="hcp"></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <a href="patient-informations">Baird Karl</a>
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            01/01/1984
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            XR6017024
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            XOLAIR Administration
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            145 Green Oak Road
                                            <br />
                                            Apt 74
                                            <br />
                                            Dayton, ID 27851{" "}
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            08/01/2020
                                        </td>
                                        <td align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <span className="status-icon inactive">Inactive</span>
                                        </td>
                                        <td align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <p className="hcp">
                                                <a href="submit-document">Upload document</a>
                                            </p>
                                            <p className="hcp"></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <a href="patient-informations">Adams Sheldon</a>
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            01/01/1989
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            XR6155330
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            XOLAIR Administration
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            170 Fabien Drive
                                            <br />
                                            Baton Rouge
                                            <br />
                                            CT 30556{" "}
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            08/01/2020
                                        </td>
                                        <td align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <span className="status-icon active">Active</span>
                                        </td>
                                        <td align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <p className="hcp">
                                                <a href="submit-document">Upload document</a>
                                            </p>
                                            <p className="hcp"></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <a href="patient-informations">Bender, Jasmine</a>
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            01/01/1950
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            XR6130492
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            XOLAIR
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            16 Green Second Drive Suite 503
                                            <br />
                                            New York,
                                            <br />
                                            ID 18260{" "}
                                        </td>
                                        <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            08/01/2020
                                        </td>
                                        <td align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <span className="status-icon inactive">
                                                Verification in Progress. Call Help Desk at <br />
                                                <strong>(855 965-2472</strong>.
                                            </span>
                                        </td>
                                        <td align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <p className="hcp">
                                                <a href="submit-document">Upload document</a>
                                            </p>
                                            <p className="hcp"></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="pages" style={{ marginTop: "0" }}>
                                <div className="col-lg-8"></div>
                                <div className="col-lg-4">
                                    <div className="col-lg-5">
                                        Items per page:{" "}
                                        <select name="">
                                            <option>10</option>
                                            <option>20</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-3">1 - 1 of 1 </div>
                                    <div className="col-lg-4">
                                        <a href="#">
                                            <span className="nav-left"></span>
                                        </a>
                                        <a href="#">
                                            <span className="nav-right"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfloat"></div>
                </div>
                <div className="space"></div>
                <div className="space"></div>
            </div>
        </div>
    );
}
