import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';
import { PARENT_CAREGIVER_INFO } from '../../graphql/queries/Patients'

export default function ParentCaregiverInfo(props) {

    var patient_id = parseInt(window.localStorage.getItem('xrefID'))
    if (props.patientID) {
        patient_id = parseInt(props.patientID)
    }
    const [firstname, setfirstname] = useState('');
    const [lastname, setlastname] = useState('');
    const [phonenumber, setphonenumber] = useState('');
    const [address1, setaddress1] = useState('');
    const [address2, setaddress2] = useState('');
    const [city, setcity] = useState('');
    const [state, setstate] = useState('');
    const [zip, setzip] = useState('');

    const { loading, error, data, refetch } = useQuery(PARENT_CAREGIVER_INFO, {
        variables: { patient_id }
    });

    const [show, setshow] = useState(true)

    useEffect(() => {
        if (patient_id) {
            if (data) {
                if (data.patientinfo != null) {
                    if (data.patientinfo.enrollment_data != null) {
                        if (data.patientinfo.enrollment_data.lap) {
                            if (data.patientinfo.enrollment_data.lap.first_name) {
                                if (data.patientinfo.enrollment_data.lap.first_name.toLowerCase() == 'null') {
                                    setfirstname('')
                                } else {
                                    setfirstname(data.patientinfo.enrollment_data.lap.first_name)
                                }
                            }
                            if (data.patientinfo.enrollment_data.lap.last_name) {
                                if (data.patientinfo.enrollment_data.lap.last_name.toLowerCase() == 'null') {
                                    setlastname('')
                                } else {
                                    setlastname(data.patientinfo.enrollment_data.lap.last_name)
                                }
                            }
                            if (data.patientinfo.enrollment_data.lap.phone) {
                                if (data.patientinfo.enrollment_data.lap.phone.toLowerCase() == 'null') {
                                    setphonenumber('')
                                } else {
                                    setphonenumber(data.patientinfo.enrollment_data.lap.phone)
                                }
                            }
                            if (data.patientinfo.enrollment_data.lap.address.address1) {
                                if (data.patientinfo.enrollment_data.lap.address.address1.toLowerCase() == 'null') {
                                    setaddress1('')
                                } else {
                                    setaddress1(data.patientinfo.enrollment_data.lap.address.address1)
                                }
                            }
                            if (data.patientinfo.enrollment_data.lap.address.address2) {
                                if (data.patientinfo.enrollment_data.lap.address.address2.toLowerCase() == 'null') {
                                    setaddress2('')
                                } else {
                                    setaddress2(data.patientinfo.enrollment_data.lap.address.address2)
                                }
                            }
                            if (data.patientinfo.enrollment_data.lap.address.city) {
                                if (data.patientinfo.enrollment_data.lap.address.city.toLowerCase() == 'null') {
                                    setcity('')
                                } else {
                                    setcity(data.patientinfo.enrollment_data.lap.address.city)
                                }
                            }
                            if (data.patientinfo.enrollment_data.lap.address.state) {
                                if (data.patientinfo.enrollment_data.lap.address.state.toLowerCase() == 'null') {
                                    setstate('')
                                } else {
                                    setstate(data.patientinfo.enrollment_data.lap.address.state)
                                }
                            }
                            if (data.patientinfo.enrollment_data.lap.address.zip_code) {
                                if (data.patientinfo.enrollment_data.lap.address.zip_code.toLowerCase() == 'null') {
                                    setzip('')
                                } else {
                                    setzip(data.patientinfo.enrollment_data.lap.address.zip_code)
                                }
                            }
                        }
                    }
                }

            }
        }

        if (firstname === '' && lastname === '' && phonenumber === '' && address1 === '' && address2 === '' && city === '' && state === '' && zip === '') {
            setshow(false)
        } else {
            setshow(true)
        }
    })

    if (show) {
        return (
            <div className="section shadow-box color-label">
                <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Parent/Caregiver Information</h2>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="col-sm-12 col-md-12 col-lg-4">
                        <p className="field required-field text">
                            <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Fullname</label>
                            {firstname} {lastname}    </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4">
                        <p className="field required-field text phone">
                            <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Phone number</label>
                            {phonenumber}    </p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="col-sm-12 col-md-12 col-lg-4">
                        <p className="field required-field text">
                            <label htmlFor="street" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Address</label>
                            {address1} <br />
                            {address2 != '' ? (address2 + <br />) : ''}
                            {city}, {state} {zip}  </p></div>
                </div>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}