import React from "react";
import { Link } from "react-router-dom";

export default function EFTPaymentList(props) {
    var data = props.data;
    var offset = props.offset;
    var limit = props.limit;

    return (
        <>
            {data &&
                data.providerreports &&
                data.providerreports
                    .slice(offset, limit)
                    .map(
                        ({
                            __typename,
                            claim_id,
                            trace_id,
                            patient_id,
                            program,
                            claim_data,
                            payment_status,
                            date_of_service,
                            claim_status,
                            first_name,
                            last_name,
                            date_of_birth,
                            member_id,
                        }) => (
                            <TableRow
                                program={program}
                                patientID={patient_id}
                                firstName={first_name}
                                lastName={last_name}
                                dob={date_of_birth}
                                memberNum={member_id}
                                dos={date_of_service}
                                claimData={claim_data}
                                amount={parseFloat(claim_data.program_benefit).toFixed(2)}
                                eftID={claim_data.trace_id}
                                status={claim_status}
                            />
                        )
                    )}
        </>
    );
}

function TableRow(props) {
    const claim_data = props.claimData;

    function viewPatient() {
        window.localStorage.setItem("xrefID", parseInt(props.patientID));
    }

    try {
        var providerFirst = claim_data.providers.provider.first_name;
    } catch {
        var providerFirst = "";
    }
    try {
        var providerLast = claim_data.providers.provider.last_name;
    } catch {
        var providerLast = "";
    }
    try {
        try {
            var address_1 = claim_data.providers.provider.address.address_1;
        } catch {
            var address_1 = claim_data.shipping_info.address.address_1;
        }
    } catch {
        var address_1 = "";
    }
    try {
        try {
            var address_2 = claim_data.providers.provider.address.address_2;
        } catch {
            var address_2 = claim_data.shipping_info.address.address_2;
        }
    } catch {
        var address_2 = "";
    }
    try {
        try {
            var city = claim_data.providers.provider.address.city;
        } catch {
            var city = claim_data.shipping_info.address.city;
        }
    } catch {
        var city = "";
    }
    try {
        try {
            var state = claim_data.providers.provider.address.state;
        } catch {
            var state = claim_data.shipping_info.address.state;
        }
    } catch {
        var state = "";
    }
    try {
        try {
            var zipcode = claim_data.providers.provider.address.zip_code;
        } catch {
            var zipcode = claim_data.shipping_info.address.zip_code;
        }
    } catch {
        var zipcode = "";
    }
    try {
        var paid_date = claim_data.paid_date.split(" ")[0];
    } catch {
        var paid_date = "";
    }
    try {
        var requested_date = claim_data.requested_date.split(" ")[0];
    } catch {
        var requested_date = "";
    }
    try {
        var date_of_service = props.dos;
    } catch {
        var date_of_service = "";
    }

    //paid date display conditional
    var paid_status_text = "";
    if (paid_date != "" && paid_date != null) {
        const customDate = new Date(paid_date);
        //get current date
        const currentDate = new Date();
        //compare
        const timeDifference = (currentDate - customDate) / (1000 * 60 * 60 * 24);
        //if more than 5 days ago display Paid
        if (timeDifference >= 10) {
            paid_status_text = "Paid";
        } else {
            paid_status_text = "Sent to bank";
        }
        if (props.status == "pm_approved") {
            paid_status_text = "Approved";
        } else if (
            props.status == "Maximizer-Hold" ||
            props.status == "Max-Ben EDI Hold" ||
            props.status == "Max-Ben Hold" ||
            props.status == "exception"
        ) {
            paid_status_text = "Pending";
        }
    }

    return (
        <tr>
            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{props.program}</td>
            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                <Link onClick={viewPatient} to="../patient-informations">
                    {props.firstName} {props.lastName}
                </Link>
            </td>
            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{props.dob}</td>
            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{props.memberNum}</td>
            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{props.dos}</td>
            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                {providerFirst} {providerLast} {address_1}{" "}
                {address_2 != "" && address_2 != null ? (
                    <>
                        <br />
                        {address_2},{" "}
                    </>
                ) : (
                    <></>
                )}
                <br />
                {city}, {state} {zipcode}
            </td>
            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>${props.amount}</td>
            <td style={{ lineHeight: "1.49em" }}>
                <span style={{ fontSize: "0.75em" }}>
                    <strong> {props.eftID} </strong>
                </span>
            </td>
            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                {paid_date != "" && paid_date != null
                    ? paid_date
                    : requested_date != "" && requested_date != null
                    ? requested_date
                    : date_of_service}
            </td>
            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{paid_date != null ? paid_status_text : props.status}</td>
        </tr>
    );
}
