import React from 'react'
import {Link} from 'react-router-dom'

export default function Eligibility(){
    return (
       

        <div className="container" id="content">
            <div id="container" className="eligibility">
                <div id="patient-eligibility"> 
                    <h2 style={{fontSize: '1.25em' , lineHeight: '1.49em'}}>Program Offering and Eligibility</h2> 
                    <h3>Program Offering</h3> 
                    <p>The XOLAIR Co-pay Program may be able to help you. If you meet the eligibility criteria, you can get help with your XOLAIR drug costs, injection costs, or both.</p> 
                    <p><strong>Help with drug out-of-pocket costs</strong></p> 
                    <div className="row help mb-2"> 
                        <div className="col-xs-12 col-sm-1" style={{paddingRight: '2px' , marginRight: '10px'}}> 
                            <div className="circle1"></div>
                        </div> 
                        <div className="col-xs-12 col-sm-3 circle"> 
                            <p>You pay as little as $0 per XOLAIR drug <span className="text-nowrap">out-of-pocket cost</span> </p> 
                        </div>  
                        <div className="col-xs-12 col-sm-5 circle-div"> 
                            <p>The program covers up to $15,000 per <br/> calendar year benefit period. There are no <br/> income limits for this program.</p> 
                        </div> 
                    </div> 
                    <p>Note: The final amount you owe may be as little as $0, but may vary depending on your health insurance plan.</p> 
                    <p><strong>Help with injection out-of-pocket costs</strong></p> 
                    <div className="row help mb-2"> 
                        <div className="col-xs-12 col-sm-1" style={{paddingRight: '2px' , marginRight: '10px'}}> 
                            <div className="circle2"></div>
                        </div> 
                        <div className="col-xs-12 col-sm-3 circle">
                            <p>You pay as little as $0 per XOLAIR injection <span className="text-nowrap">out-of-pocket cost</span> </p> 
                        </div>
                        <div className="col-xs-12 col-sm-4 circle-div"> 
                            <p>The program covers up to $1,500 per calendar year benefit period. There are no income limits for this program.</p> 
                        </div> 
                    </div> 
                    <p>Note: The final amount you owe may be as little as $0, but may vary depending on your health insurance plan.</p>  
                    <h3><strong>Eligibility for help with drug out-of-pocket costs</strong></h3> 
                    <div className="box-drug"> 
                        <div className="title-box2-drug" aria-label="Blue Table Header">ELIGIBLE PATIENTS:</div> 
                        <div className="content-box2"> 
                            <ul>
                                <li><span>Have been prescribed XOLAIR for an  <Link to="#isi"><strong>FDA-approved indication</strong></Link></span></li>
                                <li><span>Are 18 years of age or older, or have a caregiver or legally authorized person to manage the patient's co-pay assistance</span></li>
                                <li>Have commercial (private or non-governmental) insurance.<sup>*</sup> This includes plans available through state and federal health insurance exchanges</li>
                                <li>Reside and receive treatment in the U.S. or U.S. Territories</li> 
                                <li>Are <strong>not</strong> receiving assistance through the Genentech Patient Foundation or any other charitable organization for the same expenses covered by the program</li>
                                <li>Are <strong>not</strong> a government beneficiary and/or participant in a federal or state-funded health insurance program (e.g., Medicare, Medicare Advantage, Medigap, Medicaid, VA, DoD, TRICARE)</li> 
                            </ul>
                        </div> 
                    </div> 
                    <h3><strong>Eligibility for help with administration out-of-pocket costs</strong></h3> 
                    <div className="box-drug"> 
                        <div className="title-box2-inf" aria-label="Blue Table Header">ELIGIBLE PATIENTS:</div> 
                        <div className="content-box2"> 
                            <ul> 
                                <li><span>Have been prescribed XOLAIR for an <Link to="#isi"><strong>FDA-approved indication</strong></Link></span></li> 
                                <li><span>Are 18 years of age or older, or have a caregiver or legally authorized person to manage the patient's co-pay assistance</span></li> 
                                <li>Have commercial (private or non-governmental) insurance.<sup>*</sup> This includes plans available through state and federal health insurance exchanges</li>
                                <li>Reside and receive treatment in the U.S. or U.S. Territories</li>
                                <li>Are <strong>not</strong> receiving assistance through any charitable organization for the same expenses covered by the program<sup>†</sup></li>
                                <li>Are <strong>not</strong> a government beneficiary and/or participant in a federal or state-funded health insurance program (e.g., Medicare, Medicare Advantage, Medigap, Medicaid, VA, DoD, TRICARE)</li> 
                                <li><span>Do <strong>not</strong> live or get treatments in a restricted state (Massachusetts or Rhode Island)</span></li>
                            </ul>
                            <p><sup>*</sup>Commercial insurance includes plans you receive from your job or plans from the Health Insurance Marketplace. Government programs like Medicare and Medicaid are not commercial insurance.</p>
                            <p><sup>†</sup>Patients may use the XOLAIR Co-pay Program for their administration costs If they are receiving their treatment from the Genentech Patient Foundation.</p>
                            <p>If you are not eligible for the XOLAIR <span className="text-nowrap">Co-pay</span> Program, there may be other options for co-pay assistance. Call XOLAIR at <span className="text-nowrap"><strong><b>(866) 4XOLAIR</b></strong></span> <span className="text-nowrap">(1-866-496-5247)</span> or visit <Link to="https://xolair.com" target="_blank"><strong>XOLAIR.com</strong></Link> to learn more.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}