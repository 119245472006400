import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation, gql } from '@apollo/client';
import { COMMERCIAL_MEDICAL_INSURANCE_INFORMATION } from '../graphql/queries/Patients'
import { UPDATE_PATIENT_INSURANCE } from '../graphql/mutations/Patients'

export default function UpdateInsurance(props) {

    var patient_id = parseInt(window.localStorage.getItem('xrefID'))

    const url = props.url

    const [name, setcompanyname] = useState('');
    const [plan_type, setplantype] = useState('');
    const [group_number, setgroup] = useState('');
    const [member_number, setmembernumber] = useState('');
    const [effectivedate, seteffectivedate] = useState('');
    const [bin, setbin] = useState('');
    const [pcn, setpcn] = useState('')

    const { loading, error, data, refetch } = useQuery(COMMERCIAL_MEDICAL_INSURANCE_INFORMATION, {
        variables: { patient_id }
    });


    const [updateInsurance, { data: newData, loading: loadingUpdate, error: errorUpdate }] = useMutation(UPDATE_PATIENT_INSURANCE);

    useEffect(() => {
        if (patient_id) {
            if (data != null) {
                if (data.patientinfo != null) {
                    if (data.patientinfo.enrollment_data != null) {
                        if (data.patientinfo.enrollment_data.commercial_insurance != null) {
                            if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance != null) {
                                if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.name) {
                                    setcompanyname(data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.name)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.plan_type) {
                                    setplantype(data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.plan_type)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.group_number) {
                                    setgroup(data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.group_number)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.member_number) {
                                    setmembernumber(data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.member_number)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.effective_date) {
                                    seteffectivedate(data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.effective_date)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.bin) {
                                    setbin(data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.bin)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.pcn) {
                                    setpcn(data.patientinfo.enrollment_data.commercial_insurance.medical_insurance.pcn)
                                }
                            }
                        }
                    }
                }

            } else {
            }
        } else {
        }



    }, [data])

    let navigate = useNavigate()
    function saveNewInsurance() {
        var effective_date = $(".datepicker").val()
        updateInsurance({ variables: { patient_id, name, plan_type, member_number, group_number, effective_date, bin, pcn } })
        let path = `../` + url
        console.log(path)
        navigate(path)
    }

    function cancelUpdate(e) {
        e.preventDefault()
        let path = `../` + url
        navigate(path)
    }


    return (
        <>
            <div id="save-popup" className="save-popup">
                <div className="popup-box" style={{ padding: '20px' }}>
                    <p>Are you sure you want to save changes?</p>
                    <button id="ok" className="save close-button" onClick={saveNewInsurance}>OK</button> <button className="cancel close-button" type="reset">Cancel</button>
                </div>
            </div>
            <div className="section shadow-box color-label">
                <div className="col-sm-12 col-md-12 col-lg-9" style={{ paddingLeft: '0' }}>
                    <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Commercial Medical Insurance Information</h2>
                </div>
                <div encType="multipart/form-data" className="update_insurance form-validate-user" acceptCharset="utf-8">
                    <div className="col-sm-12 col-md-12 col-lg-2" style={{ float: 'right' }}>
                        <button className="save" id="save" type="submit">Save</button> <button className="cancel" id="cancel" type="reset" onClick={e => cancelUpdate(e)}>Cancel</button>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="field required-field text">
                            <label htmlFor="first_name" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Company name<span className="required">*</span></span></label>
                            <span className="select_container">
                                <select name="insuranceCompanyName" id="insuranceCompanyName" defaultValue="" value={name} onChange={e => setcompanyname(e.target.value)}>
                                    <option value="">Select Company</option>
                                    <option value="Aetna">Aetna</option><option value="Alliance Health">Alliance Health</option><option value="Amerihealth">Amerihealth</option><option value="Anthem">Anthem</option><option value="Assurant">Assurant</option><option value="Blue Cross">Blue Cross</option><option value="Blue Shield">Blue Shield</option><option value="BCBS">BCBS</option><option value="Cigna">Cigna</option><option value="Cox Health">Cox Health</option><option value="First Choice">First Choice</option><option value="Geisinger">Geisinger</option><option value="Harvard Pilgrim" selected="">Harvard Pilgrim</option><option value="Healthnet">Healthnet</option><option value="Humana">Humana</option><option value="Kaiser">Kaiser</option><option value="Medco">Medco</option><option value="Medica">Medica</option><option value="Medical Mutual">Medical Mutual</option><option value="Optima">Optima</option><option value="Preferred One">Preferred One</option><option value="Providence">Providence</option><option value="Select Health">Select Health</option><option value="United Healthcare (UHC)">United Healthcare (UHC)</option><option value="United Medical Resources (UMR)">United Medical Resources (UMR)</option><option value="Other">Other</option>				</select>
                            </span>
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="field required-field text">
                            <label htmlFor="last_name" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Plan Type<span className="required">*</span></span></label>
                            <span className="select_container"><select name="insurancePlanType" id="insurancePlanType" defaultValue="" onChange={e => setplantype(e.target.value)} value={plan_type}>
                                <option value="">Select a Plan Type</option>
                                <option value="CDHP">CDHP Consumer-directed Health Plan</option><option value="EPO">EPO Exclusive Provider Organization</option><option value="FFS">FFS Fee for Service</option><option value="HDHP">HDHP High-deductible Health Plan</option><option value="HMO">HMO Health Maintenance Organization</option><option value="HRA">HRA Health Reimbursement Account</option><option value="HSA" selected="">HSA Health Savings Account</option><option value="Indemnity">Indemnity</option><option value="POS">POS Point of Service</option><option value="PPO">PPO Preferred Provider Organization</option><option value="Union">Union</option><option value="Other">Other</option>				</select>
                            </span>
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <p className="field required-field text">
                                <label htmlFor="birthDate" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Group number<span className="required">*</span></span></label>
                                <input onChange={e => setgroup(e.target.value)} type="text" name="birth_date" value={group_number} />
                            </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <p className="field required-field text">
                                <label htmlFor="street" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Member number<span className="required">*</span></span></label>
                                <input onChange={e => setmembernumber(e.target.value)} type="text" id="address" name="address_1" value={member_number} />
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <p className="field required-field date">
                                <label htmlFor="birthDate" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Effective date<span className="required">*</span></span></label>
                                <input className="datepicker" type="text" name="effective_date" value={effectivedate} />
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <p className="field nothave-field">
                            <input type="checkbox" name="nothave" value="yes" checked="checked" />&nbsp;Insurance card does not have BIN and PCN
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}