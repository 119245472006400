import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import $ from 'jquery'
import calBtn from '../images/calbtn.gif'

export default function CommercialMedicalInsuranceInformation() {

    const { enrollment_data, setenrollmentdata } = useOutletContext()
    const [noBinPcn, setnoBinPcn] = useState(false)

    var existing_member = false
    if (window.localStorage.getItem('existing_member') == "true") {
        existing_member = true
    } else {
        existing_member = false
    }

    const [name, setname] = useState('')
    const [plantype, setplantype] = useState('')
    const [groupnumber, setgroupnumber] = useState('')
    const [membernumber, setmembernumber] = useState('')
    const [effectivedate, seteffectivedate] = useState('')
    const [bin, setbin] = useState('')
    const [pcn, setpcn] = useState('')

    function updateName(e) {
        setname(e.target.value)
        if (enrollment_data != null) {
            if (enrollment_data.insurance != null) {
                if (enrollment_data.insurance.medical_insurance != null) {
                    if (enrollment_data.insurance.medical_insurance.name != null) {
                        enrollment_data.insurance.medical_insurance.name = e.target.value
                        if (e.target.value == 'Other') {
                            setname('')
                            enrollment_data.insurance.medical_insurance.name = ''
                        }
                    }
                }
            }
        }

    }
    function updatePlanType(e) {
        if (enrollment_data != null) {
            if (enrollment_data.insurance != null) {
                if (enrollment_data.insurance.medical_insurance != null) {
                    if (enrollment_data.insurance.medical_insurance.plan_type != null) {
                        setplantype(e.target.value)
                        enrollment_data.insurance.medical_insurance.plan_type = e.target.value
                        if (e.target.value == 'Other') {
                            setplantype('')
                            enrollment_data.insurance.medical_insurance.plan_type = ''
                        }
                    }
                }
            }
        }

    }
    function updateGroupNumber(e) {
        if (enrollment_data != null) {
            if (enrollment_data.insurance != null) {
                if (enrollment_data.insurance.medical_insurance != null) {
                    if (enrollment_data.insurance.medical_insurance.group_number != null) {
                        setgroupnumber(e.target.value)
                        enrollment_data.insurance.medical_insurance.group_number = e.target.value
                    }
                }
            }
        }

    }
    function updateMemberNumber(e) {
        if (enrollment_data != null) {
            if (enrollment_data.insurance != null) {
                if (enrollment_data.insurance.medical_insurance != null) {
                    if (enrollment_data.insurance.medical_insurance.member_number != null) {
                        setmembernumber(e.target.value)
                        enrollment_data.insurance.medical_insurance.member_number = e.target.value
                    }
                }
            }
        }

    }
    function updateBin(e) {
        if (enrollment_data != null) {
            if (enrollment_data.insurance != null) {
                if (enrollment_data.insurance.medical_insurance != null) {
                    if (enrollment_data.insurance.medical_insurance.bin != null) {
                        setbin(e.target.value)
                        enrollment_data.insurance.medical_insurance.bin = e.target.value
                    }
                }
            }
        }

    }
    function updatePcn(e) {
        if (enrollment_data != null) {
            if (enrollment_data.insurance != null) {
                if (enrollment_data.insurance.medical_insurance != null) {
                    if (enrollment_data.insurance.medical_insurance.pcn != null) {
                        setpcn(e.target.value)
                        enrollment_data.insurance.medical_insurance.pcn = e.target.value
                    }
                }
            }
        }

    }
    function handleCheckbox(e) {
        if (document.getElementById("check").checked == true) {
            setbin('')
            setpcn('')
            window.localStorage.setItem('medical-insurance-checked', 'yes')
            enrollment_data.insurance.medical_insurance.bin = ''
            enrollment_data.insurance.medical_insurance.pcn = ''
            setnoBinPcn(true)
        } else {
            setnoBinPcn(false)
            window.localStorage.setItem('medical-insurance-checked', 'no')
        }
    }
    useEffect(() => {
        if (window.localStorage.getItem('enrollment_data')) {
            try {
                setenrollmentdata(JSON.parse(window.localStorage.getItem('enrollment_data')))
                setname(enrollment_data.insurance.medical_insurance.name)
                setplantype(enrollment_data.insurance.medical_insurance.plan_type)
                setgroupnumber(enrollment_data.insurance.medical_insurance.group_number)
                setmembernumber(enrollment_data.insurance.medical_insurance.member_number)
                seteffectivedate(enrollment_data.insurance.medical_insurance.effective_date)
                setbin(enrollment_data.insurance.medical_insurance.bin)
                setpcn(enrollment_data.insurance.medical_insurance.pcn)
            } catch {
            }
        }
    }, [])

    $('input[name=insurance_date]').on('change', function () {
        var date = $(".insurance-date").val()
        var month = date.substring(0, 2)
        var day = date.substring(3, 5)
        var year = date.substring(6, 10)
        if (date != '') {
            seteffectivedate(year + "-" + month + "-" + day)
            enrollment_data.insurance.medical_insurance.effective_date = year + "-" + month + "-" + day
            window.localStorage.setItem('enrollment_data', JSON.stringify(enrollment_data))
        }
    });

    return (
        <div className="section shadow-box">
            <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Commercial Medical Insurance Information</h2>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field required-field text insurance-info ignore">
                    <label htmlFor="insuranceCompanyName" className='smaller' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Insurance company name<span className="required">*</span></span></label>
                    <span className="select_container">
                        <select disabled={(existing_member == true) ? 'disabled' : false} name="insuranceCompanyName" id="insuranceCompanyName" defaultValue="" value={enrollment_data.insurance.medical_insurance.name} onChange={e => updateName(e)}>
                            <option value="">Select Company</option>
                            <option value="Aetna" >Aetna</option><option value="Alliance Health">Alliance Health</option><option value="Amerihealth">Amerihealth</option><option value="Anthem">Anthem</option><option value="Assurant">Assurant</option><option value="Blue Cross">Blue Cross</option><option value="Blue Shield">Blue Shield</option><option value="BCBS">BCBS</option><option value="Cigna">Cigna</option><option value="Cox Health">Cox Health</option><option value="First Choice">First Choice</option><option value="Geisinger">Geisinger</option><option value="Harvard Pilgrim">Harvard Pilgrim</option><option value="Healthnet">Healthnet</option><option value="Humana">Humana</option><option value="Kaiser">Kaiser</option><option value="Medco">Medco</option><option value="Medica">Medica</option><option value="Medical Mutual">Medical Mutual</option><option value="Optima">Optima</option><option value="Preferred One">Preferred One</option><option value="Providence">Providence</option><option value="Select Health">Select Health</option><option value="United Healthcare (UHC)">United Healthcare (UHC)</option><option value="United Medical Resources (UMR)">United Medical Resources (UMR)</option><option value="Other">Other</option>				</select>
                    </span>
                </p>
                <p id="ins-company-other" className="field required-field text insurance-info hide">
                    <label htmlFor="insuranceCompanyOther" className='smaller' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">&nbsp;<span className="required">*</span></span></label>
                    <input readOnly={existing_member == true ? true : false} onChange={e => updateName(e)} type="text" name="insuranceCompanyOther" id="insuranceCompanyOther" placeholder='Other' value={enrollment_data.insurance.medical_insurance.name} />
                </p></div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field require-field dropdown insurance-info ignore">
                    <label htmlFor="insurancePlanType" className='smaller' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Insurance plan type<span className="required">*</span></span></label>
                    <span className="select_container">
                        <select disabled={existing_member == true ? 'disabled' : false} name="insurancePlanType" id="insurancePlanType" defaultValue="" value={enrollment_data.insurance.medical_insurance.plan_type} onChange={e => updatePlanType(e)}>
                            <option value="">Select a Plan Type</option>
                            <option value="CDHP">CDHP Consumer-directed Health Plan</option><option value="EPO">EPO Exclusive Provider Organization</option><option value="FFS">FFS Fee for Service</option><option value="HDHP">HDHP High-deductible Health Plan</option><option value="HMO">HMO Health Maintenance Organization</option><option value="HRA">HRA Health Reimbursement Account</option><option value="HSA">HSA Health Savings Account</option><option value="Indemnity">Indemnity</option><option value="POS">POS Point of Service</option><option value="PPO" >PPO Preferred Provider Organization</option><option value="Union">Union</option><option value="Other">Other</option>				</select>
                    </span>
                </p>
                <p id="ins-plan-other" className="field required-field text insurance-info hide">
                    <label htmlFor="insurancePlanOther" className='smaller' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">&nbsp;<span className="required">*</span></span></label>
                    <input onChange={e => updatePlanType(e)} type="text" name="insurancePlanOther" id="insurancePlanOther" placeholder='Other' value={enrollment_data.insurance.medical_insurance.plan_type} />
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field required-field text insurance-info ignore">
                    <label htmlFor="insuranceGroupNumber" className='smaller' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Insurance group number<span className="required">*</span></span></label>
                    <input readOnly={existing_member == true ? true : false} onChange={e => updateGroupNumber(e)} type="text" name="insuranceGroupNumber" id="insuranceGroupNumber" value={enrollment_data.insurance.medical_insurance.group_number} />
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field required-field text insurance-info ignore">
                    <label htmlFor="insuranceMemberNumber" className='smaller' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Insurance member number<span className="required">*</span></span></label>
                    <input readOnly={existing_member == true ? true : false} onChange={e => updateMemberNumber(e)} type="text" name="insuranceMemberNumber" id="insuranceMemberNumber" value={enrollment_data.insurance.medical_insurance.member_number} />
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <p className="field required-field text date">
                        <label htmlFor="insuranceGroupNumber" className='smaller' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Insurance effective date<span className="required">*</span></span></label>
                        <input autoComplete='off' className="datepicker insurance-date" type="text" name="insurance_date" value={enrollment_data.insurance.medical_insurance.effective_date} id="dp1695111086075" size="10" /><img className="ui-datepicker-trigger" src={calBtn} alt="..." title="..." />

                    </p>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field text insurance-info ignore input-hide required-field">
                    <label htmlFor="insuranceBINNumber" className='smaller' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">BIN<span className="required">*</span><span className="dots"></span></span></label>
                    <input readOnly={((existing_member == true) || (noBinPcn == true)) ? true : false} onChange={e => updateBin(e)} type="text" id="insuranceBINNumber" name="insuranceBINNumber" value={enrollment_data.insurance.medical_insurance.bin} />
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field text insurance-info ignore input-hide required-field">
                    <label htmlFor="insurancePCNNumber" className='smaller' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">PCN<span className="required">*</span><span className="dots"></span></span></label>
                    <input readOnly={((existing_member == true) || (noBinPcn == true)) ? true : false} onChange={e => updatePcn(e)} type="text" id="insurancePCNNumber" name="insurancePCNNumber" value={enrollment_data.insurance.medical_insurance.pcn} />
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <p className="nothave-field">
                    <label className="checkbox" style={{ fontSize: '1em', lineHeight: '1.49em' }}><input onChange={e => handleCheckbox(e)} id='check' type="checkbox" name="nothave" value="yes" />&nbsp;Insurance card does not have BIN and PCN
                        <span className="checkmark "></span>
                    </label>
                </p>
            </div>
        </div>
    )
}