import React from 'react'

export default function PasswordRequestSuccess(){
    return (
<div className="container" id="content" style={{fontSize: '1em'}}>
<div id="container">
  <div id="hcp_post_content">
    <h2 style={{fontSize: '1.25em' , lineHeight: '1.49em'}}>Forgot My Password </h2>

 <p>If a matching username and associated email address was found, you will receive a password reset email. If you need further assistance, please contact the Help Center at <strong>(855) 965-2472</strong>. </p>
</div>
  <div className="clearfloat"></div>
 </div>
</div>

                    
           
    )
}