import React, { useEffect } from 'react' 
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import UpdatePharmacyComponent from '../components/UpdatePharmacy'
import AccountNavbar from '../components/AccountNavbar'
import PatientProfileDetails from '../components/PatientProfileDetails'
import MembershipInformation from '../components/MembershipInformation'
import CommercialMedicalInsurance from '../components/CommercialMedicalInsurance'
import PatientEnrollmentDetails from '../components/PatientEnrollmentDetails'

export default function UpdatePharmacy() {

	useEffect(() => {
		$(document).ready(function () {
			$('.page-alert').css('display', 'block');

			$('.page-alert .close-button').on('click', function (event) {
				event.preventDefault();
				$('.page-alert').css('display', 'none');
			});

		});
	})

	return (
		<div className="container" id="content" style={{ fontSize: '1em' }}>
			<div id="container" className="rtx_prof">
				<div id="hcp_post_content">
					<AccountNavbar page="profile" />
					<div className="clearfloat"></div>
					<div className="formBox">
						<PatientProfileDetails />
					</div>
					<MembershipInformation />
					<CommercialMedicalInsurance />
					<UpdatePharmacyComponent url='profile' />
					<PatientEnrollmentDetails />
					<div className="clearfloat"></div>
				</div>
			</div>
		</div>
	)
}