import React, { useEffect } from 'react'
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import PatientInfo from '../components/PatientInfo'
import DocumentSubmission from '../components/DocumentSubmission'



export default function SubmitDocument() {

  useEffect(() => {
    /*
    $("#input-ficons-6").fileinput({
      uploadUrl: "",
      uploadAsync: false,
      previewFileIcon: '<i className="fas fa-file"></i>',
      preferIconicZoomPreview: true, // this will force zoom preview thumbnails to display icons for following file extensions
      previewFileIconSettings: { // configure your icon file extensions
          'doc': '<i className="fas fa-file-word text-primary"></i>',
          'xls': '<i className="fas fa-file-excel text-success"></i>',
          'ppt': '<i className="fas fa-file-powerpoint text-danger"></i>',
          'pdf': '<i className="fas fa-file-pdf text-danger"></i>',
          'zip': '<i className="fas fa-file-archive text-muted"></i>',
          'htm': '<i className="fas fa-file-code text-info"></i>',
          'txt': '<i className="fas fa-file-text text-info"></i>',
          'mov': '<i className="fas fa-file-movie-o text-warning"></i>',
          'mp3': '<i className="fas fa-file-audio text-warning"></i>',
          // note for these file types below no extension determination logic
          // has been configured (the keys itself will be used as extensions)
          'jpg': '<i className="fas fa-file-image text-danger"></i>',
          'gif': '<i className="fas fa-file-image text-warning"></i>',
          'png': '<i className="fas fa-file-image text-primary"></i>'
      },
      previewFileExtSettings: { // configure the logic for determining icon file extensions
          'doc': function(ext) {
              return ext.match(/(doc|docx)$/i);
          },
          'xls': function(ext) {
              return ext.match(/(xls|xlsx)$/i);
          },
          'ppt': function(ext) {
              return ext.match(/(ppt|pptx)$/i);
          },
          'zip': function(ext) {
              return ext.match(/(zip|rar|tar|gzip|gz|7z)$/i);
          },
          'htm': function(ext) {
              return ext.match(/(htm|html)$/i);
          },
          'txt': function(ext) {
              return ext.match(/(txt|ini|csv|java|php|js|css)$/i);
          },
          'mov': function(ext) {
              return ext.match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i);
          },
          'mp3': function(ext) {
              return ext.match(/(mp3|wav)$/i);
          },
      }
  });
  
  */
  })

  

  

  return (
    <div className="container" id="content">
      <div id="container" className="upload-content">
        <div id="hcp_post_content">
          <h1 style={{ fontSize: '1.5em', lineHeight: '1.49em' }}>Upload Document</h1>
          <PatientInfo />
          <DocumentSubmission cancel='profile' />
          <p className="clearfloat"></p>
        </div>
      </div>
    </div>
  )
}