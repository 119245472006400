import React from 'react'

export default function ProgressBar(props) {
    return (
        <>
            <h1 style={{ fontSize: '1.5em', lineHeight: '1.49em' }}>{props.title}</h1>
            <ul className="progressbar">
                <li className={(props.step === '1') ? "current" : (props.step === '2' || props.step === '3' || props.step === '4' || props.step === '5' || props.step === '6') ? "active" : ""}>IDENTIFY YOURSELF</li>
                <li className={(props.step === '2') ? "current" : (props.step === '3' || props.step === '4' || props.step === '5' || props.step === '6') ? "active" : ""}>Support Option</li>
                <li className={(props.step === '3') ? "current" : (props.step === '4' || props.step === '5' || props.step === '6') ? "active" : ""}>Eligibility</li>
                <li className={(props.step === '4') ? "current" : (props.step === '5' || props.step === '6') ? "active" : ""}>Patient<br />Information</li>
                <li className={(props.step === '5') ? "current" : (props.step === '6') ? "active" : ""}>Provider's <br />Information</li>
                <li className={(props.step === '6') ? "active" : ""}>Verify Information</li>
            </ul>
        </>
    )
}