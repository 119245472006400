import React from 'react'

export default function PasswordRequest() {
    return (
        <div class="container" id="content" style={{ fontSize: '1em' }}>
            <div id="container">
                <div id="hcp_post_content">
                    <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Forgot My Password </h2>
                    <div class="find_pass">
                        <form action="password-request-success" class="form-validate" acceptCharset="utf-8">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <p>Please enter your username:</p>
                                <p class="field required-field text"><label style={{ fontSize: '1em', lineHeight: '1.49em' }}>your username.</label>
                                    <input type="text" name="" id="pass_1" autocomplete="off" />
                                </p>
                            </div>
                            <p style={{ textAlign: 'center' }}><br />
                                <button type="submit" name="loginSubmit" autocomplete="off">Submit</button>
                            </p>
                        </form>
                        <p>Forgot your username? Call <strong> (855) 965-2472</strong> for further assistance. </p>
                    </div>
                </div>
                <div class="clearfloat"></div>
            </div>
        </div>



    )
}