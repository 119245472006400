import React from "react";
import XolairPatientCheckRequestForm from "../docs/Xolair_Check_Request_Form.pdf";

export default function Forms() {
    return (
        <div className="container" id="content">
            <div id="container">
                <h1 style={{ fontSize: "1.5em", lineHeight: "1.49em" }}>Downloadable Forms</h1>
                <p>
                    <a href={XolairPatientCheckRequestForm} target="_blank">
                        Check Request Form for Patients
                    </a>
                </p>
                <p>
                    Complete this form when you are seeking reimbursement after paying the provider for your treatment. The instructions for
                    using the program with these providers are included on the form.
                </p>
                <div className="clearfloat"></div>
            </div>
        </div>
    );
}
