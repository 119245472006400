import React from 'react'
import { useOutletContext } from 'react-router-dom'

export default function ReviewCommercialMedicalInsurance() {

    const { enrollment_data, setenrollmentdata } = useOutletContext()

    return (
        <div className="section shadow-box color-label">
            <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Commercial Medical Insurance Information</h2>
            <div className="col-sm-12 col-md-12 col-lg-4">
                <p className=" text insurance-info ignore">
                    <label htmlFor="insuranceCompanyName" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Insurance company name</label><br />
                    {enrollment_data.insurance.medical_insurance.name}    </p></div>

            <div className="col-sm-12 col-md-12 col-lg-4">
                <p className="field require-field dropdown insurance-info ignore">
                    <label htmlFor="insurancePlanType" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Insurance plan type</label><br />
                    {enrollment_data.insurance.medical_insurance.plan_type}    </p></div>

            <div className="col-sm-12 col-md-12 col-lg-4">
                <p className=" text insurance-info ignore">
                    <label htmlFor="insuranceGroupNumber" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Insurance group number</label>
                    {enrollment_data.insurance.medical_insurance.group_number}    </p></div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className=" text insurance-info ignore">
                        <label htmlFor="insuranceMemberNumber" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Insurance member number</label>
                        {enrollment_data.insurance.medical_insurance.member_number}    </p></div>

                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className=" text insurance-info ignore">
                        <label htmlFor="insuranceMemberNumber" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Insurance effective date</label>
                        {enrollment_data.insurance.medical_insurance.effective_date}    </p></div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12"><div className="col-sm-12 col-md-12 col-lg-4">
                <p className=" text insurance-info ignore">
                    <label htmlFor="insuranceBINNumber" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>BIN</label>
                    {enrollment_data.insurance.medical_insurance.bin}    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className=" text insurance-info ignore">
                        <label htmlFor="insurancePCNNumber" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>PCN</label>
                        {enrollment_data.insurance.medical_insurance.pcn}    </p></div>
            </div>

        </div>
    )
}