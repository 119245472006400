import React, { useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import { PATIENT_USER_PROFILE } from '../graphql/queries/Patients'

export default function UserProfile() {

  const patient_id = parseInt(window.localStorage.getItem('xrefID'))
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setemail] = useState('');
  const [memberid, setmemberid] = useState('');

  const { loading, error, data, refetch } = useQuery(PATIENT_USER_PROFILE, {
    variables: { patient_id }
  });

  useEffect(() => {
    if (data) {
      if (data.patientinfo != null) {
        if (data.patientinfo.member_id) {
          setmemberid(data.patientinfo.member_id)
        }
        if (data.patientinfo.enrollment_data != null) {
          if (data.patientinfo.enrollment_data.first_name) {
            setFirstName(data.patientinfo.enrollment_data.first_name)
          }
          if (data.patientinfo.enrollment_data.last_name) {
            setLastName(data.patientinfo.enrollment_data.last_name)
          }
          if (data.patientinfo.enrollment_data.email) {
            setemail(data.patientinfo.enrollment_data.email)
          }
        }
      }
    }
  })

  return (
    <div className="section shadow-box  color-label">
      <div id="hcp_post_content">
        <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>User Profile</h2>
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <p><label style={{ fontSize: '1em', lineHeight: '1.49em' }}>Username</label>{memberid}</p><p>&nbsp;</p>
            <p><label style={{ fontSize: '1em', lineHeight: '1.49em' }}>Email Address</label>{email}</p>
            <p>&nbsp;</p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <p><label style={{ fontSize: '1em', lineHeight: '1.49em' }}>First Name</label>{firstName} </p><p>&nbsp;</p>
            <p><label style={{ fontSize: '1em', lineHeight: '1.49em' }}>Last Login Date</label><br /></p>
            <p>&nbsp;</p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <p><label style={{ fontSize: '1em', lineHeight: '1.49em' }}>Last Name</label>{lastName}</p><p>&nbsp;</p>
            <p><label style={{ fontSize: '1em', lineHeight: '1.49em' }}>Password Expiration Date</label><br /></p><p>&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  )
}