import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { PATIENT_ENROLLMENT_DETAILS } from "../graphql/queries/Patients";

export default function PatientEnrollmentDetails(props) {
    var patient_id = parseInt(window.localStorage.getItem("xrefID"));
    if (props.patientID) {
        patient_id = parseInt(props.patientID);
    }

    const year = new Date().getFullYear();
    const [active, setactive] = useState("");
    const [product, setproduct] = useState("XOLAIR");
    const [enrollmentstart, setenrollmentstart] = useState("");
    const [enrollmentend, setenrollmentend] = useState("");
    const [numuses, setnumuses] = useState(0);
    const [maxbenefit, setmaxbenefit] = useState("");
    const [benefitused, setbenefitused] = useState("");
    const [availablebenefit, setavailablebenefit] = useState("");

    const [adminactive, setadminactive] = useState("");
    const [adminproduct, setadminproduct] = useState("XOLAIR");
    const [adminenrollmentstart, setadminenrollmentstart] = useState("");
    const [adminenrollmentend, setadminenrollmentend] = useState("");
    const [adminnumuses, setadminnumuses] = useState(0);
    const [adminmaxbenefit, setadminmaxbenefit] = useState("");
    const [adminbenefitused, setadminbenefitused] = useState("");
    const [adminavailablebenefit, setadminavailablebenefit] = useState("");

    const { loading, error, data, refetch } = useQuery(PATIENT_ENROLLMENT_DETAILS, {
        variables: { patient_id },
    });

    useEffect(() => {
        if (patient_id) {
            if (data != null) {
                if (data.patientinfo != null) {
                    if (data.patientinfo.enrollment_status != null) {
                        if (data.patientinfo.enrollment_status) {
                            setactive(data.patientinfo.enrollment_status);
                        }
                    }
                    if (data.patientinfo.drug_patient != null) {
                        if (data.patientinfo.drug_patient) {
                            setproduct(data.patientinfo.drug_patient);
                        }
                    }
                    if (data.patientinfo.drug_admin_patient != null) {
                        if (data.patientinfo.drug_admin_patient) {
                            setadminproduct(data.patientinfo.drug_admin_patient);
                        }
                    }
                    if (data.patientinfo.current_enrollment_date_drug != null) {
                        if (data.patientinfo.current_enrollment_date_drug) {
                            setenrollmentstart(data.patientinfo.current_enrollment_date_drug);
                        }
                    }
                    if (data.patientinfo.current_enrollment_date_drug_admin != null) {
                        if (data.patientinfo.current_enrollment_date_drug_admin) {
                            setadminenrollmentstart(data.patientinfo.current_enrollment_date_drug_admin);
                        }
                    }
                    if (data.patientinfo.benefits_amounts != null) {
                        if (data.patientinfo.benefits_amounts.current_period != null) {
                            if (data.patientinfo.benefits_amounts.current_period.total_amount_drug != null) {
                                if (data.patientinfo.benefits_amounts.current_period.total_amount_drug) {
                                    setmaxbenefit(data.patientinfo.benefits_amounts.current_period.total_amount_drug);
                                }
                            }
                            if (data.patientinfo.benefits_amounts.current_period.total_amount_drug_admin != null) {
                                if (data.patientinfo.benefits_amounts.current_period.total_amount_drug_admin) {
                                    setadminmaxbenefit(data.patientinfo.benefits_amounts.current_period.total_amount_drug_admin);
                                }
                            }
                            if (data.patientinfo.benefits_amounts.current_period.balance_amount_drug != null) {
                                if (data.patientinfo.benefits_amounts.current_period.balance_amount_drug) {
                                    setavailablebenefit(data.patientinfo.benefits_amounts.current_period.balance_amount_drug);
                                }
                            }
                            if (data.patientinfo.benefits_amounts.current_period.balance_amount_drug_admin != null) {
                                if (data.patientinfo.benefits_amounts.current_period.balance_amount_drug_admin) {
                                    setadminavailablebenefit(data.patientinfo.benefits_amounts.current_period.balance_amount_drug_admin);
                                }
                            }
                            if (data.patientinfo.benefits_amounts.current_period.used_amount_drug != null) {
                                if (data.patientinfo.benefits_amounts.current_period.used_amount_drug) {
                                    setbenefitused(data.patientinfo.benefits_amounts.current_period.used_amount_drug);
                                }
                            }
                            if (data.patientinfo.benefits_amounts.current_period.used_amount_drug_admin != null) {
                                if (data.patientinfo.benefits_amounts.current_period.used_amount_drug_admin) {
                                    setadminbenefitused(data.patientinfo.benefits_amounts.current_period.used_amount_drug_admin);
                                }
                            }
                            if (data.patientinfo.benefits_amounts.current_period.use_count_drug != null) {
                                if (data.patientinfo.benefits_amounts.current_period.use_count_drug) {
                                    setnumuses(data.patientinfo.benefits_amounts.current_period.use_count_drug);
                                }
                            }
                            if (data.patientinfo.benefits_amounts.current_period.use_count_drug_admin != null) {
                                if (data.patientinfo.benefits_amounts.current_period.use_count_drug_admin) {
                                    setadminnumuses(data.patientinfo.benefits_amounts.current_period.use_count_drug_admin);
                                }
                            }
                        }
                    }
                }
            } else {
            }
        } else {
        }
    }, [data]);

    return (
        <div className="section shadow-box color-label">
            <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>Patient Enrollment Details</h2>

            <table className="account">
                <tbody>
                    <tr>
                        <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Active</th>
                        <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Product</th>
                        <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Current enrollment period</th>
                        <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Number of uses</th>
                        <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Benefit info</th>
                    </tr>
                    {product == "Y" || product == "y" ? (
                        <tr>
                            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}> {active && active.toLowerCase() === "active" ? "Yes" : "No"}</td>
                            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>Xolair Drug</td>
                            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                {enrollmentstart} - {year}-12-31
                            </td>
                            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{numuses}</td>
                            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <strong>Max Benefit:</strong> ${maxbenefit}
                                <br /> <strong>Benefit Used:</strong> ${benefitused} <br />
                                <strong>Available Benefit:</strong> ${availablebenefit}
                            </td>
                        </tr>
                    ) : (
                        <></>
                    )}

                    {adminproduct == "Y" || adminproduct == "y" ? (
                        <tr>
                            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}> {active && active.toLowerCase() === "active" ? "Yes" : "No"}</td>
                            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>Xolair Administration</td>
                            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                {adminenrollmentstart} - {year}-12-31
                            </td>
                            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{adminnumuses}</td>
                            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <strong>Max Benefit:</strong> ${adminmaxbenefit}
                                <br /> <strong>Benefit Used:</strong> ${adminbenefitused} <br />
                                <strong>Available Benefit:</strong> ${adminavailablebenefit}
                            </td>
                        </tr>
                    ) : (
                        <></>
                    )}
                </tbody>
            </table>
        </div>
    );
}
