import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import UpdateInsuranceComponent from '../components/UpdateInsurance'
import AccountNavbar from '../components/AccountNavbar'
import PatientProfileDetails from '../components/PatientProfileDetails'
import MembershipInformation from '../components/MembershipInformation'
import PharmacyInsuranceInformation from '../components/PharmacyInsuranceInformation'
import PatientEnrollmentDetails from '../components/PatientEnrollmentDetails'




export default function UpdateInsurance() {

	useEffect(() => {
		$(document).ready(function () {
			$('#save').on('click', function (event) {
				event.preventDefault();
				$('#save-popup').css('display', 'block');
			});
			$('.save-popup .close-button').on('click', function (event) {
				event.preventDefault();
				$('.save-popup').css('display', 'none');
			});
			$('#cancel').on('click', function (event) {
				event.preventDefault();
				$('.update_insurance').submit();
			});
		});
		$(document).ready(function () {
			$('#ok').on('click', function (event) {
				event.preventDefault();
				$('.update_insurance').submit();
			});
			$('.ok-popup .close-button').on('click', function (event) {
				event.preventDefault();
				$('.ok-popup').css('display', 'none');
			});
			$('.save-updates').on('click', function (event) {
				event.preventDefault();
				$('.update_insurance').submit();
			});
		});
		$(document).ready(function () {
			$('.ok-popup').css('display', 'block');

			$('.ok-popup .close-button').on('click', function (event) {
				event.preventDefault();
				$('.ok-popup').css('display', 'none');
			});
		});
		$(document).ready(function () {
			$(".datepicker").datepicker({
				dateFormat: 'mm/dd/yy', showButtonPanel: true,
				changeMonth: true,
				changeYear: true,
				showOtherMonths: true,
				selectOtherMonths: true
			});
		});
	})


	return (
		<div className="container" id="content" style={{ fontSize: '1em' }}>
			<div className="ok-popup" style={{ display: 'none' }}>
				<div className="popup-box" style={{ padding: '20px' }}>
					<p>Updates to patient profile information may not be reflected immediately.</p>
					<button className="close-button">Close</button>
				</div>
			</div>
			<div id="container" className="rtx_prof">
				<div id="hcp_post_content">
					<AccountNavbar page="profile" />
					<div className="clearfloat"></div>
					<div className="space"></div>
					<PatientProfileDetails />
					<MembershipInformation />
					<UpdateInsuranceComponent url='profile' />
					<PharmacyInsuranceInformation />
					<PatientEnrollmentDetails />
					<div className="clearfloat"></div>
				</div>
			</div>
		</div>
	)
}