import React, { useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'


export default function EnrollAdditionalPatient() {

    const { setTitle, setStep, product, setproduct } = useOutletContext()

    const enrolledproduct = window.localStorage.getItem('product')
    const enrollmentType = window.localStorage.getItem('enrollment_type')

    let navigate = useNavigate();

    function submitForm(e) {
        e.preventDefault();
        window.localStorage.setItem('product', product)
        if(product == 'drug'){
            let path = `../eligibility-` + enrollmentType + `-drug-only`
            navigate(path)
        } else if (product == 'admin'){
            let path = `../eligibility-` + enrollmentType + `-infusion-only`
            navigate(path)
        } else if (product == 'drug-admin') {
            let path = `../eligibility-` + enrollmentType + `-drug-infusion`
            navigate(path)
        }
    }

    function handleChange() {

    }

    function updateProduct(productName){
        setproduct(productName)
    }

    function continueBtn() {
        let path = `../enroll`;
        navigate(path);
    }


    useEffect(() => {
        setTitle('Apply for Additional Assistance')
        setStep('2')
    }, [])

    return (
        <>

            {((enrolledproduct == 'drug') || (enrolledproduct == 'admin')) ?
                <form className="enroll-additional-patient" onSubmit={e => submitForm(e)} acceptCharset="utf-8">
                    <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Please answer the question that applies</h2>

                    {enrolledproduct == 'drug' ? <>
                        <p>&nbsp;</p>
                        <p><strong>The patient is already enrolled in the XOLAIR Co-pay Program for drug assistance.</strong></p>
                        <p> Would the patient like to enroll to receive help with administration costs?</p>
                        <p>
                            <label className="radio" style={{ fontSize: '1em', lineHeight: '1.49em' }}> <input onClick={e => { updateProduct('admin') }} type="radio" name="options-infusion" value="yes" /> Yes<span className="checkmark"></span></label>
                            <label className="radio" style={{ fontSize: '1em', lineHeight: '1.49em' }}> <input onChange={console.log('change')} type="radio" name="options-infusion" value="no" /> No<span className="checkmark"></span></label>
                            <input onChange={handleChange} type="hidden" name="re-enroll" value="yes" />
                        </p>
                        <p>
                            <button type="submit" name="submit" value="submit">Submit</button>
                            <button className="cancel" name="submit" value="cancel">Cancel</button>
                        </p>
                    </> : <></>}


                    {enrolledproduct == 'admin' ? <>
                        <p>&nbsp;</p>
                        <p><strong>The patient is already enrolled in the XOLAIR Co-pay Program for administration assistance.</strong></p>
                        <p> Would the patient like to enroll to receive help with drug costs? </p>
                        <p>
                            <label className="radio" style={{ fontSize: '1em', lineHeight: '1.49em' }}> <input onChange={updateProduct('drug')} type="radio" name="options-drug" value="yes" /> Yes<span className="checkmark"></span></label>
                            <label className="radio" style={{ fontSize: '1em', lineHeight: '1.49em' }}><input onChange={handleChange} type="radio" name="options-drug" value="no" /> No<span className="checkmark"></span></label>
                            <input onChange={handleChange} type="hidden" name="re-enroll" value="yes" />
                        </p>
                        <p>
                            <button type="submit" name="submit" value="submit">Submit</button>
                            <button className="cancel" name="submit" value="cancel">Cancel</button>
                        </p>
                    </> : <></>}



                </form>
                : <></>}



            {enrolledproduct == 'drug-admin' ? <>
                <p>&nbsp;</p>
                <p><strong>The patient is already enrolled in the XOLAIR Co-pay Program for both drug and administration assistance. </strong></p>
                <p><button onClick={continueBtn} className="cancel">Ok</button></p>
            </> : <></>}


            {!((enrolledproduct == 'drug') || (enrolledproduct == 'admin') || (enrolledproduct == 'drug-admin')) ? <>
                <p>&nbsp;</p>
                <p><strong>The patient is not enrolled in any XOLAIR Co-pay Program for either drug or administration assistance.</strong></p>
                <p>
                    <button onClick={updateProduct('drug-admin')}>Continue</button>
                </p>
            </> : <></>}




            <div className="clearfloat"></div>
        </>
    )
}