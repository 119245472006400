import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useLazyQuery, gql } from "@apollo/client";
import { PROVIDER_SEARCH } from "../graphql/queries/Patients";
import { SEARCH_SITE_OF_CARE } from "../graphql/queries/Providers";
import ProviderSearch from "./ProviderSearch";
import SiteOfCareSearch from "./SiteOfCareSearch";
import FillProviderForm from "./FillProviderForm";
import FillSiteOfCareForm from "./FillSiteOfCareForm";

export default function EnrollDoctorInformationForm() {
    const { provider_data, setproviderdata } = useOutletContext();
    const [isHcp, setIsHcp] = useState(false);
    const [isDrugOnly, setIsDrugOnly] = useState(false);

    var existing_member = false;
    if (window.localStorage.getItem("existing_member") == "true") {
        existing_member = true;
    } else {
        existing_member = false;
    }

    var provider_user = false;
    var autofill;
    var autofillJSON;
    var product = window.localStorage.getItem("product") ? window.localStorage.getItem("product") : "";
    useEffect(() => {
        if (product != "") {
            if (!product.toString().toLowerCase().includes("admin")) {
                setIsDrugOnly(true);
            } else {
                setIsDrugOnly(false);
            }
        } else {
            navigate("/enroll");
        }
        if (window.location.toString().includes("hcp")) setIsHcp(true);
        else {
            setIsHcp(false);
        }
    }, []);

    if (window.localStorage.getItem("providerID") && window.location.toString().includes("hcp")) {
        provider_user = true;
        //in tandem with prov_user
        // setIsHcp(true);
        autofill = window.localStorage.getItem("autofillProvider");
        autofillJSON = JSON.parse(autofill);
    } else {
        provider_user = false;
    }

    const [showProviderResults, setProviderResults] = useState(false);
    const [showCareResults, setCareResults] = useState(false);

    const [provider_first_name, setproviderfirstname] = useState(
        autofillJSON != null
            ? autofillJSON.providerfind != null
                ? autofillJSON.providerfind.provider_data != null
                    ? autofillJSON.providerfind.provider_data.first_name
                        ? autofillJSON.providerfind.provider_data.first_name
                        : ""
                    : ""
                : ""
            : ""
    );
    const [provider_last_name, setproviderlastname] = useState(
        autofillJSON != null
            ? autofillJSON.providerfind != null
                ? autofillJSON.providerfind.provider_data != null
                    ? autofillJSON.providerfind.provider_data.address != null
                        ? autofillJSON.providerfind.provider_data.address.state
                            ? autofillJSON.providerfind.provider_data.address.state.toUpperCase()
                            : ""
                        : ""
                    : ""
                : ""
            : ""
    );
    const [provider_state, setproviderstate] = useState(
        autofillJSON != null
            ? autofillJSON.providerfind != null
                ? autofillJSON.providerfind.provider_data != null
                    ? autofillJSON.providerfind.provider_data.address != null
                        ? autofillJSON.providerfind.provider_data.address.state
                            ? autofillJSON.providerfind.provider_data.address.state.toUpperCase()
                            : ""
                        : ""
                    : ""
                : ""
            : ""
    );

    const [input_provider_first_name, setinputproviderfirstname] = useState(
        autofillJSON != null
            ? autofillJSON.providerfind != null
                ? autofillJSON.providerfind.provider_data != null
                    ? autofillJSON.providerfind.provider_data.first_name
                        ? autofillJSON.providerfind.provider_data.first_name
                        : ""
                    : ""
                : ""
            : ""
    );

    const [input_provider_last_name, setinputproviderlastname] = useState(
        autofillJSON != null
            ? autofillJSON.providerfind != null
                ? autofillJSON.providerfind.provider_data != null
                    ? autofillJSON.providerfind.provider_data.address != null
                        ? autofillJSON.providerfind.provider_data.address.state
                            ? autofillJSON.providerfind.provider_data.address.state.toUpperCase()
                            : ""
                        : ""
                    : ""
                : ""
            : ""
    );
    const [input_provider_state, setinputproviderstate] = useState(
        autofillJSON != null
            ? autofillJSON.providerfind != null
                ? autofillJSON.providerfind.provider_data != null
                    ? autofillJSON.providerfind.provider_data.address != null
                        ? autofillJSON.providerfind.provider_data.address.state
                            ? autofillJSON.providerfind.provider_data.address.state.toUpperCase()
                            : ""
                        : ""
                    : ""
                : ""
            : ""
    );

    const [soc_practice_name, setsocpracticename] = useState("");
    const [soc_state, setsocstate] = useState("");

    const [input_soc_practice_name, setinputsocpracticename] = useState("");
    const [input_soc_state, setinputsocstate] = useState("");

    const [getResults, { called, loading, data: providerResults }] = useLazyQuery(PROVIDER_SEARCH, {
        variables: { provider_first_name, provider_last_name, provider_state },
    });

    const [getSOCResults, { called: socCalled, loading: socLoading, data: socResults }] = useLazyQuery(SEARCH_SITE_OF_CARE, {
        variables: { soc_practice_name, soc_state },
    });
    function handleChange() {}

    let navigate = useNavigate();
    function searchProvider() {
        setproviderfirstname(input_provider_first_name);
        setproviderlastname(input_provider_last_name);
        setproviderstate(input_provider_state);
        if (!(input_provider_first_name == "" || input_provider_last_name == "" || input_provider_state == "")) {
            setProviderResults(true);
            getResults({ provider_first_name, provider_last_name, provider_state });
        }
    }

    function searchCare() {
        setsocpracticename(input_soc_practice_name);
        setsocstate(input_soc_state);
        if (!(input_soc_practice_name == "" || input_soc_state == "")) {
            setCareResults(true);
            getSOCResults({ soc_practice_name, soc_state });
        }
    }

    return (
        <>
            <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>Search for your provider:</h2>
            <div className="search-provider">
                <div className="search-form  form-validate-search-provider" acceptCharset="utf-8">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="field required-field text">
                            <label htmlFor="search_lastName" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <span className="inner-label">
                                    First name<span className="required">*</span>:
                                </span>
                            </label>
                            <input
                                readOnly={existing_member == true ? true : false}
                                onChange={(e) => setinputproviderfirstname(e.target.value)}
                                type="text"
                                id="search_firstName"
                                name="search_firstName"
                                value={input_provider_first_name}
                            />
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="field required-field text">
                            <label htmlFor="search_lastName" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <span className="inner-label">
                                    Last name<span className="required">*</span>:
                                </span>
                            </label>
                            <input
                                readOnly={existing_member == true ? true : false}
                                onChange={(e) => setinputproviderlastname(e.target.value)}
                                type="text"
                                id="search_lastName"
                                name="search_lastName"
                                value={input_provider_last_name}
                            />
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <p className="field required-field dropdown">
                                <label htmlFor="search_state" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                    <span className="inner-label">
                                        State<span className="required">*</span>:
                                    </span>
                                </label>
                                <span className="select_container">
                                    <select
                                        disabled={existing_member == true ? "disabled" : false}
                                        name="search_state"
                                        defaultValue="default"
                                        value={input_provider_state}
                                        onChange={(e) => setinputproviderstate(e.target.value)}
                                    >
                                        <option label="" value="default">
                                            Select a state
                                        </option>
                                        <option label="Alabama" value="AL">
                                            Alabama
                                        </option>
                                        <option label="Alaska" value="AK">
                                            Alaska
                                        </option>
                                        <option label="American Samoa" value="AS">
                                            American Samoa
                                        </option>
                                        <option label="Arizona" value="AZ">
                                            Arizona
                                        </option>
                                        <option label="Arkansas" value="AR">
                                            Arkansas
                                        </option>
                                        <option label="California" value="CA">
                                            California
                                        </option>
                                        <option label="Colorado" value="CO">
                                            Colorado
                                        </option>
                                        <option label="Connecticut" value="CT">
                                            Connecticut
                                        </option>
                                        <option label="Delaware" value="DE">
                                            Delaware
                                        </option>
                                        <option label="Florida" value="FL">
                                            Florida
                                        </option>
                                        <option label="Georgia" value="GA">
                                            Georgia
                                        </option>
                                        <option label="Guam" value="GU">
                                            Guam
                                        </option>
                                        <option label="Hawaii" value="HI">
                                            Hawaii
                                        </option>
                                        <option label="Idaho" value="ID">
                                            Idaho
                                        </option>
                                        <option label="Illinois" value="IL">
                                            Illinois
                                        </option>
                                        <option label="Indiana" value="IN">
                                            Indiana
                                        </option>
                                        <option label="Iowa" value="IA">
                                            Iowa
                                        </option>
                                        <option label="Kansas" value="KS">
                                            Kansas
                                        </option>
                                        <option label="Kentucky" value="KY">
                                            Kentucky
                                        </option>
                                        <option label="Louisiana" value="LA">
                                            Louisiana
                                        </option>
                                        <option label="Maine" value="ME">
                                            Maine
                                        </option>
                                        <option label="Maryland" value="MD">
                                            Maryland
                                        </option>
                                        <option label="Massachusetts" value="MA">
                                            Massachusetts
                                        </option>
                                        <option label="Michigan" value="MI">
                                            Michigan
                                        </option>
                                        <option label="Minnesota" value="MN">
                                            Minnesota
                                        </option>
                                        <option label="Mississippi" value="MS">
                                            Mississippi
                                        </option>
                                        <option label="Missouri" value="MO">
                                            Missouri
                                        </option>
                                        <option label="Montana" value="MT">
                                            Montana
                                        </option>
                                        <option label="Nebraska" value="NE">
                                            Nebraska
                                        </option>
                                        <option label="Nevada" value="NV">
                                            Nevada
                                        </option>
                                        <option label="New Hampshire" value="NH">
                                            New Hampshire
                                        </option>
                                        <option label="New Jersey" value="NJ">
                                            New Jersey
                                        </option>
                                        <option label="New Mexico" value="NM">
                                            New Mexico
                                        </option>
                                        <option label="New York" value="NY">
                                            New York
                                        </option>
                                        <option label="North Carolina" value="NC">
                                            North Carolina
                                        </option>
                                        <option label="North Dakota" value="ND">
                                            North Dakota
                                        </option>
                                        <option label="Northern Mariana Islands" value="NM">
                                            Northern Mariana Islands
                                        </option>
                                        <option label="Ohio" value="OH">
                                            Ohio
                                        </option>
                                        <option label="Oklahoma" value="OK">
                                            Oklahoma
                                        </option>
                                        <option label="Oregon" value="OR">
                                            Oregon
                                        </option>
                                        <option label="Pennsylvania" value="PA">
                                            Pennsylvania
                                        </option>
                                        <option label="Puerto Rico" value="PR">
                                            Puerto Rico
                                        </option>
                                        <option label="Rhode Island" value="RI">
                                            Rhode Island
                                        </option>
                                        <option label="South Carolina" value="SC">
                                            South Carolina
                                        </option>
                                        <option label="South Dakota" value="SD">
                                            South Dakota
                                        </option>
                                        <option label="Tennessee" value="TN">
                                            Tennessee
                                        </option>
                                        <option label="Texas" value="TX">
                                            Texas
                                        </option>
                                        <option label="U.S. Virgin Islands" value="VI">
                                            U.S. Virgin Islands
                                        </option>
                                        <option label="Utah" value="UT">
                                            Utah
                                        </option>
                                        <option label="Vermont" value="VT">
                                            Vermont
                                        </option>
                                        <option label="Virginia" value="VA">
                                            Virginia
                                        </option>
                                        <option label="Washington" value="WA">
                                            Washington
                                        </option>
                                        <option label="Washington DC" value="DC">
                                            Washington DC
                                        </option>
                                        <option label="West Virginia" value="WV">
                                            West Virginia
                                        </option>
                                        <option label="Wisconsin" value="WI">
                                            Wisconsin
                                        </option>
                                        <option label="Wyoming" value="WY">
                                            Wyoming
                                        </option>{" "}
                                    </select>
                                </span>
                            </p>{" "}
                        </div>
                    </div>
                    <input readOnly={existing_member == true ? true : false} onChange={handleChange} type="hidden" name="search_action_provider" value="1" />
                    <input readOnly={existing_member == true ? true : false} onChange={handleChange} type="hidden" name="search_action_care" value="" />
                    <input
                        readOnly={existing_member == true ? true : false}
                        onChange={handleChange}
                        type="hidden"
                        className="select_provider"
                        name="select_provider"
                        value=""
                    />
                    <input
                        readOnly={existing_member == true ? true : false}
                        onChange={handleChange}
                        type="hidden"
                        className="select_care"
                        name="select_care"
                        value=""
                    />
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <p className="field" style={{ textAlign: "left" }}>
                            <label htmlFor="approve" style={{ fontSize: "1em", lineHeight: "1.49em" }}></label>
                            <button disabled={existing_member == true ? true : false} onClick={searchProvider} name="search" value="Find" id="search">
                                Search
                            </button>
                        </p>
                    </div>
                    {!isDrugOnly && (
                        <p class="color">
                            <strong>
                                Please note that patients who reside in or are treated in Massachusetts or Rhode Island are NOT eligible for the administration
                                co-pay program.
                            </strong>
                        </p>
                    )}
                </div>
            </div>

            {showProviderResults || provider_user ? (
                <div className="search-div">
                    <table id="table">
                        <tbody>
                            <tr>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>First Name</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Last Name</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>NPI</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Address 1</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Address 2</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>City</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>State</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>ZIP</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Phone Number</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Select</th>
                            </tr>
                            {provider_user == true ? <ProviderSearch providerID={parseInt(window.localStorage.getItem("providerID"))} /> : <></>}
                            {providerResults &&
                                providerResults.providersearch.map(({ __typename, provider_id }) => <ProviderSearch providerID={provider_id} />)}
                            {providerResults && providerResults.providersearch == 0 ? (
                                <tr>
                                    <td colSpan="9" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        No matching provider
                                    </td>
                                    <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input onChange={handleChange} type="radio" name="result" value="0" />
                                    </td>
                                </tr>
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </table>
                </div>
            ) : (
                <></>
            )}

            <FillProviderForm />
            <div className="search-care section">
                <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>Search for your site of care (optional):</h2>
                <div className="search-form form-validate-search-care" acceptCharset="utf-8">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="field required-field text">
                            <label htmlFor="search_lastName" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <span className="inner-label">
                                    Practice name<span className="required">*</span>:
                                </span>
                            </label>
                            <input
                                readOnly={existing_member == true ? true : false}
                                onChange={(e) => setinputsocpracticename(e.target.value)}
                                type="text"
                                name="search_practice"
                                value={input_soc_practice_name}
                            />
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="field required-field dropdown">
                            <label htmlFor="search_state" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <span className="inner-label">
                                    State<span className="required">*</span>:
                                </span>
                            </label>
                            <span className="select_container">
                                <select
                                    disabled={existing_member == true ? "disabled" : false}
                                    name="search_state_care"
                                    defaultValue="default"
                                    value={input_soc_state}
                                    onChange={(e) => setinputsocstate(e.target.value)}
                                >
                                    <option label="" value="default">
                                        Select a state
                                    </option>
                                    <option label="Alabama" value="AL">
                                        Alabama
                                    </option>
                                    <option label="Alaska" value="AK">
                                        Alaska
                                    </option>
                                    <option label="American Samoa" value="AS">
                                        American Samoa
                                    </option>
                                    <option label="Arizona" value="AZ">
                                        Arizona
                                    </option>
                                    <option label="Arkansas" value="AR">
                                        Arkansas
                                    </option>
                                    <option label="California" value="CA">
                                        California
                                    </option>
                                    <option label="Colorado" value="CO">
                                        Colorado
                                    </option>
                                    <option label="Connecticut" value="CT">
                                        Connecticut
                                    </option>
                                    <option label="Delaware" value="DE">
                                        Delaware
                                    </option>
                                    <option label="Florida" value="FL">
                                        Florida
                                    </option>
                                    <option label="Georgia" value="GA">
                                        Georgia
                                    </option>
                                    <option label="Guam" value="GU">
                                        Guam
                                    </option>
                                    <option label="Hawaii" value="HI">
                                        Hawaii
                                    </option>
                                    <option label="Idaho" value="ID">
                                        Idaho
                                    </option>
                                    <option label="Illinois" value="IL">
                                        Illinois
                                    </option>
                                    <option label="Indiana" value="IN">
                                        Indiana
                                    </option>
                                    <option label="Iowa" value="IA">
                                        Iowa
                                    </option>
                                    <option label="Kansas" value="KS">
                                        Kansas
                                    </option>
                                    <option label="Kentucky" value="KY">
                                        Kentucky
                                    </option>
                                    <option label="Louisiana" value="LA">
                                        Louisiana
                                    </option>
                                    <option label="Maine" value="ME">
                                        Maine
                                    </option>
                                    <option label="Maryland" value="MD">
                                        Maryland
                                    </option>
                                    <option label="Massachusetts" value="MA">
                                        Massachusetts
                                    </option>
                                    <option label="Michigan" value="MI">
                                        Michigan
                                    </option>
                                    <option label="Minnesota" value="MN">
                                        Minnesota
                                    </option>
                                    <option label="Mississippi" value="MS">
                                        Mississippi
                                    </option>
                                    <option label="Missouri" value="MO">
                                        Missouri
                                    </option>
                                    <option label="Montana" value="MT">
                                        Montana
                                    </option>
                                    <option label="Nebraska" value="NE">
                                        Nebraska
                                    </option>
                                    <option label="Nevada" value="NV">
                                        Nevada
                                    </option>
                                    <option label="New Hampshire" value="NH">
                                        New Hampshire
                                    </option>
                                    <option label="New Jersey" value="NJ">
                                        New Jersey
                                    </option>
                                    <option label="New Mexico" value="NM">
                                        New Mexico
                                    </option>
                                    <option label="New York" value="NY">
                                        New York
                                    </option>
                                    <option label="North Carolina" value="NC">
                                        North Carolina
                                    </option>
                                    <option label="North Dakota" value="ND">
                                        North Dakota
                                    </option>
                                    <option label="Northern Mariana Islands" value="NM">
                                        Northern Mariana Islands
                                    </option>
                                    <option label="Ohio" value="OH">
                                        Ohio
                                    </option>
                                    <option label="Oklahoma" value="OK">
                                        Oklahoma
                                    </option>
                                    <option label="Oregon" value="OR">
                                        Oregon
                                    </option>
                                    <option label="Pennsylvania" value="PA">
                                        Pennsylvania
                                    </option>
                                    <option label="Puerto Rico" value="PR">
                                        Puerto Rico
                                    </option>
                                    <option label="Rhode Island" value="RI">
                                        Rhode Island
                                    </option>
                                    <option label="South Carolina" value="SC">
                                        South Carolina
                                    </option>
                                    <option label="South Dakota" value="SD">
                                        South Dakota
                                    </option>
                                    <option label="Tennessee" value="TN">
                                        Tennessee
                                    </option>
                                    <option label="Texas" value="TX">
                                        Texas
                                    </option>
                                    <option label="U.S. Virgin Islands" value="VI">
                                        U.S. Virgin Islands
                                    </option>
                                    <option label="Utah" value="UT">
                                        Utah
                                    </option>
                                    <option label="Vermont" value="VT">
                                        Vermont
                                    </option>
                                    <option label="Virginia" value="VA">
                                        Virginia
                                    </option>
                                    <option label="Washington" value="WA">
                                        Washington
                                    </option>
                                    <option label="Washington DC" value="DC">
                                        Washington DC
                                    </option>
                                    <option label="West Virginia" value="WV">
                                        West Virginia
                                    </option>
                                    <option label="Wisconsin" value="WI">
                                        Wisconsin
                                    </option>
                                    <option label="Wyoming" value="WY">
                                        Wyoming
                                    </option>{" "}
                                </select>
                            </span>
                        </p>
                    </div>
                    <input readOnly={existing_member == true ? true : false} onChange={handleChange} type="hidden" name="search_action_care" value="1" />
                    <input readOnly={existing_member == true ? true : false} onChange={handleChange} type="hidden" name="search_action_provider" value="" />
                    <input
                        readOnly={existing_member == true ? true : false}
                        onChange={handleChange}
                        type="hidden"
                        className="doc_firstName"
                        name="doc_firstName"
                        value=""
                    />
                    <input
                        readOnly={existing_member == true ? true : false}
                        onChange={handleChange}
                        type="hidden"
                        className="doc_lastName"
                        name="doc_lastName"
                        value=""
                    />
                    <input
                        readOnly={existing_member == true ? true : false}
                        onChange={handleChange}
                        type="hidden"
                        className="doc_address"
                        name="doc_address"
                        value=""
                    />
                    <input
                        readOnly={existing_member == true ? true : false}
                        onChange={handleChange}
                        type="hidden"
                        className="doc_address2"
                        name="doc_address2"
                        value=""
                    />
                    <input
                        readOnly={existing_member == true ? true : false}
                        onChange={handleChange}
                        type="hidden"
                        className="doc_city"
                        name="doc_city"
                        value=""
                    />
                    <input
                        readOnly={existing_member == true ? true : false}
                        onChange={handleChange}
                        type="hidden"
                        className="doc_state"
                        name="doc_state"
                        value=""
                    />
                    <input
                        readOnly={existing_member == true ? true : false}
                        onChange={handleChange}
                        type="hidden"
                        className="doc_zipCode"
                        name="doc_zipCode"
                        value=""
                    />
                    <input
                        readOnly={existing_member == true ? true : false}
                        onChange={handleChange}
                        type="hidden"
                        className="doc_phone"
                        name="doc_phone"
                        value=""
                    />
                    <input
                        readOnly={existing_member == true ? true : false}
                        onChange={handleChange}
                        type="hidden"
                        className="doc_npi"
                        name="doc_npi"
                        value=""
                    />
                    <input
                        readOnly={existing_member == true ? true : false}
                        onChange={handleChange}
                        type="hidden"
                        className="select_provider"
                        name="select_provider"
                        value=""
                    />
                    <input
                        readOnly={existing_member == true ? true : false}
                        onChange={handleChange}
                        type="hidden"
                        className="select_care"
                        name="select_care"
                        value=""
                    />
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <p className="field" style={{ textAlign: "left" }}>
                            <label htmlFor="approve" style={{ fontSize: "1em", lineHeight: "1.49em" }}></label>
                            <button onClick={searchCare} name="search_care" value="Find" id="search_care">
                                Search
                            </button>
                        </p>
                    </div>
                    {((!socResults && isHcp && !isDrugOnly) || (!isHcp && !isDrugOnly)) && (
                        <p class="color">
                            <strong>
                                Please note that patients who reside in or are treated in Massachusetts or Rhode Island are NOT eligible for the administration
                                co-pay program.
                            </strong>
                        </p>
                    )}
                </div>
            </div>

            {showCareResults ? (
                <div className="search-div-care">
                    <table id="table-care">
                        <tbody>
                            <tr>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Practice name</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>NPI</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Address 1</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Address 2</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>City</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>State</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>ZIP</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Phone Number</th>
                                <th style={{ fontSize: "1em", lineHeight: "1.49em" }}>Select</th>
                            </tr>

                            {socResults && socResults.providersearch.map(({ __typename, provider_id }) => <SiteOfCareSearch providerID={provider_id} />)}
                            {socResults && socResults.providersearch == 0 ? (
                                <tr>
                                    <td colSpan="8" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        No matching practice
                                    </td>
                                    <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input onChange={handleChange} type="radio" name="result_care" value="0" />{" "}
                                    </td>
                                </tr>
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </table>
                </div>
            ) : (
                <></>
            )}

            <FillSiteOfCareForm />
            <div className="space"></div>
        </>
    );
}
