import React, { useEffect, useState } from 'react'
import { Outlet } from "react-router-dom";
import ProgressBar from "./ProgressBar";

const Layout = () => {
    const [title, setTitle] = useState('');
    const [step, setStep] = useState('');
    const [enrollment_type, setenrollmenttype] = useState('')
    const [product, setproduct] = useState('')
    const [email_opt_in, setemailopt] = useState(
        {
            opt: '',
            email: ''
        }
    )
    const [eligibility_answers, seteligibilityanswers] = useState(
        {
            q1: '',
            q2: '',
            q3: '',
            q3date: '',
            q4: '',
            q5: '',
            q5b: '',
            q5c: '',
            q6: '',
            q7: '',
            q8: '',
            q9: '',
            q10: '',
        }
    )
    const [enrollment_data, setenrollmentdata] = useState(
        {
            first_name: '',
            last_name: '',
            gender: '',
            dob: '',
            address: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip_code: '',
            },
            phone_number: '',
            phone_type: 'Home',
            email: '',
            insurance : {
                medical_insurance : {
                    name: '',
                    plan_type: '',
                    group_number: '',
                    member_number: '',
                    effective_date: '',
                    bin: '',
                    pcn: ''
                },
                rx_insurance : {
                    name: '',
                    plan_type: '',
                    group_number: '',
                    member_number: '',
                    bin: '',
                    pcn: ''
                }
            },
            lap : {
                first_name: '',
                last_name: '',
                address: {
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip_code: '',
                },
                phone: ''
            },
            request_card: 'n'
        }
    )
    const [provider_data, setproviderdata] = useState(
        {
            provider_id: '',
            first_name: '',
            last_name: '',
            address: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip_code: '',
            },
            phones: {
                phone: ''
            },
            npi: ''
        }
    )
    const [site_of_care, setsiteofcare] = useState(
        {
            provider_id: '',
            name: '',
            address: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip_code: '',
            },
            phone_number: '',
            npi: ''
        }
    )

    useEffect(() => {
        //console.log(enrollment_data)
    }, [enrollment_data])

    return (
        <div className="container" id="content">
            <div id="container">
                <ProgressBar title={title} step={step} />
                <Outlet context={{ product, setproduct, email_opt_in, setemailopt, enrollment_type, setenrollmenttype, eligibility_answers, seteligibilityanswers, setTitle, setStep, enrollment_data, setenrollmentdata, provider_data, setproviderdata, site_of_care, setsiteofcare }} />
            </div>
        </div>
    );
};

export default Layout;