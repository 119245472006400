import React from 'react'
import {Link} from 'react-router-dom'
import NotificationsTableRow from '../components/NotificationsTableRow'
import { useQuery, gql } from '@apollo/client';
import { GET_NOTIFICATION_LIST } from '../../graphql/queries/Providers'

export default function Notifications() {

    const provider_id = parseInt(window.localStorage.getItem('providerID'))

    const { data } = useQuery(GET_NOTIFICATION_LIST, {
        variables: { provider_id }
    });
    

    return (
        <div className="container" id="content" style={{ fontSize: '1em' }}>
            <div id="container">
                <div id="hcp_post_content">
                    <h1 style={{ fontSize: '1.5em', lineHeight: '1.49em' }}>Notification Center </h1>
                    <p><i>Messages will be removed from the list after 30 days OR after marking the communication as 'read'.</i></p>
                    <p><i>Click on the Patient Name to go to the patient record to view the communication or to view the current patient enrollment status.</i></p>
                    <div className="space"></div>
                    <ul id="navsubmenu" className="nav submenu overview" style={{ borderTop: '0' }}>
                        <li className="selected" style={{ marginBottom: '0' }}><Link to="#">Informational</Link></li>
                    </ul>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <table className="account" style={{ margin: '0' }}>
                            <tbody>
                                <tr className="list_header">
                                    <th align="left" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Date</th>
                                    <th align="left" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Patient Name</th>
                                    <th align="center" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Notification Type</th>
                                    <th align="center" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Mark as Read</th>
                                </tr>
                                {data != null && data.providernotificationlist != null && data.providernotificationlist.map(({ _typename, document_id, document_sent_date, document_type, notification_status, patient_first_name, patient_last_name, patient_id }) => (
                                    <NotificationsTableRow patientID={patient_id} date={document_sent_date} firstName={patient_first_name} lastName={patient_last_name} notificationType={document_type} status={notification_status} docID={document_id} />
                                ))}

                            </tbody>
                        </table>
                        <div className="space"></div>
                        <div className="space"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}


