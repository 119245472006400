import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.min.css";
import logo from "../images/logo.png";
import NavBar from "./BottomHeader";
import { useQuery, gql, useApolloClient } from "@apollo/client";
import { PATIENT_NAME } from "../graphql/queries/Patients";

export default function Header() {
    var patient_id = window.localStorage.getItem("xrefID") ? parseInt(window.localStorage.getItem("xrefID")) : 0;
    if (window.localStorage.getItem("xrefID") == "") {
        patient_id = 0;
    }
    const client = useApolloClient();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLasttName] = useState("");
    const [credentials, setcredentials] = useState("");

    const { loading, error, data, refetch } = useQuery(PATIENT_NAME, {
        variables: { patient_id },
    });

    useEffect(() => {
        $(function () {
            $(".nav .apply-button a, .cta_box.apply-button").on("click", function (event) {
                event.preventDefault();
                $(".apply-agreement").css("display", "block");
            });
        });

        $(function () {
            $(".hcp-tab").on("click", function (event) {
                event.preventDefault();
                $(".hcp-popup").css("display", "block");
            });

            $(".hcp-popup .close-button").on("click", function (event) {
                event.preventDefault();
                $(".hcp-popup").css("display", "none");
            });
            $("a.parent").on("click", function (event) {
                event.preventDefault();
            });
        });

        $(function () {
            $(".datepicker").datepicker({
                dateFormat: "mm/dd/yy",
                showButtonPanel: true,
                changeMonth: true,
                changeYear: true,
                yearRange: "1920:+nn",
                showOtherMonths: true,
                selectOtherMonths: true,
            });
        });

        $(function () {
            $(".holidays").on("click", function (event) {
                event.preventDefault();
                $(".holidays-popup").css("display", "block");
            });

            $(".holidays-popup .close-button").on("click", function (event) {
                event.preventDefault();
                $(".holidays-popup").css("display", "none");
            });
        });

        if (patient_id) {
            if (data) {
                $(".user-area").css("display", "block");
                if (data.patientinfo != null) {
                    if (data.patientinfo.enrollment_data != null) {
                        if (data.patientinfo.enrollment_data.first_name) {
                            setFirstName(data.patientinfo.enrollment_data.first_name);
                        }
                        if (data.patientinfo.enrollment_data.last_name) {
                            setLasttName(data.patientinfo.enrollment_data.last_name);
                        }
                    } else {
                    }
                    if (data.patientinfo.credentials_id) {
                        setcredentials(data.patientinfo.credentials_id);
                    }
                }
            } else {
            }
        } else {
            $(".user-area").css("display", "none");
        }

        $("signout").on("click", function () {
            $(".user-area").css("display", "none");
        });
    }, [data]);

    let navigate = useNavigate();
    function signout() {
        let path = `login`;
        localStorage.clear();
        client.clearStore();
        client.cache.gc();
        $(".user-mm").toggleClass("show");
        $(".user-box").slideToggle();
        navigate(path);
    }

    useEffect(() => {
        window.addEventListener("GqlFlag", (e) => {
            // console.log(e, "GqlEvent");
            let gqlErrorState = localStorage.getItem("gqlFlag");

            //if gql
            if (gqlErrorState) {
                // console.log("trigger logout");
                localStorage.removeItem("gqlFlag");

                //ONLY DO IT IN THIS STATE, IDEALLY THE LOCALSTORAGE IS STOPPED
                function logout() {
                    let path = `login`;
                    console.log("Clearing 1");
                    // localStorage.removeItem("token");
                    // localStorage.removeItem("xrefID");
                    localStorage.clear();

                    //New
                    client.clearStore();
                    client.cache.gc();
                    // $(".user-mm").toggleClass("show");
                    // $(".user-box").slideToggle();
                    navigate(path);

                    // console.log(path);
                    // redirect(path);
                }
                logout();
            }
        });
    });

    function clickUserBox() {
        $(".user-mm").toggleClass("show");
        $(".user-box").slideToggle();
    }

    return (
        <div>
            <div className="hcp-popup">
                <div className="popup-box">
                    <p>
                        The information contained in this section of the site is intended for US health care professionals and specialty
                        pharmacy representatives only. Click “OK” if you are a health care professional or specialty pharmacy
                        representative.
                    </p>
                    <Link to="hcp">
                        <button>OK</button>
                    </Link>
                    <button className="close-button" type="reset">
                        Cancel
                    </button>
                </div>
            </div>
            <header>
                <div className="container">
                    <Link to="/" className="logo">
                        <img src={logo} />
                    </Link>
                    <div className="top-header">
                        <nav>
                            <Link to="./hcp" className="hcp-tab">
                                Health Care Professionals Site
                            </Link>
                        </nav>
                        <div className="text-enlarger site-text-size">
                            <ul className="nav submenu overview user-area">
                                <li className="icon-mm">
                                    <Link className="user-mm round-mm" onClick={clickUserBox}>
                                        {firstName.substring(0, 1)}
                                        {lastName.substring(0, 1)}
                                    </Link>
                                    <ul className="submenuitems user-box" style={{ display: "none" }}>
                                        <div className="line-user mm">
                                            <div className="round-mm">
                                                {firstName.substring(0, 1)}
                                                {lastName.substring(0, 1)}
                                            </div>
                                            <div className="after-mm">
                                                <div className="font-bigger-size">
                                                    {firstName} {lastName}
                                                </div>
                                                <div className="user-info">{credentials}</div>
                                            </div>
                                        </div>
                                        <div className="line-user acc">
                                            <li>
                                                <Link to="my-profile">My Profile</Link>
                                            </li>
                                        </div>
                                        <div className="line-user psw">
                                            <li>
                                                <Link to="change-password">Change Password</Link>
                                            </li>
                                        </div>
                                        <div className="line-user out">
                                            <li onClick={signout}>
                                                <Link>Sign out</Link>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <NavBar />
        </div>
    );
}
