import React from "react";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.min.css";
import phone from "../images/phone.png";
import fax from "../images/fax.png";
import genentechNovartis from "../images/genentech_novartis.png";
import icosize from "../images/ico-size.gif";

import "../js/form-validation.js";
// import "../js/jquery.datetime.js";
import "../js/maskedinput.js";
// import "../js/scripts.js";

$(function () {
    $(".holidays").on("click", function (event) {
        event.preventDefault();
        $(".holidays-popup").css("display", "block");
    });

    $(".holidays-popup .close-button").on("click", function (event) {
        event.preventDefault();
        $(".holidays-popup").css("display", "none");
    });
});

export default function Footer() {
    return (
        <>
            <div className="footer">
                <div className="holidays-popup" style={{ color: "#534e4e" }}>
                    <div className="popup-box" style={{ padding: "35px" }}>
                        <p>
                            We are open from <span className="noWrap">9 a.m.–8 p.m. ET</span>, Monday through Friday, except for the following holidays:
                        </p>
                        <ul>
                            <li>New Year's Day</li>
                            <li>Memorial Day</li>
                            <li>Independence Day</li>
                            <li>Labor Day</li>
                            <li>Thanksgiving Holiday (Thursday and Friday)</li>
                            <li>Christmas Holiday</li>
                        </ul>
                        <p>
                            <button className="close-button">Close</button>
                        </p>
                    </div>
                </div>
                <div className="container">
                    <p>
                        <strong>Questions about this program?</strong>
                    </p>
                    <div className="col-xs-12 col-sm-12 col-md-7 float-left">
                        <p>
                            <img src={phone} style={{ float: "left", marginRight: "10px" }} />
                            Call{" "}
                            <span className="noWrap">
                                <strong>(855) 965-2472</strong>
                            </span>
                            <br />9 a.m.–8 p.m. ET, Monday through Friday (except{" "}
                            <a href="#" style={{ color: "#fff" }} className="holidays">
                                major holidays
                            </a>
                            )
                            <br />
                            &nbsp;
                            <br />
                            For questions about XOLAIR, please call <strong>(866) 4XOLAIR</strong> (866-496-5247).
                        </p>
                        <p>
                            <span style={{ float: "left" }}>
                                This website is a service of Genentech, Inc. and Novartis Pharmaceuticals Corporation and is intended for US residents only.
                                <br />
                                <span style={{ fontSize: "11px" }}>&nbsp;</span>
                                <br />
                                ©2024 Genentech USA, Inc. and Novartis Pharmaceuticals Corporation.
                            </span>
                            <br />
                            All rights reserved.
                        </p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-5 float-right">
                        <p className="fax">
                            <img src={fax} style={{ float: "left", marginRight: "10px" }} />
                            <span style={{ float: "left" }}>
                                Fax <strong>(866) 440-0599</strong>
                            </span>
                        </p>
                        <p>
                            {" "}
                            <img style={{ marginBottom: "30px", marginTop: "30px" }} src={genentechNovartis} />
                        </p>
                        <p className="privacy">
                            <a href="https://www.gene.com/privacy-policy" target="_blank">
                                Privacy Policy
                            </a>
                            &nbsp;|&nbsp;<a className="ot-sdk-show-settings">Your Privacy Choices</a>
                            <br />
                            <a href="https://www.gene.com/wa-consumer-health-data-privacy-policy" target="_blank">
                                WA Consumer Health Data Privacy Policy
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="lightbox-holder">
                <div className="text-enlarger" id="text-enlarger">
                    <div className="title">
                        <div className="title-holder">
                            <h2 className="text-title" style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>
                                Welcome
                            </h2>
                        </div>
                    </div>
                    <div className="text-enlarger-body" id="text-enlarger-body">
                        <div className="content-holder">
                            <strong className="txt-area">How would you like to read? Click one below.</strong>
                            <div className="enlarger-bar">
                                <a href="#" className="size-link" rel="14" id="enlarger-size-small">
                                    I like to read words at this size.
                                </a>
                            </div>
                            <div className="enlarger-bar">
                                <a className="size-link" href="#" rel="18" id="enlarger-size-medium">
                                    I like to read words at this size.
                                </a>
                            </div>
                            <div className="enlarger-bar">
                                <a className="size-link" href="#" rel="22" id="enlarger-size-large">
                                    I like to read words at this size.
                                </a>
                            </div>
                            <a id="fancybox-close" className="fancybox-close">
                                Close
                            </a>
                            <p>
                                At any time, click this <img className="ico-size" src={icosize} alt="image description" /> to change word size.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
