import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import calBtn from "../../images/calbtn.gif";
import $, { error } from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.min.css";

export default function PatientInformation() {
    const { enrollment_data, setenrollmentdata } = useOutletContext();

    const [checked, setchecked] = useState(false);

    var existing_member = false;
    if (window.localStorage.getItem("existing_member") == "true") {
        existing_member = true;
    } else {
        existing_member = false;
    }

    const [firstname, setfirstname] = useState("");
    const [lastname, setlastname] = useState("");
    const [gender, setgender] = useState("");
    const [dob, setdob] = useState("");
    const [address1, setaddress1] = useState("");
    const [address2, setaddress2] = useState("");
    const [city, setcity] = useState("");
    const [state, setstate] = useState("");
    const [zipcode, setzipcode] = useState("");
    const [phonenumber, setphonenumber] = useState("");
    const [email, setemail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);

    $("input[name=birth_date]").on("change", function () {
        var date = $(".datepicker").val();
        var month = date.substring(0, 2);
        var day = date.substring(3, 5);
        var year = date.substring(6, 10);
        if (date != "") {
            setdob(year + "-" + month + "-" + day);
            enrollment_data.dob = year + "-" + month + "-" + day;
            window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));
        }
    });

    $(".datepicker").val(enrollment_data.dob);
    useEffect(() => {
        if (window.localStorage.getItem("enrollment_data")) {
            try {
                setenrollmentdata(JSON.parse(window.localStorage.getItem("enrollment_data")));
                setfirstname(enrollment_data.first_name);
                setlastname(enrollment_data.last_name);
                setgender(enrollment_data.gender);
                setdob(enrollment_data.dob);
                $(".datepicker").val(enrollment_data.dob);
                setaddress1(enrollment_data.address.address1);
                setaddress2(enrollment_data.address.address2);
                setcity(enrollment_data.address.city);
                setstate(enrollment_data.address.state);
                setzipcode(enrollment_data.address.zip_code);
                setphonenumber(enrollment_data.phone_number);
                setemail(enrollment_data.email);
            } catch {}
        }

        $(function () {
            $(".datepicker").datepicker({
                dateFormat: "mm/dd/yy",
                showButtonPanel: true,
                changeMonth: true,
                changeYear: true,
                yearRange: "1920:+nn",
                showOtherMonths: true,
                selectOtherMonths: true,
            });
        });
    }, []);

    function updateFirstName(e) {
        let trimmed_firstname = e.target.value.trim();
        setfirstname(trimmed_firstname);
        enrollment_data.first_name = trimmed_firstname;
        // console.log(enrollment_data);
    }
    function updateLastName(e) {
        let trimmed_lastname = e.target.value.trim();
        setlastname(trimmed_lastname);
        enrollment_data.last_name = trimmed_lastname;
        // console.log(enrollment_data);
    }
    function updateGender(e) {
        setgender(e.target.value);
        enrollment_data.gender = e.target.value;
        // console.log(enrollment_data);
    }
    function updateAddress1(e) {
        setaddress1(e.target.value);
        enrollment_data.address.address1 = e.target.value;
        // console.log(enrollment_data);
    }
    function updateTrimmedAddress1(e) {
        let trimmed_address1 = e.target.value.trim();
        setaddress1(trimmed_address1);
        enrollment_data.address.address1 = trimmed_address1;
        // window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));
    }
    function updateAddress2(e) {
        setaddress2(e.target.value);
        enrollment_data.address.address2 = e.target.value;
        // console.log(enrollment_data);
    }
    function updateTrimmedAddress2(e) {
        let trimmed_address2 = e.target.value.trim();
        setaddress2(trimmed_address2);
        enrollment_data.address.address2 = trimmed_address2;
        // window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));
    }
    function updateCity(e) {
        setcity(e.target.value);
        enrollment_data.address.city = e.target.value;
        // console.log(enrollment_data);
    }
    function updateState(e) {
        setstate(e.target.value);
        enrollment_data.address.state = e.target.value;
        // console.log(enrollment_data);
    }
    function updateZipCode(e) {
        setzipcode(e.target.value);
        enrollment_data.address.zip_code = e.target.value;
        // console.log(enrollment_data);
    }
    // function updatePhoneNumber(e) {
    //     setphonenumber(e.target.value);
    //     enrollment_data.phone_number = e.target.value;
    //     console.log(enrollment_data);
    // }

    function checkPhoneNumber(e) {
        let currPhoneNumber = e.target.value;
        // console.log($(".homePhone").val());
        // if (currPhoneNumber.match(/^\(\d{3}\) \d{3}-\d{4}$/)) {

        if (currPhoneNumber.match(/\(\d{3}\) \d{3}-\d{4}/)) {
            setIsValidPhoneNumber(true);
        } else if (currPhoneNumber.length < 14) {
            setIsValidPhoneNumber(false);
        } else {
            setIsValidPhoneNumber(false);
        }
    }

    function cleanAndSetPhoneNumber(e) {
        const phoneNumberCleaned = ("" + e.target.value).replace(/\D/g, "");
        const match = phoneNumberCleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        let finalPhoneNumber = e.target.value;

        if (phoneNumberCleaned.length === 0) {
            // check space entry/weird entry case
            e.target.value = "";
            checkPhoneNumber(e);
            setphonenumber(finalPhoneNumber);
            enrollment_data.phone_number = finalPhoneNumber;
            window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));
            return;
        }
        if (match) {
            // Format the parts into (XXX) XXX-XXXX
            const part1 = match[1] ? `(${match[1]}` : "";
            const part2 = match[2] ? `) ${match[2]}` : "";
            const part3 = match[3] ? `-${match[3]}` : "";
            finalPhoneNumber = `${part1}${part2}${part3}`.trim();
        }
        setphonenumber(finalPhoneNumber);
        enrollment_data.phone_number = finalPhoneNumber;
        window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));

        checkPhoneNumber(e);

        // return e.target.value;
    }

    function updateEmail(e) {
        let trimmed_email = e.target.value.trim();
        if (trimmed_email.length > 0) {
            //is not blank, since optional
            if (trimmed_email.toLowerCase().match(/^\S+@\S+\.\S+$/)) {
                setIsValidEmail(true);
            } else {
                setIsValidEmail(false);
            }
        } else {
            //blank email is valid (optional)
            setIsValidEmail(true);
        }
        setemail(trimmed_email);
        enrollment_data.email = trimmed_email;
        window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));
    }

    function handleCheckbox(e) {
        if (document.getElementById("same-address").checked == true) {
            setchecked(true);

            setaddress1(enrollment_data.lap.address.address1);
            setaddress2(enrollment_data.lap.address.address2);
            setcity(enrollment_data.lap.address.city);
            setzipcode(enrollment_data.lap.address.zip_code);

            enrollment_data.address.address1 = enrollment_data.lap.address.address1;
            enrollment_data.address.address2 = enrollment_data.lap.address.address2;
            enrollment_data.address.city = enrollment_data.lap.address.city;
            enrollment_data.address.zip_code = enrollment_data.lap.address.zip_code;
        } else {
            setchecked(false);
        }
    }

    return (
        <div className="section shadow-box">
            <h3 className="color">Patient Information</h3>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field required-field text">
                    <label for="first_name" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                        <span className="inner-label">
                            First name<span className="required">*</span>
                        </span>
                    </label>
                    <input
                        readOnly={existing_member == true ? true : false}
                        type="text"
                        id="first_name"
                        name="first_name"
                        onChange={(e) => updateFirstName(e)}
                        value={enrollment_data.first_name}
                    />
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field required-field text">
                    <label for="last_name" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                        <span className="inner-label">
                            Last name<span className="required">*</span>
                        </span>
                    </label>
                    <input
                        readOnly={existing_member == true ? true : false}
                        type="text"
                        id="last_name"
                        name="last_name"
                        onChange={(e) => updateLastName(e)}
                        value={enrollment_data.last_name}
                    />
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <p className="field required-field dropdown">
                        <label for="gender" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            <span className="inner-label">
                                Gender<span className="required">*</span>
                            </span>
                        </label>
                        <span className="select_container">
                            <select
                                disabled={existing_member == true ? "disabled" : false}
                                name="gender"
                                defaultValue=""
                                onChange={(e) => updateGender(e)}
                                value={enrollment_data.gender}
                            >
                                <option value="">Select gender</option>
                                <option label="Male" value="Male" selected="selected">
                                    Male
                                </option>
                                <option label="Female" value="Female">
                                    Female
                                </option>
                                <option label="Prefer not to answer" value="Prefer not to answer">
                                    Prefer not to answer
                                </option>{" "}
                            </select>
                        </span>
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <p className="field required-field text date">
                        <label for="birthDate" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            <span className="inner-label">
                                Birth date (MM/DD/YYYY)<span className="required">*</span>
                            </span>
                        </label>
                        <input
                            autoComplete="off"
                            disabled
                            className="datepicker"
                            type="text"
                            name="birth_date"
                            defaultValue={enrollment_data.dob}
                            size="10"
                        />
                        <img className="ui-datepicker-trigger" src={calBtn} alt="..." title="..." />
                    </p>
                </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
                <p></p>
                <p>
                    <label className="checkbox" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                        <input onChange={(e) => handleCheckbox(e)} type="checkbox" name="same-address" id="same-address" /> The patient's
                        address is the same as the legally authorized person's address<span className="checkmark "></span>
                    </label>{" "}
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field required-field text">
                    <label for="street" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                        <span className="inner-label">
                            Address<span className="required">*</span>
                        </span>
                    </label>
                    <input
                        readOnly={existing_member == true || checked == true ? true : false}
                        type="text"
                        id="address"
                        name="address_1"
                        onChange={(e) => updateAddress1(e)}
                        onBlur={(e) => updateTrimmedAddress1(e)}
                        value={enrollment_data.address.address1}
                    />
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field text">
                    <label for="street" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                        <span className="inner-label">Address 2</span>
                    </label>
                    <input
                        readOnly={existing_member == true || checked == true ? true : false}
                        type="text"
                        id="address2"
                        name="address_2"
                        onChange={(e) => updateAddress2(e)}
                        onBlur={(e) => updateTrimmedAddress2(e)}
                        value={enrollment_data.address.address2}
                    />
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <p className="field required-field text">
                        <label for="city" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            <span className="inner-label">
                                City<span className="required">*</span>
                            </span>
                        </label>
                        <input
                            readOnly={existing_member == true || checked == true ? true : false}
                            type="text"
                            id="city"
                            name="city"
                            onChange={(e) => updateCity(e)}
                            value={enrollment_data.address.city}
                        />
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <p className="field required-field dropdown">
                        <label for="state" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            <span className="inner-label">
                                State<span className="required">*</span>
                            </span>
                        </label>
                        <span className="select_container">
                            <select
                                disabled="disabled"
                                name="state"
                                id="state"
                                defaultValue="default"
                                value={enrollment_data.address.state}
                                onChange={(e) => updateState(e)}
                            >
                                <option label="" value="default">
                                    Select a state
                                </option>
                                <option label="Alabama" value="AL">
                                    Alabama
                                </option>
                                <option label="Alaska" value="AK">
                                    Alaska
                                </option>
                                <option label="American Samoa" value="AS">
                                    American Samoa
                                </option>
                                <option label="Arizona" value="AZ">
                                    Arizona
                                </option>
                                <option label="Arkansas" value="AR">
                                    Arkansas
                                </option>
                                <option label="California" value="CA">
                                    California
                                </option>
                                <option label="Colorado" value="CO">
                                    Colorado
                                </option>
                                <option label="Connecticut" value="CT">
                                    Connecticut
                                </option>
                                <option label="Delaware" value="DE">
                                    Delaware
                                </option>
                                <option label="Florida" value="FL">
                                    Florida
                                </option>
                                <option label="Georgia" value="GA">
                                    Georgia
                                </option>
                                <option label="Guam" value="GU">
                                    Guam
                                </option>
                                <option label="Hawaii" value="HI">
                                    Hawaii
                                </option>
                                <option label="Idaho" value="ID">
                                    Idaho
                                </option>
                                <option label="Illinois" value="IL">
                                    Illinois
                                </option>
                                <option label="Indiana" value="IN">
                                    Indiana
                                </option>
                                <option label="Iowa" value="IA">
                                    Iowa
                                </option>
                                <option label="Kansas" value="KS">
                                    Kansas
                                </option>
                                <option label="Kentucky" value="KY">
                                    Kentucky
                                </option>
                                <option label="Louisiana" value="LA">
                                    Louisiana
                                </option>
                                <option label="Maine" value="ME">
                                    Maine
                                </option>
                                <option label="Maryland" value="MD">
                                    Maryland
                                </option>
                                <option label="Massachusetts" value="MA">
                                    Massachusetts
                                </option>
                                <option label="Michigan" value="MI">
                                    Michigan
                                </option>
                                <option label="Minnesota" value="MN">
                                    Minnesota
                                </option>
                                <option label="Mississippi" value="MS">
                                    Mississippi
                                </option>
                                <option label="Missouri" value="MO">
                                    Missouri
                                </option>
                                <option label="Montana" value="MT">
                                    Montana
                                </option>
                                <option label="Nebraska" value="NE">
                                    Nebraska
                                </option>
                                <option label="Nevada" value="NV">
                                    Nevada
                                </option>
                                <option label="New Hampshire" value="NH">
                                    New Hampshire
                                </option>
                                <option label="New Jersey" value="NJ">
                                    New Jersey
                                </option>
                                <option label="New Mexico" value="NM">
                                    New Mexico
                                </option>
                                <option label="New York" value="NY">
                                    New York
                                </option>
                                <option label="North Carolina" value="NC">
                                    North Carolina
                                </option>
                                <option label="North Dakota" value="ND">
                                    North Dakota
                                </option>
                                <option label="Northern Mariana Islands" value="NM">
                                    Northern Mariana Islands
                                </option>
                                <option label="Ohio" value="OH">
                                    Ohio
                                </option>
                                <option label="Oklahoma" value="OK">
                                    Oklahoma
                                </option>
                                <option label="Oregon" value="OR">
                                    Oregon
                                </option>
                                <option label="Pennsylvania" value="PA">
                                    Pennsylvania
                                </option>
                                <option label="Puerto Rico" value="PR">
                                    Puerto Rico
                                </option>
                                <option label="Rhode Island" value="RI">
                                    Rhode Island
                                </option>
                                <option label="South Carolina" value="SC">
                                    South Carolina
                                </option>
                                <option label="South Dakota" value="SD">
                                    South Dakota
                                </option>
                                <option label="Tennessee" value="TN">
                                    Tennessee
                                </option>
                                <option label="Texas" value="TX">
                                    Texas
                                </option>
                                <option label="U.S. Virgin Islands" value="VI">
                                    U.S. Virgin Islands
                                </option>
                                <option label="Utah" value="UT">
                                    Utah
                                </option>
                                <option label="Vermont" value="VT">
                                    Vermont
                                </option>
                                <option label="Virginia" value="VA">
                                    Virginia
                                </option>
                                <option label="Washington" value="WA">
                                    Washington
                                </option>
                                <option label="Washington DC" value="DC">
                                    Washington DC
                                </option>
                                <option label="West Virginia" value="WV">
                                    West Virginia
                                </option>
                                <option label="Wisconsin" value="WI">
                                    Wisconsin
                                </option>
                                <option label="Wyoming" value="WY">
                                    Wyoming
                                </option>{" "}
                            </select>
                        </span>
                    </p>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field required-field text zipcode">
                    <label for="zipCode" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                        <span className="inner-label">
                            ZIP code<span className="required">*</span>
                        </span>
                    </label>
                    <input
                        readOnly={existing_member == true || checked == true ? true : false}
                        type="text"
                        id="zipCode"
                        name="zip"
                        onChange={(e) => updateZipCode(e)}
                        value={enrollment_data.address.zip_code}
                    />
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field text">
                    <label
                        htmlFor="homePhone_enroller"
                        className={`${!isValidEmail ? "red" : ""}`}
                        style={{ fontSize: "1em", lineHeight: "1.49em" }}
                    >
                        <span className="inner-label">Email address</span>
                    </label>
                    <input
                        readOnly={existing_member == true ? true : false}
                        onChange={(e) => updateEmail(e)}
                        onBlur={(e) => updateEmail(e)}
                        type="text"
                        id=""
                        name="email"
                        style={isValidEmail ? {} : { border: "2px solid red" }}
                        value={enrollment_data.email}
                    />
                    <small>*This email may be used for any future co-pay related communications.</small>
                    {!isValidEmail && (
                        <span class="prompt-error error" style={{ padding: 0, margin: 0, display: "inline", marginBottom: 0 }}>
                            Please enter a valid email.
                        </span>
                    )}
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <p className="field required-field text">
                        <label
                            for="homePhone"
                            className={`${!isValidPhoneNumber ? "red" : ""}`}
                            style={{ fontSize: "1em", lineHeight: "1.49em" }}
                        >
                            <span className="inner-label">
                                Phone number<span className="required">*</span>
                            </span>
                        </label>
                        <input
                            readOnly={existing_member == true ? true : false}
                            onChange={cleanAndSetPhoneNumber}
                            onBlur={cleanAndSetPhoneNumber}
                            type="text"
                            className="phone_2 homePhone"
                            name="phone_number"
                            style={isValidPhoneNumber ? {} : { border: "2px solid red" }}
                            value={phonenumber}
                        />
                        {!isValidPhoneNumber && (
                            <p class="prompt-error error" style={{ padding: 0, margin: 0, display: "inline" }}>
                                Please enter a valid phone number.
                            </p>
                        )}
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <p className="field required-field dropdown">
                        <label for="homePhone_enroller" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            <span className="inner-label">
                                Phone type<span className="required">*</span>
                            </span>
                        </label>
                        <span className="select_container">
                            <select id="Phone_type" name="phone_type_patient">
                                <option value="Home">Home</option>
                                <option value="Mobile">Mobile</option>
                            </select>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}
