import React from "react";
import specialtyPharmCircle from "../../images/SpecialtyPharm-circle.png";
import copayCard from "../../images/CopayCard-circle.png";
import noCopayCard from "../../images/NOCopayCard-circle.png";
import UserGuide from "../../docs/Xolair_User_Guide.pdf";
export default function UsingTheCard() {
    return (
        <div className="container" id="content" style={{ fontSize: "1em" }}>
            <div id="container">
                <div className="using-hcp">
                    {" "}
                    <h2 style={{ color: "#00759f" }}>Using the Program</h2>
                    <p>
                        {" "}
                        The XOLAIR Co-pay Program for drug and/or administration assistance may be used in a community practice, an
                        alternate injection center (AIC), hospital outpatient department (HOPD), or patient's address. For drug assistance
                        only, the program may also be used with specialty pharmacies.{" "}
                    </p>
                    <p>
                        {" "}
                        Participating practices, AICs, HOPDs or specialty pharmacies must complete a one-time registration so they may
                        process XOLAIR Co-pay Program payments. To register, please call{" "}
                        <span className="text-nowrap">
                            <strong>(855) 965-2472</strong>
                        </span>
                        .
                    </p>{" "}
                    <div className="clearfloat"></div>
                    <div className="using-hcp-box arrow-bottom">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="first">
                                        <img src={specialtyPharmCircle} alt="How to use the program - Step 1" />
                                    </td>
                                    <td>
                                        {" "}
                                        <div className="title strong">SPECIALTY PHARMACIES</div>{" "}
                                        <ul>
                                            {" "}
                                            <li>
                                                {" "}
                                                <span>Process</span> the patient's XOLAIR prescription using RxBIN, PCN, group number and
                                                Member ID{" "}
                                            </li>{" "}
                                            <li> Patient pays as little as $0* per XOLAIR drug out-of-pocket cost </li>{" "}
                                            <li>
                                                <span>Ship</span> XOLAIR to the treatment location
                                            </li>{" "}
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>{" "}
                    </div>
                    <div className="using-hcp-box">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="first">
                                        <img src={copayCard} alt="How to use the program - Step 2" />
                                    </td>
                                    <td>
                                        <div className="title strong"> DOCTOR'S OFFICES, HOPDs AND AICs </div>
                                        <ul>
                                            <li>
                                                {" "}
                                                Patient pays as little as $0* per XOLAIR drug out-of-pocket cost and/or as little as $0* per
                                                XOLAIR administration out-of-pocket cost
                                            </li>
                                            <li>
                                                {" "}
                                                <span>Upload</span> or <span>Fax</span> the patient's explanation of benefits (EOB)
                                                <sup>&dagger;</sup> to the XOLAIR Co-pay Program at{" "}
                                                <span className="text-nowrap">(866) 440-0599</span>
                                            </li>
                                            <li>
                                                The requirements for reimbursement must include:
                                                <ul>
                                                    <li>Name of insurance company</li>
                                                    <li>Facility name</li>
                                                    <li>Patient's name</li>
                                                    <li>Date of service</li>
                                                    <li>Name of drug or J-code</li>
                                                    <li>Amount billed</li>
                                                    <li>Amount allowed</li>
                                                    <li>Patient responsibility</li>
                                                    <li>Amount paid to provider</li>
                                                </ul>
                                            </li>
                                            <p>
                                                If the EOB is missing any of the above items, additional documentation may be required to
                                                process the claim
                                            </p>
                                            <li>
                                                {" "}
                                                Within 2 business days, the patient's co-pay claim will be processed:
                                                <br />{" "}
                                                <ul>
                                                    {" "}
                                                    <li>
                                                        If the EOB is eligible for approval, the claim will be paid up to the allowed amount
                                                    </li>
                                                    <li>
                                                        {" "}
                                                        If the EOB is not eligible for approval, a response will be provided to the provider
                                                        with next steps
                                                    </li>{" "}
                                                </ul>{" "}
                                            </li>
                                            <li>
                                                {" "}
                                                The Provider collects the remainder of the patient's out-of-pocket (OOP) drug costs via
                                                16-digit virtual card number, Electronic Funds Transfer (EFT) or check reimbursement{" "}
                                            </li>{" "}
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>{" "}
                    </div>
                    <p>
                        For additional details on the program, please see the{" "}
                        <a href={UserGuide} target="_blank">
                            User Guide
                        </a>
                        .
                    </p>
                    <p>
                        *
                        <strong>
                            Out-of-pocket costs for XOLAIR drug and/or administration may be as little as $0. The final amount owed by
                            patients may be as little as $0, but may vary depending on the patient's health insurance plan.{" "}
                        </strong>
                    </p>{" "}
                    <p>
                        <sup>&dagger;</sup>EOBs must be submitted within <strong>365 days</strong> of the date of service.
                    </p>{" "}
                </div>
            </div>
        </div>
    );
}
