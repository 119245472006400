import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import AccountNavbar from '../components/AccountNavbar'
import MyClaims from '../components/MyClaimsTable'



export default function MyAccount() {


	useEffect(() => {
		$(function () {
			$('.rejected').on('click', function (event) {
				event.preventDefault();
				$('.rejected-popup').css('display', 'block');
			});
			$('.rejected-popup .close-button').on('click', function (event) {
				event.preventDefault();
				$('.rejected-popup').css('display', 'none');
			});
		});
		$(function () {
			$('.open-menu').on('click', function (event) {
				event.preventDefault();
				$(this).toggleClass('show');
				$('#dropdown').removeClass('hide');
				$('#dropdown-2').addClass('hide');
			});
			$('.open-menu-2').on('click', function (event) {
				event.preventDefault();
				$(this).toggleClass('show');
				$('#dropdown-2').removeClass('hide');
				$('#dropdown').addClass('hide');
			});
			$('.open-menu-3').on('click', function (event) {
				event.preventDefault();
				$(this).toggleClass('show');
				$('#dropdown-3').removeClass('hide');
				$('#dropdown').addClass('hide');
			});
		});
	})

	return (
		<div className="container" id="content" style={{ fontSize: '1em' }}>
			<div id="container" className="account">
				<AccountNavbar page="claims" />
				<div className="clearfloat"></div>
				<div className="space"></div>
				<MyClaims />
			</div>
			<div className="rejected-popup">
				<div className="popup-box">
					<h2>Claim Reject Reason</h2>
					<p>Duplicate Claim.</p>
					<button className="close-button">Close</button>
				</div>
			</div>
		</div>
	)
}