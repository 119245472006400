import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';
import { PATIENT_NAME } from '../graphql/queries/Patients'


export default function AccountNavbar(props) {
    const patient_id = parseInt(window.localStorage.getItem('xrefID'))

    const [patientID, setPatientID] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLasttName] = useState('');

    const { loading, error, data, refetch } = useQuery(PATIENT_NAME, {
        variables: { patient_id }
    });

    useEffect(() => {
        if (patient_id) {
            if (data) {
                if (data.patientinfo != null) {
                    if (data.patientinfo.enrollment_data != null) {
                        if (data.patientinfo.enrollment_data.first_name) {
                            setFirstName(data.patientinfo.enrollment_data.first_name)
                        }
                        if (data.patientinfo.enrollment_data.last_name) {
                            setLasttName(data.patientinfo.enrollment_data.last_name)
                        }
                    }
                }

            } else {
            }
        } else {
        }
    }, [data])

    return (
        <>
            <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>{firstName} {lastName}</h2>
            <div className="mobilesubmenu" style={{ display: 'none' }}>
                <ul className="nav submenu overview">
                    <li className={(props.page == "profile") ? 'selected' : ''} id="submenu_profile"><Link to="../profile">Profile</Link></li>
                    <li className={(props.page == "claims") ? 'selected' : ''} id="submenu_account"><Link to="../my-account">Claims</Link></li>
                    <li className={(props.page == "communications") ? 'selected' : ''} id="submenu_messages"><Link to="../messages">Communications</Link></li>
                    <li className={(props.page == "provider-information") ? 'selected' : ''} id="submenu_doctor_information"><Link to="../doctor-information">Provider Information</Link></li>
                </ul>
            </div>
            <div className="responsive-button-submenu hidden-sm hidden-md hidden-lg"><i className="fa fa-bars" aria-hidden="true"></i><span>ACCOUNT MENU</span></div>

            <ul id="navsubmenu" className="nav submenu overview">
                <li className={(props.page == "profile") ? 'selected' : ''} id="submenu_profile"><Link to="../profile">Profile</Link></li>
                <li className={(props.page == "claims") ? 'selected' : ''} id="submenu_account"><Link to="../my-account">Claims</Link></li>
                <li className={(props.page == "communications") ? 'selected' : ''} id="submenu_messages"><Link to="../messages">Communications</Link></li>
                <li className={(props.page == "provider-information") ? 'selected' : ''} id="submenu_doctor_information"><Link to="../doctor-information">Provider Information</Link></li>
            </ul>
        </>

    )
}