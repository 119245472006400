import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import "./styles/jquery-ui-1.10.1.custom.min.css";
import "./styles/style.css";
import "./styles/screen.css";
import "./styles/fancybox.css";
import "./styles/main.css";
import "./styles/bootstrap.min.css";

/*
import 'bootstrap/dist/css/bootstrap.css';
import './styles/bootstrap.min.css';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/selectable.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/selectable';
import './styles/fancybox.css';
import './styles/main.css';
import './styles/style.css';
import './styles/screen.css';
import './styles/date-styling.css';
*/

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

const uri =
    window.location.protocol == "http:"
        ? "https://xolair-graphql.netswitchcard.com/graphql/"
        : "https://xolair-graphql.netswitchcard.com/graphql/";

const httpLink = createHttpLink({
    uri:
        window.location.protocol == "http:"
            ? "https://xolair-graphql.netswitchcard.com/graphql/"
            : "https://xolair-graphql.netswitchcard.com/graphql/",
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    // const GqlErrorSwitcher = () => {
    //     const { gqlError, setGqlError} = useContext(GqlErrorContext)
    // }

    if (graphQLErrors) {
        let authFlag = false;
        graphQLErrors.forEach(({ message, locations, path }) => {
            //console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
            if (message?.toString().trim().includes("You must be logged in to access this resource.")) {
                authFlag = true;
            }
            if (message?.toString().trim().toLowerCase().includes("expired")) {
                authFlag = true;
            }
            if (message?.toString().trim().toLowerCase().includes("authenticationerror")) {
                authFlag = true;
            }
        });
        //   gqlErrorFlag = true;
        if (localStorage.getItem("token") && authFlag) {
            localStorage.setItem("gqlFlag", true);
            window.dispatchEvent(new Event("GqlFlag"));
            authFlag = false;
        }
    }
    // if (networkError) console.error(`[Network error]: ${networkError}`);
});
const authLink = setContext((_, { headers }) => {
    let token = localStorage.getItem("token");
    // console.log(localStorage.getItem("userType"), localStorage.getItem("userID"), localStorage.getItem("credentialsID"));
    let authUserMinFlag = localStorage.getItem("userType") && localStorage.getItem("userID") && localStorage.getItem("credentialsID");
    if (
        authUserMinFlag &&
        (localStorage.getItem("userType").toString().trim().toLowerCase() === `"provider"` ||
            localStorage.getItem("userType").toString().trim().toLowerCase() === `"administrator"`)
    ) {
        token = `${localStorage.getItem("providerID").toString()}${localStorage.getItem("credentialsID").toString()}Bearer ${localStorage
            .getItem("token")
            .toString()}`;
    } else if (localStorage.getItem("token")) {
        //if not provider && token
        token = `${localStorage.getItem("xrefID").toString()}${localStorage.getItem("credentialsID").toString()}Bearer ${localStorage
            .getItem("token")
            .toString()}`;
    }

    return {
        headers: {
            ...headers,
            authorization: token ? `${token}` : "",
        },
    };
});

const client = new ApolloClient({
    link: authLink.concat(errorLink.concat(httpLink)),
    cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const maintenance = false;
root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <BrowserRouter>
                <ScrollToTop />
                {maintenance == true ? <Maintenance /> : <App />}
            </BrowserRouter>
        </ApolloProvider>
    </React.StrictMode>
);

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function Maintenance() {
    return (
        <div style={{ padding: "20px" }}>
            <h1>Program Notification</h1>
            <p1>
                We will be performing a scheduled maintenance on our platform from 8:00 pm (EST) on September 29, 2023 until 8:00 am (EST)
                October 2, 2023. This site will be unavailable during this maintenance period.
            </p1>
        </div>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
