import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client';
import { PROVIDER_DATA } from '../../graphql/queries/Providers'
import $ from 'jquery'
import {UPDATE_PROVIDER_DATA} from '../../graphql/mutations/Providers'

export default function Agreement() {

     
    
    const provider_id = window.localStorage.getItem('providerID') ? parseInt(window.localStorage.getItem('providerID')) : 0
    const [first_name, setfirstname] = useState('')
    const [last_name, setlastname] = useState('')
    const [practice_name, setpracticename] = useState('')
    const [phone_number, setphonenumber] = useState('')
    const [address_1, setaddress1] = useState('')
    const [address_2, setaddress2] = useState('')
    const [city, setcity] = useState('')
    const [state, setstate] = useState('')
    const [zip_code, setzipcode] = useState('')
    const [fax_opt_in, setfaxoptin] = useState('')
    const [fax1, setfax1] = useState('')
    const [fax2, setfax2] = useState('')
    const [fax3, setfax3] = useState('')
    const [user_agreement, setuseragreement] = useState('')


    const [date, setdate] = useState('')

    
    

    var redirect_agree = false
    var redirect_fax = false
    var nextpage = '../fax-consent'

    const { data } = useQuery(PROVIDER_DATA, {
        variables: { provider_id }
    });
    
    const [updateProvider, { data: newData }] = useMutation(UPDATE_PROVIDER_DATA);

    let navigate = useNavigate()

    useEffect(() => {
        if (data) {
            if (data.providerfind != null) {
                if (data.providerfind.provider_data != null) {
                    if (data.providerfind.provider_data.user_agreement) {
                        if (data.providerfind.provider_data.user_agreement.toLowerCase() == 'y') {
                            redirect_agree = true
                        } else {
                            setuseragreement(data.providerfind.provider_data.user_agreement)
                        }
                    }
                    if (data.providerfind.provider_data.opt_in_fax_numbers != null) {
                        if (data.providerfind.provider_data.opt_in_fax_numbers.fax1 && data.providerfind.provider_data.opt_in_fax_numbers.fax2 && data.providerfind.provider_data.opt_in_fax_numbers.fax3) {
                            if ((data.providerfind.provider_data.opt_in_fax_numbers.fax1.toUpperCase() != 'NULL') || (data.providerfind.provider_data.opt_in_fax_numbers.fax2.toUpperCase() != 'NULL') || (data.providerfind.provider_data.opt_in_fax_numbers.fax3.toUpperCase() != 'NULL')) {
                                redirect_fax = true
                            } 
                        }
                        if(data.providerfind.provider_data.opt_in_fax_numbers.fax1){
                            setfax1(data.providerfind.provider_data.opt_in_fax_numbers.fax1)
                        }
                        if(data.providerfind.provider_data.opt_in_fax_numbers.fax2){
                            setfax2(data.providerfind.provider_data.opt_in_fax_numbers.fax2)
                        }
                        if(data.providerfind.provider_data.opt_in_fax_numbers.fax3){
                            setfax3(data.providerfind.provider_data.opt_in_fax_numbers.fax3)
                        }
                    }
                    if(data.providerfind.provider_data.first_name){
                        setfirstname(data.providerfind.provider_data.first_name)
                    }
                    if(data.providerfind.provider_data.last_name){
                        setlastname(data.providerfind.provider_data.last_name)
                    }
                    if(data.providerfind.provider_data.practice_name){
                        setpracticename(data.providerfind.provider_data.practice_name)
                    }
                    if(data.providerfind.provider_data.phone_number){
                        setphonenumber(data.providerfind.provider_data.phone_number)
                    }
                    if(data.providerfind.provider_data.fax_opt_in){
                        setfaxoptin(data.providerfind.provider_data.fax_opt_in)
                    }
                    if(data.providerfind.provider_data.address != null){
                        if(data.providerfind.provider_data.address.address_1){
                            setaddress1(data.providerfind.provider_data.address.address_1)
                        }
                        if(data.providerfind.provider_data.address.address_2){
                            setaddress2(data.providerfind.provider_data.address.address_2)
                        }
                        if(data.providerfind.provider_data.address.city){
                            setcity(data.providerfind.provider_data.address.city)
                        }
                        if(data.providerfind.provider_data.address.state){
                            setstate(data.providerfind.provider_data.address.state)
                        }
                        if(data.providerfind.provider_data.address.zip_code){
                            setzipcode(data.providerfind.provider_data.address.zip_code)
                        }
                    }
                    
                }
            }
        }
        if ((redirect_agree == true) && (redirect_fax == false)) {
            let path = `/hcp/fax-consent`
            navigate(path)
        } else if ((redirect_agree == true) && (redirect_fax == true)) {
            let path = `/hcp/patient-list`
            navigate(path)
        } else if ((redirect_agree == false) && (redirect_fax == true)) {
            nextpage = '../patient-list'
        }
        else {
            nextpage = '../fax-consent'
        }
    }, [data])

    $('input[name=date]').on('change', function () {
        var date = $(".datepicker").val()
        var month = date.substring(0, 2)
        var day = date.substring(3, 5)
        var year = date.substring(6, 10)
        if (date != '') {
            setdate(year + "-" + month + "-" + day)
        }
    });

    function goBack() {
        navigate(-1)
    }

    function faxConsent(e) {
        e.preventDefault();
        const user_agreement = 'Y'
        if (((practice_name != '') && (date != ''))) {
            updateProvider({ variables: {provider_id: provider_id.toString(), first_name, last_name, practice_name, phone_number, address_1, address_2, city, state, zip_code, fax_opt_in, fax1, fax2, fax3, user_agreement}})
            navigate(nextpage)
        }
    }

    function updatePractice(e) {
        setpracticename(e.target.value)
    }

    function updateDate(e) {
        setdate(e.target.value)
    }


    return (
        <div className="container" id="content" style={{ fontSize: '1em' }}>
            <div id="container" className="account">
                <form onSubmit={e => faxConsent(e)} action="fax-consent" className="agree_form form-validate" acceptCharset="utf-8">
                    <h2 className="blue" style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>XOLAIR Co-pay Program User Agreement</h2>
                    <p><strong>Please review in entirety.</strong></p>
                    <p>This Co-pay Portal User Agreement ("Agreement") is made by and between Genentech and you the treating healthcare provider office. The Agreement contains the following terms and conditions.</p>
                    <p>Purpose–Genentech provides a portal (secure internet site) to permit secure access to information about your patients(s) who have enrolled in the XOLAIR Co-pay Program and indicated your specific healthcare providers office as the treating office. Genentech has the sole discretion of what information can be viewed in the portal. </p>
                    <p>Log in Accounts and Security–Genentech will set-up one administrative user account per healthcare provider (the "Administrator"), as well as granting access for other healthcare providers within the office. Genentech recommends that the Administrator and/or other authorized users (each an "Authorized User") not share username information or passwords. All login information including (user name and password), will be provided during the registration process.</p>
                    <p>Termination of Login Account–The administrator agrees to notify Genentech immediately when an individual log in account should be terminated (e.g. Termination of employment of such individual or access to the portal no longer needed). Genentech will make every effort to terminate such individual's access to the Co-pay portal immediately. In such instance where the Administrator no longer needs access to the Co-pay portal, the Administrator agrees to notify Genentech immediately and will also inform the program of who will be the new office administrator in order for the program to transfer his or her rights as an administrator to another Authorized User in the same practice. </p>
                    <p>Support–While the portal is user friendly, support through the customer service team is available Monday-Friday from 9am–8pm ET by calling the XOLAIR Co-pay Program support line at (855) 965-2472.</p>
                    <p>You may assist new or current patients using the XOLAIR Co-pay Program at www.xolaircopay.com or call and speak with a live agent Monday-Friday from 9am–8pm ET by calling the XOLAIR Co-pay Program support line at (855) 965-2472.</p>
                    <p>By clicking below, you certify that the patient information displayed on the XOLAIR Co-pay Program portal including, but not limited to: patient name, email address, mailing address, phone number date of birth insurance and claim information will be kept and maintained in strict confidence using such degree of care as appropriate to avoid unauthorized access or use of the information. Any use or disclosure must be made in accordance by the provider's medical or reimbursement staff that has a need to know or otherwise need to access personal information to enable the provider to perform activities related to the patient's treatment or <span className="noWrap">Co-payment</span> assistance. You also certify that the provider or Genentech will not disclose personal information to any person other than the providers medical or reimbursement staff.</p>
                    <p>Furthermore, in the event of a Security Breach, ("Security Breach" meaning any act or omission that compromises either the security, confidentiality or integrity of Personal Information), the provider or its Authorized Users must notify Genentech of a Security Breach as soon as practicable, but no later than twenty-four (24) Hours after Service Provider becomes aware of it by telephone at the following number (855) 965-2472. Additionally, Genentech will notify appropriate government agencies and users of any Security Breach as required by law.</p>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="field required-field text"><label style={{ fontSize: '1em', lineHeight: '1.49em' }}><span >Name of Practice</span></label><input value={practice_name} onChange={e => updatePractice(e)} type="text" name="practice" style={{ width: '95%' }} /></p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-3">
                        <p className="field required-field text"><label style={{ fontSize: '1em', lineHeight: '1.49em' }}><span >Date</span></label><input value={date} onChange={e => updateDate(e)} type="text" className="datepicker" autoComplete='off' name="date" style={{ width: '100%' }} /></p>
                    </div>
                    <div className="space"></div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <input type="hidden" name="agree_user" value="1" />
                        <p><button type="submit" id="submit">I Agree</button><button type="reset" id="reset" onClick={goBack}>I Disagree</button></p>
                    </div>
                </form>
            </div>
            <div className="space"></div>
        </div>
    )
}

