import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client';
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import AllergyPartnersProfileSettings from '../components/AllergyPartnersProfileSettings'
import BankAccountInfoEFT from '../components/BankAccountInfoEFT'
import {GET_PROVIDER_PAYMENT_TYPE} from '../../graphql/queries/Providers'

export default function ProviderProfile() {

    const provider_id = parseInt(window.localStorage.getItem('providerID'))
    const [preferred_payment, setpayment] = useState('')

    useEffect(() => {
        $(function () {
            $('.terms-hcp').on('click', function (event) {
                //event.preventDefault();
                $('.messages-popupx').css('display', 'block');
            });
        
            $('#accept-messagesx').on('click', function () {
                $("#next-hcp").prop("disabled", false);
                $('.messages-popupx').css('display', 'none');
        
            });
            $('#decline-messages').on('click', function () {
                $('.messages-popupx').css('display', 'none');
        
            });
        });
    })

    const { loading, error, data, refetch } = useQuery(GET_PROVIDER_PAYMENT_TYPE, {
        variables: { provider_id }
    });

    useEffect(() => {
        if(data != null){
            if(data.providerfind != null){
                if(data.providerfind.preferred_payment_method != null){
                    setpayment(data.providerfind.preferred_payment_method)
                }
            }
        }
    }, [data])

    return (
        <>
            <div className="messages-popupx">
                <div className="popup-box">
                    <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>ACH Terms and Conditions </h2>
                    <p>In order to enroll into the Federal Automated Clearing House ("ACH") payment processing service through The RIS Rx ("RIS Rx"), the individual or company ("Participant") must have a valid checking or savings account at a financial institution that participates in ACH. Submission of the XOLAIR Co-pay Program Electronic Funds Transfer (EFT) Authorization Form authorizes RIS Rx to electronically deposit payments through ACH to any Health Care Provider pertaining to payments issued by the XOLAIR Co-pay Program for approved and eligible payment requests. </p>
                    <p>In order to successfully carry out RIS Rx's fiscal responsibility, the Participant agrees:</p>
                    <ul>
                        <li>To the provisions of this ACH agreement;</li>
                        <li>To provide to RIS Rx accurate ACH enrollment information;</li>
                        <li>That payment will only be granted to the Participant that provides the required documentation for payment as outlined in the XOLAIR Co-pay Program;</li>
                        <li>That any revised authorization will replace any previous authorization;</li>
                        <li>That authorization is granted to RIS Rx to correct inadvertent duplicate or overpayment transactions;</li>
                        <li>To hold harmless RIS Rx for any delay or loss of funds due to incorrect information submitted by any of the Participant's authorized representatives or its financial institution.</li>
                    </ul>
                    <p>The Participant's authorization shall remain effective until notification of termination is provided to RIS Rx at least ten (10) business days prior via an updated EFT Authorization Form. Such notice should afford RIS Rx and the financial institution reasonable opportunity to act on it. It is the Participant's responsibility to provide notice to RIS Rx immediately of any changes to its financial institution, routing and account number(s).  </p>
                    <p>To make changes to your account information, visit Xolaircopay.com and log into the secure Health Care Provider Portal.</p>
                    <p>If you have questions, call the XOLAIR Co-pay Program at <span className="phoneNumber"><strong>(855) 965-2472</strong></span>.</p>
                    <p><button id="accept-messagesx">Accept</button>
                        <button id="decline-messages" className="cancel" type="reset">Decline</button></p>
                </div>
            </div>
            <div className="container" id="content" style={{ fontSize: '1em' }}>
                <div id="container">
                    <div id="hcp_post_content">
                        <h1 style={{ fontSize: '1.5em', lineHeight: '1.49em' }}>Provider Profile</h1>
                        <font color="red"></font>
                        <div className="profile-box">
                            <AllergyPartnersProfileSettings />
                            {preferred_payment == "ACH" ? <BankAccountInfoEFT /> : <></>}
                        </div>
                        <div className="clearfloat"></div>
                    </div>
                </div>
            </div>
        </>
    )
}