import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function PasswordReset() {
    const [password, setpassword] = useState("");
    const [confirm_password, setconfirmpassword] = useState("");

    const [validpassword, setvalidpassword] = useState(true);
    const [validconfirmpassword, setvalidconfirmpassword] = useState(true);
    const [passwordMatch, setpasswordmatch] = useState(true);
    const [hasSpecialCharacter, setHasSpecialCharacter] = useState(true);

    const [userCreated, setusercreated] = useState(false);
    const [userExists, setuserexists] = useState(false);

    const [invalidLink, setinvalidLink] = useState(false);

    const xref_id = parseInt(window.localStorage.getItem("patientID"));
    const login_id = window.localStorage.getItem("memberID");

    let navigate = useNavigate();
    const [error, showerror] = useState(false);

    function createPassword(event) {
        event.preventDefault();
        if (password != confirm_password) {
            setpasswordmatch(false);
        } else {
            setpasswordmatch(true);
        }
        if (password == "" || password.length < 12) {
            setvalidpassword(false);
        } else {
            setvalidpassword(true);
        }
        if (confirm_password == "" || confirm_password.length < 12) {
            setvalidconfirmpassword(false);
        } else {
            setvalidconfirmpassword(true);
        }
        if (containsSpecialChars(password) == false) {
            setHasSpecialCharacter(false);
        } else {
            setHasSpecialCharacter(true);
        }
        if (
            password != "" &&
            confirm_password != "" &&
            passwordMatch == true &&
            validpassword == true &&
            validconfirmpassword == true &&
            hasSpecialCharacter == true
        ) {
            resetPassword(password);
        }
    }

    function resetPassword(newpassword) {
        const queryString = window.location.search;
        var arr = queryString.split("=");
        var uniqueId = arr[1];

        let apiPayload = JSON.stringify({
            unique_id: uniqueId,
            password: newpassword,
        });
        const headers = { "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*" };

        if (uniqueId) {
            axios
                .post(`https://xremail.netswitchcard.com/password-reset/`, apiPayload, { headers: headers })
                .then((res) => {
                    if (res.status == "200") {
                        if (res.data.user_type == "Provider" || res.data.user_type == "Administrator") {
                            let path = `../hcp`;
                            navigate(path);
                        } else {
                            let path = `../login`;
                            navigate(path);
                        }
                    } else {
                        showerror(true);
                    }
                })
                .catch(function (error) {
                    console.log(error.toJSON());
                    if (error.toJSON().status == 410) {
                        setinvalidLink(true);
                    }
                });
        }
    }

    function containsSpecialChars(str) {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str);
    }

    return (
        <div className="container" id="content" style={{ fontSize: "1em" }}>
            <div id="container">
                <div id="hcp_post_content">
                    <div className="space"></div>
                    <div className="shadow-box">
                        <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>Choose New Password for User </h2>
                        <p className="required">*Required field.</p>
                        <p>Your new password must be</p>
                        <ul>
                            <li>At least 12 characters</li>
                            <li>At least 1 capital letter</li>
                            <li>At least 1 lowercase letter</li>
                            <li>At least 1 number</li>
                            <li>At least 1 special character (e.g., !@#$%^&amp;*()+|~-=`{}[]:";'&lt;&gt;?,./)</li>
                            <li>Cannot reuse the last 5 passwords</li>
                        </ul>
                        <div className="find_patient">
                            <form onSubmit={(event) => createPassword(event)} className="form-validate-create-password">
                                <div className="col-sm-12 col-md-12 col-lg-4">
                                    <p className="field required-field password">
                                        <label className="normal" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <span className="inner-label">
                                                New Password<span className="required">*</span>
                                            </span>
                                        </label>
                                        <input
                                            value={password}
                                            onChange={(e) => setpassword(e.target.value)}
                                            type="password"
                                            name=""
                                            id="pass_2"
                                            autoComplete="off"
                                        />
                                    </p>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-4">
                                    <p className="field required-field password password-retype">
                                        <label className="normal" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <span className="inner-label">
                                                Confirm New Password<span className="required">*</span>
                                            </span>
                                        </label>
                                        <input
                                            value={confirm_password}
                                            onChange={(e) => setconfirmpassword(e.target.value)}
                                            type="password"
                                            name=""
                                            id="pass_3"
                                            autoComplete="off"
                                        />
                                    </p>
                                </div>
                                {error ? <p className="red">Password Reset Error</p> : <></>}
                                <p>
                                    <br />
                                    <button type="submit" name="loginSubmit" autoComplete="off">
                                        Update
                                    </button>
                                </p>
                                {userExists == true ? <p>Account already exists</p> : <></>}
                                {userCreated == true ? (
                                    <p>
                                        Account created <Link to="../login">login</Link>
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </form>
                        </div>
                    </div>
                    <div className="clearfloat"></div>
                </div>
            </div>
        </div>
    );
}
