import React from 'react'
import { useOutletContext } from 'react-router-dom'

export default function ReviewProviderInformation() {

    const { provider_data, setproviderdata } = useOutletContext()

    return (
        <div className="section shadow-box color-label">
            <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Provider's Information</h2>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p>
                        <label htmlFor="firstName" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>First name</label>
                        {provider_data.first_name}    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p>
                        <label htmlFor="lastName" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Last name</label>
                        {provider_data.last_name}    </p></div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">

                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p>
                        <label htmlFor="street" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Address</label>
                        {provider_data.address.address1}    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p>
                        <label htmlFor="street" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Address 2</label>
                     {provider_data.address.address2} </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p>
                        <label htmlFor="city" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>City</label>
                        {provider_data.address.city}    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p>
                        <label htmlFor="state" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>State</label><br />
                        {provider_data.address.state}    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className=" text zipcode">
                        <label htmlFor="zipCode" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>ZIP code</label>
                        {provider_data.address.zip_code}    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className=" text phone">
                        <label htmlFor="phone" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Phone number</label>
                        {provider_data.phones.phone}    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="col-sm-12 col-md-12 col-lg-4">
                        <p className=" text phone">
                            <label htmlFor="phone" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>National Provider Identifier (NPI)</label>
                            {provider_data.npi}    </p>
                    </div>
                </div>
            </div>
        </div>
    )
}