import React, { useState, useEffect } from "react";
import { writeFile } from "xlsx";
import * as XLSX from "xlsx/xlsx.mjs";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery, gql } from "@apollo/client";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.min.css";
import { PATIENT_LIST } from "../../graphql/queries/Providers";
import excel from "../../images/excel.png";
import PatientListTableRow from "../components/PatientListTableRow";

export default function PatientList() {
    useEffect(() => {
        const popup = window.localStorage.getItem("hcppopup") ? window.localStorage.getItem("hcppopup") : "";
        $(function () {
            if (popup == "true") {
                $(".page-alert").css("display", "none");
            } else {
                $(".page-alert").css("display", "block");

                $(".page-alert .close-button").on("click", function (event) {
                    event.preventDefault();
                    window.localStorage.setItem("hcppopup", "true");
                    $(".page-alert").css("display", "none");
                });
            }
        });
    });

    function closeMessage() {
        $(".page-alert").css("display", "none");
    }
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [date_of_birth, setDob] = useState("");
    const [member_id, setMemberID] = useState("");
    const [product, setProduct] = useState("");

    const [inputfirst_name, setinputFirstName] = useState("");
    const [inputlast_name, setinputLastName] = useState("");
    const [inputdate_of_birth, setinputDob] = useState(null);
    const [inputmember_id, setinputMemberID] = useState("");
    const [inputproduct, setinputProduct] = useState("");

    const patient_id = parseInt(window.localStorage.getItem("xrefID"));
    const provider_id = parseInt(window.localStorage.getItem("providerID"));

    const [loadPatients, { called, loading, data, fetchMore }] = useLazyQuery(PATIENT_LIST, {
        variables: { first_name, last_name, provider_id, member_id, date_of_birth },
    });

    function handleDownloadClick() {
        /*
        // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("patient-list");
    
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    
    // Process Data (add a new row)
    var ws = workbook.Sheets["Sheet1"];
    //XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});
    
    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    XLSX.writeFile(workbook, "PatientList.xlsx");
    */

        /*
        const ws= XLSX.utils.table_to_sheet(document.getElementById("patient-list"));
        ws['!cols'][7] = {hidden: true}
        
        
            const wb= XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        
            XLSX.writeFile(wb, 'SheetTest.xlsx');
            */

        const exceldata = [
            ["Patient Name", "Date of Birth", "Member ID", "Product", "Address", "Enroll Date", "Original Enroll Date", "Status"],
        ];
        {
            data &&
                data.providerpatient &&
                data.providerpatient.map(
                    ({
                        __typename,
                        patient_id,
                        member_id,
                        enrollment_status,
                        current_enrollment_date_drug,
                        current_enrollment_date_drug_admin,
                        original_enrollment_date_drug,
                        original_enrollment_date_drug_admin,
                        drug_patient,
                        drug_admin_patient,
                        enrollment_data,
                    }) =>
                        exceldata.push([
                            enrollment_data.last_name + ", " + enrollment_data.first_name,
                            enrollment_data.date_of_birth.substring(5, 7) +
                                "/" +
                                enrollment_data.date_of_birth.substring(8, 10) +
                                "/" +
                                enrollment_data.date_of_birth.substring(0, 4),
                            member_id,
                            (drug_patient.toLowerCase() == "y" ? "XOLAIR DRUG" : "") +
                                (drug_patient.toLowerCase() == "y" && drug_admin_patient.toLowerCase() == "y" ? ", " : "") +
                                (drug_admin_patient.toLowerCase() == "y" ? "XOLAIR ADMIN" : ""),
                            enrollment_data.address.address_1 +
                                " " +
                                enrollment_data.address.address_2 +
                                " " +
                                enrollment_data.address.city +
                                ", " +
                                enrollment_data.address.state +
                                " " +
                                enrollment_data.address.zip_code,
                            (drug_patient.toLowerCase() == "y" ? current_enrollment_date_drug : "") +
                                (drug_patient.toLowerCase() == "y" && drug_admin_patient.toLowerCase() == "y" ? ", " : "") +
                                (drug_admin_patient.toLowerCase() == "y" ? current_enrollment_date_drug_admin : ""),
                            (drug_patient.toLowerCase() == "y" ? original_enrollment_date_drug : "") +
                                (drug_patient.toLowerCase() == "y" && drug_admin_patient.toLowerCase() == "y" ? ", " : "") +
                                (drug_admin_patient.toLowerCase() == "y" ? original_enrollment_date_drug_admin : ""),
                            enrollment_status,
                        ])
                );
        }

        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = mm + "-" + dd + "-" + yyyy;

        //create workbook
        const wb = XLSX.utils.book_new();
        //create sheet
        const ws = XLSX.utils.aoa_to_sheet(exceldata, { origin: "A1", skipHeader: false });
        //append sheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, "Patient List");
        XLSX.writeFile(wb, "XolairPatientList" + formattedToday + ".xlsx");
    }

    function search() {
        setFirstName(inputfirst_name);
        setLastName(inputlast_name);
        setMemberID(inputmember_id);
        var date = $(".datepicker").val();
        var month = date.substring(0, 2);
        var day = date.substring(3, 5);
        var year = date.substring(6, 10);
        if (date != "") {
            setDob(year + "-" + month + "-" + day);
        }

        loadPatients();
    }

    function resetSearch() {
        $("input[id=datepicker]").val("");
        setinputFirstName("");
        setinputLastName("");
        setinputMemberID("");
        setinputProduct("");
        setFirstName("");
        setLastName("");
        setDob("");
        setMemberID("");
        setProduct("");
    }

    useEffect(() => {
        //load all patients on page load
        //loadPatients()

        $(function () {
            $(".datepicker").datepicker({
                dateFormat: "mm/dd/yy",
                showButtonPanel: true,
                changeMonth: true,
                changeYear: true,
                yearRange: "1920:+nn",
                showOtherMonths: true,
                selectOtherMonths: true,
            });
        });
    }, [data]);

    return (
        <>
            <div className="page-alert" style={{ display: "block" }}>
                <div className="popup-box" style={{ padding: "35px" }}>
                    <p>
                        We've recently made changes to the co-pay program provider portal. Please review your email address and update the
                        information accordingly under <Link to="../provider-profile">Provider Profile</Link> (to receive co-pay related
                        communications) and <Link to="../my-profile">User Profile</Link> (to be able to reset your password online without
                        having to contact the program for assistance).{" "}
                    </p>
                    <p>
                        For more information, please call the Help Desk at <strong>(855) 965-2472</strong>.{" "}
                    </p>
                    <button className="save close-button" onClick={closeMessage}>
                        Close
                    </button>
                </div>
            </div>
            <div className="container" id="content" style={{ fontSize: "1em" }}>
                <div id="container">
                    <div id="hcp_post_content">
                        <h1 style={{ fontSize: "1.5em", lineHeight: "1.49em" }}>Patient Search</h1>
                        <p>
                            Enter one or more criteria to search for your patients. "First Name" and "Last Name" provides partial text
                            search.
                        </p>
                        <div className="required"></div>
                        <div className="find_patient" id="search_form">
                            <div className="find_patient">
                                <p>
                                    <input
                                        onChange={(e) => setinputFirstName(e.target.value)}
                                        type="text"
                                        name="firstname"
                                        placeholder="First Name"
                                        value={inputfirst_name}
                                    />{" "}
                                    <input
                                        onChange={(e) => setinputLastName(e.target.value)}
                                        type="text"
                                        name="lastname"
                                        placeholder="Last Name"
                                        value={inputlast_name}
                                    />{" "}
                                    <input
                                        id="datepicker"
                                        className="datepicker"
                                        type="text"
                                        name="birth_date"
                                        placeholder="Date of Birth"
                                    />{" "}
                                    <input
                                        onChange={(e) => setinputMemberID(e.target.value)}
                                        type="text"
                                        name="member_id"
                                        placeholder="Member ID"
                                        value={inputmember_id}
                                    />{" "}
                                </p>
                                <p>
                                    <span className="select_container">
                                        <select name="product" defaultValue="" style={{ maxWidth: "inherit", width: "100%" }}>
                                            <option onChange={(e) => setinputProduct("")} value="">
                                                Product
                                            </option>
                                            <option onChange={(e) => setinputProduct("XR")} value="XR">
                                                XOLAIR DRUG
                                            </option>
                                            <option onChange={(e) => setinputProduct("XRA")} value="XRA">
                                                XOLAIR ADMINISTRATION
                                            </option>
                                        </select>
                                    </span>
                                </p>
                                <p></p>
                            </div>
                            <p>
                                <button onClick={search} name="list_all" id="grey-button">
                                    Search
                                </button>{" "}
                                <button onClick={resetSearch}>Reset</button>
                            </p>
                        </div>
                        <div id="hcp_post_content">
                            <div>
                                <span className="tooltip" style={{ float: "right" }}>
                                    <a onClick={handleDownloadClick}>
                                        <img src={excel} className="excel" />
                                    </a>
                                    <span className="tooltiptext">
                                        Click here to download entire patient list. Please note there is a 24 hour delay for newly enrolled
                                        patients to be included in this excel list
                                    </span>
                                </span>
                                <table className="account" align="center" style={{ margin: "0" }} id="patient-list">
                                    <thead>
                                        <tr className="list_header">
                                            <th align="left" width="150" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                                Patient Name
                                            </th>
                                            <th align="left" width="150" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                                Date of Birth
                                            </th>
                                            <th align="center" width="120" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                                Member ID
                                            </th>
                                            <th align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                                Product
                                            </th>
                                            <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                                Address
                                            </th>
                                            <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                                Enroll Date
                                            </th>
                                            <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                                Original Enroll Date
                                            </th>
                                            <th align="center" style={{ textAlign: "center", fontSize: "1em", lineHeight: "1.49em" }}>
                                                Status
                                            </th>
                                            <th
                                                align="center"
                                                style={{ textAlign: "center", fontSize: "1em", lineHeight: "1.49em" }}
                                                width="150"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody id="table-list">
                                        {data &&
                                            data.providerpatient &&
                                            data.providerpatient.map(
                                                ({
                                                    __typename,
                                                    patient_id,
                                                    member_id,
                                                    enrollment_status,
                                                    current_enrollment_date_drug,
                                                    current_enrollment_date_drug_admin,
                                                    original_enrollment_date_drug,
                                                    original_enrollment_date_drug_admin,
                                                    drug_patient,
                                                    drug_admin_patient,
                                                    enrollment_data,
                                                }) => (
                                                    <PatientListTableRow
                                                        patientID={patient_id}
                                                        firstName={enrollment_data.first_name}
                                                        lastName={enrollment_data.last_name}
                                                        dob={enrollment_data.date_of_birth}
                                                        memberID={member_id}
                                                        product={product}
                                                        address1={enrollment_data.address.address_1}
                                                        address2={enrollment_data.address.address_2}
                                                        city={enrollment_data.address.city}
                                                        state={enrollment_data.address.state}
                                                        zip={enrollment_data.address.zip_code}
                                                        status={enrollment_status}
                                                        enrollmentDate={current_enrollment_date_drug}
                                                        enrollmentDateAdmin={current_enrollment_date_drug_admin}
                                                        originalEnrollmentDate={original_enrollment_date_drug}
                                                        originalEnrollmentDateAdmin={original_enrollment_date_drug_admin}
                                                        xolairDrug={drug_patient}
                                                        xolairAdmin={drug_admin_patient}
                                                    />
                                                )
                                            )}
                                    </tbody>
                                </table>
                                <div className="pages" style={{ marginTop: "0" }}>
                                    <div className="col-lg-8"></div>
                                    <div className="col-lg-4">
                                        <div className="col-lg-5">
                                            Items per page:{" "}
                                            <select name="">
                                                <option>10</option>
                                                <option>20</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-3">1 - 1 of 1 </div>
                                        <div className="col-lg-4">
                                            <a href="#">
                                                <span className="nav-left"></span>
                                            </a>
                                            <a href="#">
                                                <span className="nav-right"></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clearfloat"></div>
                    </div>
                    <div className="space"></div>
                    <div className="space"></div>
                </div>
            </div>
        </>
    );
}
