import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { PATIENT_LOGIN } from "../graphql/queries/Users";
import LoginError from "../components/LoginError";

export default function Login() {
    let navigate = useNavigate();

    var [incorrectLoginCount, setcount] = useState(0);
    var [attemptsLeft, setAttemptsLeft] = useState(7);

    const [login_id, setloginquery] = useState("");
    const [password_hash, setpasswordquery] = useState("");

    const [loginID, setLoginID] = useState("");
    const [password, setPassword] = useState("");

    const [userlogin, { data }] = useLazyQuery(PATIENT_LOGIN, {
        variables: { login_id, password_hash },
    });

    useEffect(() => {
        if (data) {
            if (data.user != null) {
                if (data.user.token != null) {
                    window.localStorage.setItem("token", data.user.token);
                }
                if (data.user.user != null) {
                    if (data.user.user.result != null) {
                        if (data.user.user.result == 0) {
                            setcount(0);
                            window.localStorage.setItem("xrefID", JSON.stringify(data.user.user.xref_id));
                            window.localStorage.setItem("credentialsID", JSON.stringify(data.user.user.credentials_id));
                            let path = `../profile`;
                            navigate(path);
                        } else {
                            setloginquery("");
                            setpasswordquery("");
                            setLoginID("");
                            setPassword("");
                            setcount(incorrectLoginCount + 1);
                        }
                    }
                }
            } else {
                setcount(incorrectLoginCount + 1);
                // setAttemptsLeft(attemptsLeft - 1);
            }
        } else {
            if (loginID != "") {
                setcount(incorrectLoginCount + 1);
                setAttemptsLeft(attemptsLeft - 1);
                setLoginID("");
                setPassword("");
            }
        }
        /*
        if (data == null) {
            setLoginID('');
            setPassword('');
            setcount(incorrectLoginCount + 1)
            setAttemptsLeft(attemptsLeft - 1)
        }
        */
    }, [data]);

    function login(e) {
        e.preventDefault();
        localStorage.removeItem("token");

        if (loginID != "" && password != "" && attemptsLeft > 0) {
            setloginquery(loginID);
            setpasswordquery(password);
            userlogin({ login_id, password_hash });
            if (data) {
                if (data.user != null) {
                    if (data.user.result) {
                        if (data.user.result == -1) {
                            setLoginID("");
                            setPassword("");
                            setcount(incorrectLoginCount + 1);
                            setAttemptsLeft(attemptsLeft - 1);
                        }
                    }
                    // console.log("DATA NUll but not rlly");
                    setcount(incorrectLoginCount + 1);
                    setAttemptsLeft(attemptsLeft - 1);
                }
            }
            if (data == null) {
                setLoginID("");
                setPassword("");
                setcount(incorrectLoginCount + 1);
                setAttemptsLeft(attemptsLeft - 1);
            }
        }
    }

    return (
        <div className="container" id="content">
            <div id="container" className="account">
                <h1 style={{ fontSize: "1.5em", lineHeight: "1.49em" }}>Log in to Your Account</h1>
                <p>Log in to your account to view your profile and account information.</p>
                <p className="required">*Required field.</p>
                <div className="required"></div>
                {incorrectLoginCount > 1 ? <LoginError count={attemptsLeft} /> : <></>}
                <div className="small-login">
                    <form onSubmit={(e) => login(e)} className="form-validate" acceptCharset="utf-8">
                        <p className="field required-field text">
                            <label htmlFor="cardNumber" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                Username:<span className="required">*</span>
                            </label>
                            <input
                                onChange={(e) => setLoginID(e.target.value)}
                                type="text"
                                name="username"
                                id="cardNumber"
                                value={loginID}
                            />
                        </p>
                        <p className="field required-field text">
                            <label htmlFor="pass" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                Password:<span className="required">*</span>
                            </label>
                            <input
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                type="password"
                                name="password"
                                id="pass"
                                autoComplete="off"
                            />
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <button type="submit" name="loginSubmit">
                                Log in
                            </button>
                        </p>
                    </form>
                </div>
                <div className="clear"></div>
                <p>
                    <Link to="../create-account">I need to set up an account.</Link>
                </p>
                <p></p>
                <p>
                    If you forgot your username please call{" "}
                    <span className="text-nowrap">
                        <strong>(855) 965-2472</strong>
                    </span>{" "}
                    for assistance. If you forgot your password please <Link to="../password-request">click here</Link>.
                </p>
            </div>
        </div>
    );
}
