import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';
import { GET_SITE_OF_CARE_NPI , PROVIDER_INFORMATION } from '../graphql/queries/Patients'

export default function SiteOfCareInformation(){

    const patient_id = parseInt(window.localStorage.getItem('xrefID'))

    const [practicename, setpracticename] = useState('')
    const [address, setaddress] = useState('');
    const [address2, setaddress2] = useState('')
    const [city, setcity] = useState('');
    const [state, setstate] = useState('');
    const [zip, setzip] = useState('');
    const [phonenumber, setphonenumber] = useState('');

    const [npi, setnpi] = useState(0)

    const { data: getnpi } = useQuery(GET_SITE_OF_CARE_NPI, {
        variables: { patient_id }
    })

    useEffect(() => {
        if(getnpi != null){
            if(getnpi.patientinfo != null){
                if(getnpi.patientinfo.provider_data != null){
                    if(getnpi.patientinfo.provider_data.secondary != null){
                        if(getnpi.patientinfo.provider_data.secondary[0] != null){
                            if(getnpi.patientinfo.provider_data.secondary[0].provider_npi != null){
                                setnpi(parseInt(getnpi.patientinfo.provider_data.secondary[0].provider_npi))
                            }
                        }
                        
                    }
                }
            }
        }
    }, [getnpi])
    
    

    const { loading, error, data: data, refetch } = useQuery(PROVIDER_INFORMATION, {
        variables: { npi, patient_id }
    });

    useEffect(() => {
        if (npi != 0) {
            if (patient_id) {
                if (data != null) {
                    if(data.providernpi != null){
                        if(data.providernpi.provider_data != null){
                            if (data.providernpi.provider_data.practice_name) {
                                setpracticename(data.providernpi.provider_data.practice_name)
                            }
                            if (data.providernpi.provider_data.address.address_1) {
                                setaddress(data.providernpi.provider_data.address.address_1)
                            }
                            if (data.providernpi.provider_data.address.address_2) {
                                setaddress2(data.providernpi.provider_data.address.address_2)
                            }
                            if (data.providernpi.provider_data.address.city) {
                                setcity(data.providernpi.provider_data.address.city)
                            }
                            if (data.providernpi.provider_data.address.state) {
                                setstate(data.providernpi.provider_data.address.state)
                            }
                            if (data.providernpi.provider_data.address.zip_code) {
                                setzip(data.providernpi.provider_data.address.zip_code)
                            }
                            if (data.providernpi.provider_data.phone_number) {
                                setphonenumber(data.providernpi.provider_data.phone_number)
                            }
                        }
                    }
                } 
            } 
        }
    }, [data])

    return (
        <div className="section shadow-box color-label">
            <h2 style={{fontSize: '1.25em' , lineHeight: '1.49em'}}>Site of Care Information</h2>
            <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="col-sm-12 col-md-12 col-lg-4">
            <p><label style={{fontSize: '1em' , lineHeight: '1.49em'}}>Practice name</label>
            {practicename}</p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
            <p><label style={{fontSize: '1em' , lineHeight: '1.49em'}}>National Provider Identifier (NPI)</label>
            {npi}</p>
            </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
            <p><label style={{fontSize: '1em' , lineHeight: '1.49em'}}>Address</label>
            {address}<br/>
            {address2}<br/>
            {city}, {state} {zip}</p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
            <p><label style={{fontSize: '1em' , lineHeight: '1.49em'}}>Phone number</label>
            {phonenumber}</p>
            </div>
            </div>
    )
}