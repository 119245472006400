import React, { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom'
import $ from 'jquery';
import calBtn from '../../images/calbtn.gif'
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import MessagesTable from '../../components/MessagesTable'
import { PATIENT_NAME } from '../../graphql/queries/Patients'



export default function Messages() {
    const [fromdate, inputsetfromdate] = useState('')
  const [todate, inputsettodate] = useState('')

    const patient_id = parseInt(window.localStorage.getItem('xrefID'))
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')



    const { loading, error, data, refetch } = useQuery(PATIENT_NAME, {
        variables: { patient_id }
    });

    useEffect(() => {
        $(document).ready(function () {
            $('#tabs').tabs({ active: 0 });
        });
        if (patient_id) {
            if (data) {
                if (data.patientinfo.enrollment_data.first_name) {
                    setFirstName(data.patientinfo.enrollment_data.first_name)
                }
                if (data.patientinfo.enrollment_data.last_name) {
                    setLastName(data.patientinfo.enrollment_data.last_name)
                }
            } else {
            }
        } else {
        }
        $(document).ready(function() {
            $(".datepicker").datepicker({dateFormat: 'mm/dd/yy',showButtonPanel: true,
              changeMonth: true,
              changeYear: true,
              yearRange: "1920:+nn",
              showOtherMonths: true,
              selectOtherMonths: true });
          });
          $(document).ready(function() {
            $(".datepicker2").datepicker({dateFormat: 'mm/dd/yy',showButtonPanel: true,
              changeMonth: true,
              changeYear: true,
              yearRange: "1920:+nn",
              showOtherMonths: true,
              selectOtherMonths: true });
          });
    }, [data])

    function updateDateRange(e){
        e.preventDefault();
  
        var date = $(".datepicker").val()
        var month = date.substring(0, 2)
        var day = date.substring(3, 5)
        var year = date.substring(6, 10)
        if (date != '') {
            inputsetfromdate(year + "-" + month + "-" + day)
        }
  
        var date2 = $(".datepicker2").val()
        var month2 = date2.substring(0, 2)
        var day2 = date2.substring(3, 5)
        var year2 = date2.substring(6, 10)
        if (date2 != '') {
            inputsettodate(year2 + "-" + month2 + "-" + day2)
        }
      }

    return (
        <div className="container" id="content" style={{ fontSize: '1em' }}>
            <article className="content patientProfile">
                <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>{first_name} {last_name}</h2>
                <section>
                    <div className="mobilesubmenu" style={{ display: 'none' }}>
                        <ul className="nav submenu overview">
                            <li id="submenu_profile"><Link to="../patient-informations">Patient</Link></li>
                            <li className="selected" id="submenu_account"><Link to="../claims">Claims</Link></li>
                            <li id="submenu_messages"><Link to="../messages">Communications</Link></li>
                        </ul>
                    </div>
                    <div className="responsive-button-submenu hidden-sm hidden-md hidden-lg"><i className="fa fa-bars" aria-hidden="true"></i><span>ACCOUNT MENU</span></div>
                    <ul id="navsubmenu" className="nav submenu overview">
                        <li id="submenu_profile"><Link to="../patient-informations">Patient</Link></li>
                        <li id="submenu_account"><Link to="../claims">Claims</Link></li>
                        <li className="selected" id="submenu_messages"><Link to="../messages">Communications</Link></li>
                    </ul>
                    <div className="clearfloat"></div>
                    <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Patient Communications History</h2>
                    <form onSubmit={e => updateDateRange(e)} >

                        <div className="find_patient">
                        <p>
                    <input className="datepicker" type="text" name="birth_date" placeholder='From Date' size="10"/><img className="ui-datepicker-trigger" src={calBtn} alt="..." title="..." />
                    <input className="datepicker2" type="text" name="birth_date" placeholder='To Date' size="10"/><img className="ui-datepicker-trigger" src={calBtn} alt="..." title="..." />
                  </p>

                            <p> <button  >Search</button></p>
                        </div>
                        <p><br /></p>
                    </form>
                    <MessagesTable fromdate = {fromdate} todate = {todate} />
                    <div className="pages" style={{ marginTop: '0' }}><div className="col-lg-8"></div><div className="col-lg-4"><div className="col-lg-5">Items per page: <select name=""><option>10</option><option>20</option></select></div><div className="col-lg-3">1 - 1 of 1 </div><div className="col-lg-4"><Link to="#"><span className="nav-left"></span></Link><Link to="#"><span className="nav-right"></span></Link></div></div></div>
                </section>
            </article>
        </div>
    )
}