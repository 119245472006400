import React from "react";

export default function TermsAndConditions() {
    return (
        <div className="container" id="content">
            <article className="content">
                <section>
                    <div>
                        <h1 style={{ fontSize: "1.5em", lineHeight: "1.49em" }}>Drug and Injection Co-pay Program Terms and Conditions</h1>
                        <p>
                            The Product and Administration Co-pay Programs ("Programs") are valid ONLY for patients with commercial (private
                            or non-governmental) insurance who have a valid prescription for a Food and Drug Administration (FDA)-approved
                            indication of a Genentech medicine. The Product and Administration Co-pay Programs are not available to patients
                            whose prescriptions are reimbursed under any federal, state, or government-funded insurance programs (included
                            by not limited to Medicare, Medicare Advantage, Medigap, Medicaid, TRICARE, Department of Defense, or Veterans
                            Affairs Programs) or where prohibited by law or by the patient's health insurance provider. If at any time a
                            patient begins receiving prescription drug coverage under any such federal, state or government-funded
                            healthcare programs, the patient will no longer be eligible for the Programs. The Programs are not valid if the
                            costs are eligible to be reimbursed in their entirety by private insurance plans or other programs.
                        </p>
                        <p>
                            Under the Programs, the patient may be required to pay a co-pay. The final amount owed by a patient may be as
                            little as $0 for the Genentech medicine or administration of the Genentech medicine (see Program specific
                            details available at the Program Website). The total patient out-of-pocket cost is dependent on the patient's
                            health insurance plan. The Programs assist with the cost of the Genentech medicine and the administration of the
                            Genentech medicine only. It does not assist with the cost of other administrations, medicines, procedures or
                            office visit fees. After reaching the maximum Programs' benefit amounts, the patient will be responsible for all
                            remaining out-of-pocket expenses. The Programs' benefit amounts cannot exceed the patient's out-of-pocket
                            expenses for the Genentech medicine or administration fees of the Genentech medicine. The maximum Programs'
                            benefits will reset every January 1st. The Programs are not health insurance or a benefit plan. The patient's
                            non-governmental insurance is the primary payer. The Programs do not obligate use of any specific medicine or
                            provider. The Drug Co-pay Program is not available or valid for patients receiving free medicine from the
                            Genentech Patient Foundation. The Administration Co-pay Program is valid for patients receiving free medicine
                            from the Genentech Patient Foundation. The Product and Administration Programs are not valid for patients
                            receiving assistance from any other charitable organization for the same expenses covered by the Programs. The
                            Programs' benefits cannot be combined with any other rebate, free trial or other offer for the Genentech
                            medicine or administration of the Genentech medicine. No party may seek reimbursement for all or any part of the
                            benefits received through these Programs.
                        </p>
                        <p>
                            The Programs may be accepted by participating pharmacies, physicians' offices or hospitals. Once a patient is
                            enrolled, the Programs will honor claims with a date of service that precedes the Programs' enrollment by 180
                            days. Claims must be submitted within 365 days from the date of service unless otherwise indicated. Use of these
                            Programs must be consistent with all relevant health insurance requirements. Participating patients, pharmacies,
                            physicians' offices and hospitals are responsible for reporting the receipt of all the Programs' benefits as
                            required by any insurer or by law. Programs' benefits may not be sold, purchased, traded or offered for sale.
                        </p>
                        <p>
                            The patient or their guardian must be 18 years of age or older to receive assistance from the Programs. The
                            Programs are only valid in the United States and U.S. Territories and are void where prohibited by law. The Drug
                            Co-pay Program shall follow state restrictions in relation to AB-rated generic equivalents (e.g., MA, CA) where
                            applicable. The Administration Co-pay Program is not valid for patients who reside or receive treatment in a
                            restricted state (e.g. Massachusetts or Rhode Island). Eligible patients will be automatically re-enrolled in
                            the Programs on an annual basis. Eligible patients will be removed from the Programs after 3 years of inactivity
                            (e.g., no claims submitted in a 3-year timeframe). Patients who choose reimbursement via virtual debit card will
                            have access to the patient's funds as long as the patient's virtual debit card is valid and the patient is
                            active in the Programs. Once a patient's virtual debit card has expired and they are no longer active in the
                            program, the funds will be removed from the virtual debit card. Programs' eligibility and automatic
                            re-enrollment are contingent upon the patient's ability to meet all the requirements set forth by the Programs.
                            Healthcare providers may not advertise or otherwise use the Programs as a means of promoting their services or
                            Genentech medicines to patients.
                        </p>
                        <p>
                            The value of the Programs is intended exclusively for the benefit of the patient. The funds made available
                            through the Programs may only be used to reduce the out-of-pocket costs for the patient enrolled in the
                            Programs. The Programs are not intended for the benefit of third parties, including without limitation third
                            party payers, pharmacy benefit managers, or their agents. If Genentech determines that a third party has
                            implemented programs that adjust patient cost-sharing obligations based on the availability of support under the
                            Programs and/or excludes the assistance provided under the Programs from counting towards the patient's
                            deductible or out-of-pocket cost limitations, Genentech may impose a per fill cap on the cost-sharing assistance
                            available under the Programs. Submission of true and accurate information is a requirement for eligibility and
                            Genentech reserves the right to disqualify patients who do not comply with Genentech Program Terms and
                            Conditions. Genentech reserves the right to rescind, revoke or amend the Program without notice at any time.
                        </p>
                    </div>
                </section>
            </article>
        </div>
    );
}
