import React from 'react'

export default function EnrollError() {
    const member_id = window.localStorage.getItem('member_id')

    var verification_data
    var memberid = ''
    

    try {
		verification_data = JSON.parse(window.localStorage.getItem('verification_data'))
		memberid = verification_data.response.CardResponseInfo.MemberId
	} catch {

	}
    return (
        <div className="container" id="content">
            <div id="container">
                <h1 style={{fontSize: '1.5em', lineHeight: '1.49em'}}>Sorry, We Are Still Processing Your Request</h1>
                <p>More information may be required. Please call <span className="noWrap"><strong>(855) 965-2472</strong></span> for further assistance.</p>
                <p>Reference number: {member_id}</p>
                <div className="clearfloat"></div>
            </div>
        </div>
    )
}