import { gql } from '@apollo/client';

export const PATIENT_LOGIN = gql`
query User($login_id: String! , $password_hash: String!) { 
  user(
    login_id: $login_id, 
    password_hash: $password_hash
  ) 
  {  
    token
    user {
      result
      xref_id,
      user_type,
      credentials_id
    }
  }
}
`;

export const HCP_LOGIN = gql`
query Userprovider($login_id: String! , $password_hash: String!) { 
  usersprovider(
    login_id: $login_id, 
    password_hash: $password_hash
  ) 
  {  
    token
    user {
      result
      xref_id,
      user_type,
      credentials_id,
      user_id
    }
  }
}
`;
