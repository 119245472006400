import React, { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client';
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import PatientProfileDetails from '../../components/PatientProfileDetails'
import MembershipInformation from '../../components/MembershipInformation'
import CommercialMedicalInsurance from '../../components/CommercialMedicalInsurance'
import ParentCaregiverInfo from '../components/ParentCaregiverInfo'
import VirtualCardNumberInfo from '../components/VirtualCardNumberInfo'
import PatientEnrollmentDetails from '../../components/PatientEnrollmentDetails'
import { PATIENT_NAME } from '../../graphql/queries/Patients'
import UpdatePharmacyComponent from '../../components/UpdatePharmacy'





export default function UpdatePharmacy() {

    const patient_id = parseInt(window.localStorage.getItem('xrefID'))
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')

    const { loading, error, data, refetch } = useQuery(PATIENT_NAME, {
        variables: { patient_id }
    });

    useEffect(() => {
        /*
        $(document).ready(function () {
            $('#save').click(function (event) {
                event.preventDefault();
                $('#save-popup').css('display', 'block');
            });

            $('.save-popup .close-button').click(function (event) {
                event.preventDefault();
                $('.save-popup').css('display', 'none');
            });
            $('#cancel').click(function (event) {
                event.preventDefault();
                $('.update_pharmacy').submit();
            });


        });
        $(document).ready(function () {
            $('#ok').click(function (event) {
                event.preventDefault();
                $('.update_pharmacy').submit();
            });

            $('.ok-popup .close-button').click(function (event) {
                event.preventDefault();
                $('.ok-popup').css('display', 'none');
            });
            $('.save-updates').click(function (event) {
                event.preventDefault();
                $('.update_pharmacy').submit();
            });
        });
        */
        $(document).ready(function () {
            $('.ok-popup').css('display', 'block');

            $('.ok-popup .close-button').click(function (event) {
                event.preventDefault();
                $('.ok-popup').css('display', 'none');
            });

        });
        $(document).ready(function () {
            $('#show_16_card').click(function (event) {
                event.preventDefault();
                $('#long_card_id').show();
                $('#masked_card_id').hide();
                $('#show_16_card').hide();
                return false;
            });

            $('#show_cvc').click(function (event) {
                event.preventDefault();
                $('#cvc').show();
                $('#show_cvc').hide();
                //$('#masked_card_id').hide();
                return false;
            });


        });

        $(document).ready(function () {
            $('#tabs').tabs({ active: 0 });
        });


        $(document).ready(function () {
            $('.rejected').click(function (event) {
                event.preventDefault();
                $('.rejected-popup').css('display', 'block');
            });

            $('.rejected-popup .close-button').click(function (event) {
                event.preventDefault();
                $('.rejected-popup').css('display', 'none');
            });
        });
        /*
        $(document).ready(function () {
            $(".datepicker").datepicker({
                dateFormat: 'mm/dd/yy', showButtonPanel: true,
                changeMonth: true,
                changeYear: true,
                showOtherMonths: true,
                selectOtherMonths: true
            });
        });
        */
        $(function ($) {
            // $.mask.definitions['~'] = '[+-]';
            //$("#SocialSecurityNumber").mask("999-99-9999");
            $("#zipCode").mask("99999");
            $("#zipCode_enroller").mask("99999");
            $("#homePhone").mask("999-999-9999");
            $("#homePhone_enroller").mask("999-999-9999");
        });

        if (patient_id) {
            if (data) {
                if(data.patientinfo != null){
                    if(data.patientinfo.enrollment_data != null){
                        if (data.patientinfo.enrollment_data.first_name) {
                            setFirstName(data.patientinfo.enrollment_data.first_name)
                        }
                        if (data.patientinfo.enrollment_data.last_name) {
                            setLastName(data.patientinfo.enrollment_data.last_name)
                        }
                    }
                }
                
            } else {
            }
        } else {
        }


    }, [data])

    return (
        <>
            <div className="container" id="content" style={{ fontSize: '1em' }}>
                <article className="content patientProfile">
                    <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>{first_name} {last_name}</h2>
                    <section>
                        <div className="mobilesubmenu" style={{ display: 'none' }}>
                            <ul className="nav submenu overview">
                                <li className="selected" id="submenu_profile"><a href="patient-informations">Patient</a></li>
                                <li id="submenu_account"><a href="claims">Claims</a></li>
                                <li id="submenu_messages"><a href="messages">Communications</a></li>

                            </ul>
                        </div>
                        <div className="responsive-button-submenu hidden-sm hidden-md hidden-lg"><i className="fa fa-bars" aria-hidden="true"></i><span>ACCOUNT MENU</span></div>

                        <ul id="navsubmenu" className="nav submenu overview">
                            <li className="selected" id="submenu_profile"><a href="patient-informations">Patient</a></li>
                            <li id="submenu_account"><a href="claims">Claims</a></li>
                            <li id="submenu_messages"><a href="messages">Communications</a></li>

                        </ul>
                        <div className="clearfloat"></div>

                        <div id="save-popup" className="save-popup">
                            <div className="popup-box" style={{ padding: '20px' }}>
                                <p>Are you sure you want to save changes?</p>
                                <button id="ok" className="save close-button" type="submit">OK</button> <button className="cancel close-button" type="reset">Cancel</button>
                            </div>
                        </div>

                        <div className="ok-popup" style={{ display: 'none' }}>
                            <div className="popup-box" style={{ padding: '20px' }}>
                                <p>Updates to patient profile information may not be reflecied immediately.</p>

                                <button className="close-button">Close</button>
                            </div>
                        </div>
                        <div className="space"></div>
                        <PatientProfileDetails updateURL='patient-profile' patientID={patient_id} />
                        <ParentCaregiverInfo patientID={patient_id} />
                        <MembershipInformation patientID={patient_id} showMessage="true" />
                        <VirtualCardNumberInfo patientID={patient_id} />
                        <CommercialMedicalInsurance patientID={patient_id} />
                        <UpdatePharmacyComponent url='patient-informations' />
                        <PatientEnrollmentDetails patientID={patient_id} />
                        <div className="clearfloat"></div>
                    </section>
                </article></div>
            <div className="rejected-popup">
                <div className="popup-box">
                    <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Claim Reject Reason</h2>
                    <p>Duplicate Claim.</p>
                    <button className="close-button">Close</button>
                </div>
            </div>
        </>
    )
}