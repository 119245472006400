import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';
import { SEARCH_PROVIDER } from '../graphql/queries/Providers'
export default function ProviderSearch(props) {
  const provider_id = parseInt(props.providerID)
  const { provider_data, setproviderdata } = useOutletContext()

  const autofillProvider = window.localStorage.getItem('autofillProvider') ? window.localStorage.getItem('autofillProvider') : ''
  const [autofill, setautofill] = useState(autofillProvider ? autofillProvider != null ? autofillProvider != '' ? true : false : false : false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [npi, setnpi] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setcity] = useState('')
  const [state2, setstate] = useState('')
  const [zip_code, setzipcode] = useState('')
  const [phone, setphone] = useState('')

  const { loading, error, data } = useQuery(SEARCH_PROVIDER, {
    variables: { provider_id }
  });


  useEffect(() => {
    if((npi != '') && (firstName != '') && (lastName != '') && (address1 != '') && (city != '') && (state2 != '')  && (zip_code != '') && (autofill == true) ){
      setproviderdata(
        {
          provider_id: provider_id,
          first_name: firstName,
          last_name: lastName,
          address: {
            address1: address1,
            address2: address2,
            city: city,
            state: state2,
            zip_code: zip_code,
          },
          phones: {
            phone: phone
          },
          npi: npi
        }
      )
      window.localStorage.setItem('provider_data', JSON.stringify(provider_data))
    }
  })


  useEffect(() => {
    if (data) {
      if (data.providerfind != null) {
        if (data.providerfind.provider_npi) {
          setnpi(data.providerfind.provider_npi)
        }
        if (data.providerfind.provider_data != null) {
          if (data.providerfind.provider_data.first_name) {
            setFirstName(data.providerfind.provider_data.first_name)
          }
          if (data.providerfind.provider_data.last_name) {
            setLastName(data.providerfind.provider_data.last_name)
          }

          if (data.providerfind.provider_data.address.address_1) {
            setAddress1(data.providerfind.provider_data.address.address_1)
          }
          if (data.providerfind.provider_data.address.address_2) {
            setAddress2(data.providerfind.provider_data.address.address_2)
          }
          if (data.providerfind.provider_data.address.city) {
            setcity(data.providerfind.provider_data.address.city)
          }
          if (data.providerfind.provider_data.address.state) {
            setstate(data.providerfind.provider_data.address.state)
          }
          if (data.providerfind.provider_data.address.zip_code) {
            setzipcode(data.providerfind.provider_data.address.zip_code)
          }
          if (data.providerfind.provider_data.phone_number) {
            setphone(data.providerfind.provider_data.phone_number)
          }
        }

      }
      
    }
    

  }, [data])



  
  function setselectedprovider() {
    provider_data.first_name = firstName
    setproviderdata(
      {
        provider_id: provider_id,
        first_name: firstName,
        last_name: lastName,
        address: {
          address1: address1,
          address2: address2,
          city: city,
          state: state2,
          zip_code: zip_code,
        },
        phones: {
          phone: phone
        },
        npi: npi
      }
    )

  }

  return (

    <tr className="first" id={provider_id} >
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{firstName}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{lastName}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{npi}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{address1}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{address2}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{city}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{state2}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{zip_code}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{phone}</td><td style={{ fontSize: '1em', lineHeight: '1.49em' }}>
        {autofill == true ? <input onChange={setselectedprovider} type="radio" name="result" checked /> : <input onChange={setselectedprovider} type="radio" name="result" />}

      </td>
    </tr>

  )
}

/*

<input
   type="radio"
   name="gender"
   defaultChecked={this.props.gender == "0"}
 />



 <label>Female</label>
 <input
   type="radio"
   name="gender"
   defaultChecked={this.props.gender == "1"}
 />


*/