import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-mask-plugin';
import 'jquery.maskedinput';
import 'jquery-ui-bundle/jquery-ui.min.css';
import ClaimsTableRow from '../components/ClaimsTableRow'
import MyClaimsTable from '../../components/MyClaimsTable'
import { PATIENT_NAME } from '../../graphql/queries/Patients'




export default function Claims() {
    const patient_id = parseInt(window.localStorage.getItem('xrefID'))
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')

    const { loading, error, data, refetch } = useQuery(PATIENT_NAME, {
        variables: { patient_id }
    });

    useEffect(() => {
        

        $(function () {
            $(".datepicker").datepicker({
                format: 'mm/dd/yy', showButtonPanel: true,
                changeMonth: true,
                changeYear: true,
                showOtherMonths: true,
                selectOtherMonths: true
            });

            $('.search_but').on('click', function () {
                //preventDefault();
                // alert( this.value );
                //alert("xxx");
                var filter = $('#claim_status :selected').text();
                //alert(filter);
                if (filter == "Approved") { $('.status_rejected').css('display', 'none'); $('.status_approved').css('display', 'table-row'); }
                else if (filter == "Rejected") { $('.status_approved').css('display', 'none'); $('.status_rejected').css('display', 'table-row'); }
                else if (filter == "In Progress") { $('.status_approved').css('display', 'none'); $('.status_rejected').css('display', 'none'); }
                else {
                    $('.status_approved').css('display', 'table-row');
                    $('.status_rejected').css('display', 'table-row');
                }
            });
        
        });
        $(function () {
            $('.open-menu').on('click', function (event) {
                event.preventDefault();
                $(this).toggleClass('show');
                $('#dropdown').removeClass('hide');
                $('#dropdown-2').addClass('hide');
            });
            $('.open-menu-2').on('click', function (event) {
                event.preventDefault();
                $(this).toggleClass('show');
                $('#dropdown-2').removeClass('hide');
                $('#dropdown').addClass('hide');
            });
        
        });
        $(function () {
            $('#tabs').tabs({ active: 0 });
        });
        $(function () {
            $('.rejected').on('click', function (event) {
                event.preventDefault();
                $('.rejected-popup').css('display', 'block');
            });
        
            $('.rejected-popup .close-button').on('click', function (event) {
                event.preventDefault();
                $('.rejected-popup').css('display', 'none');
            });
        });
        
        
        
        
        
        $(function () {
            /*
            $.mask.definitions['~'] = '[+-]';
            //$("#SocialSecurityNumber").mask("999-99-9999");
            $("#zipCode").mask("99999");
            $("#zipCode_enroller").mask("99999");
            $("#homePhone").mask("999-999-9999");
            $("#homePhone_enroller").mask("999-999-9999");
            */
        });
        
        if (patient_id) {
            if (data) {
                if (data.patientinfo.enrollment_data.first_name) {
                    setFirstName(data.patientinfo.enrollment_data.first_name)
                }
                if (data.patientinfo.enrollment_data.last_name) {
                    setLastName(data.patientinfo.enrollment_data.last_name)
                }

            } else {
            }
        } else {
        }

    }, [data])
    return (
        <div className="container" id="content" style={{ fontSize: '1em' }}>
            <article className="content patientProfile">
                <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>{first_name} {last_name}</h2>
                <section>
                    <div className="mobilesubmenu" style={{ display: 'none' }}>
                        <ul className="nav submenu overview">
                            <li id="submenu_profile"><Link to="../patient-informations">Patient</Link></li>
                            <li className="selected" id="submenu_account"><Link to="../claims">Claims</Link></li>
                            <li id="submenu_messages"><Link to="../messages">Communications</Link></li>
                        </ul>
                    </div>
                    <div className="responsive-button-submenu hidden-sm hidden-md hidden-lg"><i className="fa fa-bars" aria-hidden="true"></i><span>ACCOUNT MENU</span></div>

                    <ul id="navsubmenu" className="nav submenu overview">
                        <li id="submenu_profile"><Link to="../patient-informations">Patient</Link></li>
                        <li className="selected" id="submenu_account"><Link to="../claims">Claims</Link></li>
                        <li id="submenu_messages"><Link to="../messages">Communications</Link></li>

                    </ul>
                    <div className="clearfloat"></div>
                    <MyClaimsTable />
                    <div className="rejected-popup">
                        <div className="popup-box">
                            <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Claim Reject Reason</h2>
                            <p>Duplicate Claim.</p>
                            <button className="close-button">Close</button>
                        </div>
                    </div>
                </section>
            </article>
        </div>
    )
}