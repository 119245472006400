import React, { useEffect } from "react";
import { useNavigate, useOutletContext,useLocation } from "react-router-dom";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.min.css";
import CommercialMedicalInsuranceInformation from "../components/CommercialMedicalInsuranceInformation";
import CommercialPharmacyInsuranceInformation from "../components/CommercialPharmacyInsuranceInformation";
import LAPInformation from "../hcp/components/LAPInformation";
import PatientInformation from "../hcp/components/PatientInformation";
import AdditionalPatientServices from "../components/AdditionalPatientServices";

export default function EnrollFormLap() {
    const { setTitle, setStep, enrollment_data, setenrollmentdata, email_opt_in } = useOutletContext();

    var existing_member = false;
    if (window.localStorage.getItem("existing_member") == "true") {
        existing_member = true;
    } else {
        existing_member = false;
    }

    var eligibility_answers = window.localStorage.getItem("eligibility_answers") ? window.localStorage.getItem("eligibility_answers") : "";

    let navigate = useNavigate();
	
	const location = useLocation();
	useEffect(() => {
        const storedRoutes = window.localStorage.getItem('routeChanges');
        const existingRoutes = storedRoutes ? JSON.parse(storedRoutes) : [];
        const updatedRoutes = [...new Set([...existingRoutes, location.pathname])];
        const lastTwoRoutes = updatedRoutes.slice(-2);
        window.localStorage.setItem('routeChanges', JSON.stringify(lastTwoRoutes));
    }, [location.pathname]);
    
    useEffect(() => {
        if (eligibility_answers == "") {
            navigate("/enroll");
        }
    }, [eligibility_answers]);

    useEffect(() => {
        $(function ($) {
            $(".homePhone").mask("(999) 999-9999");
            $(".nothave-field input").change(function (event) {
                var field = $(".input-hide");
                //var f1=$('#insuranceBINNumber').val();
                //var f2=$('#insurancePCNNumber').val();
                //alert (f1);
                if ($(this).is(":checked")) {
                    field.removeClass("required-field");
                    field.find("label span.required").remove();
                    field.find("input").prop("disabled", true);
                    $(".input-hide").addClass("hide");
                } else {
                    $(".input-hide").removeClass("hide");
                    field.addClass("required-field");
                    field.find("input").prop("disabled", false);
                    field.find("label span.required").remove();
                    field.find("label span.dots").remove();
                    field
                        .find('label[for="insuranceBINNumber"] .inner-label')
                        .append('<span class="required">*</span><span class="dots"></span>');
                    field
                        .find('label[for="insurancePCNNumber"] .inner-label')
                        .append('<span class="required">*</span><span class="dots"></span>');
                }
                $.mask.definitions["~"] = "[+-]";
                //$("#SocialSecurityNumber").mask("999-99-9999");
                $("#zipCode").mask("99999");
                $("#zipCode_enroller").mask("99999");

                $("#homePhone_enroller").mask("999-999-9999");
            });
        });
        $(".nothave-field-2 input").change(function (event) {
            var field = $(".input-hide-2");
            //alert (f1);
            if ($(this).is(":checked")) {
                field.removeClass("required-field");
                field.find("label span.required").remove();
                field.find("input").prop("disabled", true);
                $(".input-hide-2").addClass("hide");
            } else {
                field.addClass("required-field");
                $(".input-hide-2").removeClass("hide");
                field.find("input").prop("disabled", false);
                field.find("label span.required").remove();
                field.find("label span.dots").remove();
                field
                    .find('label[for="RxinsuranceBINNumber"] .inner-label')
                    .append('<span class="required">*</span><span class="dots"></span>');
                field
                    .find('label[for="RxinsurancePCNNumber"] .inner-label')
                    .append('<span class="required">*</span><span class="dots"></span>');
                field
                    .find('label[for="RxinsuranceGroupNumber"] .inner-label')
                    .append('<span class="required">*</span><span class="dots"></span>');
                field
                    .find('label[for="RxinsuranceMemberNumber"] .inner-label')
                    .append('<span class="required">*</span><span class="dots"></span>');
            }
        });
        $(document).ready(function () {
            $("#agree").click(function () {
                if ($(this).is(":checked")) {
                    $(".select-form").removeClass("hide");
                }
            });
            $("#enroll-yes").click(function () {
                if ($(this).is(":checked")) {
                    $("#hide-yes .select-form").addClass("required-field");
                    $("#hide-yes .checkbox-field").addClass("required-field");
                }
            });
            $("#enroll-no").click(function () {
                if ($(this).is(":checked")) {
                    $("#hide-yes .select-form").removeClass("required-field");
                    $("#hide-yes .checkbox-field").removeClass("required-field");
                }
            });
            $("#no-agree").click(function () {
                if ($(this).is(":checked")) {
                    $(".select-form").addClass("hide");
                }
            });
            $("#checkbox_group input[type=checkbox]").click(function () {
                if ($(this).is(":checked")) {
                    $("#hidden-email").addClass("required-field");
                    $("#hidden-email label span").show();
                }
                if (!$(this).is(":checked")) {
                    $("#hidden-email").removeClass("required-field");
                    $("#hidden-email label span").hide();
                }
            });
            $("#enroll-yes").click(function () {
                if ($(this).is(":checked")) {
                    $(".radio-field").addClass("required-field");
                    $("#hide-yes").removeClass("hide");
                    $("#hidden-email").addClass("required-field");
                    $("#byemail").prop("checked", true);
                    $("#hidden-email label span").show();
                }
                if (!$(this).is(":checked")) {
                    $("#hide-yes").addClass("hide");
                }
            });
            $("#enroll-no").click(function () {
                if ($(this).is(":checked")) {
                    $("#hide-yes").addClass("hide");
                }
            });
            $("#select").change(function () {
                var id = $(this).find(":selected")[0].id;
                if (id == "other") {
                    var field = $("#ins-other");
                    field.removeClass("hide");
                } else {
                    var field = $("#ins-other");
                    field.addClass("hide");
                }
            });
            $("#haveins input[type=checkbox]").click(function () {
                if ($(this).is(":checked")) {
                    var field = $(".insurance-info");
                    $(".insurance-info label span").hide();
                    $(".insurance-info .prompt-error").hide();
                    $(".insurance-info").removeClass("required-field");
                    $("select[name='insurancePlanType'] option[value='NONE']").attr("selected", "selected");
                }
                if (!$(this).is(":checked")) {
                    var field = $(".insurance-info");
                    $(".insurance-info label span").show();
                    $(".insurance-info").addClass("required-field");
                    $("select[name='insurancePlanType'] option[value='NONE']").removeAttr("selected");
                }
            });
            if ($("select[name='insurancePlanType']").val() == "NONE") {
                $(".insurance-info label span").hide();
                $(".insurance-info .prompt-error").hide();
                $(".insurance-info").removeClass("required-field");
                $("#haveins input[type=checkbox]").attr("checked", "checked");
            }
            $("select[name='insurancePlanType']").change(function () {
                if ($(this).val() == "NONE") {
                    $(".insurance-info label span").hide();
                    $(".insurance-info .prompt-error").hide();
                    $(".insurance-info").removeClass("required-field");
                    $("#haveins input[type=checkbox]").attr("checked", "checked");
                } else {
                    $(".insurance-info label span").show();
                    $(".insurance-info").addClass("required-field");
                    $("#haveins input[type=checkbox]").removeAttr("checked");
                }
            });
            $("#same-address").change(function () {
                if ($(this).is(":checked")) {
                    $("#address_enroller").val($("#address").val());
                    $("#address2_enroller").val($("#address2").val());
                    $("#city_enroller").val($("#city").val());
                    $("#state_enroller").val($("#state").val());
                    $("#zipCode_enroller").val($("#zipCode").val());
                } else {
                    $("#address_enroller").val("");
                    $("#address2_enroller").val("");
                    $("#city_enroller").val("");
                    $("#state_enroller").val("");
                    $("#zipCode_enroller").val("");
                }
            });
            if ($("#enroll-yes").is(":checked")) {
                $("#hide-yes").removeClass("hide");
                $("#hidden-email").addClass("required-field");
            }
            $("#enroll-yes").click(function () {
                if ($(this).is(":checked")) {
                    $("#hide-yes").removeClass("hide");
                    $("#hidden-email").addClass("required-field");
                }
                if (!$(this).is(":checked")) {
                    $("#hide-yes").addClass("hide");
                }
            });
            $(".field.social-security-number-dontwantto input").change(function (event) {
                var field = $(".social-security-number-field");
                if ($(this).is(":checked")) {
                    field.removeClass("required-field");
                    field.find("label span.required").remove();
                } else {
                    field.addClass("required-field");
                    field.find("label span").remove();
                    field.find('label[for="SocialSecurityNumber"]').append('<span class="required"><sup>*</sup></span><span></span>');
                    //$('form.apply-step-two-form').attr('action', '<?php echo $BASEURL;?>enroll-doctor-information');
                }
            });
            $(".social-number-need").click(function (event) {
                event.preventDefault();
                $(".social-number-popup").css("display", "block");
                $(".field.social-security-number-dontwantto").css("display", "block");
            });
            $(".social-number-popup .close-button").click(function (event) {
                event.preventDefault();
                $(".social-number-popup").css("display", "none");
            });
            //Check if set to Other on page reload
            if ($("#insuranceCompanyName").val() == "Other") {
                $("#ins-company-other").removeClass("hide");
            } else {
                $("#ins-company-other").addClass("hide");
            }

            if ($("#insurancePlanType").val() == "Other") {
                $("#ins-plan-other").removeClass("hide");
            } else {
                $("#ins-plan-other").addClass("hide");
            }
            $("#dob-year, #dob-month, #dob-day").change(function () {
                // event.preventDefault();
                var luna = $("#dob-month").val();
                var ziua = $("#dob-day").val();
                var anul = $("#dob-year").val();
                var data = luna + "/" + ziua + "/" + anul;
                //var date = new Date(data);
                var currentDate = new Date();
                var valid = true;
                if (currentDate.getFullYear() - anul < 18) valid = false;
                else if (currentDate.getFullYear() - anul == 18) {
                    if (currentDate.getMonth() + 1 < luna) valid = false;
                    else if (currentDate.getMonth() + 1 == luna) {
                        if (currentDate.getDate() < ziua) valid = false;
                    }
                }
                if (!valid) {
                    $(this).addClass("invalid");
                    if (!$(".date span.error").length) {
                        $(".date").append(
                            "<label class='error'>&nbsp;</label><span class='error'>LUCENTIS is not indicated for patients under 18 years of age.<br/> Please confirm birth date.</span>"
                        );
                    }
                } else {
                    $(this).removeClass("invalid");
                    $(".date .error").remove();
                }
            });
            $("form").submit(function () {
                // event.preventDefault();
                var luna = $("#dob-month").val();
                var ziua = $("#dob-day").val();
                var anul = $("#dob-year").val();
                var data = luna + "/" + ziua + "/" + anul;
                var date = new Date(data);
                var currentDate = new Date();
                var valid = true;
                if (currentDate.getFullYear() - date.getFullYear() < 18) valid = false;
                else if (currentDate.getFullYear() - date.getFullYear() == 18) {
                    if (currentDate.getMonth() < date.getMonth()) valid = false;
                    else if (currentDate.getMonth() == date.getMonth()) {
                        if (currentDate.getDate() < date.getDate()) valid = false;
                    }
                }
                var $ssn = $("#SocialSecurityNumber"),
                    ssnval = $ssn.data("value");
                if (ssnval) {
                    $ssn.val(ssnval);
                }
                if (!valid) {
                    $(this).addClass("invalid");
                    $(".date").append(
                        "<label>&nbsp;</label><span class='error'>LUCENTIS is not indicated for patients under 18 years of age.<br/> Please confirm birth date.</span>"
                    );
                } else {
                    $(this).removeClass("invalid");
                }
            });
        });
        $("#insuranceCompanyName").change(function () {
            if ($(this).val() == "Other") {
                $("#ins-company-other").removeClass("hide");
            } else {
                $("#ins-company-other").addClass("hide");
            }
        });
        $("#insurancePlanType").change(function () {
            if ($(this).val() == "Other") {
                $("#ins-plan-other").removeClass("hide");
            } else {
                $("#ins-plan-other").addClass("hide");
            }
        });
        $(document).ready(function () {
            $(".datepicker").datepicker({
                dateFormat: "mm/dd/yy",
                showButtonPanel: true,
                changeMonth: true,
                changeYear: true,
                showOtherMonths: true,
                selectOtherMonths: true,
            });
        });
        setTitle("Apply for the XOLAIR Co-pay Program");
        setStep("4");
    });

    function goBack() {
        navigate(-1);
    }

    function next(e) {
        e.preventDefault();
        let path = `../enroll-doctor-information-lap`;
        const phoneNumber_regex = /^\(\d{3}\) \d{3}-\d{4}$/;
		const emailValidation = JSON.parse(window.localStorage.getItem('email_validation'));
		
        if (existing_member == true) {
            navigate(path);
        } else {
            if (
                enrollment_data.lap.first_name == "" ||
                enrollment_data.lap.last_name == "" ||
                enrollment_data.lap.address.address1 == "" ||
                enrollment_data.lap.address.city == "" ||
                enrollment_data.lap.address.state == "" ||
                enrollment_data.lap.address.zip_code == "" ||
                enrollment_data.lap.phone == ""
            ) {
                //alert('missing legally authorized person\'s info')
                window.scroll(0, 0);
            } else if (!phoneNumber_regex.test(enrollment_data.lap.phone) || enrollment_data.lap.phone.length != 14) {
                window.scroll(0, 0);
            } else if (
                enrollment_data.first_name == "" ||
                enrollment_data.last_name == "" ||
                enrollment_data.gender == "" ||
                enrollment_data.dob == "" ||
                enrollment_data.address.address1 == "" ||
                enrollment_data.address.city == "" ||
                enrollment_data.address.state == "" ||
                enrollment_data.address.zip_code == "" ||
                enrollment_data.phone_number == ""
            ) {
                //alert('missing personal info')
                window.scroll(0, 0);
            } else if (!phoneNumber_regex.test(enrollment_data.phone_number) || enrollment_data.phone_number.length != 14) {
                window.scroll(0, 0);
            } else if (
                enrollment_data.email &&
                enrollment_data.email.length > 0 &&
                !enrollment_data.email.toLowerCase().match(/^\S+@\S+\.\S+$/)
            ) {
                window.scroll(0, 0);
            } else if (
                enrollment_data.insurance.medical_insurance.name == "" ||
                enrollment_data.insurance.medical_insurance.plan_type == "" ||
                enrollment_data.insurance.medical_insurance.group_number == "" ||
                enrollment_data.insurance.medical_insurance.member_number == "" ||
                (window.localStorage.getItem("medical-insurance-checked") != "yes" &&
                    enrollment_data.insurance.medical_insurance.bin == "") ||
                (window.localStorage.getItem("medical-insurance-checked") != "yes" && enrollment_data.insurance.medical_insurance.pcn == "")
            ) {
                //alert('missing medical insurance info')
                window.scroll(0, 0);
            } else if (
                (window.localStorage.getItem("pharmacy-insurance-checked") != "yes" && enrollment_data.insurance.rx_insurance.name == "") ||
                (window.localStorage.getItem("pharmacy-insurance-checked") != "yes" &&
                    enrollment_data.insurance.rx_insurance.member_number == "") ||
                (window.localStorage.getItem("pharmacy-insurance-checked") != "yes" && enrollment_data.insurance.rx_insurance.bin == "") ||
                (window.localStorage.getItem("pharmacy-insurance-checked") != "yes" && enrollment_data.insurance.rx_insurance.pcn == "")
            ) {
                //alert('missing pharmacy info')
                window.scroll(0, 0);
            } else if (
                (email_opt_in.opt == "Y" && document.getElementById("terms-checkbox").checked == false) ||
                (email_opt_in.opt != "N" && email_opt_in.opt != "Y")
            ) {
                window.scroll(0, 0);
            } else if (email_opt_in.opt === "Y" && emailValidation?.email_validations === 'Please Enter Valid Email') {
                window.scroll(0, 0);
            } else {
                navigate(path);
            }
        }
        window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));
    }

    /*

    function next() {
        let path = `../enroll-doctor-information-lap`
        if (existing_member == true) {
            navigate(path)
        } else {
            if (enrollment_data.lap.first_name == '' || enrollment_data.lap.last_name == '' || enrollment_data.lap.address.address1 == '' || enrollment_data.lap.address.city == '' || enrollment_data.lap.address.state == '' || enrollment_data.lap.address.zip_code == '' || enrollment_data.lap.phone_number == '') {
                alert('missing legally authorized person\'s info')
            } else if (enrollment_data.first_name == '' || enrollment_data.last_name == '' || enrollment_data.gender == '' || enrollment_data.dob == '' || enrollment_data.address.address1 == '' || enrollment_data.address.city == '' || enrollment_data.address.state == '' || enrollment_data.address.zip_code == '' || enrollment_data.phone_number == '') {
                alert('missing personal info')
            } else if (enrollment_data.insurance.medical_insurance.name == '' || enrollment_data.insurance.medical_insurance.plan_type == '' || enrollment_data.insurance.medical_insurance.group_number == '' || enrollment_data.insurance.medical_insurance.member_number == '' || (window.localStorage.getItem('medical-insurance-checked') != 'yes' && enrollment_data.insurance.medical_insurance.bin == '') || (window.localStorage.getItem('medical-insurance-checked') != 'yes' && enrollment_data.insurance.medical_insurance.pcn == '')) {
                alert('missing medical insurance info')
            } else if ((window.localStorage.getItem('pharmacy-insurance-checked') != 'yes' && enrollment_data.insurance.rx_insurance.name == '') || (window.localStorage.getItem('pharmacy-insurance-checked') != 'yes' && enrollment_data.insurance.rx_insurance.member_number == '') || (window.localStorage.getItem('pharmacy-insurance-checked') != 'yes' && enrollment_data.insurance.rx_insurance.bin == '') || (window.localStorage.getItem('pharmacy-insurance-checked') != 'yes' && enrollment_data.insurance.rx_insurance.pcn == '')) {
                alert('missing pharmacy info')
            } else {
                navigate(path)
            }
        }
        window.localStorage.setItem('enrollment_data', JSON.stringify(enrollment_data))
    }

    */
    return (
        <div className="container" id="content">
            <div id="container" className="enroll-form">
                <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>Additional information</h2>
                <p>We need some additional information to complete your application.</p>
                <div className="form-validate apply-step-two-form" acceptCharset="utf-8">
                    <p>
                        <span style={{ color: "red" }}> *Required field.</span>
                    </p>
                    <LAPInformation />
                    <PatientInformation />
                    <CommercialMedicalInsuranceInformation />
                    <CommercialPharmacyInsuranceInformation />
                    <AdditionalPatientServices />
                    <p>&nbsp;</p>
                    <p>
                        <button type="reset" onClick={goBack}>
                            Back
                        </button>{" "}
                        <button onClick={next} name="apply-step-one-submit" className="input-submit" value="Next">
                            Next
                        </button>
                    </p>
                </div>
                <div className="clearfloat"></div>
            </div>
        </div>
    );
}
