import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { PROVIDER_DATA } from "../../graphql/queries/Providers";
import { UPDATE_PROVIDER_DATA } from "../../graphql/mutations/Providers";

export default function AllergyPartnersProfileSettings() {
  const provider_id = parseInt(window.localStorage.getItem("providerID"));
  const [email, setemail] = useState("");
  const [first_name, setfirstname] = useState("");
  const [last_name, setlastname] = useState("");
  const [practice_name, setpracticename] = useState("");
  const [address_1, setaddress1] = useState("");
  const [address_2, setaddress2] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [zip_code, setzip] = useState("");
  const [phone_number, setphone] = useState("");
  const [fax1, setfax1] = useState("");
  const [fax2, setfax2] = useState("");
  const [fax3, setfax3] = useState("");
  const [npi, setnpi] = useState("");
  const [fax_opt_in, setfaxoptin] = useState("");
  const [user_agreement, setuseragreement] = useState("");

  const { loading, error, data, refetch } = useQuery(PROVIDER_DATA, {
    variables: { provider_id },
  });

  const [
    updateProvider,
    { data: newData, loading: loadingUpdate, error: errorUpdate },
  ] = useMutation(UPDATE_PROVIDER_DATA);

  useEffect(() => {
    if (provider_id) {
      if (data != null) {
        if (data.providerfind != null) {
          if (data.providerfind.provider_data != null) {
            if (data.providerfind.provider_data.first_name) {
              setfirstname(data.providerfind.provider_data.first_name);
            }
            if (data.providerfind.provider_data.last_name) {
              setlastname(data.providerfind.provider_data.last_name);
            }
            if (data.providerfind.provider_data.practice_name) {
              setpracticename(data.providerfind.provider_data.practice_name);
            }
            if (data.providerfind.provider_data.address != null) {
              if (data.providerfind.provider_data.address.address_1) {
                setaddress1(data.providerfind.provider_data.address.address_1);
              }
              if (data.providerfind.provider_data.address.address_2) {
                setaddress2(data.providerfind.provider_data.address.address_2);
              }
              if (data.providerfind.provider_data.address.city) {
                setcity(data.providerfind.provider_data.address.city);
              }
              if (data.providerfind.provider_data.address.state) {
                setstate(
                  data.providerfind.provider_data.address.state.toUpperCase()
                );
              }
              if (data.providerfind.provider_data.address.zip_code) {
                setzip(data.providerfind.provider_data.address.zip_code);
              }
            }
            if (data.providerfind.provider_data.fax_opt_in != null) {
              setfaxoptin(data.providerfind.provider_data.fax_opt_in);
            }
            if (data.providerfind.provider_data.opt_in_fax_numbers != null) {
              if (
                data.providerfind.provider_data.opt_in_fax_numbers.fax1 != null
              ) {
                if (
                  data.providerfind.provider_data.opt_in_fax_numbers.fax1 !=
                    "" &&
                  data.providerfind.provider_data.opt_in_fax_numbers.fax1.toLowerCase() !=
                    "null"
                ) {
                  setfax1(
                    data.providerfind.provider_data.opt_in_fax_numbers.fax1
                  );
                } else {
                  if (data.providerfind.provider_data.fax_numbers != null) {
                    if (
                      data.providerfind.provider_data.fax_numbers.fax_number !=
                      null
                    ) {
                      setfax1(
                        data.providerfind.provider_data.fax_numbers.fax_number
                      );
                    }
                  }
                }
              } else {
                if (data.providerfind.provider_data.fax_numbers != null) {
                  if (
                    data.providerfind.provider_data.fax_numbers.fax_number !=
                    null
                  ) {
                    setfax1(
                      data.providerfind.provider_data.fax_numbers.fax_number
                    );
                  }
                }
              }
              if (data.providerfind.provider_data.opt_in_fax_numbers.fax2) {
                setfax2(
                  data.providerfind.provider_data.opt_in_fax_numbers.fax2
                );
              }
              if (data.providerfind.provider_data.opt_in_fax_numbers.fax3) {
                setfax3(
                  data.providerfind.provider_data.opt_in_fax_numbers.fax3
                );
              }
            } else {
              if (data.providerfind.provider_data.fax_numbers != null) {
                if (
                  data.providerfind.provider_data.fax_numbers.fax_number != null
                ) {
                  setfax1(
                    data.providerfind.provider_data.fax_numbers.fax_number
                  );
                }
              }
            }

            if (data.providerfind.provider_data.phone_number) {
              setphone(data.providerfind.provider_data.phone_number);
            }
            if (data.providerfind.provider_data.user_agreement) {
              setuseragreement(data.providerfind.provider_data.user_agreement);
            }
          }
          if (data.providerfind.provider_npi) {
            setnpi(data.providerfind.provider_npi);
          }
          if (data.providerfind.email) {
            setemail(data.providerfind.email);
          }
        }
      }
    }
  }, [data]);

  function updateProviderInfo() {
    updateProvider({
      variables: {
        provider_id: provider_id.toString(),
        email,
        first_name,
        last_name,
        practice_name,
        phone_number,
        address_1,
        address_2,
        city,
        state,
        zip_code,
        fax_opt_in,
        fax1,
        fax2,
        fax3,
        user_agreement,
      },
    });
    window.location.reload();
  }

  return (
    <div className="section shadow-box">
      <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>
        Profile Settings
      </h2>
      <div className="form-validate" acceptCharset="utf-8">
        <div className="col-sm-12 col-md-12 col-lg-6">
          <p className="field required-field text">
            <label
              for="practiceName"
              style={{ fontSize: "1em", lineHeight: "1.49em" }}
            >
              <span className="inner-label">
                Practice name<span className="required">*</span>
              </span>
            </label>
            <input
              onChange={(e) => setpracticename(e.target.value)}
              type="text"
              name="practice_name"
              id="practice_name"
              value={practice_name}
            />
          </p>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <p className="field">
            <label
              for="lastName"
              style={{ fontSize: "1em", lineHeight: "1.49em" }}
            >
              <span className="inner-label">Specialty</span>
            </label>
            <input type="text" name="specialty" id="specialty" value="" />
          </p>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <p className="field required-field text">
            <label
              for="street"
              style={{ fontSize: "1em", lineHeight: "1.49em" }}
            >
              <span className="inner-label">
                Address<span className="required">*</span>
              </span>
            </label>
            <input
              onChange={(e) => setaddress1(e.target.value)}
              type="text"
              name="address_1"
              id="address_1"
              value={address_1}
            />
          </p>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <p className="field">
            <label
              for="street"
              style={{ fontSize: "1em", lineHeight: "1.49em" }}
            >
              <span className="inner-label">Address 2</span>
            </label>
            <input
              onChange={(e) => setaddress2(e.target.value)}
              type="text"
              name="address_2"
              id="address_2"
              value={address_2}
            />
          </p>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <p className="field required-field text">
            <label for="city" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
              <span className="inner-label">
                City<span className="required">*</span>
              </span>
            </label>
            <input
              onChange={(e) => setcity(e.target.value)}
              type="text"
              name="city"
              id="city"
              value={city}
            />
          </p>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <p className="field required-field dropdown">
            <label
              for="state"
              style={{ fontSize: "1em", lineHeight: "1.49em" }}
            >
              <span className="inner-label">
                State<span className="required">*</span>
              </span>
            </label>
            <span className="select_container">
              <select
                name="state"
                id="state"
                onChange={(e) => setstate(e.target.value)}
                defaultValue=""
                value={state}
              >
                <option label="" value="default">
                  Select a state
                </option>
                <option label="Alabama" value="AL">
                  Alabama
                </option>
                <option label="Alaska" value="AK">
                  Alaska
                </option>
                <option label="American Samoa" value="AS">
                  American Samoa
                </option>
                <option label="Arizona" value="AZ">
                  Arizona
                </option>
                <option label="Arkansas" value="AR">
                  Arkansas
                </option>
                <option label="California" value="CA">
                  California
                </option>
                <option label="Colorado" value="CO">
                  Colorado
                </option>
                <option label="Connecticut" value="CT">
                  Connecticut
                </option>
                <option label="Delaware" value="DE">
                  Delaware
                </option>
                <option label="Florida" value="FL">
                  Florida
                </option>
                <option label="Georgia" value="GA">
                  Georgia
                </option>
                <option label="Guam" value="GU">
                  Guam
                </option>
                <option label="Hawaii" value="HI">
                  Hawaii
                </option>
                <option label="Idaho" value="ID">
                  Idaho
                </option>
                <option label="Illinois" value="IL">
                  Illinois
                </option>
                <option label="Indiana" value="IN">
                  Indiana
                </option>
                <option label="Iowa" value="IA">
                  Iowa
                </option>
                <option label="Kansas" value="KS">
                  Kansas
                </option>
                <option label="Kentucky" value="KY">
                  Kentucky
                </option>
                <option label="Louisiana" value="LA">
                  Louisiana
                </option>
                <option label="Maine" value="ME">
                  Maine
                </option>
                <option label="Maryland" value="MD">
                  Maryland
                </option>
                <option label="Massachusetts" value="MA">
                  Massachusetts
                </option>
                <option label="Michigan" value="MI">
                  Michigan
                </option>
                <option label="Minnesota" value="MN">
                  Minnesota
                </option>
                <option label="Mississippi" value="MS">
                  Mississippi
                </option>
                <option label="Missouri" value="MO">
                  Missouri
                </option>
                <option label="Montana" value="MT">
                  Montana
                </option>
                <option label="Nebraska" value="NE">
                  Nebraska
                </option>
                <option label="Nevada" value="NV">
                  Nevada
                </option>
                <option label="New Hampshire" value="NH">
                  New Hampshire
                </option>
                <option label="New Jersey" value="NJ">
                  New Jersey
                </option>
                <option label="New Mexico" value="NM">
                  New Mexico
                </option>
                <option label="New York" value="NY">
                  New York
                </option>
                <option label="North Carolina" value="NC">
                  North Carolina
                </option>
                <option label="North Dakota" value="ND">
                  North Dakota
                </option>
                <option label="Northern Mariana Islands" value="NM">
                  Northern Mariana Islands
                </option>
                <option label="Ohio" value="OH">
                  Ohio
                </option>
                <option label="Oklahoma" value="OK">
                  Oklahoma
                </option>
                <option label="Oregon" value="OR">
                  Oregon
                </option>
                <option label="Pennsylvania" value="PA">
                  Pennsylvania
                </option>
                <option label="Puerto Rico" value="PR">
                  Puerto Rico
                </option>
                <option label="Rhode Island" value="RI">
                  Rhode Island
                </option>
                <option label="South Carolina" value="SC">
                  South Carolina
                </option>
                <option label="South Dakota" value="SD">
                  South Dakota
                </option>
                <option label="Tennessee" value="TN">
                  Tennessee
                </option>
                <option label="Texas" value="TX">
                  Texas
                </option>
                <option label="U.S. Virgin Islands" value="VI">
                  U.S. Virgin Islands
                </option>
                <option label="Utah" value="UT">
                  Utah
                </option>
                <option label="Vermont" value="VT">
                  Vermont
                </option>
                <option label="Virginia" value="VA">
                  Virginia
                </option>
                <option label="Washington" value="WA">
                  Washington
                </option>
                <option label="Washington DC" value="DC">
                  Washington DC
                </option>
                <option label="West Virginia" value="WV">
                  West Virginia
                </option>
                <option label="Wisconsin" value="WI">
                  Wisconsin
                </option>
                <option label="Wyoming" value="WY">
                  Wyoming
                </option>
              </select>
            </span>
          </p>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <p className="field required-field text">
            <label
              for="zipCode"
              style={{ fontSize: "1em", lineHeight: "1.49em" }}
            >
              <span className="inner-label">
                ZIP code<span className="required">*</span>
              </span>
            </label>
            <input
              onChange={(e) => setzip(e.target.value)}
              type="text"
              name="zip"
              id="zip"
              value={zip_code}
            />
          </p>
        </div>
        <div className="clear"></div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <p className="field required-field text">
            <label
              for="mainPhone"
              style={{ fontSize: "1em", lineHeight: "1.49em" }}
            >
              <span className="inner-label">
                Main phone number<span className="required">*</span>
              </span>
            </label>
            <input
              onChange={(e) => setphone(e.target.value)}
              type="text"
              name="phone_number"
              id="phone_number"
              value={phone_number}
            />
          </p>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <p className="field text">
            <label for="fax" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
              <span className="inner-label">Fax number</span>
            </label>
            <input
              onChange={(e) => setfax1(e.target.value)}
              type="text"
              name="fax_number"
              id="fax_number"
              value={fax1}
            />
          </p>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <p className="field required-field text">
            <label
              for="email"
              style={{ fontSize: "1em", lineHeight: "1.49em" }}
            >
              <span className="inner-label">
                Email address<span className="required">*</span>
              </span>
            </label>
            <input
              onChange={(e) => setemail(e.target.value)}
              type="text"
              name="email_address"
              id="email_address"
              value={email}
            />
            <small>All emails for your practice will go to this address.</small>
          </p>
        </div>
        <div className="clear"></div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <p>
            <label
              for="uname"
              style={{ fontSize: "1em", lineHeight: "1.49em" }}
            >
              <span className="inner-label">NPI</span>
            </label>
            <input readOnly type="text" value={npi} id="NPI" />
          </p>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <p>
            <label
              for="uname"
              style={{ fontSize: "1em", lineHeight: "1.49em" }}
            >
              <span className="inner-label">Tax ID</span>
            </label>
            <input type="text" value="" id="Tax_ID" />
          </p>
        </div>
        <p>
          <label style={{ fontSize: "1em", lineHeight: "1.49em" }}>
            &nbsp;
          </label>
          <button onClick={updateProviderInfo}>Update</button>
        </p>
      </div>
    </div>
  );
}
