import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useLazyQuery, gql } from '@apollo/client';
import { READ_NOTIFICATION } from '../../graphql/queries/Providers'

export default function Notifications(props) {

    const provider_id = parseInt(window.localStorage.getItem('providerID'))

    let navigate= useNavigate()
    function viewPatient(){
        window.localStorage.setItem('xrefID', props.patientID)
    }

    const [markRead, {data}] = useLazyQuery(READ_NOTIFICATION, {
        variables: {provider_id, user_id: props.patientID, document_id: props.docID }
    });

    function markAsRead(){
        markRead()
        window.location.reload()
    }

    return (
        <tr>
            <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{props.date}</td>
            <td style={{ fontSize: '1em', lineHeight: '1.49em' }}><Link onClick={viewPatient} to="../patient-informations" >{props.firstName} {props.lastName}</Link></td>
            <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{props.notificationType}</td>
            <td style={{ fontSize: '1em', lineHeight: '1.49em' }}><Link to="#" onClick={markAsRead} style={{ color: '#4d4d4f' }}>Mark as Read</Link></td> 
        </tr>
    )
}


