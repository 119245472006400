import React, { useEffect, useState } from "react";
import { useQuery, gql, useApolloClient } from "@apollo/client";
import { Link, NavLink, useNavigate } from "react-router-dom";
import $ from "jquery";
import logo from "../../images/logo.png";
import bellIcon from "../../images/bell-icon.png";
import { PROVIDER_NAME, ADMIN_PROVIDER_NAME, GET_NOTIFICATION_COUNT } from "../../graphql/queries/Providers";
import UserGuide from "../../docs/Xolair_User_Guide.pdf";

export default function HCPHeader() {
    const provider_id = parseInt(window.localStorage.getItem("providerID")) ? parseInt(window.localStorage.getItem("providerID")) : 0;
    const client = useApolloClient();
    let navigate1 = useNavigate();

    useEffect(() => {
        $(function () {
            $(".nav .apply-button a, .cta_box.apply-button").on("click", function (event) {
                event.preventDefault();
                $(".apply-agreement").css("display", "block");
            });
        });

        $(function () {
            $(".responsive-button").on("click", function (event) {
                event.preventDefault();
                $(this).toggleClass("active");
                $("#header ul.nav").slideToggle();
            });
            $(".responsive-button-submenu").on("click", function (event) {
                event.preventDefault();
                $(this).toggleClass("active");
                $("#navsubmenu").slideToggle();
            });
        });

        $(function () {
            $(".hcp-tab").on("click", function (event) {
                event.preventDefault();
                $(".hcp-popup").css("display", "block");
            });

            $(".hcp-popup .close-button").on("click", function (event) {
                event.preventDefault();
                $(".hcp-popup").css("display", "none");
            });
            $("a.parent").on("click", function (event) {
                event.preventDefault();
            });
        });
    }, []);

    useEffect(() => {
        window.addEventListener("GqlFlag", (e) => {
            let gqlErrorState = localStorage.getItem("gqlFlag");

            //if gql
            if (gqlErrorState) {
                localStorage.removeItem("gqlFlag");

                //ONLY DO IT IN THIS STATE, IDEALLY THE LOCALSTORAGE IS STOPPED
                function logout() {
                    let path = `hcp`;
                    console.log("clearing hcp");
                    // localStorage.removeItem("token");
                    // localStorage.removeItem("providerID");
                    // localStorage.removeItem("userID");
                    // localStorage.removeItem("userType");
                    // localStorage.removeItem("credentialsID");
                    localStorage.clear();

                    //New
                    client.clearStore();
                    client.cache.gc();
                    // $(".user-mm").toggleClass("show");
                    // $(".user-box").slideToggle();
                    navigate1(path);

                    // console.log(path);
                    // redirect(path);
                }
                logout();
            }
        });
    });
    window.addEventListener("storage", () => {
        // ...
    });

    const [unreadmessages, setunreadmessages] = useState(0);

    const { data: notificationData } = useQuery(GET_NOTIFICATION_COUNT, {
        variables: { provider_id },
    });

    useEffect(() => {
        if (notificationData) {
            if (notificationData.providernotification != null) {
                if (notificationData.providernotification[0] != null) {
                    if (notificationData.providernotification[0].total != null) {
                        setunreadmessages(notificationData.providernotification[0].total);
                    }
                }
            }
        }
    }, [notificationData]);

    return (
        <div>
            <header>
                <div className="container">
                    <NavLink to="hcp/patient-list" className="logo">
                        <img src={logo} />
                    </NavLink>
                    <div className="top-header">
                        <nav>
                            <NavLink to="../">Patients and Caregivers Site</NavLink>
                        </nav>
                        <div className="text-enlarger site-text-size">
                            {" "}
                            {window.localStorage.getItem("providerID") ? <AccountMenu /> : <></>}
                            {window.localStorage.getItem("providerID") ? (
                                <NavLink to="hcp/notifications" className="notif">
                                    <img src={bellIcon} />
                                    {unreadmessages != 0 ? <small>{unreadmessages}</small> : <></>}
                                </NavLink>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </header>
            {window.localStorage.getItem("providerID") ? <LoggedInHeader /> : <LoggedOutHeader />}
        </div>
    );
}

function AccountMenu() {
    const provider_id = parseInt(window.localStorage.getItem("providerID"));
    const credentials_id = window.localStorage.getItem("credentials_id");
    const [firstname, setfirstname] = useState("");
    const [lastname, setlastname] = useState("");
    const [credentialsid, setcredentialsid] = useState("");
    const client = useApolloClient();
    const { data } = useQuery(PROVIDER_NAME, {
        variables: { provider_id },
    });
    const { data: admindata, error } = useQuery(ADMIN_PROVIDER_NAME, {
        variables: { provider_id },
    });

    useEffect(() => {
        if (error != null) {
            if (error.toString().includes("You must be logged in to access this resource")) {
                signout();
            }
        }
    }, [error]);

    useEffect(() => {
        if (credentials_id == null) {
            if (provider_id) {
                if (data != null) {
                    if (data.providerfind != null) {
                        if (data.providerfind.provider_data != null) {
                            if (data.providerfind.provider_data.first_name) {
                                setfirstname(data.providerfind.provider_data.first_name);
                            }
                            if (data.providerfind.provider_data.last_name) {
                                setlastname(data.providerfind.provider_data.last_name);
                            }
                        }
                        if (data.providerfind.credentials_id) {
                            setcredentialsid(data.providerfind.credentials_id);
                        }
                    }
                }
            }
        }
    }, [data]);

    useEffect(() => {
        if (credentials_id) {
            if (admindata != null) {
                console.log(admindata);
                if (admindata.providerusersearch != null) {
                    admindata.providerusersearch.map((x) => {
                        var compare = '"' + x.credentials_id + '"';
                        if (compare == credentials_id) {
                            setfirstname(x.first_name);
                            setlastname(x.last_name);
                            setcredentialsid(x.credentials_id);
                        }
                    });
                }
            }
        }
    }, [admindata]);

    let navigate = useNavigate();
    function signout() {
        let path = `hcp`;
        localStorage.clear();

        //New
        client.clearStore();
        client.cache.gc();
        $(".user-mm").toggleClass("show");
        $(".user-box").slideToggle();
        navigate(path);
    }

    function clickUserBox() {
        $(".user-mm").toggleClass("show");
        $(".user-box").slideToggle();
    }

    return (
        <ul className="nav submenu overview user-area">
            <li className="icon-mm">
                <NavLink className="user-mm round-mm" onClick={clickUserBox}>
                    {firstname.substring(0, 1)}
                    {lastname.substring(0, 1)}
                </NavLink>
                <ul className="submenuitems user-box">
                    <div className="line-user mm">
                        <div className="round-mm">
                            {firstname.substring(0, 1)}
                            {lastname.substring(0, 1)}
                        </div>
                        <div className="after-mm">
                            <div className="font-bigger-size">
                                {firstname} {lastname}
                            </div>
                            <div className="user-info">{credentialsid}</div>
                        </div>
                    </div>
                    <div className="line-user acc">
                        <li>
                            <NavLink to="hcp/my-profile">My Profile</NavLink>
                        </li>
                    </div>
                    <div className="line-user psw">
                        <li>
                            <NavLink to="hcp/change-password">Change Password</NavLink>
                        </li>
                    </div>
                    <div className="line-user users">
                        <li>
                            <NavLink to="hcp/manage-users">Manage Office Users</NavLink>
                        </li>
                    </div>
                    <div className="line-user out">
                        <li onClick={signout}>
                            <Link>Sign out</Link>
                        </li>
                    </div>
                </ul>
            </li>
        </ul>
    );
}

function LoggedInHeader() {
    const pathname = window.location.pathname;

    return (
        <div id="header">
            <div className="container" id="header-hcp">
                <div className="responsive-button hidden-sm hidden-md hidden-lg">
                    <i className="fa fa-bars" aria-hidden="true"></i>
                    <span>MENU</span>
                </div>
                <ul className="nav clearfix hcp" id="nav" style={{ marginTop: "2px" }}>
                    <MainTabs />

                    <li id="submenu_profile">
                        <NavLink className={(navData) => (pathname == "/hcp/provider-profile" ? "selected" : "")} to="hcp/provider-profile">
                            Provider Profile
                        </NavLink>
                    </li>
                    <li className="has-child ">
                        <NavLink
                            className={(navData) =>
                                pathname == "/hcp/enroll" ||
                                pathname == "/hcp/enroll-additional-lap" ||
                                pathname == "/hcp/enroll-additional-patient" ||
                                pathname == "/hcp/enroll-attestation-lap" ||
                                pathname == "/hcp/enroll-attestation-patient" ||
                                pathname == "/hcp/enroll-doctor-information" ||
                                pathname == "/hcp/enroll-doctor-information-lap" ||
                                pathname == "/hcp/enroll-doctor-information-search-care" ||
                                pathname == "/hcp/enroll-doctor-information-search-care-lap" ||
                                pathname == "/hcp/enroll-doctor-information-search-provider" ||
                                pathname == "/hcp/enroll-doctor-information-search-provider-lap" ||
                                pathname == "/hcp/enroll-form" ||
                                pathname == "/hcp/enroll-form-lap" ||
                                pathname == "/hcp/enroll-form-review" ||
                                pathname == "/hcp/enroll-form-review-lap" ||
                                pathname == "/hcp/enroll-success" ||
                                pathname == "/hcp/patient-list" ||
                                pathname == "/hcp/patient-informations" ||
                                pathname == "/hcp/claims" ||
                                pathname == "/hcp/messages"
                                    ? "parent selected"
                                    : "parent"
                            }
                        >
                            Patient <i className="arrow down"></i>
                        </NavLink>
                        <ul className="submenuitems">
                            <li>
                                <NavLink to="hcp/enroll">Enroll a Patient</NavLink>
                            </li>
                            <li id="submenu_notifications">
                                <NavLink to="hcp/patient-list">Patient Search</NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="has-child ">
                        <NavLink className={(navData) => (pathname == "/hcp/eft" ? "parent selected" : "parent")}>
                            Reports <i className="arrow down"></i>
                        </NavLink>
                        <ul className="submenuitems">
                            <li id="submenu_messages">
                                <NavLink to="hcp/eft">Payment Report</NavLink>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
}

function LoggedOutHeader() {
    return (
        <div id="header">
            <div className="container" id="header-hcp">
                <div className="responsive-button hidden-sm hidden-md hidden-lg">
                    <i className="fa fa-bars" aria-hidden="true"></i>
                    <span>MENU</span>
                </div>
                <ul className="nav clearfix hcp" id="nav" style={{ marginTop: "2px" }}>
                    <MainTabs />
                </ul>
            </div>
        </div>
    );
}

function MainTabs() {
    const pathname = window.location.pathname;
    const loggedIn = window.localStorage.getItem("providerID");
    return (
        <>
            <li>
                <NavLink className={(navData) => (pathname == "/hcp" ? "selected" : "")} to={loggedIn ? "hcp/patient-list" : "hcp"}>
                    Home
                </NavLink>
            </li>
            <li className="has-child ">
                <NavLink
                    className={(navData) =>
                        pathname == "/hcp/eligibility" || pathname == "/hcp/using-the-card" || pathname == "/hcp/terms-and-conditions"
                            ? "parent selected"
                            : "parent"
                    }
                >
                    Program Details <i className="arrow down"></i>
                </NavLink>
                <ul className="submenuitems">
                    <li>
                        <NavLink to="hcp/eligibility">Offering/Eligibility</NavLink>
                    </li>
                    <li>
                        <NavLink to="hcp/using-the-card">Using the Program</NavLink>
                    </li>
                    <li>
                        <NavLink to="hcp/terms-and-conditions">Terms and Conditions</NavLink>
                    </li>
                    <li>
                        <a target="_blank" href={UserGuide}>
                            User Guide
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <NavLink className={(navData) => (pathname == "/hcp/forms" ? "selected" : "")} to="hcp/forms">
                    Forms
                </NavLink>
            </li>
        </>
    );
}
