import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import $ from "jquery";

export default function LAPInformation() {
    const { enrollment_data, setenrollmentdata } = useOutletContext();

    var existing_member = false;
    if (window.localStorage.getItem("existing_member") == "true") {
        existing_member = true;
    } else {
        existing_member = false;
    }

    const [first_name, setfirstname] = useState("");
    const [last_name, setlastname] = useState("");
    const [address1, setaddress1] = useState("");
    const [address2, setaddress2] = useState("");
    const [city, setcity] = useState("");
    const [state, setstate] = useState("");
    const [zip_code, setzipcode] = useState("");
    const [phone, setphone] = useState("");
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);

    function updateFirstName(e) {
        let trimmed_firstname = e.target.value.trim();
        setfirstname(trimmed_firstname);

        enrollment_data.lap.first_name = e.target.value;
    }
    function updateLastName(e) {
        let trimmed_lastname = e.target.value.trim();
        setlastname(trimmed_lastname);
        enrollment_data.lap.last_name = e.target.value;
    }
    function updateAddress1(e) {
        setaddress1(e.target.value);
        enrollment_data.lap.address.address1 = e.target.value;
    }
    function updateTrimmedAddress1(e) {
        let trimmed_address1 = e.target.value.trim();
        setaddress1(trimmed_address1);
        enrollment_data.lap.address.address1 = trimmed_address1;
        // window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));
    }
    function updateAddress2(e) {
        setaddress2(e.target.value);
        enrollment_data.lap.address.address2 = e.target.value;
    }

    function updateTrimmedAddress2(e) {
        let trimmed_address2 = e.target.value.trim();
        setaddress2(trimmed_address2);
        enrollment_data.lap.address.address2 = trimmed_address2;
        // window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));
    }
    function updateCity(e) {
        setcity(e.target.value);
        enrollment_data.lap.address.city = e.target.value;
    }
    function updateState(e) {
        setstate(e.target.value);
        enrollment_data.lap.address.state = e.target.value;
    }
    function updateZip(e) {
        setzipcode(e.target.value);
        enrollment_data.lap.address.zip_code = e.target.value;
    }
    function updatePhone(e) {
        setphone(e.target.value);
        enrollment_data.lap.phone = e.target.value;
    }
    function checkPhoneNumber(e) {
        let currPhoneNumber = e.target.value;
        // console.log($(".homePhone").val());
        // if (currPhoneNumber.match(/^\(\d{3}\) \d{3}-\d{4}$/)) {

        if (currPhoneNumber.match(/\(\d{3}\) \d{3}-\d{4}/)) {
            setIsValidPhoneNumber(true);
        } else if (currPhoneNumber.length < 14) {
            setIsValidPhoneNumber(false);
        } else {
            setIsValidPhoneNumber(false);
        }
    }

    function cleanAndSetPhoneNumber(e) {
        const phoneNumberCleaned = ("" + e.target.value).replace(/\D/g, "");
        const match = phoneNumberCleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        let finalPhoneNumber = e.target.value;

        if (phoneNumberCleaned.length === 0) {
            // check space entry/weird entry case

            e.target.value = "";
            checkPhoneNumber(e);
            setphone(finalPhoneNumber);
            enrollment_data.lap.phone = finalPhoneNumber;
            //Doesnt occur in this file for whatever reason
            window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));
            return;
        }
        if (match) {
            // Format the parts into (XXX) XXX-XXXX
            const part1 = match[1] ? `(${match[1]}` : "";
            const part2 = match[2] ? `) ${match[2]}` : "";
            const part3 = match[3] ? `-${match[3]}` : "";
            finalPhoneNumber = `${part1}${part2}${part3}`.trim();
        }
        setphone(finalPhoneNumber);
        enrollment_data.lap.phone = finalPhoneNumber;
        //Doesnt occur in this file for whatever reason
        window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));

        checkPhoneNumber(e);

        // return e.target.value;
    }

    useEffect(() => {
        if (window.localStorage.getItem("enrollment_data")) {
            setenrollmentdata(JSON.parse(window.localStorage.getItem("enrollment_data")));
            setfirstname(enrollment_data.lap.first_name);
            setlastname(enrollment_data.lap.last_name);
            setaddress1(enrollment_data.lap.address.address1);
            setaddress2(enrollment_data.lap.address.address2);
            setcity(enrollment_data.lap.address.city);
            setstate(enrollment_data.lap.address.state);
            setzipcode(enrollment_data.lap.address.zip_code);
            setphone(enrollment_data.lap.phone);
        }
        $(".homePhone").mask("(999) 999-9999", { autoclear: false });
    }, []);

    return (
        <div className="section shadow-box">
            <h3 className="color">Legally Authorized Person's Information</h3>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field required-field text">
                    <label htmlFor="first_name" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                        <span className="inner-label">
                            First name<span className="required">*</span>
                        </span>
                    </label>
                    <input
                        readOnly={existing_member == true ? true : false}
                        type="text"
                        id="first_name_enroller"
                        name="first_name_enroller"
                        onChange={(e) => updateFirstName(e)}
                        value={enrollment_data.lap.first_name}
                    />
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field required-field text">
                    <label htmlFor="last_name" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                        <span className="inner-label">
                            Last name<span className="required">*</span>
                        </span>
                    </label>
                    <input
                        readOnly={existing_member == true ? true : false}
                        type="text"
                        id="last_name_enroller"
                        name="last_name_enroller"
                        onChange={(e) => updateLastName(e)}
                        value={enrollment_data.lap.last_name}
                    />
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field required-field text">
                    <label htmlFor="street" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                        <span className="inner-label">
                            Address<span className="required">*</span>
                        </span>
                    </label>
                    <input
                        readOnly={existing_member == true ? true : false}
                        type="text"
                        id="address_enroller"
                        name="address_1_enroller"
                        onChange={(e) => updateAddress1(e)}
                        onBlur={(e) => updateTrimmedAddress1(e)}
                        value={enrollment_data.lap.address.address1}
                    />
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field text">
                    <label htmlFor="street" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                        <span className="inner-label">Address 2</span>
                    </label>
                    <input
                        readOnly={existing_member == true ? true : false}
                        type="text"
                        id="address2_enroller"
                        name="address_2_enroller"
                        onChange={(e) => updateAddress2(e)}
                        onBlur={(e) => updateTrimmedAddress2(e)}
                        value={enrollment_data.lap.address.address2}
                    />
                </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <p className="field required-field text">
                        <label htmlFor="city" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            <span className="inner-label">
                                City<span className="required">*</span>
                            </span>
                        </label>
                        <input
                            readOnly={existing_member == true ? true : false}
                            type="text"
                            id="city_enroller"
                            name="city_enroller"
                            onChange={(e) => updateCity(e)}
                            value={enrollment_data.lap.address.city}
                        />
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <p className="field required-field dropdown">
                        <label htmlFor="state" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            <span className="inner-label">
                                State<span className="required">*</span>
                            </span>
                        </label>
                        <span className="select_container">
                            <select
                                disabled={existing_member == true ? "disabled" : false}
                                name="state_enroller"
                                id="state_enroller"
                                defaultValue="default"
                                onChange={(e) => updateState(e)}
                                value={enrollment_data.lap.address.state}
                            >
                                <option label="" value="default">
                                    Select a state
                                </option>
                                <option label="Alabama" value="AL">
                                    Alabama
                                </option>
                                <option label="Alaska" value="AK">
                                    Alaska
                                </option>
                                <option label="American Samoa" value="AS">
                                    American Samoa
                                </option>
                                <option label="Arizona" value="AZ">
                                    Arizona
                                </option>
                                <option label="Arkansas" value="AR">
                                    Arkansas
                                </option>
                                <option label="California" value="CA">
                                    California
                                </option>
                                <option label="Colorado" value="CO">
                                    Colorado
                                </option>
                                <option label="Connecticut" value="CT">
                                    Connecticut
                                </option>
                                <option label="Delaware" value="DE">
                                    Delaware
                                </option>
                                <option label="Florida" value="FL">
                                    Florida
                                </option>
                                <option label="Georgia" value="GA">
                                    Georgia
                                </option>
                                <option label="Guam" value="GU">
                                    Guam
                                </option>
                                <option label="Hawaii" value="HI">
                                    Hawaii
                                </option>
                                <option label="Idaho" value="ID">
                                    Idaho
                                </option>
                                <option label="Illinois" value="IL">
                                    Illinois
                                </option>
                                <option label="Indiana" value="IN">
                                    Indiana
                                </option>
                                <option label="Iowa" value="IA">
                                    Iowa
                                </option>
                                <option label="Kansas" value="KS">
                                    Kansas
                                </option>
                                <option label="Kentucky" value="KY">
                                    Kentucky
                                </option>
                                <option label="Louisiana" value="LA">
                                    Louisiana
                                </option>
                                <option label="Maine" value="ME">
                                    Maine
                                </option>
                                <option label="Maryland" value="MD">
                                    Maryland
                                </option>
                                <option label="Massachusetts" value="MA">
                                    Massachusetts
                                </option>
                                <option label="Michigan" value="MI">
                                    Michigan
                                </option>
                                <option label="Minnesota" value="MN">
                                    Minnesota
                                </option>
                                <option label="Mississippi" value="MS">
                                    Mississippi
                                </option>
                                <option label="Missouri" value="MO">
                                    Missouri
                                </option>
                                <option label="Montana" value="MT">
                                    Montana
                                </option>
                                <option label="Nebraska" value="NE">
                                    Nebraska
                                </option>
                                <option label="Nevada" value="NV">
                                    Nevada
                                </option>
                                <option label="New Hampshire" value="NH">
                                    New Hampshire
                                </option>
                                <option label="New Jersey" value="NJ">
                                    New Jersey
                                </option>
                                <option label="New Mexico" value="NM">
                                    New Mexico
                                </option>
                                <option label="New York" value="NY">
                                    New York
                                </option>
                                <option label="North Carolina" value="NC">
                                    North Carolina
                                </option>
                                <option label="North Dakota" value="ND">
                                    North Dakota
                                </option>
                                <option label="Northern Mariana Islands" value="NM">
                                    Northern Mariana Islands
                                </option>
                                <option label="Ohio" value="OH">
                                    Ohio
                                </option>
                                <option label="Oklahoma" value="OK">
                                    Oklahoma
                                </option>
                                <option label="Oregon" value="OR">
                                    Oregon
                                </option>
                                <option label="Pennsylvania" value="PA">
                                    Pennsylvania
                                </option>
                                <option label="Puerto Rico" value="PR">
                                    Puerto Rico
                                </option>
                                <option label="Rhode Island" value="RI">
                                    Rhode Island
                                </option>
                                <option label="South Carolina" value="SC">
                                    South Carolina
                                </option>
                                <option label="South Dakota" value="SD">
                                    South Dakota
                                </option>
                                <option label="Tennessee" value="TN">
                                    Tennessee
                                </option>
                                <option label="Texas" value="TX">
                                    Texas
                                </option>
                                <option label="U.S. Virgin Islands" value="VI">
                                    U.S. Virgin Islands
                                </option>
                                <option label="Utah" value="UT">
                                    Utah
                                </option>
                                <option label="Vermont" value="VT">
                                    Vermont
                                </option>
                                <option label="Virginia" value="VA">
                                    Virginia
                                </option>
                                <option label="Washington" value="WA">
                                    Washington
                                </option>
                                <option label="Washington DC" value="DC">
                                    Washington DC
                                </option>
                                <option label="West Virginia" value="WV">
                                    West Virginia
                                </option>
                                <option label="Wisconsin" value="WI">
                                    Wisconsin
                                </option>
                                <option label="Wyoming" value="WY">
                                    Wyoming
                                </option>{" "}
                            </select>
                        </span>
                    </p>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <p className="field required-field text zipcode">
                        <label htmlFor="zipCode" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            <span className="inner-label">
                                ZIP code<span className="required">*</span>
                            </span>
                        </label>
                        <input
                            readOnly={existing_member == true ? true : false}
                            type="text"
                            id="zipCode_enroller"
                            name="zip_enroller"
                            onChange={(e) => updateZip(e)}
                            value={enrollment_data.lap.address.zip_code}
                        />
                    </p>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field required-field text">
                    <label htmlFor="homePhone_enroller" className={`${!isValidPhoneNumber ? "red" : ""}`} style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                        <span className="inner-label">
                            Phone number<span className="required">*</span>
                        </span>
                    </label>
                    <input
                        readOnly={existing_member == true ? true : false}
                        type="text"
                        className="phone_2 homePhone"
                        name="phone_number_enroller"
                        style={isValidPhoneNumber ? {} : { border: "2px solid red" }}
                        onChange={(e) => updatePhone(e)}
                        onBlur={(e) => cleanAndSetPhoneNumber(e)}
                        value={phone}
                        required
                    />
                    {!isValidPhoneNumber && (
                        <p class="prompt-error error" style={{ padding: 0, margin: 0, display: "inline" }}>
                            Please enter a valid phone number.
                        </p>
                    )}
                </p>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field required-field dropdown">
                    <label htmlFor="homePhone_enroller" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                        <span className="inner-label">
                            Phone type<span className="required">*</span>
                        </span>
                    </label>
                    <span className="select_container">
                        <select id="hometype_enroller" name="phone_type_enroller">
                            <option value="Home">Home</option>
                            <option value="Mobile">Mobile</option>
                        </select>
                    </span>
                </p>
            </div>
        </div>
    );
}
