import React from 'react'
import UserProfile from '../components/UserProfile.js'

export default function MyProfile(){
    return (
        <div className="container" id="content" style={{fontSize: '1em'}}>
          <div id="container">
            <div className="space"></div>
            <UserProfile />
            <div className="clearfloat"></div>
          </div>
        </div>  
    )
}