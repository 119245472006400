import React from "react";
import Xolair_HCP_Check_Authorization_Form from "../../docs/XOLAIR-HCP-One-Time-Check-Auth-Form 7.27.23.pdf";
import Xolair_HCP_Check_Request_Form from "../../docs/Xolair_Check_Request_Form.pdf";
import Xolair_EFT_Authroization_Form from "../../docs/XOLAIR_EFT-Form_7.27.23.pdf";
import XOLAIR_Verification_of_Administration_Form from "../../docs/XOLAIR_Verification_of_Administration_Form_4_30_24.pdf";
import XOLAIRFaxConsentForm from "../../docs/Genentech-Fax-Communication-Opt-In_7.27.23.pdf";

export default function Forms() {
    return (
        <div className="container" id="content" style={{ fontSize: "1em" }}>
            <div id="container">
                <h1 style={{ fontSize: "1.5em", lineHeight: "1.49em" }}>Downloadable Forms</h1>
                <p className="color">
                    <a href={Xolair_HCP_Check_Authorization_Form} target="_blank">
                        Check Request Authorization Form for Healthcare Providers
                    </a>
                </p>
                <p>
                    This form is used to initiate the check reimbursement process. It is a one-time registration completed by the practice.
                    Once completed, all reimbursements will be issued via check.
                </p>
                <p className="color">
                    <a href={Xolair_HCP_Check_Request_Form} target="_blank">
                        One-time Check Request Form for Healthcare Providers
                    </a>
                </p>
                <p>This form is used when the practice would like a one-time check payment. It is completed by the practice.</p>
                <p className="color">
                    <a href={Xolair_EFT_Authroization_Form} target="_blank">
                        Electronic Funds Transfer (EFT) Authorization Form
                    </a>
                </p>
                <p>
                    This form is used to initiate the EFT registration process when the practice chooses not to use check reimbursements. It
                    is a one-time registration completed by the practice.
                </p>
                <p className="color">
                    <a href={XOLAIR_Verification_of_Administration_Form} target="_blank">
                        Verification of Administration Form
                    </a>
                </p>
                <p>
                    This form is used when the drug is not clearly identified on the explanation of benefits (EOB). This form can accompany
                    the EOB.
                </p>
                <p className="color">
                    <a href={XOLAIRFaxConsentForm} target="_blank">
                        Fax Consent Form
                    </a>
                </p>
                <p>This form is used by the practice to give consent to receive fax communications from Genentech, Inc.</p>
                <div className="clearfloat"></div>
            </div>
        </div>
    );
}
