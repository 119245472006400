import React from "react";
import { Link } from "react-router-dom";

export default function Eligibility() {
    return (
        <div className="container" id="content" style={{ fontSize: "1em" }}>
            <div id="container" className="eligibility">
                <div id="provider-eligibility">
                    <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>Program Offering and Eligibility</h2>
                    <h3>Program Offering</h3>
                    <p>
                        The XOLAIR Co-pay Program may help eligible commercially insured patients. If the patient meets the eligibility
                        criteria, the patient can get help with their XOLAIR drug costs, injection costs, or both.
                    </p>{" "}
                    <p>
                        <strong>Help with drug out-of-pocket costs</strong>
                    </p>
                    <div className="row help my-4">
                        <div className="col-xs-12 col-sm-4">
                            <div className="circle1">
                                <span style={{ color: "#83156f" }}>
                                    Eligible patients pay as little as $0 per XOLAIR drug <span className="text-nowrap">out-of-pocket</span>{" "}
                                    cost
                                </span>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            <ul>
                                <li>
                                    <span>The program covers up to $15,000 per calendar year benefit period</span>
                                </li>
                                <li>
                                    <span>There are no income limits for this program</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <p>
                        Note: The final amount owed by patients may be as little as $0, but may vary depending on the patient's health
                        insurance plan.
                    </p>
                    <p>
                        <strong>Help with administration out-of-pocket costs</strong>
                    </p>
                    <div className="row help mt-4 mb-5">
                        <div className="col-xs-12 col-sm-4">
                            <div className="circle2">
                                <span style={{ color: "#83156f" }}>
                                    Eligible patients pay as little as $0 per XOLAIR administration{" "}
                                    <span className="text-nowrap">out-of-pocket</span> cost
                                </span>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            <ul>
                                <li>
                                    <span className="bluetext">The program covers up to $1,500 per calendar year benefit period </span>
                                </li>
                                <li>
                                    <span className="bluetext">There are no income limits for this program</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <p>
                        Note: The final amount owed by patients may be as little as $0, but may vary depending on the patient's health
                        insurance plan.
                    </p>
                    <h3>
                        <strong style={{ color: "#00759f" }}>Eligibility for help with drug out-of-pocket costs</strong>
                    </h3>
                    <div className="box-drug">
                        <div className="title-box2-drug" aria-label="Blue Table Header">
                            ELIGIBLE PATIENTS:
                        </div>
                        <div className="content-box2">
                            <ul>
                                <li>
                                    <span>
                                        Have been prescribed XOLAIR for an{" "}
                                        <Link to="#isi">
                                            <strong>FDA-approved indication</strong>
                                        </Link>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        Are 18 years of age or older, or have a caregiver or legally authorized person to manage the
                                        patient's co-pay assistance
                                    </span>
                                </li>
                                <li>
                                    Have commercial (private or non-governmental) insurance.<sup>*</sup> This includes plans available
                                    through state and federal health insurance exchanges
                                </li>
                                <li>
                                    <span>Reside and receive treatment in the U.S. or U.S. Territories</span>
                                </li>
                                <li>
                                    Are <strong style={{ color: "#83156f" }}>not</strong> receiving assistance through the Genentech Patient
                                    Foundation or any other charitable organization for the same expenses covered by the program
                                </li>{" "}
                                <li>
                                    Are <strong style={{ color: "#83156f" }}>not</strong> a government beneficiary and/or participant in a
                                    federal or state-funded health insurance program (e.g., Medicare, Medicare Advantage, Medigap, Medicaid,
                                    VA, DoD, TRICARE)
                                </li>
                            </ul>
                        </div>
                    </div>
                    <h3>
                        <strong style={{ color: "#00759f" }}>Eligibility for help with administration out-of-pocket costs</strong>
                    </h3>
                    <div className="box-drug">
                        <div className="title-box2-inf" aria-label="Blue Table Header">
                            ELIGIBLE PATIENTS:
                        </div>
                        <div className="content-box2">
                            <ul>
                                <li>
                                    <span>
                                        Have been prescribed XOLAIR for an{" "}
                                        <Link to="#isi">
                                            <strong>FDA-approved indication</strong>
                                        </Link>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        Are 18 years of age or older, or have a caregiver or legally authorized person to manage the
                                        patient's co-pay assistance
                                    </span>
                                </li>
                                <li>
                                    Have commercial (private or non-governmental) insurance.<sup>*</sup> This includes plans available
                                    through state and federal health insurance exchanges
                                </li>
                                <li>Reside and receive treatment in the U.S. or U.S. Territories</li>
                                <li>
                                    Are <strong style={{ color: "#83156f" }}>not</strong> receiving assistance through any charitable
                                    organization for the same expenses covered by the program<sup>†</sup>
                                </li>
                                <li>
                                    Are <strong style={{ color: "#83156f" }}>not</strong> a government beneficiary and/or participant in a
                                    federal or state-funded health insurance program (e.g., Medicare, Medicare Advantage, Medigap, Medicaid,
                                    VA, DoD, TRICARE)
                                </li>
                                <li>
                                    <span>
                                        Do <strong>not</strong> reside or receive treatment in a restricted state (e.g. Massachusetts or
                                        Rhode Island)
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <p>
                        <sup>*</sup>Commercial insurance includes plans you receive from your job or plans from the Health Insurance
                        Marketplace. Government programs like Medicare and Medicaid are not commercial insurance.
                    </p>
                    <p>
                        <sup>†</sup>Patients may use the XOLAIR Co-pay Program for their administration costs If they are receiving their
                        treatment from the Genentech Patient Foundation.{" "}
                    </p>
                    <p>
                        If a patient is not eligible for the XOLAIR <span className="text-nowrap">Co-pay</span> Program, there may be other
                        options for assistance with out-of-pocket costs. Call XOLAIR at{" "}
                        <span className="text-nowrap">
                            <strong>(866) 4XOLAIR</strong>
                        </span>{" "}
                        <span className="text-nowrap">(1-866-496-5247)</span> or visit{" "}
                        <Link to="https://xolair.com" target="_blank">
                            <strong>XOLAIR.com</strong>
                        </Link>{" "}
                        to learn more.{" "}
                    </p>
                </div>
            </div>
        </div>
    );
}
