import React from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

export default function PatientListTableRow(props) {
    let navigate = useNavigate();
    function viewPatient() {
        window.localStorage.setItem("xrefID", props.patientID);
        //let path = `.../patient-informations`
        //navigate(path)
    }

    function uploadDocument() {
        window.localStorage.setItem("xrefID", props.patientID);
    }

    return (
        <tr>
            <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                <Link onClick={viewPatient} to="../patient-informations">
                    {props.lastName} {props.firstName}
                </Link>
            </td>
            <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                {props.dob}
            </td>
            <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                {props.memberID}
            </td>
            <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                {props.xolairDrug == "Y" ? "XOLAIR DRUG" : <></>}
                {props.xolairDrug == "Y" && props.xolairAdmin == "Y" ? <br /> : <></>}
                {props.xolairAdmin == "Y" ? "XOLAIR ADMIN" : <></>}
            </td>
            <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                {props.address1}
                <br />
                {(props.address2 == "") | (props.address2 == null) ? (
                    <></>
                ) : (
                    <>
                        {props.address2}
                        <br />
                    </>
                )}
                {props.city}, {props.state} {props.zip}{" "}
            </td>
            <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                {props.xolairDrug == "Y" ? props.enrollmentDate : <></>}
                {props.xolairDrug == "Y" && props.xolairAdmin == "Y" ? <br /> : <></>}
                {props.xolairAdmin == "Y" ? props.enrollmentDateAdmin : <></>}
            </td>
            <td align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                {props.xolairDrug == "Y" ? props.originalEnrollmentDate : <></>}
                {props.xolairDrug == "Y" && props.xolairAdmin == "Y" ? <br /> : <></>}
                {props.xolairAdmin == "Y" ? props.originalEnrollmentDateAdmin : <></>}
            </td>
            <td align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                <span className={props.status == "Active" || props.status == "active" ? "status-icon active" : "status-icon inactive"}>{props.status}</span>
            </td>
            <td align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                <p className="hcp">
                    <Link onClick={uploadDocument} to="../submit-document">
                        Upload document
                    </Link>
                </p>
                <p className="hcp"></p>
            </td>
        </tr>
    );
}
