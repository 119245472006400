import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';
import { FIND_SITE_OF_CARE } from '../graphql/queries/Providers'
export default function SiteOfCareSearch(props) {

  const provider_id = parseInt(props.providerID)

  const { site_of_care, setsiteofcare } = useOutletContext()

  const [practiceName, setPracticeName] = useState('')
  const [npi, setnpi] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setcity] = useState('')
  const [state2, setstate] = useState('')
  const [zip_code, setzipcode] = useState('')
  const [phone, setphone] = useState('')

  const { loading, error, data } = useQuery(FIND_SITE_OF_CARE, {
    variables: { provider_id }
  });

  useEffect(() => {
    if (data) {
      if (data.providerfind != null) {
        if (data.providerfind.provider_npi != null) {
          setnpi(data.providerfind.provider_npi)
        }
        if (data.providerfind.provider_data != null) {
          if (data.providerfind.provider_data.practice_name) {
            setPracticeName(data.providerfind.provider_data.practice_name)
          }
          if (data.providerfind.provider_data.phone_number) {
            setphone(data.providerfind.provider_data.phone_number)
          }
          if (data.providerfind.provider_data.address != null) {
            if (data.providerfind.provider_data.address.address_1) {
              setAddress1(data.providerfind.provider_data.address.address_1)
            }
            if (data.providerfind.provider_data.address.address_2) {
              setAddress2(data.providerfind.provider_data.address.address_2)
            }
            if (data.providerfind.provider_data.address.city) {
              setcity(data.providerfind.provider_data.address.city)
            }
            if (data.providerfind.provider_data.address.state) {
              setstate(data.providerfind.provider_data.address.state)
            }
            if (data.providerfind.provider_data.address.zip_code) {
              setzipcode(data.providerfind.provider_data.address.zip_code)
            }
          }
        }
      }



    }

  }, [data])

  function setselectedsoc() {
    setsiteofcare(
      {
        provider_id: provider_id,
        name: practiceName,
        address: {
          address1: address1,
          address2: address2,
          city: city,
          state: state2,
          zip_code: zip_code,
        },
        phone_number: phone,
        npi: npi
      }
    )

  }



  return (

    <tr className="first"><td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{practiceName}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{npi}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{address1}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{address2}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{city}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{state2}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{zip_code}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>{phone}</td>
      <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>
        <input onChange={setselectedsoc} type="radio" name="result_care" value={provider_id} />
      </td>
    </tr>
  )
}