import { React, useEffect } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Home from "./pages/Index";
import AddOfficeUser from "./pages/AddOfficeUser";
import Agreement from "./pages/Agreement";
import ChangePassword from "./pages/ChangePassword";
import CreatePassword from "../pages/CreatePassword";
import Claims from "./pages/Claims";
import EditOfficeUser from "./pages/EditOfficeUser";
import Eft from "./pages/Eft";
import Eligibility from "./pages/Eligibility";
import EligibilityAccount from "./pages/EligibilityAccount";
import EligibilityErrorDrug from "../pages/EligibilityErrorDrug";
import EligibilityErrorInfusion from "../pages/EligibilityErrorInfusion";
import EligibilityError from "../pages/EligibilityError";
import EligibilityLap from "./components/EligibilityLap";
import EligibilityPatient from "./components/EligibilityPatient";
import EnrollAdditionalPatient from "../pages/EnrollAdditionalPatient";
import EnrollAttestation from "./pages/EnrollAttestation";
import EnrollDoctorInformationLap from "../pages/EnrollDoctorInformationLap";
import EnrollDoctorInformation from "../pages/EnrollDoctorInformation";
import EnrollFormLap from "./pages/EnrollFormLap";
import EnrollFormReviewLap from "./pages/EnrollFormReviewLap";
import EnrollFormReview from "./pages/EnrollFormReview";
import EnrollForm from "./pages/EnrollForm";
import EnrollSuccess from "./pages/EnrollSuccess";
import EnrollError from "../pages/EnrollError";
import Enroll from "./pages/Enroll";
import FaxConsent from "./pages/FaxConsent";
import Forms from "./pages/Forms";
import ManageUsers from "./pages/ManageUsers";
import Messages from "./pages/Messages";
import MyProfile from "./pages/MyProfile";
import Notifications from "./pages/Notifications";
import PasswordRequestSuccess from "./pages/PasswordRequestSuccess";
import PasswordRequest from "./pages/PasswordRequest";
import PatientInformations from "./pages/PatientInformations";
import PatientListAll from "./pages/PatientListAll";
import PatientList from "./pages/PatientList";
import PatientProfile from "./pages/PatientProfile";
import ProviderProfile from "./pages/ProviderProfile";
import SubmitDocument from "./pages/SubmitDocument";
import TermsAndConditions from "./pages/TermsAndConditions";
import TermsAndConditionsAccount from "./pages/TermsAndConditionsAccount";
import UpdateInsurance from "./pages/UpdateInsurance";
import UpdatePharmacy from "./pages/UpdatePharmacy";
import UpdateProfile from "./pages/UpdateProfile";
import UsingTheCard from "./pages/UsingTheCard";
import UsingTheCardAccount from "./pages/UsingTheCardAccount";
import Layout from "../components/Layout";

export default function HCPRoutes() {
  return (
    <Routes>
      <Route path="/add-office-user" element={<AddOfficeUser />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/create-password" element={<CreatePassword />} />

      <Route path="/forms" element={<Forms />} />
      <Route path="/" element={<Home />} />
      <Route
        path="/password-request-success"
        element={<PasswordRequestSuccess />}
      />
      <Route path="/password-request" element={<PasswordRequest />} />
      <Route
        path="/terms-and-conditions-account"
        element={<TermsAndConditionsAccount />}
      />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/using-the-card-account" element={<UsingTheCardAccount />} />
      <Route path="/using-the-card" element={<UsingTheCard />} />
      <Route path="/eligibility" element={<Eligibility />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/agreement" element={<Agreement />} />
        <Route path="/claims" element={<Claims />} />
        <Route path="/edit-office-user" element={<EditOfficeUser />} />
        <Route path="/eft" element={<Eft />} />
        <Route path="/manage-users" element={<ManageUsers />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/patient-informations" element={<PatientInformations />} />
        <Route path="/patient-list-all" element={<PatientListAll />} />
        <Route path="/patient-list" element={<PatientList />} />
        <Route path="/patient-profile" element={<PatientProfile />} />
        <Route path="/provider-profile" element={<ProviderProfile />} />
        <Route path="/submit-document" element={<SubmitDocument />} />
        <Route path="/update-insurance" element={<UpdateInsurance />} />
        <Route path="/update-pharmacy" element={<UpdatePharmacy />} />
        <Route path="/update-profile" element={<UpdateProfile />} />
        <Route path="/eligibility-account" element={<EligibilityAccount />} />
        <Route
          path="/eligibility-error-drug"
          element={<EligibilityErrorDrug />}
        />
        <Route
          path="/eligibility-error-infusion"
          element={<EligibilityErrorInfusion />}
        />
        <Route path="/eligibility-error" element={<EligibilityError />} />
        <Route element={<Layout />}>
          <Route
            path="/eligibility-lap-drug-infusion"
            element={<EligibilityLap pageType="drug-infusion" />}
          />
          <Route
            path="/eligibility-lap-drug-only"
            element={<EligibilityLap pageType="drug" />}
          />
          <Route
            path="/eligibility-lap-infusion-only"
            element={<EligibilityLap pageType="infusion" />}
          />
          <Route
            path="/eligibility-patient-drug-infusion"
            element={<EligibilityPatient pageType="drug-infusion" />}
          />
          <Route
            path="/eligibility-patient-drug-only"
            element={<EligibilityPatient pageType="drug" />}
          />
          <Route
            path="/eligibility-patient-infusion-only"
            element={<EligibilityPatient pageType="infusion" />}
          />
          <Route
            path="/enroll-additional-lap"
            element={<EnrollAdditionalPatient />}
          />
          <Route
            path="/enroll-additional-patient"
            element={<EnrollAdditionalPatient />}
          />
          <Route
            path="/enroll-attestation-lap"
            element={<EnrollAttestation pageType="lap" />}
          />
          <Route
            path="/enroll-attestation-patient"
            element={<EnrollAttestation pageType="patient" />}
          />
          <Route
            path="/enroll-doctor-information-lap"
            element={<EnrollDoctorInformationLap type="provider" />}
          />
          <Route
            path="/enroll-doctor-information"
            element={<EnrollDoctorInformation type="provider" />}
          />
          <Route path="/enroll-form-lap" element={<EnrollFormLap />} />
          <Route
            path="/enroll-form-review-lap"
            element={<EnrollFormReviewLap />}
          />
          <Route path="/enroll-form-review" element={<EnrollFormReview />} />
          <Route path="/enroll-form" element={<EnrollForm />} />
          <Route path="/enroll" element={<Enroll />} />
        </Route>
        <Route path="/enroll-success" element={<EnrollSuccess />} />
        <Route path="/enroll-error" element={<EnrollError />} />
        <Route path="/fax-consent" element={<FaxConsent />} />
      </Route>
    </Routes>
  );
}

const ProtectedRoute = () => {
  const token = localStorage.getItem("token");
  return token ? <Outlet /> : <Navigate to="/hcp" replace />;
};
