import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';
import { PATIENT_PROFILE_DETAILS } from '../graphql/queries/Patients'

export default function PatientProfileDetails(props) {

    var patient_id = parseInt(window.localStorage.getItem('xrefID'))
    if (props.patientID) {
        patient_id = parseInt(props.patientID)
    }

    

    const [patientID, setPatientID] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLasttName] = useState('');
    const [dob, setdob] = useState('');
    const [email, setemail] = useState('');
    const [address1, setaddress1] = useState('');
    const [address2, setaddress2] = useState('');
    const [city, setcity] = useState('');
    const [state, setstate] = useState('');
    const [zip, setzip] = useState('');
    const [phonenumber, setphonenumber] = useState('');
    const [phonetype, setphonetype] = useState('');


    const { loading, error, data, refetch } = useQuery(PATIENT_PROFILE_DETAILS, {
        variables: { patient_id }
    });

    
    useEffect(() => {
        if (patient_id) {
            if (data != null) {
                if (data.patientinfo != null) {
                    if (data.patientinfo.enrollment_data != null) {
                        if (data.patientinfo.enrollment_data.first_name) {
                            setFirstName(data.patientinfo.enrollment_data.first_name)
                        }
                        if (data.patientinfo.enrollment_data.last_name) {
                            setLasttName(data.patientinfo.enrollment_data.last_name)
                        }
                        if (data.patientinfo.enrollment_data.date_of_birth) {
                            try{
                                let date = data.patientinfo.enrollment_data.date_of_birth
                                let year = date.substring(0,4)
                                let month = date.substring(5,7)
                                let day = date.substring(8,10)
                                setdob(month + "/" + day + "/" + year)
                            } catch {
                                setdob(data.patientinfo.enrollment_data.date_of_birth)
                            }
                        }
                        if (data.patientinfo.enrollment_data.address.address_1) {
                            setaddress1(data.patientinfo.enrollment_data.address.address_1)
                        }
                        if (data.patientinfo.enrollment_data.address.address_2) {
                            setaddress2(data.patientinfo.enrollment_data.address.address_2)
                        }
                        if (data.patientinfo.enrollment_data.address.city) {
                            setcity(data.patientinfo.enrollment_data.address.city)
                        }
                        if (data.patientinfo.enrollment_data.address.state) {
                            setstate(data.patientinfo.enrollment_data.address.state)
                        }
                        if (data.patientinfo.enrollment_data.address.zip_code) {
                            setzip(data.patientinfo.enrollment_data.address.zip_code)
                        }
                        if (data.patientinfo.enrollment_data.email) {
                            setemail(data.patientinfo.enrollment_data.email)
                        }
                        if (data.patientinfo.enrollment_data.phone) {
                            setphonenumber(data.patientinfo.enrollment_data.phone)
                        }
                        if (data.patientinfo.enrollment_data.phone_type) {
                            setphonetype(data.patientinfo.enrollment_data.phone_type)
                        }
                    }
                }

            } else {
            }
        } else {
        }

    }, [data])

    function updateProfile() {

    }

    function handleChange() {

    }

    return (
        <div className="section shadow-box  color-label">
            <div className="col-sm-12 col-md-12 col-lg-9" style={{ padding: '0' }}>
                <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Patient Profile Details {((email == '') ) ? <span className="red" style={{ paddingLeft: '40px', textDecoration: 'underline' }}>Please update items marked in red.</span> : <></> }</h2>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3" style={{ textAlign: 'right' }}>
                <form action={props.updateURL ? props.updateURL : "update-profile"} onSubmit={updateProfile} encType="multipart/form-data">
                    <input onChange={handleChange} type="hidden" name="action" value="update_profile" />
                    <button className="update" type="submit" value="Next">Update Patient Details</button>
                </form>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text">
                        <label htmlFor="first_name" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Full name</label>
                        {firstName + ' ' + lastName}
                    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text date">
                        <label htmlFor="birthDate" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Date of birth</label>
                        {dob}
                    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text date">
                        <label htmlFor="birthDate" style={{ fontSize: '1em', lineHeight: '1.49em' }}>{email == '' ? <span className="red">Email address</span> : 'Email address'}</label>
                        {email}
                    </p></div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
                <p className="field required-field text">
                    <label htmlFor="street" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Address</label>
                    {address1}<br />
                    {address2 ? <>{address2}<br /></> : <></>}
                    {city}, {state} {zip}
                </p></div>
            <div className="col-sm-12 col-md-12 col-lg-4">

                <p className="field required-field text phone">
                    <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Phone number</label>
                    {phonenumber}
                </p></div>
            <div className="col-sm-12 col-md-12 col-lg-4">

                <p className="field required-field text phone">
                    <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Phone type</label>
                    {phonetype}
                </p></div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <p className="alignright"><Link to="../submit-document">Upload a document such as your explanation of benefits (E0B)</Link></p>
            </div>
        </div>
    )
}

