import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { PAYMENT_REPORTS } from "../../graphql/queries/Providers";
import excel from "../../images/excel.png";
import * as XLSX from "xlsx/xlsx.mjs";
import CheckPaymentList from "./CheckPaymentList.js";

export default function CheckPaymentStatus(props) {
    const provider_id = parseInt(window.localStorage.getItem("providerID"));
    const payment_type = "check";
    const [itemsPerPage, setitems] = useState("50");
    const [offset, setoffset] = useState("0");
    const [limit, setlimit] = useState("50");
    const [page, setpage] = useState(1);

    function pageBack() {
        if (page > 1) {
            setpage(page - 1);
        }
        setoffset((page - 1) * itemsPerPage);
        setlimit(page * itemsPerPage);
    }

    function pageForward() {
        if (data) {
            if (data.providerreports) {
                if (data.providerreports.length > limit) {
                    setpage(page + 1);
                }
            }
        }
        setoffset((page - 1) * itemsPerPage);
        setlimit(page * itemsPerPage);
    }

    useEffect(() => {
        setlimit(page * itemsPerPage);
    }, [itemsPerPage]);

    var from_date_of_service = null;
    var to_date_of_service = null;
    var from_paid_date = "";
    var to_paid_date = "";

    var fromYear;
    var fromMonth;
    var fromDay;

    if (props.fromdate) {
        from_date_of_service = props.fromdate;
        from_paid_date = props.fromdate;
        fromYear = parseInt(props.fromdate.substring(0, 4));
        fromMonth = parseInt(props.fromdate.substring(5, 7));
        fromDay = parseInt(props.fromdate.substring(8, 10));
    }

    if (props.todate) {
        to_date_of_service = props.todate;
        to_paid_date = props.todate;
    }
    var sendPaidDate = true;

    if (fromYear <= 2023) {
        if (fromYear < 2023) {
            sendPaidDate = false;
        } else if (fromMonth < 10 && fromYear === 2023) {
            sendPaidDate = false;
        }
    } else {
        sendPaidDate = true;
    }

    const [loadReports, { loading, error, data, refetch }] =
        sendPaidDate == true
            ? useLazyQuery(PAYMENT_REPORTS, {
                  variables: {
                      provider_id,
                      payment_type,
                      from_paid_date,
                      to_paid_date,
                      from_requested_date: null,
                      to_requested_date: null,
                  },
              })
            : useLazyQuery(PAYMENT_REPORTS, {
                  variables: { provider_id, payment_type, from_date_of_service, to_date_of_service },
              });

    useEffect(() => {
        from_date_of_service = props.fromdate;
        to_date_of_service = props.todate;
        loadReports();
    }, [props]);

    function handleDownloadClick() {
        const excel = [
            ["Product", "Patient Name", "DOB", "Member Number", "DOS", "Payee Info", "Amount", "Check Number", "Status Date", "Status"],
        ];
        {
            const excelData = data;

            excelData.providerreports.forEach((patient) => {
                try {
                    var program = patient.program;
                } catch {
                    var program = "";
                }
                try {
                    var first_name = patient.first_name;
                } catch {
                    var first_name = "";
                }
                try {
                    var last_name = patient.last_name;
                } catch {
                    var last_name = "";
                }
                try {
                    var date_of_birth = patient.date_of_birth;
                } catch {
                    var date_of_birth = "";
                }
                try {
                    var member_id = patient.member_id;
                } catch {
                    var member_id = "";
                }
                try {
                    var date_of_service = patient.date_of_service;
                } catch {
                    var date_of_service = "";
                }
                try {
                    var check_num = patient.claim_data.check_num;
                } catch {
                    var check_num = "";
                }
                try {
                    try {
                        var address_1 = patient.claim_data.providers.provider.address.address_1;
                    } catch {
                        var address_1 = patient.claim_data.shipping_info.address.address_1;
                    }
                } catch {
                    var address_1 = "";
                }
                try {
                    try {
                        var address_2 = patient.claim_data.providers.provider.address.address_2;
                    } catch {
                        var address_2 = patient.claim_data.shipping_info.address.address_2
                            ? patient.claim_data.shipping_info.address.address_2
                            : "";
                    }
                } catch {
                    var address_2 = "";
                }
                try {
                    try {
                        var city = patient.claim_data.providers.provider.address.city;
                    } catch {
                        var city = patient.claim_data.shipping_info.address.city;
                    }
                } catch {
                    var city = "";
                }
                try {
                    try {
                        var state = patient.claim_data.providers.provider.address.state;
                    } catch {
                        var state = patient.claim_data.shipping_info.address.state;
                    }
                } catch {
                    var state = "";
                }
                try {
                    try {
                        var zip_code = patient.claim_data.providers.provider.address.zip_code;
                    } catch {
                        var zip_code = patient.claim_data.shipping_info.address.zip_code;
                    }
                } catch {
                    var zip_code = "";
                }
                var address = address_1 + " " + address_2 + " " + city + " " + state + " " + zip_code;
                try {
                    var program_benefit = "$" + parseFloat(patient.claim_data.program_benefit).toFixed(2);
                } catch {
                    var program_benefit = "";
                }
                try {
                    var claim_status = patient.claim_status;
                } catch {
                    var claim_status = "";
                }
                try {
                    var paid_date = patient.claim_data.paid_date;
                } catch {
                    var paid_date = "";
                }
                try {
                    var requested_date = patient.claim_data.requested_date;
                } catch {
                    var requested_date = "";
                }
                try {
                    var date_of_service = date_of_service;
                } catch {
                    var date_of_service = "";
                }

                excel.push([
                    program,
                    last_name + ", " + first_name,
                    date_of_birth,
                    member_id,
                    date_of_service,
                    address,
                    program_benefit,
                    check_num,
                    paid_date != "" && paid_date != null
                        ? paid_date
                        : requested_date != "" && requested_date != null
                        ? requested_date
                        : date_of_service,
                    paid_date != null ? "Paid" : claim_status,
                ]);
            });
        }
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;
        const formattedToday = mm + "-" + dd + "-" + yyyy;

        //create workbook
        const wb = XLSX.utils.book_new();
        //create sheet
        const ws = XLSX.utils.aoa_to_sheet(excel, { origin: "A1", skipHeader: false });
        //append sheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, "Check Payment Status");
        XLSX.writeFile(wb, "CheckPaymentStatus" + formattedToday + ".xlsx");
    }

    return (
        <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="clear">
                <div className="border-color">
                    <strong>
                        <span className="underline">Check Payment Status:</span>
                    </strong>
                    <strong>Approved:</strong> Claim has been approved and awaiting check to be issued <br />
                    <strong>Paid:</strong> Claim is approved and check has been mailed to the address on file <br />
                    <strong>Check Cleared:</strong> Funds have been deposited into the account
                </div>
            </div>
            <span className="tooltip" style={{ float: "right" }}>
                <a onClick={handleDownloadClick}>
                    <img src={excel} className="excel" />
                </a>
                <span className="tooltiptext">
                    Click here to download entire patient list. Please note there is a 24 hour delay for newly enrolled patients to be
                    included in this excel list
                </span>
            </span>
            <table style={{ marginTop: "0", marginBottom: "0" }} className="account">
                <tbody>
                    <tr className="list_header">
                        <th align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Product
                        </th>
                        <th align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Patient Name
                        </th>
                        <th align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            DOB
                        </th>
                        <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Member Number
                        </th>
                        <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            DOS
                        </th>
                        <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Payee Info
                        </th>
                        <th style={{ textAlign: "center", fontSize: "1em", lineHeight: "1.49em" }}>Amount</th>
                        <th style={{ textAlign: "center", fontSize: "1em", lineHeight: "1.49em" }}>Check Number</th>
                        <th style={{ textAlign: "center", fontSize: "1em", lineHeight: "1.49em" }}>Status Date</th>
                        <th style={{ textAlign: "center", fontSize: "1em", lineHeight: "1.49em" }}>Status </th>
                    </tr>
                    <CheckPaymentList data={data} page={page} items={itemsPerPage} offset={offset} limit={limit} />
                </tbody>
            </table>
            <div className="pages">
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                    <div className="col-lg-4">
                        Items per page:{" "}
                        <select onChange={(e) => setitems(e.target.value)} name="">
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                    <div className="col-lg-3">
                        {data ? (data.providerreports ? (data.providerreports.length == 0 ? 0 : offset + 1) : offset + 1) : offset + 1} -{" "}
                        {data
                            ? data.providerreports
                                ? data.providerreports.length < limit
                                    ? data.providerreports.length
                                    : limit
                                : limit
                            : limit}{" "}
                        of {data ? (data.providerreports ? data.providerreports.length : "") : ""}{" "}
                    </div>
                    <div className="col-lg-5">
                        <a onClick={pageBack} href="#">
                            <span className="nav-left"></span>
                        </a>
                        <a onClick={pageForward} href="#">
                            <span className="nav-right"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
