import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';
import { RX_INSURANCE_INFORMATION } from '../graphql/queries/Patients'

export default function PharmacyInsuranceInformation(props) {

    var patient_id = parseInt(window.localStorage.getItem('xrefID'))
    if (props.patientID) {
        patient_id = parseInt(props.patientID)
    }
    const [companyname, setcompanyname] = useState('');
    const [membernumber, setmembernumber] = useState('');
    const [groupnumber, setgroupnumber] = useState('');
    const [bin, setbin] = useState('');
    const [pcn, setpcn] = useState('');

    const { loading, error, data, refetch } = useQuery(RX_INSURANCE_INFORMATION, {
        variables: { patient_id }
    });

    useEffect(() => {
        if (patient_id) {
            if (data != null) {
                if (data.patientinfo != null) {
                    if (data.patientinfo.enrollment_data != null) {
                        if (data.patientinfo.enrollment_data.commercial_insurance != null) {
                            if (data.patientinfo.enrollment_data.commercial_insurance.rx_insurance) {
                                if (data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.name) {
                                    if (data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.name.toLowerCase() == 'null') {
                                        setcompanyname('')
                                    } else {
                                        setcompanyname(data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.name)
                                    }
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.member_number) {
                                    if (data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.member_number.toLowerCase() == 'null') {
                                        setmembernumber('')
                                    } else {
                                        setmembernumber(data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.member_number)
                                    }
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.group_number) {
                                    if(data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.group_number.toLowerCase() == 'null'){
                                        setgroupnumber('')
                                    } else {
                                        setgroupnumber(data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.group_number)
                                    }
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.bin) {
                                    if(data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.bin.toLowerCase() == 'null'){
                                        setbin('')
                                    } else {
                                        setbin(data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.bin)
                                    }
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.pcn) {
                                    if(data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.pcn.toLowerCase() == 'null'){
                                        setpcn('')
                                    } else {
                                        setpcn(data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.pcn)
                                    }
                                }
                            }
                        }
                    }
                }

            }
        } else {
        }
    })


    let navigate = useNavigate()
    function updatePharmacy(e) {
        e.preventDefault()
        let path = `../update-pharmacy`
        navigate(path)
    }

    function handleChange() {

    }

    var hasInsurance = false
    if ((companyname == '') && (membernumber == '') && (groupnumber == '') && (bin == '') && (pcn == '')) {
        hasInsurance = false
    } else {
        hasInsurance = true
    }

    if (hasInsurance) {
        return (
            <div className="section shadow-box color-label">
                <div className="col-sm-12 col-md-12 col-lg-9" style={{ paddingLeft: '0' }}>
                    <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Pharmacy Insurance Information</h2>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3">
                    <form onSubmit={e => updatePharmacy(e)} encType="multipart/form-data">
                        <input onChange={handleChange} type="hidden" name="action" value="update_pharmacy" />
                        <button className="update" type="submit" value="Next">Update Pharmacy Insurance</button>
                    </form>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="col-sm-12 col-md-12 col-lg-3">
                        <p className="field required-field text">
                            <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Rx company name</label>
                            {companyname}   </p></div>
                    <div className="col-sm-12 col-md-12 col-lg-3">
                        <p className="field required-field text phone">
                            <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Rx member number</label>
                            {membernumber} </p></div>
                    <div className="col-sm-12 col-md-12 col-lg-3">
                        <p className="field required-field text phone">
                            <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Rx group number</label>
                            {groupnumber} </p></div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="col-sm-12 col-md-12 col-lg-3">
                        <p className="field required-field text phone">
                            <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Rx BIN</label>
                            {bin} </p></div>
                    <div className="col-sm-12 col-md-12 col-lg-3">
                        <p className="field required-field text phone">
                            <label htmlFor="homePhone" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Rx PCN</label>
                            {pcn} </p></div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="section shadow-box color-label">

                <div className="col-sm-12 col-md-12 col-lg-9" style={{ paddingLeft: '0' }}>
                    <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Pharmacy Insurance Information</h2>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3">
                    <form action="update-pharmacy" encType="multipart/form-data">
                        <input type="hidden" name="action" value="update_pharmacy" />
                        <button className="update" type="submit" value="Next">Add Pharmacy Insurance</button>
                    </form>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <p>No pharmacy insurance information found.</p>


                </div>


            </div>
        )
    }
}