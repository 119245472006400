import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  MY_PROFILE,
  ADMIN_MY_PROFILE,
  PROVIDER_DATA,
} from "../../graphql/queries/Providers";
import { UPDATE_PROVIDER_EMAIL } from "../../graphql/queries/Providers";

export default function UpdateProfile() {
  const provider_id = parseInt(window.localStorage.getItem("providerID"));
  const user_id = parseInt(window.localStorage.getItem("userID"));
  const credentialsID = JSON.parse(
    window.localStorage.getItem("credentialsID")
  );
  const user_type = JSON.parse(
    window.localStorage.getItem("userType")
  ).toLowerCase();

  const [username, setusername] = useState("");
  const [email, setemail] = useState("");
  const [oldEmail, setoldemail] = useState("");
  const [first_name, setfirstname] = useState("");
  const [last_name, setlastname] = useState("");
  const [last_login, setlastlogin] = useState("");
  const [password_expiration, setpasswordexpiration] = useState("");

  const [practice_name, setpracticename] = useState("");
  const [phone_number, setphonenumber] = useState("");
  const [address_1, setaddress1] = useState("");
  const [address_2, setaddress2] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [zip_code, setzipcode] = useState("");
  const [fax_opt_in, setfaxoptin] = useState("");
  const [fax1, setfax1] = useState("");
  const [fax2, setfax2] = useState("");
  const [fax3, setfax3] = useState("");
  const [user_agreement, setuseragreement] = useState("");

  const [user_updated, setupdated] = useState(false);
  const [popup, setpopup] = useState(false);

  const credentials_id = window.localStorage.getItem("credentials_id");
  const { loading, error, data, refetch } = useQuery(PROVIDER_DATA, {
    variables: { provider_id },
  });

  const { data: admindata } = useQuery(ADMIN_MY_PROFILE, {
    variables: { provider_id },
  });

  const [updateUser, { data: updatedata }] = useLazyQuery(
    UPDATE_PROVIDER_EMAIL,
    {
      variables: {
        user_id,
        user_type,
        login_id: credentialsID,
        email,
        xref_id: provider_id.toString(),
      },
    }
  );

  useEffect(() => {
    if (credentials_id == null) {
      if (data != null) {
        if (data.providerfind != null) {
          if (data.providerfind.provider_data != null) {
            if (data.providerfind.provider_data.first_name != null) {
              setfirstname(data.providerfind.provider_data.first_name);
            }
            if (data.providerfind.provider_data.last_name != null) {
              setlastname(data.providerfind.provider_data.last_name);
            }
            if (data.providerfind.provider_data.practice_name) {
              setpracticename(data.providerfind.provider_data.practice_name);
            }
            if (data.providerfind.provider_data.phone_number) {
              setphonenumber(data.providerfind.provider_data.phone_number);
            }
            if (data.providerfind.provider_data.fax_opt_in) {
              setfaxoptin(data.providerfind.provider_data.fax_opt_in);
            }
            if (data.providerfind.provider_data.address != null) {
              if (data.providerfind.provider_data.address.address_1) {
                setaddress1(data.providerfind.provider_data.address.address_1);
              }
              if (data.providerfind.provider_data.address.address_2) {
                setaddress2(data.providerfind.provider_data.address.address_2);
              }
              if (data.providerfind.provider_data.address.city) {
                setcity(data.providerfind.provider_data.address.city);
              }
              if (data.providerfind.provider_data.address.state) {
                setstate(data.providerfind.provider_data.address.state);
              }
              if (data.providerfind.provider_data.address.zip_code) {
                setzipcode(data.providerfind.provider_data.address.zip_code);
              }
            }
          }
          if (data.providerfind.credentials_id != null) {
            setusername(data.providerfind.credentials_id);
          }
          if (data.providerfind.email != null) {
            setemail(data.providerfind.email);
            setoldemail(data.providerfind.email);
          }
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (credentials_id) {
      if (admindata != null) {
        if (admindata.providerusersearch != null) {
          admindata.providerusersearch.map((x) => {
            var compare = '"' + x.credentials_id + '"';
            if (compare == credentials_id) {
              setfirstname(x.first_name);
              setlastname(x.last_name);
              setusername(x.user_name);
              setemail(x.user_name);
              if (x.user_data) {
                setemail(x.user_data.email);
              }
            }
          });
        }
      }
    }
  }, [admindata]);

  let path = `/hcp/my-profile`;
  let navigate = useNavigate();

  useEffect(() => {
    if (user_updated === true) {
      localStorage.clear();
      window.location.href =
        window.location.protocol + "//" + window.location.host + `/hcp`;
    }
  }, [updatedata]);
  function updateInfo() {
    setemail(email.toLowerCase());
    updateUser({
      user_id,
      user_type,
      login_id: credentialsID,
      xref_id: provider_id,
      email,
    });
    setupdated(true);
  }

  function save() {
    if (email !== null) {
      if (
        email !== "" &&
        email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) &&
        credentialsID
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        setpopup(true);
      }
    }
  }

  function cancel() {
    navigate(path);
  }

  function submit(e) {
    e.preventDefault();
  }

  return (
    <div className="container" id="content" style={{ fontSize: "1em" }}>
      {popup === true ? (
        <div
          id="save-popup"
          className="save-popup update-profile"
          style={{ display: "block" }}
        >
          <div className="popup-box" style={{ padding: "20px" }}>
            <p>
              If your Username is currently an email address and you update the
              Email Address field, you will immediately be logged out. Please
              log back in using the updated email address and your existing
              password.
            </p>
            <button
              id="ok"
              className="save close-button"
              type="submit"
              onClick={updateInfo}
            >
              OK
            </button>{" "}
            <button
              id="cancel"
              className="cancel close-button"
              type="reset"
              onClick={() => setpopup(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div id="container">
        <div id="hcp_post_content" className="user-profile">
          <div className="space"></div>
          <div className="section shadow-box color-label">
            <form
              encType="multipart/form-data"
              className="form-validate-create-password"
              acceptCharset="utf-8"
              method="post"
              onSubmit={(e) => submit(e)}
            >
              <div className="col-sm-12 col-md-12 col-lg-10">
                <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>
                  User Profile
                </h2>
              </div>
              <div
                className="col-sm-12 col-md-12 col-lg-2"
                style={{ textAlign: "right" }}
              >
                <button className="save" id="save" type="submit" onClick={save}>
                  Save
                </button>{" "}
                <button
                  className="cancel"
                  id="cancel"
                  type="reset"
                  onClick={cancel}
                >
                  Cancel
                </button>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p>
                    <span className="color">Username</span>
                    <br /> {username}
                  </p>
                  <p
                    className="field required-field text"
                    style={{ minHeight: "inherit" }}
                  >
                    <label
                      className="smaller"
                      style={{ fontSize: "1em", lineHeight: "1.49em" }}
                    >
                      <span className="inner-label">
                        Email address<sup>*</sup>
                      </span>
                    </label>
                    <input
                      type="text"
                      id=""
                      name="email"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </p>
                  Password reset information is sent here.
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p>
                    <span className="color">First Name</span>
                    <br />
                    {first_name}{" "}
                  </p>
                  <p>
                    <span className="color">Last Login Date</span>
                    <br />
                    {last_login}
                  </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p>
                    <span className="color">Last Name</span>
                    <br />
                    {last_name}
                  </p>
                  <p>
                    <span className="color">Password Expiration Date</span>
                    <br />
                    {password_expiration}
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div className="clearfloat"></div>
        </div>
      </div>
    </div>
  );
}

/*
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  MY_PROFILE,
  ADMIN_MY_PROFILE,
  PROVIDER_DATA,
} from "../../graphql/queries/Providers";
import { UPDATE_PROVIDER_EMAIL } from "../../graphql/queries/Providers";

export default function UpdateProfile() {
  const provider_id = parseInt(window.localStorage.getItem("providerID"));
  const user_id = parseInt(window.localStorage.getItem("userID"));
  const credentialsID = JSON.parse(
    window.localStorage.getItem("credentialsID")
  );
  const user_type = JSON.parse(
    window.localStorage.getItem("userType")
  ).toLowerCase();

  const [username, setusername] = useState("");
  const [email, setemail] = useState("");
  const [first_name, setfirstname] = useState("");
  const [last_name, setlastname] = useState("");
  const [last_login, setlastlogin] = useState("");
  const [password_expiration, setpasswordexpiration] = useState("");

  const [practice_name, setpracticename] = useState("");
  const [phone_number, setphonenumber] = useState("");
  const [address_1, setaddress1] = useState("");
  const [address_2, setaddress2] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [zip_code, setzipcode] = useState("");
  const [fax_opt_in, setfaxoptin] = useState("");
  const [fax1, setfax1] = useState("");
  const [fax2, setfax2] = useState("");
  const [fax3, setfax3] = useState("");
  const [user_agreement, setuseragreement] = useState("");

  const credentials_id = window.localStorage.getItem("credentials_id");
  const { loading, error, data, refetch } = useQuery(PROVIDER_DATA, {
    variables: { provider_id },
  });

  const { data: admindata } = useQuery(ADMIN_MY_PROFILE, {
    variables: { provider_id },
  });

  const [updateUser, { data: updatedata }] = useLazyQuery(
    UPDATE_PROVIDER_EMAIL,
    {
      variables: {
        user_id,
        user_type,
        login_id: credentialsID,
        xref_id: provider_id,
        email,
      },
    }
  );

  useEffect(() => {
    if (credentials_id == null) {
      if (data != null) {
        if (data.providerfind != null) {
          if (data.providerfind.provider_data != null) {
            if (data.providerfind.provider_data.first_name != null) {
              setfirstname(data.providerfind.provider_data.first_name);
            }
            if (data.providerfind.provider_data.last_name != null) {
              setlastname(data.providerfind.provider_data.last_name);
            }
            if (data.providerfind.provider_data.practice_name) {
              setpracticename(data.providerfind.provider_data.practice_name);
            }
            if (data.providerfind.provider_data.phone_number) {
              setphonenumber(data.providerfind.provider_data.phone_number);
            }
            if (data.providerfind.provider_data.fax_opt_in) {
              setfaxoptin(data.providerfind.provider_data.fax_opt_in);
            }
            if (data.providerfind.provider_data.address != null) {
              if (data.providerfind.provider_data.address.address_1) {
                setaddress1(data.providerfind.provider_data.address.address_1);
              }
              if (data.providerfind.provider_data.address.address_2) {
                setaddress2(data.providerfind.provider_data.address.address_2);
              }
              if (data.providerfind.provider_data.address.city) {
                setcity(data.providerfind.provider_data.address.city);
              }
              if (data.providerfind.provider_data.address.state) {
                setstate(data.providerfind.provider_data.address.state);
              }
              if (data.providerfind.provider_data.address.zip_code) {
                setzipcode(data.providerfind.provider_data.address.zip_code);
              }
            }
          }
          if (data.providerfind.credentials_id != null) {
            setusername(data.providerfind.credentials_id);
          }
          if (data.providerfind.email != null) {
            setemail(data.providerfind.email);
          }
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (credentials_id) {
      if (admindata != null) {
        if (admindata.providerusersearch != null) {
          admindata.providerusersearch.map((x) => {
            var compare = '"' + x.credentials_id + '"';
            if (compare == credentials_id) {
              setfirstname(x.first_name);
              setlastname(x.last_name);
              setusername(x.user_name);
              setemail(x.user_name);
              if (x.user_data) {
                setemail(x.user_data.email);
              }
            }
          });
        }
      }
    }
  }, [admindata]);

  let path = `/hcp/my-profile`;
  let navigate = useNavigate();

  function updateInfo() {
    updateUser({
      user_id,
      user_type,
      login_id: credentialsID,
      xref_id: provider_id,
      email,
    });
    window.localStorage.setItem("updateEmail", true);
    // navigate(path)
  }

  function cancel() {
    navigate(path);
  }

  function submit(e) {
    e.preventDefault();
  }

  return (
    <div className="container" id="content" style={{ fontSize: "1em" }}>
      <div id="container">
        <div id="hcp_post_content" className="user-profile">
          <div className="space"></div>
          <div className="section shadow-box color-label">
            <form
              encType="multipart/form-data"
              className="form-validate-create-password"
              acceptCharset="utf-8"
              method="post"
              onSubmit={(e) => submit(e)}
            >
              <div className="col-sm-12 col-md-12 col-lg-10">
                <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>
                  User Profile
                </h2>
              </div>
              <div
                className="col-sm-12 col-md-12 col-lg-2"
                style={{ textAlign: "right" }}
              >
                <button
                  className="save"
                  id="save"
                  type="submit"
                  onClick={updateInfo}
                >
                  Save
                </button>{" "}
                <button
                  className="cancel"
                  id="cancel"
                  type="reset"
                  onClick={cancel}
                >
                  Cancel
                </button>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p>
                    <span className="color">Username</span>
                    <br /> {username}
                  </p>
                  <p
                    className="field required-field text"
                    style={{ minHeight: "inherit" }}
                  >
                    <label
                      className="smaller"
                      style={{ fontSize: "1em", lineHeight: "1.49em" }}
                    >
                      <span className="inner-label">
                        Email address<sup>*</sup>
                      </span>
                    </label>
                    <input
                      type="text"
                      id=""
                      name="email"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </p>
                  Password reset information is sent here.
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p>
                    <span className="color">First Name</span>
                    <br />
                    {first_name}{" "}
                  </p>
                  <p>
                    <span className="color">Last Login Date</span>
                    <br />
                    {last_login}
                  </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <p>
                    <span className="color">Last Name</span>
                    <br />
                    {last_name}
                  </p>
                  <p>
                    <span className="color">Password Expiration Date</span>
                    <br />
                    {password_expiration}
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div className="clearfloat"></div>
        </div>
      </div>
    </div>
  );
}
*/
