import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useLazyQuery, gql } from "@apollo/client";
import { MEMBER_ID } from "../graphql/queries/Patients";
import $ from "jquery";
import "../upload/js/fileinput.js";
export default function DocumentSubmission(props) {
    const cancelpage = props.cancel;
    const [docSubmitted, setstatus] = useState(false);
    const [tooManyFiles, setTooManyFiles] = useState(false);
    const [fileTooLarge, setFileTooLarge] = useState(false);
    const [extensionNotSupported, setExtensionNotSupported] = useState(false);

    const [doc_type, setdoctype] = useState("EOB");
    const [program, setprogram] = useState("XOLAIR");
    const [member_id, setmemberid] = useState("");
    const [doc_id, setdocid] = useState([]);

    const patient_id = parseInt(window.localStorage.getItem("xrefID"));
    const { loading, error, data } = useQuery(MEMBER_ID, {
        variables: { patient_id },
    });

    useEffect(() => {
        $("#input-ficons-6").fileinput({
            theme: "fas",
            uploadUrl: "file-upload.js",
            uploadAsync: false,
            showRemove: false,
            showUpload: false,
            previewFileIcon: '<i class="fas fa-file"></i>',
            browseIcon: '<i class="fas fa-folder-open"></i>',
            browseClass: "btn btn-primary",
            removeIcon: '<i class="fas fa-trash-alt"></i>',
            cancelIcon: '<i class="fas fa-ban"></i>',
            pauseIcon: '<i class="fas fa-pause"></i>',
            uploadIcon: '<i class="fas fa-upload"></i>',
            preferIconicZoomPreview: true,
            previewFileIconSettings: {
                jpg: '<i class="fas fa-file-image text-danger"></i>',
                gif: '<i class="fas fa-file-image text-warning"></i>',
                png: '<i class="fas fa-file-image text-primary"></i>',
            },
            layoutTemplates: {
                fileIcon: '<i class="fas fa-file kv-caption-icon"></i> ',
            },
            dropZoneTitle: "",
            previewZoomButtonIcons: {
                prev: '<i class="fas fa-caret-left fa-lg"></i>',
                next: '<i class="fas fa-caret-right fa-lg"></i>',
                toggleheader: '<i class="fas fa-fw fa-arrows-alt-v"></i>',
                fullscreen: '<i class="fas fa-fw fa-arrows-alt"></i>',
                borderless: '<i class="fas fa-fw fa-external-link-alt"></i>',
                close: '<i class="fas fa-fw fa-times"></i>',
            },
            fileActionSettings: {
                removeIcon: '<i class="fas fa-trash-alt"></i>',
                uploadIcon: false,
                showUpload: false,
                uploadRetryIcon: '<i class="fas fa-redo-alt"></i>',
                downloadIcon: '<i class="fas fa-download"></i>',
                zoomIcon: '<i class="fas fa-search-plus"></i>',
                dragIcon: '<i class="fas fa-arrows-alt"></i>',
                indicatorNew: '<i class="fas fa-plus-circle text-warning"></i>',
                indicatorSuccess: '<i class="fas fa-check-circle text-success"></i>',
                indicatorError: '<i class="fas fa-exclamation-circle text-danger"></i>',
                indicatorLoading: '<i class="fas fa-hourglass text-muted"></i>',
                indicatorPaused: '<i class="fa fa-pause text-info"></i>',
            },
        });

        $("#input-ficons-6").fileinput("enable");

        $(function () {
            $("#input-ficons-6").on("click", function () {
                var $fileUpload = $("#input-ficons-6");
                if (parseInt($fileUpload.get(0).files.length) > 5) {
                    alert("You are only allowed to upload a maximum of 5 files");
                }
            });
        });
    }, []);

    useEffect(() => {
        updateDocID(doc_id);
    }, [doc_id.length]);

    function updateDocID(docid) {
        setdocid(docid);
    }

    useEffect(() => {
        if (data) {
            if (data.patientinfo != null) {
                if (data.patientinfo.member_id) {
                    setmemberid(data.patientinfo.member_id);
                }
            }
        }
    }, [data]);

    let navigate = useNavigate();
    function cancelBtn() {
        let path = `../` + cancelpage;
        navigate(path);
    }

    const handleFileInput = (e) => {};

    function timeout(delay) {
        return new Promise((res) => setTimeout(res, delay));
    }

    var filestack;
    var fstack;
    async function submitDoc(e) {
        e.preventDefault();

        (filestack = $("#input-ficons-6").fileinput("getFileStack")), (fstack = []);
        $.each(filestack, function (fileId, fileObj) {
            if (fileObj !== undefined) {
                fstack.push(fileObj);
            }
        });

        if (doc_type != "") {
            if (fstack.length > 0) {
                await fileUpload();
            } else {
            }
        } else {
        }
    }

    async function fileUpload() {
        setTooManyFiles(false);
        setstatus(false);
        const arr = [];
        setdocid(arr);
        const bucket = "";
        if (fstack.length > 5) {
            setTooManyFiles(true);
        } else {
            $("#submitbtn").attr("disabled", true);
            for (let index = 0; index < fstack.length && index < 5; index++) {
                var extension = fstack[index].file.name.substr(fstack[index].file.name.lastIndexOf("."), fstack[index].file.name.length);
                console.log(extension);
                if (
                    fstack[index].file.type == "application/pdf" ||
                    fstack[index].file.type == "image/jpeg" ||
                    fstack[index].file.type == "image/png" ||
                    fstack[index].file.type == "image/tiff"
                ) {
                    setExtensionNotSupported(false);
                    if (fstack[index].file.size < 5242880) {
                        setFileTooLarge(false);
                        const requestOptions = {
                            method: "POST",
                            body: JSON.stringify({
                                bucketName: bucket,
                                memberID: member_id,
                                patient_id: patient_id,
                                docType: doc_type,
                                program: program,
                                file_type: fstack[index].file.type,
                                file_extension: extension,
                            }),
                        };
                        await timeout(1000);
                        await fetch("https://w8efuoxgaj.execute-api.us-east-1.amazonaws.com/xolair/upload", requestOptions)
                            .then((response) => {
                                if (response.ok) {
                                    return response.json();
                                }
                                throw new Error("Something went wrong");
                            })
                            .then((responseJson) => {
                                console.log(responseJson);
                                var arr = doc_id;
                                arr.push({
                                    value: responseJson.document_id,
                                });
                                setdocid(arr);
                                uploadFile(responseJson.url, index);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } else {
                        setFileTooLarge(true);
                    }
                } else {
                    setExtensionNotSupported(true);
                }
            }
        }
    }
    async function uploadFile(url, index) {
        console.log(fstack[index].file);
        var file = fstack[index].file;
        await timeout(1000);
        const requestOptions = {
            method: "PUT",
            body: file,
        };
        await fetch(url, requestOptions)
            .then((response) => {
                if (response.ok) {
                    console.log(response);
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                console.log(responseJson);
            })
            .catch((error) => {
                console.log(error);
            });

        if (fstack.length == index + 1) {
            console.log(doc_id);
            setstatus(true);
            $("#input-ficons-6").fileinput("clear");
            $("#submitbtn").attr("disabled", false);
        } else {
            setstatus(false);
            console.log(fstack.length);
            console.log(index);
            console.log("fail");
        }
    }

    return (
        <div className="section shadow-box color-label">
            <div className="col-sm-12 col-md-12 col-lg-12">
                <h2>Document</h2>
                <div>
                    <p>The requirements for reimbursement must include:</p>
                    <ul className="mb-4" style={{ marginLeft: "40px" }}>
                        <li>Name of health insurance company</li>
                        <li>Facility name</li>
                        <li>Patient's name</li>
                        <li>Date of service (DOS)</li>
                        <li>Name of drug or J-code</li>
                        <li>Amount billed, amount allowed, patient responsibility, amount paid to provider</li>
                    </ul>
                    If the Explanation of Benefits (EOB) does not include any of the above items, additional documentation may be required to process the claim.
                </div>
                <p>All fields are mandatory unless otherwise indicated.</p>
                <form encType="multipart/form-data" acceptCharset="utf-8" onSubmit={(e) => submitDoc(e)}>
                    <p>
                        <span className="color">Please select the product to upload a document for:</span>{" "}
                    </p>
                    <div className="col-sm-12 col-md-12 col-lg-3">
                        <p>
                            <label style={{ color: "#888" }}>Product</label>
                            <br />
                            <span className="select_container">
                                <select name="product" defaultValue="XR" onChange={(e) => setprogram(e.target.value)}>
                                    <option value="XR">XOLAIR</option>
                                    <option value="XRA">XOLAIR ADMINISTRATION</option>
                                </select>
                            </span>
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <section>
                            <div className="submitDocument">
                                <div className="color info">
                                    <h2>Information</h2>
                                    <p>
                                        You can upload a maximum of 5 files at one time (max size per file is 5MB).
                                        <br /> Multiple pages are combined into one EOB, etc.
                                        <br />
                                        Allowed file types: jpg/jpeg, png, pdf.
                                    </p>
                                </div>
                                <div className="file-loading">
                                    <input id="input-ficons-6" name="input-ficons-6[]" multiple type="file" data-theme="fas" onChange={handleFileInput} />
                                </div>
                                <p>
                                    <label>&nbsp;</label>
                                    <button id="submitbtn" type="submit" value="" name="find" className="fltrt noTopSpace">
                                        Submit
                                    </button>{" "}
                                    <button type="reset" value="" name="find" className="cancel" onClick={cancelBtn}>
                                        Cancel
                                    </button>
                                </p>
                            </div>
                            {tooManyFiles ? <p>Maximum of 5 files at one time</p> : <></>}
                            {fileTooLarge ? <p>Max size of 5MB per file</p> : <></>}
                            {extensionNotSupported ? <p>Allowed file types: jpg/jpeg, png, pdf, tiff</p> : <></>}
                            {docSubmitted == true ? DocumentID(doc_id) : <></>}
                        </section>
                    </div>
                </form>
            </div>
        </div>
    );
}

function DocumentID(doc_id) {
    return <>{doc_id && doc_id.map((document) => <p key={doc_id}>Document ID {document.value} submitted</p>)}</>;
}

/*
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery, useLazyQuery, gql } from '@apollo/client';
import $ from 'jquery'
import { MEMBER_ID } from '../graphql/queries/Patients'
//import '../upload/js/fileinput.js'
export default function DocumentSubmission(props) {

  const cancelpage = props.cancel
  const [docSubmitted, setstatus] = useState(false)

  const [doc_type, setdoctype] = useState('EOB')
  const [program, setprogram] = useState('XOLAIR')
  const [member_id, setmemberid] = useState('')
  const [doc_id, setdocid] = useState('')
  const [file_type, setfiletype] = useState('')
  const [file_size, setfilesize] = useState('')
  const [file_extension, setfileextenstion] = useState('')


  const patient_id = parseInt(window.localStorage.getItem('xrefID'))
  const { loading, error, data } = useQuery(MEMBER_ID, {
    variables: { patient_id }
  });

   useEffect(() => {
    if (data) {
      if(data.patientinfo != null){
        if (data.patientinfo.member_id) {
          setmemberid(data.patientinfo.member_id)
        }
      }
      
    }
  }, [data])


  let navigate = useNavigate();
  function cancelBtn() {
    let path = `../` + cancelpage;
    navigate(path);
  }
  const [selectedFile, setSelectedFile] = useState(null);
  const [file_name, setfilename] = useState('');

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    setfilename(e.target.files[0].name)
    setfiletype(e.target.files[0].type)
    setfilesize(e.target.files[0].size)
    setfileextenstion(e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.'),e.target.files[0].name.length))
  }


  function submitDoc() {

    const bucket = 'xolair-files'
    const folder = ''



    if(doc_type != ''){
      if(selectedFile){
        if((file_type == 'application/pdf') || (file_type == 'image/jpeg') || (file_type == 'image/png') || (file_type == 'image/tiff')){
          if (file_size < 5242880) {
            const requestOptions = {
              method: 'POST',
              body: JSON.stringify({ bucketName: bucket, memberID: member_id, patient_id: patient_id, docType: doc_type, program: program, file_type: file_type, file_extension: file_extension})
            };
            fetch('https://w8efuoxgaj.execute-api.us-east-1.amazonaws.com/xolair/upload', requestOptions)
              .then((response) => {
                if (response.ok) {
                  return response.json();
                }
                throw new Error('Something went wrong');
              })
              .then((responseJson) => {
                console.log(responseJson);
                setdocid(responseJson.document_id)
                uploadFile(responseJson.url);
              })
              .catch((error) => {
                console.log(error)
              })
        
          }  else {
            console.log('file too large')
          }
        } else {
          console.log('file type not supported')
        }
        
      } else {
        console.log('no file selected')
      }
    } else {
      console.log('select document type')
    }

  }

  function uploadFile(url) {
    $("#submitbtn").attr("disabled", true);
    const requestOptions = {
      method: 'PUT',
      body: document.getElementById("input-ficons-6").files[0]
    };
    fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          setstatus(true)
          $("#submitbtn").attr("disabled", false);
          document.getElementById("input-ficons-6").value = null;
          return response.json();
        }
        throw new Error('Something went wrong');
      })
      .then((responseJson) => {
        console.log(responseJson)
      })
      .catch((error) => {
        console.log(error)
      })

  }

  return (
    <div className="section shadow-box color-label">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <h2>Document</h2>
        <div><p>The requirements for reimbursement must include:</p><ul className="mb-4" style={{ marginLeft: '40px' }}><li>Name of health insurance company</li><li>Facility name</li><li>Patient's name</li><li>Date of service (DOS)</li><li>Name of drug or J-code</li><li>Amount billed, amount allowed, patient responsibility, amount paid to provider</li></ul>If the Explanation of Benefits (EOB) does not include any of the above items, additional documentation may be required to process the claim.</div>
        <p>All fields are mandatory unless otherwise indicated.</p>
        <div encType="multipart/form-data" acceptCharset="utf-8" >
          <p><span className="color">Please select the product to upload a document for:</span> </p>
          <div className="col-sm-12 col-md-12 col-lg-3">
            <p><label style={{ color: '#888' }}>Product</label><br /><span className="select_container">
              <select name="product"  defaultValue="XR" onChange={e => setprogram(e.target.value)}>
                <option value="XR" >XOLAIR</option>
                <option value="XRA" >XOLAIR ADMINISTRATION</option>
              </select>
            </span></p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12">
            <section>
              <div className="submitDocument">
                <div className="color info">
                  <h2>Information</h2>
                  <p>You can upload a maximum of 5 files at one time  (max size per file is 5MB).<br /> Multiple pages are combined into one EOB, etc.<br />
                    Allowed file types: jpg/jpeg, png, pdf.
                  </p>
                </div>
                <div className="file-loading">
                  <input id="input-ficons-6" name="input-ficons-6[]" multiple type="file" data-theme="fas" onChange={handleFileInput} />
                </div>
                <p>
                  <label>&nbsp;</label>
                  <button id="submitbtn" onClick={submitDoc} value="" name="find" className="fltrt noTopSpace">Submit</button> <button type="reset" value="" name="find" className="cancel" onClick={cancelBtn} >Cancel</button>
                </p>
              </div>
              {docSubmitted ? (<p>Document ID {doc_id} submitted</p>) : <></>}
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}
*/
