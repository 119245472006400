import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useLazyQuery, gql } from '@apollo/client';
import { GET_PATIENT_ID } from '../graphql/queries/Patients'
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import callBtn from '../images/calbtn.gif'


export default function CreateAccount() {

    const [inputfirst_name, setinputfirstname] = useState('')
    const [inputlast_name, setinputlastname] = useState('')
    const [inputmember_id, setinputmemberid] = useState('')
    const [first_name, setfirstname] = useState('')
    const [last_name, setlastname] = useState('')
    const [member_id, setmemberid] = useState('')
    const [dob, setdob] = useState('')

    const [validFirstName, setvalidfirstname] = useState(true)
    const [validLastName, setvalidlastname] = useState(true)
    const [validMemberID, setvalidmemberid] = useState(true)
    const [validDOB, setvaliddob] = useState(true)

    let navigate = useNavigate()

    const [findPatient, { called, loading, data }] = useLazyQuery(GET_PATIENT_ID, {
        variables: { member_id, dob, first_name, last_name }
    });

    useEffect(() => {
        if (data) {
            console.log(data)
            if (data.patientfindmember != null) {
                if (data.patientfindmember[0] != null) {
                    if (data.patientfindmember[0].patient_id) {
                        window.localStorage.setItem('patientID', data.patientfindmember[0].patient_id)
                        window.localStorage.setItem('memberID', member_id)
                        let path = '../create-password'
                        navigate(path)
                    } else {

                    }
                }

            } else {
            }
        }
    }, [data])


    function createAccount(e) {
        e.preventDefault()
        if (inputfirst_name == '') {
            setvalidfirstname(false)
        } else {
            setvalidfirstname(true)
        }
        if (inputlast_name == '') {
            setvalidlastname(false)
        } else {
            setvalidlastname(true)
        }
        if (inputmember_id == '') {
            setvalidmemberid(false)
        } else {
            setvalidmemberid(true)
        }
        var date = $(".datepicker").val()
        if (date == '') {
            setvaliddob(false)
        } else {
            setvaliddob(true)
        }
        if (inputfirst_name != '' && inputlast_name != '' && inputmember_id != '') {
            var date = $(".datepicker").val()
            var month = date.substring(0, 2)
            var day = date.substring(3, 5)
            var year = date.substring(6, 10)
            if (date != '') {
                setdob(year + "-" + month + "-" + day)
                setfirstname(inputfirst_name)
                setlastname(inputlast_name)
                setmemberid(inputmember_id)
                findPatient()
            }

        }

    }

    useEffect(() => {
        $(function () {
            $(".datepicker").datepicker({
                dateFormat: 'mm/dd/yy', showButtonPanel: true,
                changeMonth: true,
                changeYear: true,
                yearRange: "1920:+nn",
                showOtherMonths: true,
                selectOtherMonths: true
            });
        });

    }, [])

    return (
        <div className="container" id="content" style={{ fontSize: '1em' }}>
            <div id="container" className="account">
                <h1 style={{ fontSize: '1.5em', lineHeight: '1.49em' }}>Create an Account</h1>
                <p className="required">*Required field.</p>
                <div className="find_patient">
                    <form onSubmit={e => createAccount(e)} className="form-validate-create-password" acceptCharset="utf-8">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <p className="field required-field text">
                                    <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">First name<span className="required">*</span></span></label>
                                    {validFirstName ? <input onChange={e => setinputfirstname(e.target.value)} value={inputfirst_name} type="text" id="firstName" name="first_name" /> : <><input onChange={e => setinputfirstname(e.target.value)} value={inputfirst_name} type="text" id="firstName" name="first_name" style={{ border: '1px solid red' }} /><span className="prompt-error error">Please enter First name</span></>}
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <p className="field required-field text">
                                    <label htmlFor="lastName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Last name<span className="required">*</span></span></label>
                                    {validLastName ? <input onChange={e => setinputlastname(e.target.value)} value={inputlast_name} type="text" id="lastName" name="last_name" /> : <><input onChange={e => setinputlastname(e.target.value)} value={inputlast_name} type="text" id="lastName" name="last_name" style={{ border: '1px solid red' }} /><span className="prompt-error error">Please enter Last name</span></>}
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <p className="field required-field text">
                                    <label htmlFor="memberid" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Member ID<span className="required">*</span></span></label>
                                    {validMemberID ? <input onChange={e => setinputmemberid(e.target.value)} value={inputmember_id} type="text" id="memberid" name="member_id" /> : <><input onChange={e => setinputmemberid(e.target.value)} value={inputmember_id} type="text" id="memberid" name="member_id" style={{ border: '1px solid red' }} /><span className="prompt-error error">Please enter Member ID</span></>}
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <p className="field required-field text date">
                                    <label htmlFor="birthDate" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Birth date<span className="required">*</span></span></label>
                                    {validDOB ? <input className="datepicker" type="text" name="birth_date" size="10" /> : <><input className="datepicker" type="text" name="birth_date" size="10" style={{ border: '1px solid red' }} /><span className="prompt-error error">Please enter Birth date</span></>}<img className="ui-datepicker-trigger" src={callBtn} alt="..." title="..." />
                                </p>
                            </div>
                        </div>
                        <p><button type='submit' name="">Submit</button></p>
                    </form>
                </div>
                <p>
                    <br />
                </p>
                <p>If you don't know your Member ID number, please call <span><strong>(855) 965-2472</strong></span> for assistance.</p>
            </div>
        </div>
    )
}