import React, { useEffect, useState } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client';
import { CHANGE_PASSWORD, PATIENT_INFO } from '../graphql/queries/Patients'
import {useNavigate} from 'react-router-dom'

export default function ChangePassword() {

  const patient_id  = parseInt(window.localStorage.getItem('xrefID'))

  const [password, setpassword] = useState('')
  const [confirmpassword, setconfirmpassword] = useState('')

  const [login_id, setloginid] = useState('')
  const [password_hash, setpasswordhash] = useState('')

  const [member_id, setmemberid] = useState('')
  const new_password_hash = ''

  const [first_name, setfirstname] = useState('')
  const [last_name, setlastname] = useState('')

  let navigate = useNavigate()
	let path = '../my-profile'

  const { data: memberdata } = useQuery(PATIENT_INFO, {
    variables: { patient_id }
  });

  useEffect(() => {
    if(memberdata){
      if(memberdata.patientinfo != null){
        if(memberdata.patientinfo.member_id){
          setmemberid(memberdata.patientinfo.member_id)
        }
        if(memberdata.patientinfo.enrollment_data != null){
          if(memberdata.patientinfo.enrollment_data.first_name){
            setfirstname(memberdata.patientinfo.enrollment_data.first_name)
          }
          if(memberdata.patientinfo.enrollment_data.last_name){
            setlastname(memberdata.patientinfo.enrollment_data.last_name)
          }
        }
      }
    }
  }, [memberdata])



  const [changePassword, { data }] = useLazyQuery(CHANGE_PASSWORD, {
    variables: { member_id, new_password_hash }
  });

  useEffect(() => {
		if(data != null){
			if(data.patientpassword != null){
				if(data.patientpassword.result){
					if(data.patientpassword.result == 'true'){
						navigate(path)
					}
				}
			}
		}
	}, [data])


  


  function createPassword(e) {
    e.preventDefault()
    if(password_hash != ''){
      changePassword({variables:{ member_id, new_password_hash: password_hash }})
    }
    

  }
  return (

    <div className="container" id="content" style={{fontSize: '1em'}}>
        <div id="container">
          <div className="space"></div>
          <div className="section shadow-box  color-label">
            <h2 style={{fontSize: '1.25em' , lineHeight: '1.49em'}}>Change Password for {first_name} {last_name} </h2>
            <p>Your new password must be</p>
            <ul _ngcontent-arf-c194="" className="cp-list"><li _ngcontent-arf-c194="">At least 12 characters</li><li _ngcontent-arf-c194="">At least 1 capital letter</li><li _ngcontent-arf-c194="">At least 1 lowercase letter</li><li _ngcontent-arf-c194="">At least 1 number</li><li _ngcontent-arf-c194="">At least 1 special character (e.g., !@#$%^&amp;*()+|~-=`{}[]:";'&lt;&gt;?,./)</li><li _ngcontent-arf-c194="">Cannot reuse the last 5 passwords</li></ul>
            <div className="find_patient">
            <form onSubmit={e => createPassword(e)} className="form-validate-password" acceptCharset="utf-8">
            <div className="col-sm-12 col-md-12 col-lg-4"> <p className="field required-field password">
            <label style={{fontSize: '1em' , lineHeight: '1.49em'}}><span className='inner-label'>Current Password<span className="required">*</span></span></label>
            <input onChange={e => setpassword(e.target.value)} type="password" name="" id="pass" autoComplete="off" />
            </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
            <p className="field required-field password">
            <label style={{fontSize: '1em' , lineHeight: '1.49em'}}><span className='inner-label'>New Password<span className="required">*</span></span></label>
            <input onChange={e => setpasswordhash(e.target.value)} type="password" name="" id="pass_2" autoComplete="off" />
            </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
            <p className="field required-field password password-retype">
            <label style={{fontSize: '1em' , lineHeight: '1.49em'}}><span className='inner-label'>Confirm New Password<span className="required">*</span></span></label>
            <input type="password" name="" id="pass_3" autoComplete="off" />
            </p>
            </div>
            <p><br/>
            <button type='submit'  name="loginSubmit" autoComplete="off">Update</button>
            </p>
            </form>   

            </div>
          </div>
          <div className="clearfloat"></div>
        </div>
      </div>



  )
}

