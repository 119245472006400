import React, {useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'
import $ from "jquery";

export default function NavBar() {
	$(function () {
        // $(".responsive-button").on("click", function (event) {
        //     event.preventDefault();
        //     $(this).toggleClass("active");
        //     $("#header ul.nav").slideToggle();
        // });
        $(document).ready(function () {
            $(".responsive-button").off("click").on("click", function (event) {
                event.preventDefault();
                $(this).toggleClass("active");
                let navMenu = $("#header ul.nav");
                if (navMenu.is(":visible")) {
                    navMenu.stop(true, true).slideUp();
                } else {
                    navMenu.stop(true, true).slideDown();
                }
            });
        });
        $(".responsive-button-submenu").on("click", function (event) {
            event.preventDefault();
            $(this).toggleClass("active");
            $("#navsubmenu").slideToggle();
        });
    });
    const pathname = window.location.pathname
    const [programDetails , setProgramDetails] = useState(false)
    const loggedIn = window.localStorage.getItem("xrefID")
    var myAccount = "login"
    if(loggedIn){
        myAccount = "profile"
    } else {
        myAccount = "login"
    }
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);
	const toggleSubMenu = () => {
	  setSubMenuOpen(!isSubMenuOpen);
	};
    return (
        <div id="header">
            <div className="container" id="header-patients">
                <div className="responsive-button hidden-sm hidden-md hidden-lg"><i className="fa fa-bars" aria-hidden="true"></i><span>MENU</span></div>
                <ul className="nav clearfix" id="nav" style={{marginTop:'2px'}}>
                    <li ><NavLink className={(navData) => navData.isActive ? "selected" : ""} to="/" >Home</NavLink></li>
                    <li><NavLink to="enroll" className={(navData) => (pathname == "/enroll" || pathname == "/enroll-attestation-patient" || pathname == "/enroll-attestation-lap" || pathname == "/eligibility-patient-infusion-only" || pathname == "/enroll-additional-patient" || pathname == "/enroll-additional-lap" || pathname == "/eligibility-patient-drug-only" || pathname == "/eligibility-error" || pathname == "/eligibility-error-drug" || pathname == "/eligibility-lap-infusion-only" || pathname == "/eligibility-error-infusion" || pathname == "/enroll-doctor-information" || pathname == "/enroll-doctor-information-search-provider" || pathname == "/enroll-doctor-information-lap" || pathname == "/enroll-doctor-information-search-provider-lap" || pathname == "/enroll-doctor-information-search-care" || pathname == "/enroll-doctor-information-search-care-lap" || pathname == "/enroll-form" || pathname == "/enroll-form-review" || pathname == "/enroll-form-lap" || pathname == "/enroll-form-review-lap"  || pathname == "/enroll-success") ? "selected" : ""}  >Enroll</NavLink></li>
                    <li>
                        <NavLink className={(navData) => (pathname == "/login" || pathname == "/my-account" || pathname == "/profile" || pathname == "/update-profile" || pathname == "/update-insurance" || pathname == "/update-pharmacy" || pathname == "/submit-document" || pathname == "/doctor-information" || pathname == "/messages") ? "selected" : ""} to={myAccount} >My Account</NavLink>
                    </li>
                    <li className="has-child "><NavLink className={(navData) => (pathname == "/eligibility" || pathname == "/using-the-card" || pathname == "/terms-and-conditions") ? "parent selected" : "parent"} onClick={toggleSubMenu} >Program Details <i className={`arrow down ${isSubMenuOpen ? "open" : ""}`}></i></NavLink>
                    {isSubMenuOpen && (
                        <ul className="submenuitems">
                            <li><NavLink to="eligibility" >Offering/Eligibility</NavLink></li>
                            <li><NavLink to="using-the-card" >Using the Program</NavLink></li>
                            <li><NavLink to="terms-and-conditions">Terms and Conditions</NavLink></li>
                        </ul>
                    )}
                    </li>
                    <li><NavLink className={(navData) => navData.isActive ? "selected" : ""} to="forms">Forms</NavLink></li>
                </ul>
            </div>
        </div>
    )
}