import { gql } from "@apollo/client";

export const PATIENT_LIST = gql`
    query Patientsearch($first_name: String, $last_name: String, $provider_id: Int!, $member_id: String, $date_of_birth: String) {
        providerpatient(
            first_name: $first_name
            last_name: $last_name
            provider_id: $provider_id
            member_id: $member_id
            date_of_birth: $date_of_birth
        ) {
            patient_id
            member_id
            enrollment_status
            current_enrollment_date_drug
            current_enrollment_date_drug_admin
            original_enrollment_date_drug
            original_enrollment_date_drug_admin
            drug_patient
            drug_admin_patient
            enrollment_data {
                date_of_birth
                first_name
                last_name
                address {
                    address_1
                    city
                    state
                    zip_code
                    address_2
                }
            }
        }
    }
`;

export const PROVIDER_NAME = gql`
    query Providerfind($provider_id: Int!) {
        providerfind(provider_id: $provider_id) {
            provider_data {
                first_name
                last_name
            }
            credentials_id
        }
    }
`;

export const ADMIN_PROVIDER_NAME = gql`
    query Providerusersearch($provider_id: Int!) {
        providerusersearch(provider_id: $provider_id) {
            credentials_id
            first_name
            last_name
        }
    }
`;

export const ALLERGY_PARTNERS_PROFILE_SETTINGS = gql`
    query Providerfind($provider_id: Int!) {
        providerfind(provider_id: $provider_id) {
            provider_npi
            email
            provider_data {
                first_name
                last_name
                practice_name
                specialty
                address {
                    address_1
                    address_2
                    city
                    state
                    zip_code
                }
                fax_numbers {
                    fax_number
                    fax_number_2
                }
                opt_in_fax_numbers {
                    opt_in
                    fax1
                    fax2
                    fax3
                }
                phone_number
            }
        }
    }
`;

export const PAYMENT_REPORTS = gql`
    query Providerreports(
        $provider_id: Int!
        $payment_type: String!
        $from_date_of_service: String
        $to_date_of_service: String
        $from_paid_date: String
        $to_paid_date: String
        $from_requested_date: String
        $to_requested_date: String
    ) {
        providerreports(
            provider_id: $provider_id
            payment_type: $payment_type
            from_date_of_service: $from_date_of_service
            to_date_of_service: $to_date_of_service
            from_paid_date: $from_paid_date
            to_paid_date: $to_paid_date
            from_requested_date: $from_requested_date
            to_requested_date: $to_requested_date
        ) {
            claim_id
            patient_id
            claim_data {
                member_id
                paid_date
                approved_date
                requested_date
                check_num
                program_benefit
                trace_id
                providers {
                    provider {
                        first_name
                        last_name
                        address {
                            address_1
                            address_2
                            address1
                            address2
                            city
                            state
                            zip_code
                        }
                    }
                }
                patient_data {
                    enrollment_data {
                        dob
                        first_name
                        last_name
                    }
                }
                shipping_info {
                    address {
                        address_1
                        address_2
                        city
                        state
                        zip_code
                    }
                }
            }
            payment_status
            date_of_service
            claim_status
            first_name
            last_name
            program
            date_of_birth
            member_id
        }
    }
`;

export const MANAGE_USERS_LIST = gql`
    query Providerusersearch($provider_id: Int!, $first_name: String, $last_name: String) {
        providerusersearch(provider_id: $provider_id, first_name: $first_name, last_name: $last_name) {
            id
            user_name
            provider_id
            credentials_id
            first_name
            last_name
            user_status
            createdAt
            user_data {
                email
            }
        }
    }
`;

export const EDIT_OFFICE_USER = gql`
    query Provadminedit($provider_id: Int!, $id: String!, $email: String!, $user_status: String!) {
        provadminedit(provider_id: $provider_id, id: $id, user_status: $user_status, email: $email) {
            message
        }
    }
`;

export const MY_PROFILE = gql`
    query Providerfind($provider_id: Int!) {
        providerfind(provider_id: $provider_id) {
            provider_data {
                first_name
                last_name
            }
            credentials_id
            email
        }
    }
`;

export const ADMIN_MY_PROFILE = gql`
    query Providerusersearch($provider_id: Int!) {
        providerusersearch(provider_id: $provider_id) {
            id
            user_name
            provider_id
            credentials_id
            first_name
            last_name
            user_status
            createdAt
            message
            user_data {
                email
            }
        }
    }
`;

export const SEARCH_PROVIDER = gql`
    query Providerfind($provider_id: Int!) {
        providerfind(provider_id: $provider_id) {
            provider_data {
                first_name
                last_name
                address {
                    address_1
                    address_2
                    city
                    state
                    zip_code
                }
                phone_number
            }
            provider_npi
        }
    }
`;

export const SEARCH_SITE_OF_CARE = gql`
    query Providersearch($soc_practice_name: String!, $soc_state: String!) {
        providersearch(practice_name: $soc_practice_name, state: $soc_state) {
            provider_id
        }
    }
`;

export const FIND_SITE_OF_CARE = gql`
    query Providerfind($provider_id: Int!) {
        providerfind(provider_id: $provider_id) {
            provider_data {
                practice_name
                address {
                    address_1
                    address_2
                    city
                    state
                    zip_code
                }
                phone_number
            }
            provider_npi
        }
    }
`;

export const ADD_OFFICE_USER = gql`
    query Provadminadd($provider_id: Int!, $first_name: String!, $last_name: String!, $email: String!) {
        provadminadd(
            provider_id: $provider_id
            first_name: $first_name
            last_name: $last_name
            user_name: $email
            email: $email
            user_status: "Active"
            credentials_id: $email
        ) {
            provider_id
        }
    }
`;

export const GET_PROVIDER_PAYMENT_TYPE = gql`
    query Providerfind($provider_id: Int!) {
        providerfind(provider_id: $provider_id) {
            preferred_payment_method
        }
    }
`;

export const BANK_ACCOUNT_INFO = gql`
    query Providerach($provider_id: Int!) {
        providerach(provider_id: $provider_id) {
            message
            provider_id
            ach_info {
                account_name
                bank
                routing_number
                account_number
                request_date
                eft_status
                approval_date
                bank_address
                account_name
            }
        }
    }
`;

export const PROVIDER_FILL_HCP = gql`
    query Providerfind($provider_id: Int!) {
        providerfind(provider_id: $provider_id) {
            provider_data {
                first_name
                last_name
                address {
                    state
                }
            }
            provider_npi
        }
    }
`;

export const PROVIDER_AGREEMENT = gql`
    query Providerfind($provider_id: Int!) {
        providerfind(provider_id: $provider_id) {
            provider_data {
                user_agreement
                opt_in_fax_numbers {
                    opt_in
                    fax1
                    fax2
                    fax3
                }
                fax_opt_in
            }
        }
    }
`;

export const PROVIDER_FAX_CONSENT = gql`
    query Providerfind($provider_id: Int!) {
        providerfind(provider_id: $provider_id) {
            provider_data {
                opt_in_fax_numbers {
                    opt_in
                    fax1
                    fax2
                    fax3
                }
                fax_opt_in
            }
        }
    }
`;

export const PROVIDER_DATA = gql`
    query Providerfind($provider_id: Int!) {
        providerfind(provider_id: $provider_id) {
            provider_npi
            email
            provider_data {
                provider_id
                provider_type
                specialty
                first_name
                last_name
                practice_name
                phone_number
                address {
                    address_1
                    address_2
                    city
                    state
                    zip_code
                }
                fax_opt_in
                fax_numbers {
                    fax_number
                    fax_number_2
                }
                opt_in_fax_numbers {
                    opt_in
                    fax1
                    fax2
                    fax3
                }
                user_agreement
            }
        }
    }
`;

export const CHANGE_PASSWORD = gql`
    query Providerpassword($provider_id: Int!, $npi: String!, $new_password_hash: String!) {
        providerpassword(provider_id: $provider_id, npi: $npi, new_password_hash: $new_password_hash) {
            result
            login_id
        }
    }
`;

export const GET_PROVIDER_NPI = gql`
    query Providerfind($provider_id: Int!) {
        providerfind(provider_id: $provider_id) {
            provider_npi
        }
    }
`;

export const GET_NOTIFICATION_COUNT = gql`
    query Providernotification($provider_id: Int!) {
        providernotification(provider_id: $provider_id) {
            total
        }
    }
`;

export const GET_NOTIFICATION_LIST = gql`
    query Providernotificationlist($provider_id: Int!) {
        providernotificationlist(provider_id: $provider_id) {
            document_id
            document_type
            document_sent_date
            patient_first_name
            patient_last_name
            patient_id
            notification_status
        }
    }
`;

export const READ_NOTIFICATION = gql`
    query Providernotificationupdate($provider_id: Int!, $user_id: Int!, $document_id: Int!) {
        providernotificationupdate(provider_id: $provider_id, user_id: $user_id, document_id: $document_id, status: 1) {
            document_id
        }
    }
`;

export const UPDATE_PROVIDER_EMAIL = gql`
    query Updateemail($user_id: Int!, $user_type: String!, $login_id: String!, $email: String!, $xref_id: String!) {
        updateemail(user_id: $user_id, user_type: $user_type, login_id: $login_id, email: $email, xref_id: $xref_id) {
            message
        }
    }
`;
