import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';
import { MY_PROFILE, ADMIN_MY_PROFILE } from '../../graphql/queries/Providers'

export default function MyProfile() {
    const provider_id = parseInt(window.localStorage.getItem('providerID'))
    const [username, setusername] = useState('')
    const [email, setemail] = useState('')
    const [first_name, setfirstname] = useState('')
    const [last_name, setlastname] = useState('')
    const [last_login, setlastlogin] = useState('')
    const [password_expiration, setpasswordexpiration] = useState('')
    const credentials_id = window.localStorage.getItem('credentials_id')
    const { data, refetch } = useQuery(MY_PROFILE, {
        variables: { provider_id }
    });
    const { data: admindata, refetch: adminrefetch } = useQuery(ADMIN_MY_PROFILE, {
        variables: { provider_id }
    });

    useEffect(() => {
        if (credentials_id == null) {
            if (data != null) {
                if (data.providerfind != null) {
                    if (data.providerfind.provider_data != null) {
                        if (data.providerfind.provider_data.first_name != null) {
                            setfirstname(data.providerfind.provider_data.first_name)
                        }
                        if (data.providerfind.provider_data.last_name != null) {
                            setlastname(data.providerfind.provider_data.last_name)
                        }
                    }
                    if (data.providerfind.credentials_id != null) {
                        setusername(data.providerfind.credentials_id)
                    }
                    if (data.providerfind.email != null) {
                        setemail(data.providerfind.email)
                    }
                }
            }
        }
    }, [data])

    useEffect(() => {
        if (credentials_id) {
            if (admindata != null) {
                if (admindata.providerusersearch != null) {
                    admindata.providerusersearch.map(x => {
                        var compare = "\"" + x.credentials_id + "\""
                        if (compare == credentials_id) {
                            setfirstname(x.first_name)
                            setlastname(x.last_name)
                            setusername(x.user_name)
                            setemail(x.user_name)
                            if(x.user_data){
                                setemail(x.user_data.email)
                            }
                        }
                    })
                }
            }
        }
    }, [admindata])

    useEffect(() => {
        if(window.localStorage.getItem('updateEmail') == "true"){
            window.localStorage.removeItem('updateEmail')
            window.location.reload()
        }
    }, [window.localStorage.getItem('updateEmail')])

    

    return (
        <div className="container" id="content" style={{ fontSize: '1em' }}>
            <div id="container">
                <div id="hcp_post_content" className="user-profile">
                    <div className="space"></div>
                    <div className="section shadow-box color-label">
                        <div className="col-sm-12 col-md-12 col-lg-10">
                            <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>User Profile</h2>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-2" style={{textAlign: 'right'}}>
                            <Link to="/hcp/update-profile">Update User Details</Link>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <p><span className="color">Username</span><br /> {username}</p>
                                <p><span className="color">Email Address</span><br />{email}<br />
                                    Password reset information is sent here. 
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <p><span className="color">First Name</span><br />{first_name} </p>
                                <p><span className="color">Last Login Date</span><br />{last_login}</p>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <p><span className="color">Last Name</span><br />{last_name}</p>
                                <p><span className="color">Password Expiration Date</span><br />{password_expiration}</p>
                            </div>
                        </div>
                    </div>
                    <div className="clearfloat"></div>
                </div>
            </div>
        </div>
    )
}
