import React from "react";
import { Link } from "react-router-dom";

export default function EligibilityErrorInfusion() {
    return (
        <div className="container" id="content">
            <div id="container" className="eligibility">
                <h1 style={{ fontSize: "1.5em", lineHeight: "1.49em" }}>Eligibility Check</h1>
                <p>We're sorry. You do not appear to qualify for help with injection out-of-pocket costs from the XOLAIR Co-pay Program.</p>
                <p>
                    If you believe you are eligible or entered incorrect information,{" "}
                    <Link to="../enroll">complete the application form again</Link>. You can also call for help at
                    <br />
                    <span className="text-nowrap">
                        <strong>(855) 965-2472</strong>
                    </span>
                    .
                </p>
                <p>
                    If you do not meet the requirements listed below, you might still be able to get help. Call XOLAIR Access Solutions at{" "}
                    <span className="text-nowrap">
                        <strong>(800) 704-6610</strong>
                    </span>{" "}
                    or visit us online at{" "}
                    <Link to="https://www.genentech-access.com/hcp/brands/xolair.html?cid=gas_WE_00008168_1" target="_blank">
                        Genentech-Access.com/XOLAIR
                    </Link>{" "}
                    to learn about other options.{" "}
                </p>

                <h2 style={{ color: "rgb(131, 21, 111)", fontSize: "1.25em", lineHeight: "1.49em" }}>
                    Eligibility for help with injection out-of-pocket costs
                </h2>

                <div className="row col-xs-12 col-sm-12 col-md-12 col-lg-12 box-drug">
                    <div className="title-box2-inf" aria-label="Blue Table Header">
                        ELIGIBLE PATIENTS:
                    </div>
                    <div className="content-box2">
                        <ul>
                            <li>
                                <span>Have been prescribed XOLAIR for an FDA-approved indication</span>
                            </li>
                            <li>
                                <span>
                                    Are 18 years of age or older, or have a caregiver or legally authorized person to manage the patient's
                                    co-pay assistance
                                </span>
                            </li>
                            <li>
                                <span>
                                    Have commercial (private or non-governmental) insurance. This includes plans available through state and
                                    federal health insurance exchanges
                                </span>
                            </li>
                            <li>
                                <span>Reside and receive treatment in the U.S. or U.S. Territories</span>
                            </li>
                            <li>
                                <span>
                                    Are <strong>not</strong> receiving assistance through any charitable organization for the same expenses
                                    covered by the program<sup>†</sup>
                                </span>
                            </li>
                            <li>
                                <span>
                                    Are <strong>not</strong> a government beneficiary and/or participant in a federal or state-funded health
                                    insurance program (e.g., Medicare, Medicare Advantage, Medigap, Medicaid, VA, DoD, TRICARE)
                                </span>
                            </li>
                            <li>
                                <span>
                                    Do <strong>not</strong> reside or receive treatment in a restricted state (e.g. Massachusetts or Rhode
                                    Island)
                                </span>
                            </li>
                        </ul>
                        <p>
                            <sup>†</sup>Patients may use the XOLAIR Co-pay Program for their administration costs If they are receiving
                            their treatment from the Genentech Patient Foundation.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
