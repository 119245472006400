// my-account page

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import $ from "jquery";
import { SEARCH_CLAIM_HISTORY } from "../graphql/queries/Patients";
import calBtn from "../images/calbtn.gif";

export default function Claims(props) {
    const [from_date_of_service, setfromdate] = useState(null);
    const [claim_status, setclaimstatus] = useState(null);
    const [payment_status, setpaymentstatus] = useState(null);
    const [fromdate, inputsetfromdate] = useState(null);
    const [claimstatus, inputsetclaimstatus] = useState(new Array(2).fill(false));
    const [paymentstatus, inputsetpaymentstatus] = useState(new Array(5).fill(false));
    var patient_id = parseInt(window.localStorage.getItem("xrefID"));
    if (props.patientID) {
        patient_id = props.patientID;
    } else {
        patient_id = parseInt(window.localStorage.getItem("xrefID"));
    }

    const { loading, error, data, refetch } = useQuery(SEARCH_CLAIM_HISTORY, {
        variables: { patient_id, from_date_of_service, claim_status, payment_status },
    });

    const handleOnChange = (position) => {
        const updatedCheckedState = claimstatus.map((item, index) => (index === position ? !item : item));
        inputsetclaimstatus(updatedCheckedState);
    };
    const handlePaymentStatusOnChange = (position) => {
        const updatedCheckedState = paymentstatus.map((item, index) => (index === position ? !item : item));
        inputsetpaymentstatus(updatedCheckedState);
    };

    function search() {
        if (fromdate == "") {
            setfromdate(null);
        } else {
            setfromdate(fromdate);
        }
        if (claimstatus[0] == true && claimstatus[1] == true) {
            setclaimstatus(null);
        } else if (claimstatus[0] == true && claimstatus[1] == false) {
            setclaimstatus("approved");
        } else if (claimstatus[0] == false && claimstatus[1] == true) {
            setclaimstatus("rejected");
        } else {
            setclaimstatus(null);
        }
        if (
            paymentstatus[0] == false &&
            paymentstatus[1] == false &&
            paymentstatus[2] == false &&
            paymentstatus[3] == false &&
            paymentstatus[4] == false
        ) {
            setpaymentstatus(null);
        } else if (
            paymentstatus[0] == true &&
            paymentstatus[1] == true &&
            paymentstatus[2] == true &&
            paymentstatus[3] == true &&
            paymentstatus[4] == true
        ) {
            setpaymentstatus(null);
        } else if (
            paymentstatus[0] == true &&
            paymentstatus[1] == false &&
            paymentstatus[2] == false &&
            paymentstatus[3] == false &&
            paymentstatus[4] == false
        ) {
            setpaymentstatus("approved");
        } else if (
            paymentstatus[0] == false &&
            paymentstatus[1] == false &&
            paymentstatus[2] == false &&
            paymentstatus[3] == false &&
            paymentstatus[4] == true
        ) {
            setpaymentstatus("paid");
        }

        var date = $(".datepicker").val();
        var month = date.substring(0, 2);
        var day = date.substring(3, 5);
        var year = date.substring(6, 10);
        if (date != "") {
            setfromdate(year + "-" + month + "-" + day);
        }
    }

    function resetSearch() {
        $("input[id=datepicker]").val("");
        setfromdate(null);
        setclaimstatus(null);
        setpaymentstatus(null);
    }

    useEffect(() => {
        $(function () {
            $(".datepicker").datepicker({
                dateFormat: "mm/dd/yy",
                showButtonPanel: true,
                changeMonth: true,
                changeYear: true,
                showOtherMonths: true,
                selectOtherMonths: true,
            });
        });
    }, [data]);

    useEffect(() => {
        window.onclick = function (event) {
            if (
                !event.target.matches(".claim-status-dropdown") &&
                !event.target.matches(".checkbox") &&
                !event.target.matches(".checkmark") &&
                !event.target.matches(".status-icon") &&
                !event.target.matches(".active") &&
                !event.target.matches(".grey") &&
                !event.target.matches(".open-menu") &&
                !event.target.matches(".rejected") &&
                !event.target.matches(".select-container")
            ) {
                $(".claim-status-dropdown").addClass("hide");
            }
            if (
                !event.target.matches(".payment-status-dropdown") &&
                !event.target.matches(".checkbox") &&
                !event.target.matches(".checkmark") &&
                !event.target.matches(".status-icon") &&
                !event.target.matches(".grey") &&
                !event.target.matches(".open-menu-2") &&
                !event.target.matches(".select-container") &&
                !event.target.matches(".payment-option")
            ) {
                $(".payment-status-dropdown").addClass("hide");
                if (!$(".payment-status-dropdown").hasClass("hide")) {
                }
            }
        };
    });

    return (
        <>
            <div className="section shadow-box color-label">
                <div>
                    <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>Patient Claim History</h2>
                    <div className="find_patient">
                        <input
                            id="datepicker"
                            className="datepicker"
                            type="text"
                            name="birth_date"
                            placeholder="Date of Service"
                            size="10"
                            value={fromdate}
                        />
                        <img className="ui-datepicker-trigger" src={calBtn} alt="..." title="..." />
                        <span className="select_container inner-drop">
                            <select className="open-menu">
                                <option>Claim Status</option>
                            </select>
                            <div id="dropdown" className="claim-status-dropdown hide">
                                <p className="grey">
                                    {" "}
                                    <label className="checkbox" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            className="claim-status-dropdown"
                                            type="checkbox"
                                            name="status1"
                                            checked={claimstatus[0]}
                                            onChange={() => handleOnChange(0)}
                                            value=""
                                        />
                                        <span className="status-icon active">Accepted</span>
                                        <span className="checkmark "></span>
                                    </label>
                                </p>
                                <p style={{ margin: "0" }}>
                                    {" "}
                                    <label className="checkbox" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            className="claim-status-dropdown"
                                            type="checkbox"
                                            name="status2"
                                            checked={claimstatus[1]}
                                            onChange={() => handleOnChange(1)}
                                            value=""
                                        />
                                        <span className="status-icon rejected">Rejected</span>
                                        <span className="checkmark "></span>
                                    </label>
                                </p>
                            </div>
                        </span>
                        <span className="select_container inner-drop">
                            <select className="open-menu-2">
                                <option>Payment Status</option>
                            </select>
                            <div id="dropdown-2" className="payment-status-dropdown hide">
                                <p className="grey">
                                    <label className="checkbox" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            className="payment-option"
                                            checked={paymentstatus[0]}
                                            onChange={() => handlePaymentStatusOnChange(0)}
                                            type="checkbox"
                                            name="status1"
                                            value=""
                                        />{" "}
                                        Approved <span className="checkmark "></span>
                                    </label>{" "}
                                </p>
                                <p className="payment-option">
                                    <label className="checkbox" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            className="payment-option"
                                            checked={paymentstatus[1]}
                                            onChange={() => handlePaymentStatusOnChange(1)}
                                            type="checkbox"
                                            name="status2"
                                            value=""
                                        />{" "}
                                        Sent to Bank <span className="checkmark "></span>
                                    </label>{" "}
                                </p>
                                <p className="payment-option">
                                    <label className="checkbox" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            className="payment-option"
                                            checked={paymentstatus[2]}
                                            onChange={() => handlePaymentStatusOnChange(2)}
                                            type="checkbox"
                                            name="status2"
                                            value=""
                                        />{" "}
                                        Returned From Bank, Call Help Desk <span className="checkmark "></span>
                                    </label>{" "}
                                </p>
                                <p className="payment-option">
                                    <label className="checkbox" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            className="payment-option"
                                            checked={paymentstatus[3]}
                                            onChange={() => handlePaymentStatusOnChange(3)}
                                            type="checkbox"
                                            name="status2"
                                            value=""
                                        />{" "}
                                        Check Cleared <span className="checkmark "></span>
                                    </label>{" "}
                                </p>
                                <p className="payment-option" style={{ margin: "0" }}>
                                    <label className="checkbox" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            className="payment-option"
                                            checked={paymentstatus[4]}
                                            onChange={() => handlePaymentStatusOnChange(4)}
                                            type="checkbox"
                                            name="status2"
                                            value=""
                                        />{" "}
                                        Paid<span className="checkmark "></span>
                                    </label>{" "}
                                </p>
                            </div>
                        </span>
                        <span className="select_container">
                            <select name="product" defaultValue="">
                                <option value="">Product</option>
                                <option value="XR">XOLAIR DRUG</option>
                                <option value="XRA">XOLAIR ADMINISTRATION</option>
                            </select>
                        </span>
                        <br />
                        <br />
                        <p></p>

                        <p>
                            <button onClick={search} name="list_all" id="grey-button">
                                Search
                            </button>{" "}
                            <button type="reset" onClick={resetSearch}>
                                Clear
                            </button>
                        </p>
                    </div>
                    <p>
                        <br />
                    </p>
                </div>
            </div>
            <table className="account">
                <tbody>
                    <tr className="list_header">
                        <th align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Product
                        </th>
                        <th align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Claim ID
                        </th>
                        <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Claim Type
                        </th>
                        <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Date of Service
                        </th>
                        <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Amount Requested
                        </th>
                        <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Amount Paid
                        </th>
                        <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Claim Status
                        </th>
                        <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Payment Status
                        </th>
                        <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                            Payee Info
                        </th>
                    </tr>
                    {data &&
                        data.patientclaims.map(
                            ({
                                __typename,
                                claim_id,
                                program,
                                date_of_service,
                                claim_data,
                                claim_status,
                                payment_status,
                                payment_type,
                            }) => (
                                <tr key={claim_id}>
                                    <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{program}</td>
                                    <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{claim_id}</td>
                                    <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        {payment_type == "PBM" ? "Pharmacy" : "Medical"}
                                    </td>
                                    <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>{date_of_service}</td>
                                    <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        ${claim_data.patient_responsibility_before_benefit_applied}
                                    </td>
                                    <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>${claim_data.program_benefit}</td>
                                    <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <span
                                            className={
                                                claim_status == "Maximizer-Hold" || claim_status == "pending"
                                                    ? ""
                                                    : claim_status == "approved" || claim_status == "Approved"
                                                    ? "status-icon active"
                                                    : claim_status == "rejected"
                                                    ? "status-icon rejected"
                                                    : "status-icon inactive"
                                            }
                                        >
                                            {claim_status == "Maximizer-Hold" || claim_status == "pending"
                                                ? ""
                                                : claim_status == "approved" || claim_status == "Approved"
                                                ? "Accepted"
                                                : claim_status == "rejected"
                                                ? "Rejected"
                                                : "Pending"}
                                        </span>
                                    </td>

                                    <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        {payment_status == "pm_approved" || payment_status == "approved"
                                            ? "Approved"
                                            : payment_status != "rejected" && payment_status != "rejeted"
                                            ? ""
                                            : "Rejected"}
                                    </td>
                                    <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        {claim_data.shipping_info != null
                                            ? claim_data.shipping_info != null
                                                ? claim_data.shipping_info.first_name != null
                                                    ? claim_data.shipping_info.first_name
                                                    : ""
                                                : ""
                                            : ""}{" "}
                                        {claim_data.shipping_info != null
                                            ? claim_data.shipping_info != null
                                                ? claim_data.shipping_info.last_name != null
                                                    ? claim_data.shipping_info.last_name
                                                    : ""
                                                : ""
                                            : ""}
                                        <br />
                                        {claim_data.shipping_info != null
                                            ? claim_data.shipping_info.address != null
                                                ? claim_data.shipping_info.address != null
                                                    ? claim_data.shipping_info.address.city != null
                                                        ? claim_data.shipping_info.address.city
                                                        : ""
                                                    : ""
                                                : ""
                                            : ""}
                                        <br />
                                        {claim_data.shipping_info != null
                                            ? claim_data.shipping_info.address != null
                                                ? claim_data.shipping_info.address != null
                                                    ? claim_data.shipping_info.address.address_1 != null
                                                        ? claim_data.shipping_info.address.address_1
                                                        : ""
                                                    : ""
                                                : ""
                                            : ""}
                                        <br />
                                        {claim_data.shipping_info != null
                                            ? claim_data.shipping_info.address != null
                                                ? claim_data.shipping_info.address != null
                                                    ? claim_data.shipping_info.address.zip_code != null
                                                        ? claim_data.shipping_info.address.zip_code
                                                        : ""
                                                    : ""
                                                : ""
                                            : ""}
                                        <br />
                                        {claim_data.shipping_info != null
                                            ? claim_data.shipping_info.address != null
                                                ? claim_data.shipping_info.address != null
                                                    ? claim_data.shipping_info.address.state != null
                                                        ? claim_data.shipping_info.address.state
                                                        : ""
                                                    : ""
                                                : ""
                                            : ""}
                                    </td>
                                </tr>
                            )
                        )}
                </tbody>
            </table>
            <div className="pages">
                <div className="col-lg-8"></div>
                <div className="col-lg-4">
                    <div className="col-lg-5">Items per page: 10</div>
                    <div className="col-lg-3">1 - 1 of 1 </div>
                    <div className="col-lg-4">
                        <Link to="#">
                            <span className="nav-left"></span>
                        </Link>
                        <Link to="#">
                            <span className="nav-right"></span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
