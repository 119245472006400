import React , {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import calBtn from '../images/calbtn.gif'
import AccountNavbar from '../components/AccountNavbar'
import MessagesTable from '../components/MessagesTable'


export default function Messages(){
  const [fromdate, inputsetfromdate] = useState('')
  const [todate, inputsettodate] = useState('')

    useEffect(() => {
      $(document).ready(function() {
        $('.rejected').click(function(event) {
          event.preventDefault();
          $('.rejected-popup').css('display', 'block');
        });
      
       $('.rejected-popup .close-button').click(function(event) {
          event.preventDefault();
          $('.rejected-popup').css('display', 'none');
        });
      });
      
      $(document).ready(function() {
        $('.open-menu').click(function(event) {
          event.preventDefault();
          $(this).toggleClass('show');
          $('#dropdown').removeClass('hide');
        $('#dropdown-2').addClass('hide');
        });
         $('.open-menu-2').click(function(event) {
          event.preventDefault();
          $(this).toggleClass('show');
          $('#dropdown-2').removeClass('hide');
        $('#dropdown').addClass('hide');
        });
      
      });
      
      
      $(document).ready(function() {
        $(".datepicker").datepicker({dateFormat: 'mm/dd/yy',showButtonPanel: true,
          changeMonth: true,
          changeYear: true,
          yearRange: "1920:+nn",
          showOtherMonths: true,
          selectOtherMonths: true });
      });
      $(document).ready(function() {
        $(".datepicker2").datepicker({dateFormat: 'mm/dd/yy',showButtonPanel: true,
          changeMonth: true,
          changeYear: true,
          yearRange: "1920:+nn",
          showOtherMonths: true,
          selectOtherMonths: true });
      });
      
      
    })

    
    function updateDateRange(e){
      e.preventDefault();

      var date = $(".datepicker").val()
      var month = date.substring(0, 2)
      var day = date.substring(3, 5)
      var year = date.substring(6, 10)
      if (date != '') {
          inputsetfromdate(year + "-" + month + "-" + day)
      }

      var date2 = $(".datepicker2").val()
      var month2 = date2.substring(0, 2)
      var day2 = date2.substring(3, 5)
      var year2 = date2.substring(6, 10)
      if (date2 != '') {
          inputsettodate(year2 + "-" + month2 + "-" + day2)
      }
    }

    return (
        <div className="container" id="content" style={{fontSize: '1em'}}>
          <div id="container" className="account">
          <AccountNavbar page="communications" />
            <div className="clearfloat"></div>
            <h2 style={{fontSize: '1.25em' , lineHeight: '1.49em'}}>Patient Communications History</h2>
            <div className="section shadow-box color-label">
              <form  onSubmit={e => updateDateRange(e)} acceptCharset="utf-8">
                <div className="find_patient">
                  <p>
                    <input className="datepicker" type="text" name="birth_date" placeholder='From Date' size="10"/><img className="ui-datepicker-trigger" src={calBtn} alt="..." title="..." />
                    <input className="datepicker2" type="text" name="birth_date" placeholder='To Date' size="10"/><img className="ui-datepicker-trigger" src={calBtn} alt="..." title="..." />
                  </p>

                  <p> <button >Search</button></p>
                </div>
                <p><br/></p>
              </form>
              <p><br/></p>
              <MessagesTable fromdate = {fromdate} todate = {todate} />
              <div className="pages"><div className="col-lg-8"></div><div className="col-lg-4"><div className="col-lg-5">Items per page: 10</div><div className="col-lg-3">1 - 1 of 1 </div><div className="col-lg-4"><Link to="#"><span className="nav-left"></span></Link><Link to="#"><span className="nav-right"></span></Link></div></div></div>
            </div>
          
          </div>
          <div className="rejected-popup">
            <div className="popup-box">
              <h2>Claim Reject Reason</h2>
              <p>Duplicate Claim.</p>
              <button className="close-button">Close</button>
            </div>
          </div>
        </div>
    )
}