import React, { useEffect, useState } from 'react'
import {useNavigate} from 'react-router-dom'
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import { useQuery, useMutation } from '@apollo/client';
import {PROVIDER_DATA} from '../../graphql/queries/Providers'
import {UPDATE_PROVIDER_DATA} from '../../graphql/mutations/Providers'

export default function FaxConsent() {

    const provider_id = window.localStorage.getItem('providerID') ? parseInt(window.localStorage.getItem('providerID')) : 0
    const [first_name, setfirstname] = useState('')
    const [last_name, setlastname] = useState('')
    const [practice_name, setpracticename] = useState('')
    const [phone_number, setphonenumber] = useState('')
    const [address_1, setaddress1] = useState('')
    const [address_2, setaddress2] = useState('')
    const [city, setcity] = useState('')
    const [state, setstate] = useState('')
    const [zip_code, setzipcode] = useState('')
    const [fax_opt_in, setfaxoptin] = useState('')
    const [fax1, setfax1] = useState('')
    const [fax2, setfax2] = useState('')
    const [fax3, setfax3] = useState('')
    const [user_agreement, setuseragreement] = useState('')

    const { data } = useQuery(PROVIDER_DATA, {
        variables: { provider_id }
    });

    const [updateProvider, { data: newData }] = useMutation(UPDATE_PROVIDER_DATA);

    let navigate = useNavigate()
   
    useEffect(() => {
        if(data){
            if(data.providerfind != null){
                if(data.providerfind.provider_data != null){
                    if(data.providerfind.provider_data.opt_in_fax_numbers != null){
                        if((data.providerfind.provider_data.opt_in_fax_numbers.fax1 != null) && (data.providerfind.provider_data.opt_in_fax_numbers.fax2 != null) && (data.providerfind.provider_data.opt_in_fax_numbers.fax3 != null)){
                            if((data.providerfind.provider_data.opt_in_fax_numbers.fax1.toUpperCase() != 'NULL') || (data.providerfind.provider_data.opt_in_fax_numbers.fax2.toUpperCase() != 'NULL') || (data.providerfind.provider_data.opt_in_fax_numbers.fax3.toUpperCase() != 'NULL')){
                                setfax1(data.providerfind.provider_data.opt_in_fax_numbers.fax1)
                                let path = `/hcp/patient-list`
                                navigate(path)
                            }
                        }
                        if(data.providerfind.provider_data.opt_in_fax_numbers.fax2){
                            setfax2(data.providerfind.provider_data.opt_in_fax_numbers.fax2)
                        }
                        if(data.providerfind.provider_data.opt_in_fax_numbers.fax3){
                            setfax3(data.providerfind.provider_data.opt_in_fax_numbers.fax3)
                        }
                    }
                    if(data.providerfind.provider_data.first_name){
                        setfirstname(data.providerfind.provider_data.first_name)
                    }
                    if(data.providerfind.provider_data.last_name){
                        setlastname(data.providerfind.provider_data.last_name)
                    }
                    if(data.providerfind.provider_data.practice_name){
                        setpracticename(data.providerfind.provider_data.practice_name)
                    }
                    if(data.providerfind.provider_data.phone_number){
                        setphonenumber(data.providerfind.provider_data.phone_number)
                    }
                    if(data.providerfind.provider_data.fax_opt_in){
                        setfaxoptin(data.providerfind.provider_data.fax_opt_in)
                    }
                    if(data.providerfind.provider_data.user_agreement){
                        setuseragreement(data.providerfind.provider_data.user_agreement)
                    }
                    if(data.providerfind.provider_data.address != null){
                        if(data.providerfind.provider_data.address.address_1){
                            setaddress1(data.providerfind.provider_data.address.address_1)
                        }
                        if(data.providerfind.provider_data.address.address_2){
                            setaddress2(data.providerfind.provider_data.address.address_2)
                        }
                        if(data.providerfind.provider_data.address.city){
                            setcity(data.providerfind.provider_data.address.city)
                        }
                        if(data.providerfind.provider_data.address.state){
                            setstate(data.providerfind.provider_data.address.state)
                        }
                        if(data.providerfind.provider_data.address.zip_code){
                            setzipcode(data.providerfind.provider_data.address.zip_code)
                        }
                    }
                }
            }
        }
    }, [data])



    useEffect(() => {
        $(function () {
            $(".fax").mask("9999999999");
            //function check() { // Define a function that we can call our event listeners with
            function isNumber(n) {
                return (parseFloat(n) == n);
            }
            $("document").ready(function () {
                $("input.fax").keyup(function (event) {
                    var input = $(this).val();
                    if (!isNumber(input)) {

                        $(this).val(input.substring(0, input.length - 1));
                    }
                });
            });
        });
    })

    function acceptFax(e){
        e.preventDefault();
        if(((document.getElementById("agree_user_yes").checked == true) && (fax1 != '') && (fax1.length == 10))){
            setfaxoptin('Y')
            console.log(provider_id, first_name, last_name, practice_name, phone_number, address_1, address_2, city, state, zip_code, fax_opt_in, fax1, fax2, fax3, user_agreement)
            updateProvider({variables : {provider_id: provider_id.toString(), first_name, last_name, practice_name, phone_number, address_1, address_2, city, state, zip_code, fax_opt_in, fax1, fax2, fax3, user_agreement}})
            let path = `../patient-list`
            navigate(path)
        } else if(document.getElementById("agree_user_no").checked == true){
            let path = `../patient-list`
            navigate(path)
        }
        
    }

    function updateFaxNumber(e){
        e.preventDefault();
        setfax1(e.target.value)
    }

    return (
        <div className="container" id="content" style={{ fontSize: '1em' }}>
            <div id="container" className="account">
                <form onSubmit={e => acceptFax(e)} className="apply-step-one-form" acceptCharset="utf-8">
                    <h1 style={{ fontSize: '1.5em', lineHeight: '1.49em' }}>Genentech Fax Opt-in</h1>
                    <p>By clicking below, you agree to receive faxed messages from Genentech, Inc. (Genentech), or persons acting on Genentech's behalf, at the fax number reflected below ("Genentech Faxes"). These Genentech Faxes are designed to help administer Genentech's co-pay card assistance programs and may include information such as confirmation of patient enrollment/<span className="noWrap">re-enrollment,</span> acknowledgment of receipt of claims, explanation of benefits statements, as well as more general information concerning Genentech's co-pay card assistance programs. </p>
                    <p>By opting in, you agree to receive these Genentech Faxes, which may be sent via automatic dialing machines and IP-based fax technologies, and may include advertising content.
                        You confirm that the fax number below belongs to you or your practice, and that you have authority to provide consent to receive Genentech Faxes at that number. Your consent to receiving Genentech Faxes is not required as a condition of purchasing any goods or services. </p>
                    <p>Genentech is obligated by law to honor within 30 days any valid requests to opt out of receiving faxes containing advertising content.  To be valid, the opt-out request must be sent to (866) 440-0599 and must include the fax number to which it relates.  If you opt out but later provide express permission to receive fax advertisements again, that permission will override your earlier opt-out request.</p>
                    <p><label className="radio" style={{ fontSize: '1em', lineHeight: '1.49em' }}><input type="radio" id="agree_user_yes" name="agree_fax" value="1" /> I agree to the above Genentech fax opt-in Terms and Conditions and wish to receive Genentech Faxes at the below number<span className="checkmark"></span></label></p>
                    <p>FAX NUMBER:<br /><input value={fax1} onChange={e => updateFaxNumber(e)} type="text" name="fax1" className="required-field fax" maxlength="10" size="10" pattern="[0-9]+" style={{ width: '170px' }} /></p>
                    <p><label className="radio" style={{ fontSize: '1em', lineHeight: '1.49em' }}><input type="radio" id="agree_user_no" name="agree_fax" value="1" /> I do not agree to the above Genentech fax opt-in Terms and Conditions and do not wish to receive Genentech Faxes<span className="checkmark"></span></label></p>
                    <div className="space"></div>
                    <p><button type="submit" name="submit">Submit</button></p>
                </form>
                <div className="space"></div><div className="space"></div>
            </div>
        </div>
    )
}