import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.min.css";
import iconInfoRound from "../../images/icon-info-round.png";

export default function EligibilityPatient(props) {
    const { setTitle, setStep, eligibility_answers, seteligibilityanswers, enrollment_data, setenrollmentdata } = useOutletContext();

    const pageType = props.pageType;
    var errorPage;
    if (pageType == "drug") {
        errorPage = "eligibility-error-drug";
    } else if (pageType == "infusion") {
        errorPage = "eligibility-error-infusion";
    } else {
        errorPage = "eligibility-error";
    }

    const [dob, setdob] = useState("");
    const [state, setstate] = useState("");
    const [user_age, setage] = useState(0);
    const [validAge, setValidAge] = useState(true);
    const [question2State, setQuestion2State] = useState("");
    const [question3Date, setQuestion3Date] = useState("");

    const product = window.localStorage.getItem("product") ? window.localStorage.getItem("product") : "";
    let navigate = useNavigate();

    useEffect(() => {
        if (product == "") {
            navigate("../enroll");
        }
    }, [product]);

    useEffect(() => {
        if (question2State != "" && question3Date != "") {
            calculateAge(question3Date);
            setValidAge(user_age >= 18);
        }
    }, [question2State, question3Date]);

    // useEffect(() => {
    //     if (question2State != "") {
    //         setValidAge(user_age >= 18);
    //     }
    // }, [user_age]);

    var existing_member = false;
    if (window.localStorage.getItem("existing_member") == "true") {
        existing_member = true;
    } else {
        existing_member = false;
    }

    // $(".datepicker").val(enrollment_data.dob);
    useEffect(() => {
        if (window.localStorage.getItem("enrollment_data")) {
            try {
                setenrollmentdata(JSON.parse(window.localStorage.getItem("enrollment_data")));
                setdob(enrollment_data.dob);
                calculateAge(enrollment_data.dob);
                $(".datepicker").val(enrollment_data.dob);
                setQuestion3Date(enrollment_data.dob);
                setstate(enrollment_data.address.state);
                eligibility_answers.q6 = enrollment_data.address.state;
            } catch {}
        } else {
            $(".datepicker").val(question3Date).trigger("change");
        }

        $(".datepicker").datepicker({
            dateFormat: "mm/dd/yy",
            showButtonPanel: true,
            changeMonth: true,
            changeYear: true,
            yearRange: "1920:+nn",
            showOtherMonths: true,
            selectOtherMonths: true,
            onSelect: function (d, i) {
                // if (d !== i.lastVal) {
                // $(this).change();
                // }

                async function dateRefresh() {
                    await calculateAge(d);
                    $(".datepicker").datepicker("refresh");
                }
                dateRefresh();
            },
        });

        return () => {
            $(".datepicker").each(function () {
                try {
                    if ($(this).datepicker("widget")) {
                        $(this).datepicker("destroy");
                    }
                } catch (error) {
                    console.error("Error occurred during datepicker destruction:", error);
                }
            });
        };
    }, []);

    useEffect(() => {
        $(function () {
            $("#more-med1").on("click", function () {
                if ($(this).is(":checked")) {
                    $(".hide-med1").removeClass("hide");
                    $(".hide-med2").addClass("hide");
                }
            });
            $("#more-med2").on("click", function () {
                if ($(this).is(":checked")) {
                    //$(".hide-med1").removeClass( "hide" );
                    $(".hide-med2").removeClass("hide");
                }
            });
            $(".hide-question-7b").on("click", function () {
                if ($(this).is(":checked")) {
                    $(".hide-med1").addClass("hide");
                    $(".hide-med2").addClass("hide");
                }
            });
            $(".hide-question-7c").on("click", function () {
                if ($(this).is(":checked")) {
                    $(".hide-med2").addClass("hide");
                }
            });
            $(".indication").on("click", function () {
                if ($(this).is(":checked")) {
                    $(".dob").removeClass("hide");
                    $(".dob").addClass("required-field");
                }
            });
            $(".none").on("click", function () {
                if ($(this).is(":checked")) {
                    $(".dob").addClass("hide");
                    $(".dob").removeClass("required-field");
                }
            });
        });

        $(function () {
            $(".apply-step-one-form").submit(function (event) {
                var q1 = $(".question-1:checked", this).val();
                var q2 = $(".question-2:checked", this).val();
                var q3 = $(".question-3:checked", this).val();
                var q4 = $(".question-4:checked", this).val();
                var q6 = $(".question-6", this).val();
                var q5 = $(".question-5:checked", this).val();
                var q5b = $(".question-5b:checked", this).val();
                var q5c = $(".question-5c").val();
                var q7 = $(".question-7:checked", this).val();
                var q7x = $(".question-7x:checked", this).val();
                var q8 = $(".question-8:checked", this).val();
                var q9 = $(".question-9:checked", this).val();

                if (
                    q1 === "yes" &&
                    q2 === "yes" &&
                    q3 !== "NONE" &&
                    q3 !== "UNSURE" &&
                    q4 === "yes" &&
                    q6 !== "MA" &&
                    (q5 === "no" || (q5 === "yes" && q5b === "yes" && q5c !== "")) &&
                    q7 === "no" &&
                    q7x === "no" &&
                    q8 === "agree" &&
                    q9 === "agree"
                ) {
                    $("form.apply-step-one-form").attr("action", "enroll-form");
                } else {
                    $("form.apply-step-one-form").attr("action", { errorPage });
                }
            });
        });

        // $(document).ready(function () {
        //     $(".datepicker").datepicker({
        //         dateFormat: "mm/dd/yy",
        //         showButtonPanel: true,
        //         changeMonth: true,
        //         changeYear: true,
        //         yearRange: "1920:+nn",
        //         showOtherMonths: true,
        //         selectOtherMonths: true,
        //         onSelect: function (d, i) {
        //             // if (d !== i.lastVal) {
        //             // $(this).change();
        //             // }

        //             async function dateRefresh() {
        //                 await calculateAge(d);
        //                 $(".datepicker").datepicker("refresh");
        //             }
        //             dateRefresh();
        //         },
        //     });
        // });

        setTitle("Apply for the XOLAIR Co-pay Program");
        setStep("3");
    }, []);
    /*
        $('input[name=birth_date]').on('change', function () {
    
            var date = $(".datepicker").val()
            var month = date.substring(0, 2)
            var day = date.substring(3, 5)
            var year = date.substring(6, 10)
            var date_of_birth = new Date(date);
            if (date != '') {
                var month_diff = Date.now() - date_of_birth.getTime();
                var age_dt = new Date(month_diff);   
                var year_d = age_dt.getUTCFullYear(); 
                var age = Math.abs(year_d - 1970);
                setage(age)
                setdob(year + "-" + month + "-" + day)
                enrollment_data.dob = year + "-" + month + "-" + day
                window.localStorage.setItem('enrollment_data', JSON.stringify(enrollment_data))
    
            }
    
    
    
        });
        */
    async function getAge() {
        var date = $(".datepicker").val();
        var month = date.substring(0, 2);
        var day = date.substring(3, 5);
        var year = date.substring(6, 10);
        var date_of_birth = new Date(date);
        if (date != "") {
            if (!date.includes("-")) {
                var month_diff = Date.now() - date_of_birth.getTime();
                var age_dt = new Date(month_diff);
                var year_d = age_dt.getUTCFullYear();
                var age = Math.abs(year_d - 1970);
                setage(age);
                setdob(year + "-" + month + "-" + day);
                enrollment_data.dob = year + "-" + month + "-" + day;
                eligibility_answers.q3date = year + "-" + month + "-" + day;
                window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));
            } else {
                setdob(date);
                enrollment_data.dob = date;
                eligibility_answers.q3date = date;
                window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));
            }
        }
        return age;
    }

    /**
     * calculateAge needed for real-time rendering
     *
     * @param {*} entered_date :string
     * @returns
     */
    async function calculateAge(entered_date) {
        $(".datepicker").val(entered_date).trigger("change");

        eligibility_answers.q3date = entered_date;
        let currentDay = new Date();
        let ageDate = new Date(entered_date);

        setQuestion3Date(entered_date);
        let ageInMilliseconds = currentDay.getTime() - ageDate.getTime();

        let age = Math.floor(ageInMilliseconds / (1000 * 3600 * 24 * 365.25));

        setage(age);
        let year = ageDate.getFullYear();
        let month = (ageDate.getMonth() + 1).toString().padStart(2, "0");
        let day = ageDate.getDate().toString().padStart(2, "0");
        ageDate = `${year}-${month}-${day}`;
        setdob(ageDate);
        enrollment_data.dob = ageDate;
        eligibility_answers.q3date = ageDate;
        window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));

        if (question2State != "") {
            if (age <= 18) {
                setValidAge(validAge ? validAge : !validAge);
            }
        }
        return age;
    }

    async function next(e) {
        e.preventDefault();
        let path = `../enroll-form`;
        let errorPath = `../` + errorPage;
        const age = await getAge();
        eligibility_answers.q3date = $(".datepicker").val();

        if (
            eligibility_answers.q1 == "" ||
            eligibility_answers.q2 == "" ||
            eligibility_answers.q3 == "" ||
            eligibility_answers.q3date.toString().length < 1 ||
            eligibility_answers.q4 == "" ||
            eligibility_answers.q5 == "" ||
            eligibility_answers.q6 == "" ||
            eligibility_answers.q7 == "" ||
            eligibility_answers.q8 == "" ||
            eligibility_answers.q9 == "" ||
            eligibility_answers.q10 == ""
        ) {
            alert("Please answer all questions");
        } else if (
            (eligibility_answers.q3 == "1" && !validAge) ||
            (eligibility_answers.q3 == "2" && !validAge) ||
            (eligibility_answers.q3 == "3" && !validAge) ||
            (eligibility_answers.q3 == "4" && !validAge)
        ) {
            navigate(errorPath);
        } else if (
            (pageType == "infusion" && (eligibility_answers.q6 == "RI" || eligibility_answers.q6 == "MA")) ||
            (pageType == "drug-infusion" && (eligibility_answers.q6 == "RI" || eligibility_answers.q6 == "MA")) ||
            (eligibility_answers.q3 == "1" && age < 6) ||
            (eligibility_answers.q3 == "2" && age < 12) ||
            (eligibility_answers.q3 == "3" && age < 18) ||
            (eligibility_answers.q3 == "4" && age < 1)
        ) {
            navigate(errorPath);
        } else if (
            eligibility_answers.q1 === "yes" &&
            eligibility_answers.q2 === "yes" &&
            eligibility_answers.q3 !== "NONE" &&
            eligibility_answers.q3 !== "UNSURE" &&
            eligibility_answers.q3date.toString().length > 1 &&
            eligibility_answers.q4 === "yes" &&
            (eligibility_answers.q5 === "no" ||
                (eligibility_answers.q5 === "yes" && eligibility_answers.q5b === "yes" && eligibility_answers.q5c !== "")) &&
            (eligibility_answers.q7 === "no" || (eligibility_answers.q7 === "yes" && pageType == "infusion")) &&
            eligibility_answers.q8 === "no" &&
            eligibility_answers.q9 === "agree" &&
            eligibility_answers.q10 === "agree"
        ) {
            window.localStorage.setItem("eligibility_answers", JSON.stringify(eligibility_answers));
            navigate(path);
        } else {
            navigate(errorPath);
        }
    }

    function updateState(e) {
        e.preventDefault();
        eligibility_answers.q6 = e.target.value;
        if (existing_member != true) {
            enrollment_data.address.state = e.target.value;
            window.localStorage.setItem("enrollment_data", JSON.stringify(enrollment_data));
        }
    }

    return (
        <>
            <p>
                <font color="red"> </font>
            </p>
            <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>Eligibility</h2>
            <p>
                By completing this application on behalf of the patient, you certify that the information provided is correct and you have
                authorization to complete the application on behalf of the patient.{" "}
            </p>
            <form onSubmit={(e) => next(e)} className="apply-step-one-form form-eligibility-patient" acceptCharset="utf-8">
                <table cellSpacing="0" cellPadding="0" border="0" className="eligibility">
                    <tbody>
                        <tr>
                            <td valign="top" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <strong>1.</strong>
                            </td>
                            <td className="radios" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <p>
                                    Does the patient consent to enroll in the XOLAIR Co-pay Program for{" "}
                                    {pageType == "drug" ? "drug" : pageType == "infusion" ? "administration" : "drug and administration"}{" "}
                                    assistance?
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q1 = e.target.value;
                                            }}
                                            type="radio"
                                            className="question-1"
                                            name="question-1"
                                            value="yes"
                                        />{" "}
                                        Yes <span className="checkmark"></span>
                                    </label>
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q1 = e.target.value;
                                            }}
                                            type="radio"
                                            className="question-1"
                                            name="question-1"
                                            value="no"
                                        />{" "}
                                        No<span className="checkmark"></span>
                                    </label>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <strong>2.</strong>
                            </td>
                            <td className="radios" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <p>Is the patient 18 years of age or older?</p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q2 = e.target.value;
                                                setQuestion2State("yes");
                                            }}
                                            type="radio"
                                            name="question-2"
                                            className="question-2"
                                            value="yes"
                                        />{" "}
                                        Yes <span className="checkmark"></span>
                                    </label>
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q2 = e.target.value;
                                                setQuestion2State("no");
                                            }}
                                            type="radio"
                                            name="question-2"
                                            className="question-2"
                                            value="no"
                                        />{" "}
                                        No<span className="checkmark"></span>
                                    </label>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <strong>3.</strong>
                            </td>
                            <td className="radios" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <p>Is the patient using XOLAIR for one of the following FDA-approved indications?</p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q3 = e.target.value;
                                            }}
                                            type="radio"
                                            className="question-3 indication"
                                            value="1"
                                            name="question-3"
                                        />{" "}
                                        Adults and pediatric patients 6 years of age and older with moderate to severe persistent asthma who
                                        have a positive skin test or in vitro reactivity to a perennial aeroallergen and whose symptoms are
                                        inadequately controlled with inhaled corticosteroids. <br /> <br></br>
                                        Limitations of Use: XOLAIR is not indicated for the relief of acute bronchospasm or status
                                        asthmaticus.
                                        <span className="checkmark"></span>
                                    </label>
                                </p>
                                <p>
                                    <a
                                        className="show-indication"
                                        href="https://www.genentech-access.com/content/dam/gene/accesssolutions/pdfs/coding/XOLAIR-Billing-Coding-for-Moderate-to-Severe-Presistent-Allergic-Asthma.pdf"
                                        target="blank"
                                    >
                                        ICD-10 Reference
                                        <img width="20" src={iconInfoRound} />
                                    </a>
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q3 = e.target.value;
                                            }}
                                            type="radio"
                                            className="question-3 indication"
                                            value="2"
                                            name="question-3"
                                        />{" "}
                                        Chronic spontaneous urticaria (CSU) in adults and adolescents 12 years of age and older who remain
                                        symptomatic despite H1 antihistamine treatment. <br />
                                        <br></br>
                                        Limitations of Use: XOLAIR is not indicated for treatment of other forms of urticaria.
                                        <span className="checkmark"></span>
                                    </label>
                                </p>
                                <p>
                                    <a
                                        className="show-indication"
                                        href="https://www.genentech-access.com/content/dam/gene/accesssolutions/pdfs/coding/XOLAIR-Billing-Coding-for-Chronic-Spontaneous-Urticaria.pdf"
                                        target="blank"
                                    >
                                        ICD-10 Reference
                                        <img width="20" src={iconInfoRound} />
                                    </a>
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q3 = e.target.value;
                                            }}
                                            type="radio"
                                            className="question-3 indication"
                                            value="3"
                                            name="question-3"
                                        />{" "}
                                        Add-on maintenance treatment of chronic rhinosinusitis with nasal polyps (CRSwNP) in adult patients
                                        18 years of age and older with inadequate response to nasal corticosteroids.
                                        <span className="checkmark"></span>
                                    </label>
                                </p>
                                <a
                                    className="show-indication"
                                    href="https://www.genentech-access.com/content/dam/gene/accesssolutions/pdfs/coding/XOLAIR-Billing-Coding-for-CRSwNP.pdf"
                                    target="blank"
                                >
                                    ICD-10 Reference
                                    <img width="20" src={iconInfoRound} />
                                </a>
                                <p></p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            type="radio"
                                            className="question-3 indication"
                                            value="4"
                                            name="question-3"
                                            onChange={(e) => {
                                                eligibility_answers.q3 = e.target.value;
                                            }}
                                        />{" "}
                                        The reduction of allergic reactions (Type I), including anaphylaxis, that may occur with accidental
                                        exposure to one or more foods in adult and pediatric patients aged 1 year and older with
                                        IgE-mediated food allergy.<br></br>
                                        <br></br> XOLAIR is to be used in conjunction with food allergen avoidance. <br />
                                        <br></br>
                                        Limitations of Use: XOLAIR is not indicated for the emergency treatment of allergic reactions,
                                        including anaphylaxis.
                                        <span className="checkmark"></span>
                                    </label>
                                </p>
                                <p>
                                    <a
                                        className="show-indication"
                                        href="https://www.genentech-access.com/content/dam/gene/accesssolutions/pdfs/coding/XOLAIR-Billing-Coding-for-FA.pdf"
                                        target="blank"
                                    >
                                        ICD-10 Reference
                                        <img width="20" src={iconInfoRound} />
                                    </a>
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q3 = e.target.value;
                                            }}
                                            type="radio"
                                            className="question-3 none"
                                            value="NONE"
                                            name="question-3"
                                        />{" "}
                                        None of the above<span className="checkmark"></span>
                                    </label>
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q3 = e.target.value;
                                            }}
                                            type="radio"
                                            className="question-3 none"
                                            value="UNSURE"
                                            name="question-3"
                                        />{" "}
                                        Unsure<span className="checkmark"></span>
                                    </label>
                                </p>
                                <p className="field required-field text dob hide">
                                    <label htmlFor="birthDate" className="normal" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        Select patient's date of birth.
                                    </label>
                                    <input
                                        readonly="true"
                                        // value={eligibility_answers.q3date}
                                        value={question3Date}
                                        autoComplete="off"
                                        className="datepicker"
                                        type="text"
                                        name="birth_date"
                                    />
                                    {!validAge && (
                                        <p style={{ color: "red" }}>
                                            Patients under 18 years of age must have a legally authorized person enroll on their behalf.
                                        </p>
                                    )}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <strong>4.</strong>
                            </td>
                            <td className="radios" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <p>
                                    Is the patient on commercial (also known as private) insurance? This includes insurance from an employer
                                    and non-government funded insurance purchased from a health insurance marketplace.
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q4 = e.target.value;
                                            }}
                                            type="radio"
                                            name="question-4"
                                            className="question-4"
                                            value="yes"
                                        />{" "}
                                        Yes <span className="checkmark"></span>
                                    </label>
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q4 = e.target.value;
                                            }}
                                            type="radio"
                                            name="question-4"
                                            className="question-4"
                                            value="no"
                                        />{" "}
                                        No<span className="checkmark"></span>
                                    </label>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <strong>5.</strong>
                            </td>
                            <td className="radios" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <p>
                                    ls the patient using a federal or state-funded insurance program? This includes, but is not limited to,
                                    Medicare, Medicaid, Medigap, VA, DoD and TRICARE.
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q5 = e.target.value;
                                            }}
                                            type="radio"
                                            id="more-med1"
                                            name="question-5"
                                            className="question-5"
                                            value="yes"
                                        />{" "}
                                        Yes <span className="checkmark"></span>
                                    </label>
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q5 = e.target.value;
                                            }}
                                            type="radio"
                                            name="question-5"
                                            className="question-5 hide-question-7b"
                                            value="no"
                                        />{" "}
                                        No<span className="checkmark"></span>
                                    </label>
                                </p>

                                <p className="hide hide-med1" style={{ marginLeft: "20px" }}>
                                    Does the patient have a Medicare (red, white and blue) card?
                                </p>
                                <p className="hide hide-med1" style={{ marginLeft: "20px" }}>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q5b = e.target.value;
                                            }}
                                            type="radio"
                                            id="more-med2"
                                            value="yes"
                                            name="question-5b"
                                        />{" "}
                                        Yes <span className="checkmark"></span>
                                    </label>
                                </p>
                                <p className="hide hide-med1" style={{ marginLeft: "20px" }}>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q5b = e.target.value;
                                            }}
                                            type="radio"
                                            className="hide-question-7c"
                                            value="no"
                                            name="question-5b"
                                        />{" "}
                                        No <span className="checkmark"></span>
                                    </label>
                                </p>

                                <p className="hide hide-med2" style={{ marginLeft: "20px" }}>
                                    Enter the Medicare Number:
                                </p>
                                <p className="hide hide-med2" style={{ marginLeft: "20px" }}>
                                    <input
                                        onChange={(e) => {
                                            eligibility_answers.q5c = e.target.value;
                                        }}
                                        type="text"
                                        name="question-5c"
                                    />
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <strong>6.</strong>
                            </td>
                            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <p className="required-field dropdown">
                                    What state does the patient live in?
                                    <br />
                                    {(pageType == "infusion" || pageType == "drug-infusion") && (
                                        <p>
                                            <em>
                                                Please note that patients who reside in or are treated in Massachusetts or Rhode Island are
                                                NOT eligible for the administration co-pay program.
                                            </em>
                                        </p>
                                    )}
                                    {existing_member == true ? (
                                        <select
                                            disabled="disabled"
                                            value={enrollment_data.address.state}
                                            name="state"
                                            className="question-6"
                                            defaultValue="default"
                                        >
                                            <option label="" value="default">
                                                Select a state
                                            </option>
                                            <option label="Alabama" value="AL">
                                                Alabama
                                            </option>
                                            <option label="Alaska" value="AK">
                                                Alaska
                                            </option>
                                            <option label="American Samoa" value="AS">
                                                American Samoa
                                            </option>
                                            <option label="Arizona" value="AZ">
                                                Arizona
                                            </option>
                                            <option label="Arkansas" value="AR">
                                                Arkansas
                                            </option>
                                            <option label="California" value="CA">
                                                California
                                            </option>
                                            <option label="Colorado" value="CO">
                                                Colorado
                                            </option>
                                            <option label="Connecticut" value="CT">
                                                Connecticut
                                            </option>
                                            <option label="Delaware" value="DE">
                                                Delaware
                                            </option>
                                            <option label="Florida" value="FL">
                                                Florida
                                            </option>
                                            <option label="Georgia" value="GA">
                                                Georgia
                                            </option>
                                            <option label="Guam" value="GU">
                                                Guam
                                            </option>
                                            <option label="Hawaii" value="HI">
                                                Hawaii
                                            </option>
                                            <option label="Idaho" value="ID">
                                                Idaho
                                            </option>
                                            <option label="Illinois" value="IL">
                                                Illinois
                                            </option>
                                            <option label="Indiana" value="IN">
                                                Indiana
                                            </option>
                                            <option label="Iowa" value="IA">
                                                Iowa
                                            </option>
                                            <option label="Kansas" value="KS">
                                                Kansas
                                            </option>
                                            <option label="Kentucky" value="KY">
                                                Kentucky
                                            </option>
                                            <option label="Louisiana" value="LA">
                                                Louisiana
                                            </option>
                                            <option label="Maine" value="ME">
                                                Maine
                                            </option>
                                            <option label="Maryland" value="MD">
                                                Maryland
                                            </option>
                                            <option label="Massachusetts" value="MA">
                                                Massachusetts
                                            </option>
                                            <option label="Michigan" value="MI">
                                                Michigan
                                            </option>
                                            <option label="Minnesota" value="MN">
                                                Minnesota
                                            </option>
                                            <option label="Mississippi" value="MS">
                                                Mississippi
                                            </option>
                                            <option label="Missouri" value="MO">
                                                Missouri
                                            </option>
                                            <option label="Montana" value="MT">
                                                Montana
                                            </option>
                                            <option label="Nebraska" value="NE">
                                                Nebraska
                                            </option>
                                            <option label="Nevada" value="NV">
                                                Nevada
                                            </option>
                                            <option label="New Hampshire" value="NH">
                                                New Hampshire
                                            </option>
                                            <option label="New Jersey" value="NJ">
                                                New Jersey
                                            </option>
                                            <option label="New Mexico" value="NM">
                                                New Mexico
                                            </option>
                                            <option label="New York" value="NY">
                                                New York
                                            </option>
                                            <option label="North Carolina" value="NC">
                                                North Carolina
                                            </option>
                                            <option label="North Dakota" value="ND">
                                                North Dakota
                                            </option>
                                            <option label="Northern Mariana Islands" value="NM">
                                                Northern Mariana Islands
                                            </option>
                                            <option label="Ohio" value="OH">
                                                Ohio
                                            </option>
                                            <option label="Oklahoma" value="OK">
                                                Oklahoma
                                            </option>
                                            <option label="Oregon" value="OR">
                                                Oregon
                                            </option>
                                            <option label="Pennsylvania" value="PA">
                                                Pennsylvania
                                            </option>
                                            <option label="Puerto Rico" value="PR">
                                                Puerto Rico
                                            </option>
                                            <option label="Rhode Island" value="RI">
                                                Rhode Island
                                            </option>
                                            <option label="South Carolina" value="SC">
                                                South Carolina
                                            </option>
                                            <option label="South Dakota" value="SD">
                                                South Dakota
                                            </option>
                                            <option label="Tennessee" value="TN">
                                                Tennessee
                                            </option>
                                            <option label="Texas" value="TX">
                                                Texas
                                            </option>
                                            <option label="U.S. Virgin Islands" value="VI">
                                                U.S. Virgin Islands
                                            </option>
                                            <option label="Utah" value="UT">
                                                Utah
                                            </option>
                                            <option label="Vermont" value="VT">
                                                Vermont
                                            </option>
                                            <option label="Virginia" value="VA">
                                                Virginia
                                            </option>
                                            <option label="Washington" value="WA">
                                                Washington
                                            </option>
                                            <option label="Washington DC" value="DC">
                                                Washington DC
                                            </option>
                                            <option label="West Virginia" value="WV">
                                                West Virginia
                                            </option>
                                            <option label="Wisconsin" value="WI">
                                                Wisconsin
                                            </option>
                                            <option label="Wyoming" value="WY">
                                                Wyoming
                                            </option>
                                        </select>
                                    ) : (
                                        <select name="state" className="question-6" defaultValue="default" onChange={(e) => updateState(e)}>
                                            <option label="" value="default">
                                                Select a state
                                            </option>
                                            <option label="Alabama" value="AL">
                                                Alabama
                                            </option>
                                            <option label="Alaska" value="AK">
                                                Alaska
                                            </option>
                                            <option label="American Samoa" value="AS">
                                                American Samoa
                                            </option>
                                            <option label="Arizona" value="AZ">
                                                Arizona
                                            </option>
                                            <option label="Arkansas" value="AR">
                                                Arkansas
                                            </option>
                                            <option label="California" value="CA">
                                                California
                                            </option>
                                            <option label="Colorado" value="CO">
                                                Colorado
                                            </option>
                                            <option label="Connecticut" value="CT">
                                                Connecticut
                                            </option>
                                            <option label="Delaware" value="DE">
                                                Delaware
                                            </option>
                                            <option label="Florida" value="FL">
                                                Florida
                                            </option>
                                            <option label="Georgia" value="GA">
                                                Georgia
                                            </option>
                                            <option label="Guam" value="GU">
                                                Guam
                                            </option>
                                            <option label="Hawaii" value="HI">
                                                Hawaii
                                            </option>
                                            <option label="Idaho" value="ID">
                                                Idaho
                                            </option>
                                            <option label="Illinois" value="IL">
                                                Illinois
                                            </option>
                                            <option label="Indiana" value="IN">
                                                Indiana
                                            </option>
                                            <option label="Iowa" value="IA">
                                                Iowa
                                            </option>
                                            <option label="Kansas" value="KS">
                                                Kansas
                                            </option>
                                            <option label="Kentucky" value="KY">
                                                Kentucky
                                            </option>
                                            <option label="Louisiana" value="LA">
                                                Louisiana
                                            </option>
                                            <option label="Maine" value="ME">
                                                Maine
                                            </option>
                                            <option label="Maryland" value="MD">
                                                Maryland
                                            </option>
                                            <option label="Massachusetts" value="MA">
                                                Massachusetts
                                            </option>
                                            <option label="Michigan" value="MI">
                                                Michigan
                                            </option>
                                            <option label="Minnesota" value="MN">
                                                Minnesota
                                            </option>
                                            <option label="Mississippi" value="MS">
                                                Mississippi
                                            </option>
                                            <option label="Missouri" value="MO">
                                                Missouri
                                            </option>
                                            <option label="Montana" value="MT">
                                                Montana
                                            </option>
                                            <option label="Nebraska" value="NE">
                                                Nebraska
                                            </option>
                                            <option label="Nevada" value="NV">
                                                Nevada
                                            </option>
                                            <option label="New Hampshire" value="NH">
                                                New Hampshire
                                            </option>
                                            <option label="New Jersey" value="NJ">
                                                New Jersey
                                            </option>
                                            <option label="New Mexico" value="NM">
                                                New Mexico
                                            </option>
                                            <option label="New York" value="NY">
                                                New York
                                            </option>
                                            <option label="North Carolina" value="NC">
                                                North Carolina
                                            </option>
                                            <option label="North Dakota" value="ND">
                                                North Dakota
                                            </option>
                                            <option label="Northern Mariana Islands" value="NM">
                                                Northern Mariana Islands
                                            </option>
                                            <option label="Ohio" value="OH">
                                                Ohio
                                            </option>
                                            <option label="Oklahoma" value="OK">
                                                Oklahoma
                                            </option>
                                            <option label="Oregon" value="OR">
                                                Oregon
                                            </option>
                                            <option label="Pennsylvania" value="PA">
                                                Pennsylvania
                                            </option>
                                            <option label="Puerto Rico" value="PR">
                                                Puerto Rico
                                            </option>
                                            <option label="Rhode Island" value="RI">
                                                Rhode Island
                                            </option>
                                            <option label="South Carolina" value="SC">
                                                South Carolina
                                            </option>
                                            <option label="South Dakota" value="SD">
                                                South Dakota
                                            </option>
                                            <option label="Tennessee" value="TN">
                                                Tennessee
                                            </option>
                                            <option label="Texas" value="TX">
                                                Texas
                                            </option>
                                            <option label="U.S. Virgin Islands" value="VI">
                                                U.S. Virgin Islands
                                            </option>
                                            <option label="Utah" value="UT">
                                                Utah
                                            </option>
                                            <option label="Vermont" value="VT">
                                                Vermont
                                            </option>
                                            <option label="Virginia" value="VA">
                                                Virginia
                                            </option>
                                            <option label="Washington" value="WA">
                                                Washington
                                            </option>
                                            <option label="Washington DC" value="DC">
                                                Washington DC
                                            </option>
                                            <option label="West Virginia" value="WV">
                                                West Virginia
                                            </option>
                                            <option label="Wisconsin" value="WI">
                                                Wisconsin
                                            </option>
                                            <option label="Wyoming" value="WY">
                                                Wyoming
                                            </option>
                                        </select>
                                    )}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <strong>7.</strong>
                            </td>
                            <td className="radios" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <p>ls the patient currently receiving XOLAIR from the Genentech Patient Foundation?</p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q7 = e.target.value;
                                            }}
                                            type="radio"
                                            name="question-7"
                                            className="question-7"
                                            value="yes"
                                        />{" "}
                                        Yes <span className="checkmark"></span>
                                    </label>
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q7 = e.target.value;
                                            }}
                                            type="radio"
                                            name="question-7"
                                            className="question-7"
                                            value="no"
                                        />{" "}
                                        No<span className="checkmark"></span>
                                    </label>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <strong>8.</strong>
                            </td>
                            <td className="radios" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <p>
                                    ls the patient currently receiving assistance from any other charitable organization for any of your
                                    out-of-pocket costs that are covered by the XOLAIR Co-pay Program?
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q8 = e.target.value;
                                            }}
                                            type="radio"
                                            name="question-7x"
                                            className="question-7x"
                                            value="yes"
                                        />{" "}
                                        Yes <span className="checkmark"></span>
                                    </label>
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q8 = e.target.value;
                                            }}
                                            type="radio"
                                            name="question-7x"
                                            className="question-7x"
                                            value="no"
                                        />{" "}
                                        No<span className="checkmark"></span>
                                    </label>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <strong>9.</strong>
                            </td>
                            <td className="radios" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <p>
                                    The patient acknowledges and agrees that any patient information disclosed during enrollment, including
                                    contact information, demographic information, and sensitive personal information, such as information
                                    related to the patient's medical condition, treatments, and health insurance benefits, will be shared
                                    with Genentech, the sponsor of the program, its partners, and their respective affiliates. In addition,
                                    information shared by the pharmacy/physician, such as the date the prescription was filled, the date the
                                    medication was administered by the physician (if applicable) and the amount that will be reimbursed by
                                    Genentech will also be shared. The patient authorizes Genentech to receive, use, and share the patient's
                                    personal information in connection with the XOLAIR Co-pay Program. The patient agrees to be contacted by
                                    phone, mail, or email about the XOLAIR Co-pay Program. For more information, please see the Genentech
                                    Privacy Policy at{" "}
                                    <a href="http://www.gene.com/privacy-policy" target="_blank">
                                        www.gene.com/privacy-policy
                                    </a>
                                    . To withdraw from the Program, please contact the Program at <strong>(855) 965-2472</strong> Monday
                                    through Friday between 9am – 8pm ET.
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q9 = e.target.value;
                                            }}
                                            type="radio"
                                            name="question-8"
                                            className="question-8"
                                            value="agree"
                                        />{" "}
                                        Agree <span className="checkmark"></span>
                                    </label>
                                </p>
                                <p>
                                    <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <input
                                            onChange={(e) => {
                                                eligibility_answers.q9 = e.target.value;
                                            }}
                                            type="radio"
                                            name="question-8"
                                            className="question-8"
                                            value="disagree"
                                        />{" "}
                                        Do not agree<span className="checkmark"></span>
                                    </label>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <strong>10.</strong>
                            </td>
                            {pageType == "drug" ? (
                                <td className="radios" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                    <p>
                                        The Co-pay Program ("Program") is valid ONLY for patients with commercial (private or
                                        non-governmental) insurance who have a valid prescription for a Food and Drug Administration
                                        (FDA)-approved indication of a Genentech medicine. The Program is not available to patients whose
                                        prescriptions are reimbursed under any federal, state, or government-funded insurance programs
                                        (included but not limited to Medicare, Medicare Advantage, Medigap, Medicaid, TRICARE, Department of
                                        Defense, or Veterans Affairs Programs) or where prohibited by law or by the patient's health
                                        insurance provider. If at any time a patient begins receiving prescription drug coverage under any
                                        such federal, state or government-funded healthcare programs, the patient will no longer be eligible
                                        for the Program. The Program is not valid for Genentech medicines that are eligible to be reimbursed
                                        in their entirety by private insurance plans or other programs.
                                    </p>
                                    <p>
                                        Under the Program, the patient may be required to pay a co-pay. The final amount owed by a patient
                                        may be as little as $0 for the Genentech medicine (see Program specific details available on the
                                        Program Website). The total patient out-of-pocket cost is dependent on the patient's health
                                        insurance plan. The Program assists with the cost of the Genentech medicine only. It does not assist
                                        with the cost of other medicines, procedures or office visit fees. After reaching the maximum annual
                                        Program benefit amount, the patient will be responsible for all remaining out-of-pocket expenses.
                                        The Program benefit amount cannot exceed the patient's out-of-pocket expenses for the Genentech
                                        medicine. The maximum Program benefit will reset every January 1st. The Program is not health
                                        insurance or a benefit plan. The patient's non-governmental insurance is the primary payer. The
                                        Program does not obligate the use of any specific medicine or provider. Patients receiving
                                        assistance from charitable free medicine programs (such as the Genentech Patient Foundation) or any
                                        other charitable organizations for the same expenses covered by the Program are not eligible. The
                                        Program benefit cannot be combined with any other rebate, free trial or other offer for the
                                        Genentech medicine. No party may seek reimbursement for all or any part of the benefit received
                                        through the Program.
                                    </p>
                                    <p>
                                        The Program may be accepted by participating pharmacies, physicians' offices or hospitals. Once a
                                        patient is enrolled, the Program will honor claims with a date of service that precedes the Program
                                        enrollment date up to 180 days. Claims must be submitted within 365 days from the date of service
                                        unless otherwise indicated. Use of the Program must be consistent with all relevant health insurance
                                        requirements. Participating patients, pharmacies, physicians' offices and hospitals are responsible
                                        for reporting the receipt of all Program benefits as required by any insurer or by law. Programs'
                                        benefits may not be sold, purchased, traded or offered for sale.
                                    </p>
                                    <p>
                                        The patient or their guardian must be 18 years of age or older to receive Program assistance. The
                                        Program is only valid in the United States and U.S. Territories, is void where prohibited by law and
                                        shall follow state restrictions in relation to AB-rated generic equivalents (e.g., MA, CA) where
                                        applicable. Eligible patients will be automatically re-enrolled in the Program on an annual basis
                                        each January 1st. Eligible patients will be removed from the Program after 3 years of inactivity
                                        (e.g., no claims submitted in a 3-year timeframe). Patients who choose reimbursement via virtual
                                        debit card will have access to the patient's funds as long as the patient's virtual debit card is
                                        valid and the patient is active in the Program. Once a patient's virtual debit card has expired and
                                        they are no longer active in the program, the funds will be removed from the virtual debit card.
                                        Program eligibility and automatic re-enrollment are contingent upon the patient's ability to meet
                                        all requirements set forth by the Program. Healthcare providers may not advertise or otherwise use
                                        the Program as a means of promoting their services or Genentech medicines to patients.
                                    </p>
                                    <p>
                                        The value of the Program is intended exclusively for the benefit of the patient. The funds made
                                        available through the Program may only be used to reduce the out-of-pocket costs for the patient
                                        enrolled in the Program. The Program is not intended for the benefit of third parties, including
                                        without limitation third party payers, pharmacy benefit managers, or their agents. If Genentech
                                        determines that a third party has implemented a program that adjusts patient cost-sharing
                                        obligations based on the availability of support under the Program and/or excludes the assistance
                                        provided under the Program from counting towards the patient's deductible or out-of-pocket cost
                                        limitations, Genentech may impose a per fill cap on the cost-sharing assistance available under the
                                        Program. Submission of true and accurate information is a requirement for eligibility and Genentech
                                        reserves the right to disqualify patients who do not comply with Genentech Program Terms and
                                        Conditions. Genentech reserves the right to rescind, revoke or amend the Program without notice at
                                        any time.
                                    </p>
                                    <p>
                                        <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <input
                                                onChange={(e) => {
                                                    eligibility_answers.q10 = e.target.value;
                                                }}
                                                type="radio"
                                                name="question-9"
                                                className="question-9"
                                                value="agree"
                                            />{" "}
                                            Agree <span className="checkmark"></span>
                                        </label>
                                    </p>
                                    <p>
                                        <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <input
                                                onChange={(e) => {
                                                    eligibility_answers.q10 = e.target.value;
                                                }}
                                                type="radio"
                                                name="question-9"
                                                className="question-9"
                                                value="disagree"
                                            />{" "}
                                            Do not agree<span className="checkmark"></span>
                                        </label>
                                    </p>
                                </td>
                            ) : pageType == "infusion" ? (
                                <td className="radios" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                    <p>
                                        The Administration Co-pay Program ("Program") is valid ONLY for patients with commercial (private or
                                        non-governmental) insurance who have a valid prescription for a Food and Drug Administration
                                        (FDA)-approved indication of a Genentech medicine. The Program is not available to patients whose
                                        prescriptions are reimbursed under any federal, state, or government-funded insurance programs
                                        (included but not limited to Medicare, Medicare Advantage, Medigap, Medicaid, TRICARE, Department of
                                        Defense, or Veterans Affairs Programs) or where prohibited by law or by the patient's health
                                        insurance provider. If at any time a patient begins receiving prescription drug coverage under any
                                        such federal, state or government-funded healthcare programs, the patient will no longer be eligible
                                        for the Program. The Program is not valid for administration that is eligible to be reimbursed in
                                        their entirety by private insurance plans or other programs. If the patient chooses to enroll in the
                                        Drug Co-pay Program, the patient must separately enroll and meet all eligibility criteria of that
                                        program.
                                    </p>
                                    <p>
                                        Under the Program, the patient may be required to pay a co-pay. The final amount owed by a patient
                                        may be as little as $0 for the administration of the Genentech medicine (see Program specific
                                        details available at the Program Website). The total patient out-of-pocket cost is dependent on the
                                        patient's health insurance plan. The Program assists with the costs of the administration of the
                                        Genentech medicine only. It does not assist with the cost of other administrations, medicines,
                                        procedures or office visit fees. After reaching the maximum per treatment or annual Program benefit
                                        amounts, the patient will be responsible for all remaining out-of-pocket expenses. The Program
                                        benefit amount cannot exceed the patient's out-of-pocket expenses for the administration fees for
                                        the Genentech medicine. The maximum Program benefit will reset every January 1st. The Program is not
                                        health insurance or a benefit plan. The patient's non-governmental insurance is the primary payer.
                                        The Program does not obligate the use of any specific medicine or provider. The Program is valid for
                                        patients receiving free medicine from the Genentech Patient Foundation. The Program is not valid for
                                        patients receiving assistance from any other charitable organizations for the same expenses covered
                                        by the Program. The Program benefit cannot be combined with any other rebate, free trial or other
                                        offer for the administration of the Genentech medicine. No party may seek reimbursement for all or
                                        any part of the benefit received through the Program.
                                    </p>
                                    <p>
                                        The Program may be accepted by participating pharmacies, physicians' offices or hospitals. Once a
                                        patient is enrolled, the Program will honor administration claims with a date of service that
                                        precedes the Program enrollment up to 180 days. Claims must be submitted within 365 days from the
                                        date of service unless otherwise indicated. Use of the Program must be consistent with all relevant
                                        health insurance requirements. Participating patients, pharmacies, physicians' offices and hospitals
                                        are responsible for reporting the receipt of all Program benefits as required by any insurer or by
                                        law. Programs' benefits may not be sold, purchased, traded or offered for sale.
                                    </p>
                                    <p>
                                        The patient or their guardian must be 18 years of age or older to receive Program assistance. The
                                        Program is only valid in the United States and U.S. Territories and is void where prohibited by law.
                                        The Program is not valid for patients who reside or receive treatment in a restricted state (e.g.
                                        Massachusetts or Rhode Island). Eligible patients will be automatically re-enrolled in the Program
                                        on an annual basis. Eligible patients will be removed from the Program after 3 years of inactivity
                                        (e.g., no claims submitted in a 3-year timeframe). Patients who choose reimbursement via virtual
                                        debit card will have access to the patient's funds as long as the patient's virtual debit card is
                                        valid and the patients are active in the Program. Once a patient's virtual debit card has expired
                                        and they are no longer active in the program, the funds will be removed from the card. Program
                                        eligibility and automatic re-enrollment are contingent upon the patient's ability to meet all
                                        requirements set forth by the Program. Healthcare providers may not advertise or otherwise use the
                                        Program as a means of promoting their services or Genentech medicines to patients.
                                    </p>
                                    <p>
                                        The value of the Program is intended exclusively for the benefit of the patient. The funds made
                                        available through the Program may only be used to reduce the out-of-pocket costs for the patient
                                        enrolled in the Program. The Program is not intended for the benefit of third parties, including
                                        without limitation third party payers, pharmacy benefit managers, or their agents. If Genentech
                                        determines that a third party has implemented a program that adjusts patient cost-sharing
                                        obligations based on the availability of support under the Program and/or excludes the assistance
                                        provided under the Program from counting towards the patient's deductible or out-of-pocket cost
                                        limitations, Genentech may impose a per fill cap on the cost-sharing assistance available under the
                                        Program. Submission of true and accurate information is a requirement for eligibility and Genentech
                                        reserves the right to disqualify patients who do not comply with Genentech Program Terms and
                                        Conditions. Genentech reserves the right to rescind, revoke or amend the Program without notice at
                                        any time.
                                    </p>
                                    <p>
                                        <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <input
                                                onChange={(e) => {
                                                    eligibility_answers.q10 = e.target.value;
                                                }}
                                                type="radio"
                                                name="question-9"
                                                className="question-9"
                                                value="agree"
                                            />{" "}
                                            Agree <span className="checkmark"></span>
                                        </label>
                                    </p>
                                    <p>
                                        <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <input
                                                onChange={(e) => {
                                                    eligibility_answers.q10 = e.target.value;
                                                }}
                                                type="radio"
                                                name="question-9"
                                                className="question-9"
                                                value="disagree"
                                            />{" "}
                                            Do not agree<span className="checkmark"></span>
                                        </label>
                                    </p>
                                </td>
                            ) : (
                                <td className="radios" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                    <p>
                                        The Product and Administration Co-pay Programs ("Programs") are valid ONLY for patients with
                                        commercial (private or non-governmental) insurance who have a valid prescription for a Food and Drug
                                        Administration (FDA)-approved indication of a Genentech medicine. The Product and Administration
                                        Co-pay Programs are not available to patients whose prescriptions are reimbursed under any federal,
                                        state, or government-funded insurance programs (included by not limited to Medicare, Medicare
                                        Advantage, Medigap, Medicaid, TRICARE, Department of Defense, or Veterans Affairs Programs) or where
                                        prohibited by law or by the patient's health insurance provider. If at any time a patient begins
                                        receiving prescription drug coverage under any such federal, state or government-funded healthcare
                                        programs, the patient will no longer be eligible for the Programs. The Programs are not valid if the
                                        costs are eligible to be reimbursed in their entirety by private insurance plans or other programs.
                                    </p>
                                    <p>
                                        Under the Programs, the patient may be required to pay a co-pay. The final amount owed by a patient
                                        may be as little as $0 for the Genentech medicine or administration of the Genentech medicine (see
                                        Program specific details available at the Program Website). The total patient out-of-pocket cost is
                                        dependent on the patient's health insurance plan. The Programs assist with the cost of the Genentech
                                        medicine and the administration of the Genentech medicine only. It does not assist with the cost of
                                        other administrations, medicines, procedures or office visit fees. After reaching the maximum
                                        Programs' benefit amounts, the patient will be responsible for all remaining out-of-pocket expenses.
                                        The Programs' benefit amounts cannot exceed the patient's out-of-pocket expenses for the Genentech
                                        medicine or administration fees of the Genentech medicine. The maximum Programs' benefits will reset
                                        every January 1st. The Programs are not health insurance or a benefit plan. The patient's
                                        non-governmental insurance is the primary payer. The Programs do not obligate use of any specific
                                        medicine or provider. The Drug Co-pay Program is not available or valid for patients receiving free
                                        medicine from the Genentech Patient Foundation. The Administration Co-pay Program is valid for
                                        patients receiving free medicine from the Genentech Patient Foundation. The Product and
                                        Administration Programs are not valid for patients receiving assistance from any other charitable
                                        organization for the same expenses covered by the Programs. The Programs' benefits cannot be
                                        combined with any other rebate, free trial or other offer for the Genentech medicine or
                                        administration of the Genentech medicine. No party may seek reimbursement for all or any part of the
                                        benefits received through these Programs.
                                    </p>
                                    <p>
                                        The Programs may be accepted by participating pharmacies, physicians' offices or hospitals. Once a
                                        patient is enrolled, the Programs will honor claims with a date of service that precedes the
                                        Programs' enrollment by 180 days. Claims must be submitted within 365 days from the date of service
                                        unless otherwise indicated. Use of these Programs must be consistent with all relevant health
                                        insurance requirements. Participating patients, pharmacies, physicians' offices and hospitals are
                                        responsible for reporting the receipt of all the Programs' benefits as required by any insurer or by
                                        law. Programs' benefits may not be sold, purchased, traded or offered for sale.
                                    </p>
                                    <p>
                                        The patient or their guardian must be 18 years of age or older to receive assistance from the
                                        Programs. The Programs are only valid in the United States and U.S. Territories and are void where
                                        prohibited by law. The Drug Co-pay Program shall follow state restrictions in relation to AB-rated
                                        generic equivalents (e.g., MA, CA) where applicable. The Administration Co-pay Program is not valid
                                        for patients who reside or receive treatment in a restricted state (e.g. Massachusetts or Rhode
                                        Island). Eligible patients will be automatically re-enrolled in the Programs on an annual basis.
                                        Eligible patients will be removed from the Programs after 3 years of inactivity (e.g., no claims
                                        submitted in a 3-year timeframe). Patients who choose reimbursement via virtual debit card will have
                                        access to the patient's funds as long as the patient's virtual debit card is valid and the patient
                                        is active in the Programs. Once a patient's virtual debit card has expired and they are no longer
                                        active in the program, the funds will be removed from the virtual debit card. Programs' eligibility
                                        and automatic re-enrollment are contingent upon the patient's ability to meet all the requirements
                                        set forth by the Programs. Healthcare providers may not advertise or otherwise use the Programs as a
                                        means of promoting their services or Genentech medicines to patients.
                                    </p>
                                    <p>
                                        The value of the Programs is intended exclusively for the benefit of the patient. The funds made
                                        available through the Programs may only be used to reduce the out-of-pocket costs for the patient
                                        enrolled in the Programs. The Programs are not intended for the benefit of third parties, including
                                        without limitation third party payers, pharmacy benefit managers, or their agents. If Genentech
                                        determines that a third party has implemented programs that adjust patient cost-sharing obligations
                                        based on the availability of support under the Programs and/or excludes the assistance provided
                                        under the Programs from counting towards the patient's deductible or out-of-pocket cost limitations,
                                        Genentech may impose a per fill cap on the cost-sharing assistance available under the Programs.
                                        Submission of true and accurate information is a requirement for eligibility and Genentech reserves
                                        the right to disqualify patients who do not comply with Genentech Program Terms and Conditions.
                                        Genentech reserves the right to rescind, revoke or amend the Program without notice at any time.
                                    </p>
                                    <p>
                                        <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <input
                                                onChange={(e) => {
                                                    eligibility_answers.q10 = e.target.value;
                                                }}
                                                type="radio"
                                                name="question-9"
                                                className="question-9"
                                                value="agree"
                                            />{" "}
                                            Agree <span className="checkmark"></span>
                                        </label>
                                    </p>
                                    <p>
                                        <label className="radio" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                            <input
                                                onChange={(e) => {
                                                    eligibility_answers.q10 = e.target.value;
                                                }}
                                                type="radio"
                                                name="question-9"
                                                className="question-9"
                                                value="disagree"
                                            />{" "}
                                            Do not agree<span className="checkmark"></span>
                                        </label>
                                    </p>
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}></td>
                            <td style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                <p className="centerTxt">
                                    <button type="submit" name="apply-step-one-submit">
                                        Next
                                    </button>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
            <div className="clearfloat"></div>
        </>
    );
}
