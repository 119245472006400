import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import EFTPaymentStatus from '../components/EFTPaymentStatus'
import DebitLoadStatus from '../components/DebitLoadStatus'
import CheckPaymentStatus from '../components/CheckPaymentStatus'



export default function Eft() {
    
    var todaysdate = new Date() 
    var date = new Date(new Date().setDate(todaysdate.getDate() - 30));
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    if(month < 10){
        month = '0' + month
    }
    var day = date.getDate()
    if(day < 10){
        day = '0' + day
    }

    
    var todaymonth = todaysdate.getMonth() + 1
    var todayday = todaysdate.getDate()
    var todayyear = todaysdate.getFullYear()
    if(todaymonth < 10){
        todaymonth = '0' + todaymonth
    }
    if(todayday < 10){
        todayday = '0' + todayday
    }


    const [fromdate, inputsetfromdate] = useState(year + '-' + month + '-' + day)
    const [todate, inputsettodate] = useState(todayyear + '-' + todaymonth + '-' + todayday)

    //const [date, setDate] = useState('')
    /*
        useEffect(() => {
          
    $(function () {
        $(".datepicker").datepicker({ dateFormat: 'mm/dd/yy' });
    });
    
    function updateToDate() {
        //var date = new Date();
        var date = $("#start_date").datepicker("getDate");
        date.setDate(date.getDate() + 7);
        var dateMsg = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
        //alert(dateMsg);
        document.getElementById("end_date").value = dateMsg;
    }
    
           
        })
        */

    useEffect(() => {
        $(function () {
            $('#tabs').tabs({ active: 0 });
            $('#ui-tabs-active').css("color", "#454545");
        });

        $(document).ready(function () {
            $(".datepicker").datepicker({
                dateFormat: 'mm/dd/yy', showButtonPanel: true,
                changeMonth: true,
                changeYear: true,
                yearRange: "1920:+nn",
                showOtherMonths: true,
                selectOtherMonths: true
            });
        });
        $(document).ready(function () {
            $(".datepicker2").datepicker({
                dateFormat: 'mm/dd/yy', showButtonPanel: true,
                changeMonth: true,
                changeYear: true,
                yearRange: "1920:+nn",
                showOtherMonths: true,
                selectOtherMonths: true
            });
        });

    })

    function updateDateRange(e) {
        e.preventDefault();

        var date = $(".datepicker").val()
        var month = date.substring(0, 2)
        var day = date.substring(3, 5)
        var year = date.substring(6, 10)
        if (date != '') {
            inputsetfromdate(year + "-" + month + "-" + day)
        }

        var date2 = $(".datepicker2").val()
        var month2 = date2.substring(0, 2)
        var day2 = date2.substring(3, 5)
        var year2 = date2.substring(6, 10)
        if (date2 != '') {
            inputsettodate(year2 + "-" + month2 + "-" + day2)
        }
    }

    return (
        <div className="container" id="content" style={{ fontSize: '1em' }}>
            <div id="container">
                <div className="containerRight">
                    <h1 style={{ fontSize: '1.5em', lineHeight: '1.49em' }}>PAYMENT REPORT</h1>
                    <div className="clearfloat"></div>
                    <div id="date_wrapper">
                        <div className="required"></div>
                        <form onSubmit={e => updateDateRange(e)} acceptCharset="utf-8" className="find_patient">
                        <p><input className="datepicker" name="start_date" type="text" size="12" placeholder={month + '/' + day + '/' + year} id="start_date" autoComplete='off' /> </p>
                        
                         
                        <p><input className="datepicker2" name="end_date" type="text" size="12" placeholder={todaymonth + '/' + todayday + '/' + todayyear} id="end_date" autoComplete='off' /></p>
                    
                            <p><button type="submit" name="list_all" id="grey-button">Search</button> </p>
                        </form>
                    </div>
                    <div id="tabs" className="tabs ui-tabs ui-widget ui-widget-content ui-corner-all">
                        <p></p>
                        <p></p>
                        <ul className="nav ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all" role="tablist" style={{ padding: '0' }}>
                            <li style={{ marginLeft: '0' }} className="ui-state-default ui-corner-top ui-tabs-active ui-state-active" role="tab" tabIndex="0" aria-controls="tabs-2" aria-labelledby="ui-id-2" aria-selected="true"><Link to="#tabs-2" style={{ color: "#454545" }} className="ui-tabs-anchor" role="presentation" tabIndex="-1" id="ui-id-2">EFT</Link></li>
                            <li style={{ marginLeft: '0' }} className="ui-state-default ui-corner-top" role="tab" tabIndex="-1" aria-controls="tabs-3" aria-labelledby="ui-id-3" aria-selected="false"><Link to="#tabs-3" style={{ color: "#454545" }} className="ui-tabs-anchor" role="presentation" tabIndex="-1" id="ui-id-3">Debit</Link></li>
                            <li style={{ marginLeft: '0' }} className="ui-state-default ui-corner-top" role="tab" tabIndex="-1" aria-controls="tabs-1" aria-labelledby="ui-id-1" aria-selected="false"><Link to="#tabs-1" style={{ color: "#454545" }} className="ui-tabs-anchor" role="presentation" tabIndex="-1" id="ui-id-1">Check</Link></li>
                        </ul>
                        <div id="tabs-1" style={{ padding: '0px', display: 'none' }} className="fromAlign ui-tabs-panel ui-widget-content ui-corner-bottom" aria-labelledby="ui-id-1" role="tabpanel" aria-expanded="false" aria-hidden="true">
                            <CheckPaymentStatus fromdate={fromdate} todate={todate} />
                        </div>
                        <div id="tabs-3" style={{ display: 'none', padding: '0' }} aria-labelledby="ui-id-3" className="ui-tabs-panel ui-widget-content ui-corner-bottom" role="tabpanel" aria-expanded="false" aria-hidden="true">
                            <DebitLoadStatus fromdate={fromdate} todate={todate} />
                        </div>
                        <div id="tabs-2" style={{ padding: '0px' }} aria-labelledby="ui-id-2" className="ui-tabs-panel ui-widget-content ui-corner-bottom" role="tabpanel" aria-expanded="true" aria-hidden="false">
                            <EFTPaymentStatus fromdate={fromdate} todate={todate} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


