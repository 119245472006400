import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import ProgressBar from '../../components/ProgressBar'


export default function Enroll() {

    const { setTitle, setStep, enrollment_type, setenrollmenttype,eligibility_answers } = useOutletContext()

    const [formURL, setFormURL] = useState('')
    const navigate = useNavigate()
    function submitForm(e) {
        e.preventDefault()
        //console.log(formURL)
        navigate('../enroll-attestation-' + formURL)
        setenrollmenttype(formURL)
        window.localStorage.setItem('enrollment_type', formURL)
    }

    window.localStorage.removeItem('provider_data');
    window.localStorage.removeItem('product');
    window.localStorage.removeItem('email_opt_in');
    window.localStorage.removeItem('site_of_care');
    window.localStorage.removeItem('eligibility_answers');
    window.localStorage.removeItem('enrollment_data');
    window.localStorage.removeItem('existing_member');
    window.localStorage.removeItem('enrolled_patient_id');
    window.localStorage.removeItem('enroll_success');
    window.localStorage.removeItem('verification_data');
    window.localStorage.removeItem('member_id');
    window.localStorage.removeItem('enrollment_type');
    eligibility_answers.q3date='';
    window.localStorage.removeItem('routeChanges');

    useEffect(() => {
        $(function () {
            $('input.other').on('change', function (event) {
                if ($(this).is(':checked')) {

                    $('form.apply-step-one-form').attr('action', 'enroll-attestation-lap');
                }
            });
            $('input.patient').on('change', function (event) {
                if ($(this).is(':checked')) {

                    $('form.apply-step-one-form').attr('action', 'enroll-attestation-patient');
                }
            });
        });

        $(function () {
            $('.patient, .other').on('click', function () {
                if ($(this).is(':checked')) {
                    $("#privacy1").css("display", "block");
                    $(".buttons").css("display", "block");
                    $("#agree").prop("disabled", false);
                    $("#privacy3").css("display", "none");
                } else {
                    $("#privacy1").css("display", "none");
                }
            });

            $('.hcp-enroll').on('click', function () {
                if ($(this).is(':checked')) {
                    $("#privacy1").css("display", "none");
                    $("#privacy3").css("display", "block");
                    $(".buttons").css("display", "none");
                } else {
                    $("#privacy3").css("display", "none");
                }
            });
        });

        setTitle('Apply for the XOLAIR Co-pay Program')
        setStep('1')



    })
    return (
        <>
            <form onSubmit={e => submitForm(e)}>
                <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Who is the provider applying for?</h2>
                <p><label className="radio" style={{ fontSize: '1em', lineHeight: '1.49em' }}><input type="radio" className="patient" name="enroll" value="patient" onChange={e => setFormURL(e.target.value)} />&nbsp;Patient <span className="checkmark"></span></label></p>
                <p><label className="radio" style={{ fontSize: '1em', lineHeight: '1.49em' }}><input type="radio" className="other" name="enroll" value="lap" onChange={e => setFormURL(e.target.value)} />&nbsp;Legally Authorized Person on behalf of the patient<span className="checkmark"></span></label></p>
                <div id="privacy1" style={{ display: 'none' }}>
                    <p><button id="agree" type="submit" value="Next" >Next</button></p>
                </div>
                <div id="privacy3" style={{ display: 'none' }}>
                    <p><button id="agree" type="button" className="hcp-tab">Next</button></p>
                </div>
            </form>
            <div className="clearfloat"></div>
        </>
    )
}
