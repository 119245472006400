import React , {useEffect} from 'react'
import {Link} from 'react-router-dom'
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import AccountNavbar from '../components/AccountNavbar'
import ProviderInformation from '../components/ProviderInformation'
import SiteOfCareInformation from '../components/SiteOfCareInformation'

/*
$(function() {
	$(".datepicker").datepicker({dateFormat: 'mm/dd/yy',showButtonPanel: true,
    changeMonth: true,
    changeYear: true,
    showOtherMonths: true,
    selectOtherMonths: true });
});
*/

export default function DoctorInformation(){
    return (
        <div className="container" id="content" style={{fontSize: '1em'}}>
          <div id="container" className="account">
          <AccountNavbar page="provider-information" />
            <div className="clearfloat"></div>
            <ProviderInformation />
            <SiteOfCareInformation />
            </div>
            <div className="rejected-popup">
            <div className="popup-box">
            <h2>Claim Reject Reason</h2>
            <p>Duplicate Claim.</p>
            <button className="close-button">Close</button>
            </div>
          </div>
        </div>


    )
}