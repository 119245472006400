import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client';
import { BANK_ACCOUNT_INFO } from '../../graphql/queries/Providers'
import { UPDATE_ACH } from '../../graphql/mutations/Providers'


export default function BankAccountInfoEFT() {

    const provider_id = window.localStorage.getItem('providerID')

    const [account_name, setpracticename] = useState('')
    const [bank, setbank] = useState('')
    const [request_date, setrequestdate] = useState('')
    const [routing_number, setroutingnumber] = useState('')
    const [approval_date, setapprovaldate] = useState('')
    const [account_number, setaccountnumber] = useState('')
    const [original_account_number, setoriginalaccountnumber] = useState('')
    const [eft_status, seteftstatus] = useState('')
    const [bank_address, setbankaddress] = useState('')

    const { loading, error, data, refetch } = useQuery(BANK_ACCOUNT_INFO, {
        variables: { provider_id: parseInt(provider_id) }
    });

    const [updateProvider, { data: newData, loading: loadingUpdate, error: errorUpdate }] = useMutation(UPDATE_ACH);


    useEffect(() => {
        if(data != null){
            if(data.providerach != null){
                if(data.providerach.ach_info != null){
                    if(data.providerach.ach_info.account_name){
                        setpracticename(data.providerach.ach_info.account_name)
                    }
                    if(data.providerach.ach_info.bank){
                        setbank(data.providerach.ach_info.bank)
                    }
                    if(data.providerach.ach_info.request_date){
                        setrequestdate(data.providerach.ach_info.request_date)
                    }
                    if(data.providerach.ach_info.routing_number){
                        setroutingnumber(data.providerach.ach_info.routing_number)
                    }
                    if(data.providerach.ach_info.approval_date){
                        setapprovaldate(data.providerach.ach_info.approval_date)
                    }
                    if(data.providerach.ach_info.account_number){
                        setaccountnumber(data.providerach.ach_info.account_number)
                        setoriginalaccountnumber(data.providerach.ach_info.account_number)
                        if (data.providerach.ach_info.account_number != "") {
                            const show = data.providerach.ach_info.account_number.slice(-4)
                            const length = data.providerach.ach_info.account_number.length - 2
                            var stars = ''
                            for (let i = 0; i < length; i++) {
                                stars += '*'
                              }
                            setaccountnumber(stars + show)
                        }

                    }
                    if(data.providerach.ach_info.eft_status){
                        seteftstatus(data.providerach.ach_info.eft_status)
                    }
                    if(data.providerach.ach_info.bank_address){
                        setbankaddress(data.providerach.ach_info.bank_address)
                    }
                }
            }
        }
    }, [data])

    function updateProviderInfo(e) {
        e.preventDefault()
        var accountNumber 
        if(account_number.includes("*") == true){
            accountNumber = original_account_number
        } else {
            accountNumber = account_number
        }
        updateProvider({ variables: { provider_id, bank, routing_number, account_number: accountNumber, request_date, bank_address, account_name } })
        window.location.reload()
    }

    return (
        <div className="section shadow-box">
            <h2 style={{ marginBottom: '0px', fontSize: '1.25em', lineHeight: '1.49em' }}>Bank Account Information for Electronic Funds Transfer (EFT) </h2>
            <p style={{ minHeight: 'inherit' }}><small><strong>(required if requesting direct deposit of payments)</strong></small></p>
            <div className="space"></div>
            <p style={{ minHeight: 'inherit' }}>Please complete and update the following information after faxing the completed XOLAIR Co-pay Program EFT Authorization Form:</p>
            <form id="form" onSubmit={e => updateProviderInfo(e)} className="form-validate">
                <div className="col-sm-12 col-md-12 col-lg-8">
                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Practice name as registered to the bank account<span className="required">*</span></span></label>
                        <input type="text" onChange={e => setpracticename(e.target.value)} value={account_name} />
                    </p>
                </div>
                <div className="clear"></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Bank name<span className="required">*</span></span></label>
                        <input type="text" onChange={e => setbank(e.target.value)} value={bank} />
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-8">
                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Bank branch address, city, state, ZIP code<span className="required">*</span></span></label>
                        <input type="text" onChange={e => setbankaddress(e.target.value)} value={bank_address} />
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Bank ABA routing number<span className="required">*</span></span></label>
                        <input type="text" onChange={e => setroutingnumber(e.target.value)} value={routing_number} />
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">

                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Confirm bank ABA routing number<span className="required">*</span></span></label>
                        <input type="text" />
                    </p>
                </div>
                <div className="clear"></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Account number<span className="required">*</span></span></label>
                        <input type="text" onChange={e => setaccountnumber(e.target.value)} value={account_number} />
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">

                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Confirm account number<span className="required">*</span></span></label>
                        <input type="text" />
                    </p>
                </div>
                <div className="clear"></div>
                <div className="col-sm-12 col-md-12 col-lg-4"><span className="color">Date of ACH Request</span><br />{request_date}</div>
                <div className="col-sm-12 col-md-12 col-lg-4"><span className="color">EFT Enrollment Status</span><br />{eft_status}</div>
                <div className="col-sm-12 col-md-12 col-lg-4"><span className="color">Date of ACH Approval</span><br />{approval_date ? approval_date : 'N/A'}</div>
                <div className="clear"><p></p></div>
                <p style={{ minHeight: 'inherit' }}><input type="checkbox" className="terms-hcp" />&nbsp;Please click <a style={{ cursor: 'pointer' }} className="terms-hcp">Terms and Conditions</a>. You must read and accept to continue.<br /></p>
                <p><label style={{ fontSize: '1em', lineHeight: '1.49em' }}>&nbsp;</label>
                    <button type="submit" id="next-hcp" name="profile_update" value="Submit" disabled="">Update</button>
                </p>
            </form>
        </div>
    )
}

/*
import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client';
import { BANK_ACCOUNT_INFO } from '../../graphql/queries/Providers'
import { UPDATE_ACH } from '../../graphql/mutations/Providers'


export default function BankAccountInfoEFT() {

    const provider_id = window.localStorage.getItem('providerID')

    const [account_name, setpracticename] = useState('')
    const [bank, setbank] = useState('')
    const [request_date, setrequestdate] = useState('')
    const [routing_number, setroutingnumber] = useState('')
    const [approval_date, setapprovaldate] = useState('')
    const [original_account_number, setoriginalaccountnumber] = useState('')
    const [account_number, setaccountnumber] = useState('')
    const [eft_status, seteftstatus] = useState('')
    const [bank_address, setbankaddress] = useState('')

    const { loading, error, data, refetch } = useQuery(BANK_ACCOUNT_INFO, {
        variables: { provider_id: parseInt(provider_id) }
    });

    const [updateProvider, { data: newData, loading: loadingUpdate, error: errorUpdate }] = useMutation(UPDATE_ACH);


    useEffect(() => {
        if(data != null){
            if(data.providerach != null){
                if(data.providerach.ach_info != null){
                    if(data.providerach.ach_info.account_name){
                        setpracticename(data.providerach.ach_info.account_name)
                    }
                    if(data.providerach.ach_info.bank){
                        setbank(data.providerach.ach_info.bank)
                    }
                    if(data.providerach.ach_info.request_date){
                        setrequestdate(data.providerach.ach_info.request_date)
                    }
                    if(data.providerach.ach_info.routing_number){
                        setroutingnumber(data.providerach.ach_info.routing_number)
                    }
                    if(data.providerach.ach_info.approval_date){
                        setapprovaldate(data.providerach.ach_info.approval_date)
                    }
                    if(data.providerach.ach_info.account_number){
                        setaccountnumber(data.providerach.ach_info.account_number)
                        setoriginalaccountnumber(data.providerach.ach_info.account_number)
                        if (data.providerach.ach_info.account_number != "") {
                            const show = data.providerach.ach_info.account_number.substring(4, 8)
                            setaccountnumber('****' + show)
                        }
                    }
                    if(data.providerach.ach_info.eft_status){
                        seteftstatus(data.providerach.ach_info.eft_status)
                    }
                    if(data.providerach.ach_info.bank_address){
                        setbankaddress(data.providerach.ach_info.bank_address)
                    }
                }
            }
        }
    }, [data])

    function updateProviderInfo(e) {
        e.preventDefault()
        var accountNumber 
        if(account_number.includes("*") == true){
            accountNumber = original_account_number
        } else {
            accountNumber = account_number
        }
        updateProvider({ variables: { provider_id, bank, routing_number, account_number: accountNumber, request_date, bank_address, account_name } })
    }

    return (
        <div className="section shadow-box">
            <h2 style={{ marginBottom: '0px', fontSize: '1.25em', lineHeight: '1.49em' }}>Bank Account Information for Electronic Funds Transfer (EFT) </h2>
            <p style={{ minHeight: 'inherit' }}><small><strong>(required if requesting direct deposit of payments)</strong></small></p>
            <div className="space"></div>
            <p style={{ minHeight: 'inherit' }}>Please complete and update the following information after faxing the completed XOLAIR Co-pay Program EFT Authorization Form:</p>
            <form id="form" autoComplete='off' onSubmit={e => updateProviderInfo(e)} className="form-validate">
                <div className="col-sm-12 col-md-12 col-lg-8">
                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Practice name as registered to the bank account<span className="required">*</span></span></label>
                        <input type="text" onChange={e => setpracticename(e.target.value)} value={account_name} />
                    </p>
                </div>
                <div className="clear"></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Bank name<span className="required">*</span></span></label>
                        <input type="text" onChange={e => setbank(e.target.value)} value={bank} />
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-8">
                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Bank branch address, city, state, ZIP code<span className="required">*</span></span></label>
                        <input type="text" onChange={e => setbankaddress(e.target.value)} value={bank_address} />
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Bank ABA routing number<span className="required">*</span></span></label>
                        <input type="text" onChange={e => setroutingnumber(e.target.value)} value={routing_number} />
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">

                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Confirm bank ABA routing number<span className="required">*</span></span></label>
                        <input type="text" />
                    </p>
                </div>
                <div className="clear"></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Account number<span className="required">*</span></span></label>
                        <input type="text" onChange={e => setaccountnumber(e.target.value)} value={account_number} />
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">

                    <p className="field required-field text">
                        <label htmlFor="firstName" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Confirm account number<span className="required">*</span></span></label>
                        <input type="text" />
                    </p>
                </div>
                <div className="clear"></div>
                <div className="col-sm-12 col-md-12 col-lg-4"><span className="color">Date of ACH Request</span><br />{request_date}</div>
                <div className="col-sm-12 col-md-12 col-lg-4"><span className="color">EFT Enrollment Status</span><br />{eft_status}</div>
                <div className="col-sm-12 col-md-12 col-lg-4"><span className="color">Date of ACH Approval</span><br />{approval_date ? approval_date : 'N/A'}</div>
                <div className="clear"><p></p></div>
                <p style={{ minHeight: 'inherit' }}><input type="checkbox" className="terms-hcp" />&nbsp;Please click <a style={{ cursor: 'pointer' }} className="terms-hcp">Terms and Conditions</a>. You must read and accept to continue.<br /></p>
                <p><label style={{ fontSize: '1em', lineHeight: '1.49em' }}>&nbsp;</label>
                    <button type="submit" id="next-hcp" name="profile_update" value="Submit" disabled="">Update</button>
                </p>
            </form>
        </div>
    )
}
*/