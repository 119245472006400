import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $jquery from "jquery";
import patientBanner from "../images/patients-banner.jpeg";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.min.css";

// $(function () {
//     $(".home-page-announcement .close-button").on("click", function (event) {
//         event.preventDefault();
//         $(".home-page-announcement").css("display", "none");
//     });
// });
$(function () {
    $(".hcp-tab").on("click", function (event) {
        event.preventDefault();
        $(".hcp-popup").css("display", "block");
    });

    $(".hcp-popup .close-button").on("click", function (event) {
        event.preventDefault();
        $(".hcp-popup").css("display", "none");
    });
    $("a.parent").on("click", function (event) {
        event.preventDefault();
    });
});

export default function Home() {
    function closeAnnouncement() {
        $(".home-page-announcement").css("display", "none");
    }

    return (
        <>
            {/* <div className="home-page-announcement">
                <div className="popup-box">
                    <h1>Program Update</h1>
                    

                    <p>
                        <button className="close-button" onClick={closeAnnouncement} type="button">
                            Close
                        </button>
                    </p>
                </div>
            </div> */}

            <div className="container" id="content" style={{ fontSize: "1em" }}>
                <div id="container">
                    <div id="home-container-patients">
                        <img src={patientBanner} />
                        <p style={{ fontSize: "14px", marginTop: "10px" }}>
                            <strong>
                                The final amount owed by patients may be as little as $0, but may vary depending on the patient's health
                                insurance plan. Eligible commercially insured patients who are prescribed XOLAIR for an FDA-approved use can
                                receive up to $15,000 in assistance annually for drug costs and/or up to $1,500 in assistance annually for
                                injection costs. For more information, see full program{" "}
                                <Link to="terms-and-conditions">Terms and Conditions</Link>.
                            </strong>
                        </p>
                    </div>
                    <div className="col-xs-12 col-sm-8 col-md-8 content-buttons" style={{ float: "none" }}>
                        <div className="col-xs-12 col-sm-12 col-lg-4">
                            <p>
                                <Link className="button enroll" to="enroll">
                                    Apply Now
                                </Link>
                            </p>
                            <p style={{ textAlign: "center" }}>Apply for drug and/or administration co-pay assistance.</p>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-lg-4" style={{ float: "right" }}>
                            <p>
                                <Link className="button account" to="login">
                                    My Account
                                </Link>
                            </p>
                            <p style={{ textAlign: "center" }}>
                                Don't have an account? <br />
                                <Link to="create-account">Create one here.</Link>
                            </p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-lg-12">
                        <div className="col-xs-12 col-sm-12 col-lg-12"> </div>
                        <div className="col-xs-12 col-sm-12 col-lg-12"> </div>
                    </div>
                </div>
            </div>
        </>
    );
}
