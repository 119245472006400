import React from 'react'
import { useOutletContext } from 'react-router-dom'

export default function ReviewPatientInformation() {

    const { enrollment_data, setenrollmentdata } = useOutletContext()

    
    return (
        <div className="section shadow-box color-label">
            <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Patient Information</h2>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p>
                        <label htmlFor="firstName" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>First name</label>
                        {enrollment_data.first_name}    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p>
                        <label htmlFor="lastName" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Last name</label>
                        {enrollment_data.last_name}    </p></div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p>
                        <label htmlFor="gender" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Gender</label><br />
                        {enrollment_data.gender}    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className=" text date">
                        <label htmlFor="birthDate" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Birth date</label>
                        {enrollment_data.dob}    </p></div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p>
                        <label htmlFor="street" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Address</label>
                        {enrollment_data.address.address1}    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p>
                        <label htmlFor="street" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Address 2</label>
                    {enrollment_data.address.address2} </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p>
                        <label htmlFor="city" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>City</label>
                        {enrollment_data.address.city}   </p></div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p>
                        <label htmlFor="state" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>State</label><br />
                        {enrollment_data.address.state}    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className=" text zipcode">
                        <label htmlFor="zipCode" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>ZIP code</label>
                        {enrollment_data.address.zip_code}    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className=" text zipcode">
                        <label htmlFor="zipCode" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Email Address</label>
                        {enrollment_data.email}<br />
                        <small>*This email may be used for any future co-pay related communications.</small>
                    </p></div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className=" text phone">
                        <label htmlFor="homePhone" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Phone number</label>
                        {enrollment_data.phone_number}    </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className=" text phone">
                        <label htmlFor="homePhone" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Phone type</label>
                        Home    </p></div>
            </div>
        </div>
    )
}