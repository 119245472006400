import React, { useEffect, useState, useCallback } from "react";
import { useQuery, useLazyQuery, gql, useApolloClient } from "@apollo/client";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.min.css";
import { MANAGE_USERS_LIST, EDIT_OFFICE_USER } from "../../graphql/queries/Providers";
import ManageUsersTableRow from "../components/ManageUsersTableRow";

export default function ManageUsers() {
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [username, setUserName] = useState("");

    const [inputfirst_name, setinputFirstName] = useState("");
    const [inputlast_name, setinputLastName] = useState("");
    const [inputusername, setinputUsername] = useState("");

    const [fetchedData, setFetchedData] = useState([]);
    const [loadingState, setLoadingState] = useState(false);

    const patient_id = parseInt(window.localStorage.getItem("xrefID"));
    const provider_id = parseInt(window.localStorage.getItem("providerID"));
    var temp_id = window.localStorage.getItem("tempID");
    var temp_email = window.localStorage.getItem("tempEmail");
    var temp_user_status = window.localStorage.getItem("tempStatus");

    const client = useApolloClient();

    const { loading, error, data, refetch } = useQuery(MANAGE_USERS_LIST, {
        variables: { provider_id, first_name: first_name, last_name: last_name },
        onCompleted: (data) => {
            // console.log(data);
            setFetchedData(data);
        },
    });

    const [editUser, lazyData = { loading: officeLoading, error: officeError, data: officeData }] = useLazyQuery(EDIT_OFFICE_USER, {
        // variables: { provider_id, id: temp_id, email: temp_email, user_status: temp_user_status },
        onCompleted: () => {
            refetch;
        },
        fetchPolicy: "cache-and-network",
    });

    const handleEditUser = useCallback(() => {
        editUser({ variables: { provider_id, id: temp_id, email: temp_email, user_status: temp_user_status } });
    }, [editUser, temp_id, temp_email, temp_user_status]);

    function handleSearch() {
        if (inputfirst_name == "") {
            setFirstName("");
        } else {
            setFirstName(inputfirst_name);
        }
        if (inputlast_name == "") {
            setLastName("");
        } else {
            setLastName(inputlast_name);
        }
        // if (inputusername == "") {
        //     setUserName("");
        // } else {
        //     setUserName(inputusername);
        // }
        refetch();
    }

    function handleClear() {
        setFirstName("");

        setLastName("");
        setUserName("");
        setinputFirstName("");
        setinputLastName("");
        setinputUsername("");
        refetch();
    }

    useEffect(() => {
        let activateFlag = false;
        let deactivateFlag = false;
        $("#action-deactivate1").one("click", function (event) {
            // $("#action1").val("activate");
            refetcher();
            temp_user_status = "inactive";
            console.log(provider_id, temp_id, temp_email, temp_user_status);

            handleEditUser();
            deactivateFlag = true;

            refetcher();

            console.log("DeActivate 2");
            console.log(provider_id, temp_id, temp_email, temp_user_status, "part 2 deact");
            window.location.reload();
        });

        $("#action-activate2").one("click", function (event) {
            // $("#action2").val("deactivate");
            refetcher();
            temp_user_status = "Active";
            console.log(provider_id, temp_id, temp_email, temp_user_status);

            handleEditUser();
            activateFlag = true;

            refetcher();
            console.log(provider_id, temp_id, temp_email, temp_user_status, "part 2 act");
            console.log("Activate 3");

            window.location.reload();
        });
        if (activateFlag) {
            return () => {
                $("#action-activate2").off("click");
            };
        }
        if (deactivateFlag) {
            return () => {
                $("#action-deactivate1").off("click");
            };
        }
    }, [handleEditUser]);

    async function refetcher() {
        temp_id = window.localStorage.getItem("tempID");
        temp_email = window.localStorage.getItem("tempEmail");
        temp_user_status = window.localStorage.getItem("tempStatus");
    }
    function clearTemps() {
        window.localStorage.removeItem("selectedUser");
        window.localStorage.removeItem("tempID");
        window.localStorage.removeItem("tempEmail");
        window.localStorage.removeItem("tempStatus");
    }

    function search() {
        if (inputfirst_name == "") {
            setFirstName(null);
        } else {
            setFirstName(inputfirst_name);
        }
        if (inputlast_name == "") {
            setLastName(null);
        } else {
            setLastName(inputlast_name);
        }
        if (inputusername == "") {
            setUserName(null);
        } else {
            setUserName(inputusername);
        }

        // loadUsers()
    }

    const [selectedUser, setSelectedUser] = useState("");

    useEffect(() => {
        $(function () {
            $(".deactivate_1").on("click", function (event) {
                event.preventDefault();
                $("#deactivate-popup-1").css("display", "block");
            });
            $(".deactivate_2").on("click", function (event) {
                event.preventDefault();
                $("#deactivate-popup-2").css("display", "block");
            });
            $(".deactivate-popup .close-button").on("click", function (event) {
                event.preventDefault();
                $(".deactivate-popup").css("display", "none");
            });
        });
        $(function () {
            $(".activate_1").on("click", function (event) {
                event.preventDefault();
                $("#activate-popup-1").css("display", "block");
            });
            $(".activate_2").on("click", function (event) {
                event.preventDefault();
                $("#activate-popup-2").css("display", "block");
            });

            $(".activate-popup .close-button").on("click", function (event) {
                event.preventDefault();
                $(".activate-popup").css("display", "none");
            });
            // $("#action-activate1").on("click", function (event) {
            //     $("#action1").val("deactivate");
            //     temp_user_status = "Active";
            //     let id = temp_id;
            //     let email = temp_email;
            //     let user_status = temp_user_status;
            //     $("#manage-users").submit();
            //     editUser({ variables: { provider_id, id, email, user_status } });
            //     // refetch();

            //     console.log("Activate 1");
            // });
            // $("#action-deactivate1").one("click", function (event) {
            //     // $("#action1").val("activate");leave out

            //     // $("#manage-users").submit();leave out
            //     temp_user_status = "inactive";
            //     console.log(provider_id, temp_id, temp_email, temp_user_status);
            //     handleEditUser();
            //     // editUser({ variables: { provider_id, id: temp_id, email: temp_email, user_status: temp_user_status } });

            //     refetcher();

            //     console.log("DeActivate 2");

            //     window.location.reload();
            // });

            // $("#action-activate2").one("click", function (event) {
            //     // $("#action2").val("deactivate");leave out

            //     // $("#manage-users").submit(); leave out

            //     temp_user_status = "Active";
            //     console.log(provider_id, temp_id, temp_email, temp_user_status);
            //     handleEditUser();
            //     // editUser({ variables: { provider_id, id: temp_id, email: temp_email, user_status: temp_user_status } });

            //     refetcher();
            //     console.log("Activate 3");

            //     window.location.reload();
            // });

            // $("#action-deactivate2").on("click", function (event) {
            //     $("#action2").val("activate");
            //     temp_user_status = "inactive";
            //     let id = temp_id;
            //     let email = temp_email;
            //     let user_status = temp_user_status;
            //     $("#manage-users").submit();
            //     editUser({ variables: { provider_id, id, email, user_status } });
            //     // refetch();
            //     console.log("DeActivate 4");
            // });
        });
    });

    useEffect(() => {
        // false;
        if (data) {
            // true;
            if (data.providerusersearch != null) {
                if (data.providerusersearch.first_name != null) {
                    setFirstName(data.providerusersearch.first_name);
                }
            }
        }

        // let temp_data = { ...fetchedData };
        // temp_data = data;

        //maybe uncomment this

        if (data) {
            // setFetchedData(data);
            // clearTemps();
            console.log(temp_id, temp_email, temp_user_status);
            //pseudo clearing
            // temp_id = window.localStorage.getItem("tempID");
            // temp_email = window.localStorage.getItem("tempEmail");
            // temp_user_status = window.localStorage.getItem("tempStatus");
        }
    }, [data]);

    useEffect(() => {
        // console.log("Running initial fetch");
        // console.log(data);
        // setFetchedData(data);
        window.addEventListener("storage", () => {
            setSelectedUser(window.localStorage.getItem("selectedUser"));
        });
        temp_id = window.localStorage.getItem("tempID");
        temp_email = window.localStorage.getItem("tempEmail");
        temp_user_status = window.localStorage.getItem("tempStatus");
    }, []);

    useEffect(() => {
        // console.log("refetched");
    }, [fetchedData]);
    /*
    useEffect(() => {
        window.addEventListener('storage', () => {
            console.log("Change to local storage!");
            // ...
        })
        if(window.localStorage.getItem('selectedUser') != selectedUser){
            setSelectedUser(window.localStorage.getItem('selectedUser'))
        }
    }, window.localStorage.getItem('selectedUser'))
*/

    return (
        <div className="container" id="content" style={{ fontSize: "1em" }}>
            <div id="deactivate-popup-1" className="deactivate-popup">
                <div className="popup-box" style={{ padding: "35px" }}>
                    <p>Are you sure want to deactivate the user {window.localStorage.getItem("selectedUser")}?</p>
                    <button id="action-deactivate1" className="save close-button" type="submit">
                        Yes
                    </button>{" "}
                    <button className="cancel close-button" type="reset">
                        No
                    </button>
                </div>
            </div>
            <div id="deactivate-popup-2" className="deactivate-popup">
                <div className="popup-box" style={{ padding: "35px" }}>
                    <p>Are you sure want to deactivate the user {window.localStorage.getItem("selectedUser")}?</p>
                    <button id="action-deactivate2" className="save close-button" type="submit">
                        Yes
                    </button>{" "}
                    <button className="cancel close-button" type="reset">
                        No
                    </button>
                </div>
            </div>
            <div id="activate-popup-1" className="activate-popup">
                <div className="popup-box" style={{ padding: "35px" }}>
                    <p>Are you sure want to activate the user {window.localStorage.getItem("selectedUser")}?</p>
                    <button id="action-activate1" className="save close-button" type="submit">
                        Yes
                    </button>{" "}
                    <button className="cancel close-button" type="reset">
                        No
                    </button>
                </div>
            </div>
            <div id="activate-popup-2" className="activate-popup">
                <div className="popup-box" style={{ padding: "35px" }}>
                    <p>Are you sure want to activate the user {window.localStorage.getItem("selectedUser")}?</p>
                    <button id="action-activate2" className="save close-button" type="submit">
                        Yes
                    </button>{" "}
                    <button className="cancel close-button" type="reset">
                        No
                    </button>
                </div>
            </div>
            <div id="container">
                <div id="hcp_post_content">
                    <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>Manage Office Users</h2>
                    <p>Enter first name, last name or username to filter office user list.</p>
                    <div className="find_patient" id="manage-users">
                        <p>
                            <input
                                type="text"
                                name="firstname"
                                placeholder="First Name"
                                value={inputfirst_name}
                                onChange={(e) => setinputFirstName(e.target.value)}
                            />{" "}
                            <input
                                type="text"
                                name="lastname"
                                placeholder="Last Name"
                                value={inputlast_name}
                                onChange={(e) => setinputLastName(e.target.value)}
                            />
                            <input
                                type="text"
                                name="member_id"
                                placeholder="Username"
                                value={inputusername}
                                onChange={(e) => setinputUsername(e.target.value)}
                            />
                        </p>
                        <p></p>
                        <input id="action1" type="hidden" name="action1" value="" />
                        <input id="action2" type="hidden" name="action2" value="" />
                        <p>
                            <button type="submit" onClick={handleSearch} id="grey-button">
                                Search
                            </button>

                            <button type="reset" onClick={handleClear}>
                                Clear
                            </button>
                            <a className="button fltright" href="add-office-user" style={{ lineHeight: " 1.2" }}>
                                Add new user
                            </a>
                        </p>
                    </div>
                    <div className="space"></div>
                    <table className="account">
                        <thead>
                            <tr className="list_header">
                                <th align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                    First Name
                                </th>
                                <th align="left" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                    Last Name
                                </th>
                                <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                    Username
                                </th>
                                <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                    Email Address
                                </th>
                                <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                    Created On
                                </th>
                                <th align="center" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                    User Type
                                </th>
                                <th style={{ textAlign: "center", fontSize: "1em", lineHeight: "1.49em" }}>Status</th>
                                <th style={{ textAlign: "center", fontSize: "1em", lineHeight: "1.49em" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchedData != null &&
                                fetchedData.providerusersearch != null &&
                                fetchedData &&
                                fetchedData.providerusersearch.map(
                                    (
                                        {
                                            __typename,
                                            id,
                                            user_name,
                                            provider_id,
                                            credentials_id,
                                            first_name,
                                            last_name,
                                            user_status,
                                            createdAt,
                                            user_data,
                                        },
                                        index
                                    ) => (
                                        <ManageUsersTableRow
                                            key={id}
                                            userID={id}
                                            firstName={first_name}
                                            lastName={last_name}
                                            username={user_name}
                                            email=""
                                            createdOn={createdAt}
                                            userType="Admin"
                                            status={user_status}
                                        />
                                    )
                                )}
                        </tbody>
                    </table>
                    <div className="pages" style={{ marginTop: "0" }}>
                        <div className="col-lg-8"></div>
                        <div className="col-lg-4">
                            <div className="col-lg-5">
                                Items per page:{" "}
                                <select name="">
                                    <option>10</option>
                                    <option>20</option>
                                </select>
                            </div>
                            <div className="col-lg-3">1 - 1 of 1 </div>
                            <div className="col-lg-4">
                                <a href="#">
                                    <span className="nav-left"></span>
                                </a>
                                <a href="#">
                                    <span className="nav-right"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
