import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation, gql } from '@apollo/client';
import { RX_INSURANCE_INFORMATION } from '../graphql/queries/Patients'
import { UPDATE_PATIENT_PHARMACY } from '../graphql/mutations/Patients'

export default function UpdatePharmacy(props) {

    var patient_id = parseInt(window.localStorage.getItem('xrefID'))

    const url = props.url

    const [name, setcompanyname] = useState('');
    const [member_number, setmembernumber] = useState('');
    const [group_number, setgroupnumber] = useState('');
    const [bin, setbin] = useState('');
    const [pcn, setpcn] = useState('');

    const { loading, error, data, refetch } = useQuery(RX_INSURANCE_INFORMATION, {
        variables: { patient_id }
    });

    const [updatePharmacy, { data: newData }] = useMutation(UPDATE_PATIENT_PHARMACY);

    useEffect(() => {
        if (patient_id) {
            if (data != null) {
                if(data.patientinfo != null){
                    if(data.patientinfo.enrollment_data != null){
                        if(data.patientinfo.enrollment_data.commercial_insurance != null){
                            if (data.patientinfo.enrollment_data.commercial_insurance.rx_insurance != null) {
                                if (data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.name) {
                                    setcompanyname(data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.name)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.member_number) {
                                    setmembernumber(data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.member_number)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.group_number) {
                                    setgroupnumber(data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.group_number)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.bin) {
                                    setbin(data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.bin)
                                }
                                if (data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.pcn) {
                                    setpcn(data.patientinfo.enrollment_data.commercial_insurance.rx_insurance.pcn)
                                }
                            } 
                        }
                    }
                }
                
            }
        } 
    }, [data])

    let navigate = useNavigate()
    function saveNewPharmacy(e) {
        e.preventDefault()
        updatePharmacy({ variables: { patient_id, name, member_number, group_number, bin, pcn } })
        let path = `../` + url
        navigate(path)
    }

    function cancelUpdate() {
        let path = `../` + url
        navigate(path)
    }

    return (
        <div className="section shadow-box color-label">
            <div className="col-sm-12 col-md-12 col-lg-9" style={{ paddingLeft: '0' }}>
                <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Pharmacy Insurance Information</h2>
            </div>
            <form onSubmit={e => saveNewPharmacy(e)}  encType="multipart/form-data" className="update_pharmacy form-validate-user" acceptCharset="utf-8">
                <div className="col-sm-12 col-md-12 col-lg-2" style={{ float: 'right' }}>
                    <button className="save" id="save" type="submit">Save</button> <button className="cancel" id="cancel" type="reset" onClick={cancelUpdate}>Cancel</button>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <p className="field required-field text">
                        <label htmlFor="first_name" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Rx company name<span className="required">*</span></span></label>
                        <span className="select_container">
                            <select name="insuranceCompanyName" id="insuranceCompanyName" defaultValue="" value={name} onChange={e => setcompanyname(e.target.value)}>
                                <option value="">Select Company</option>
                                <option value="Aetna">Aetna</option><option value="Alliance Health">Alliance Health</option><option value="Amerihealth">Amerihealth</option><option value="Anthem">Anthem</option><option value="Assurant">Assurant</option><option value="Blue Cross">Blue Cross</option><option value="Blue Shield">Blue Shield</option><option value="BCBS">BCBS</option><option value="Cigna" selected="">Cigna</option><option value="Cox Health">Cox Health</option><option value="First Choice">First Choice</option><option value="Geisinger">Geisinger</option><option value="Harvard Pilgrim">Harvard Pilgrim</option><option value="Healthnet">Healthnet</option><option value="Humana">Humana</option><option value="Kaiser">Kaiser</option><option value="Medco">Medco</option><option value="Medica">Medica</option><option value="Medical Mutual">Medical Mutual</option><option value="Optima">Optima</option><option value="Preferred One">Preferred One</option><option value="Providence">Providence</option><option value="Select Health">Select Health</option><option value="United Healthcare (UHC)">United Healthcare (UHC)</option><option value="United Medical Resources (UMR)">United Medical Resources (UMR)</option><option value="Other">Other</option>				</select>
                        </span>
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <p className="field required-field text">
                        <label htmlFor="street" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Rx member number<span className="required">*</span></span></label>
                        <input onChange={e => setmembernumber(e.target.value)} type="text" id="address" name="address_1" value={member_number} />
                    </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="field required-field text">
                            <label htmlFor="birthDate" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Rx group number<span className="required">*</span></span></label>
                            <input onChange={e => setgroupnumber(e.target.value)} className="" type="text" name="effective_date" value={group_number} />
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="field required-field text">
                            <label htmlFor="birthDate" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Rx BIN<span className="required">*</span></span></label>
                            <input onChange={e => setbin(e.target.value)} type="text" name="birth_date" value={bin} />
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <p className="field required-field text">
                            <label htmlFor="birthDate" style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Rx PCN<span className="required">*</span></span></label>
                            <input onChange={e => setpcn(e.target.value)} type="text" name="birth_date" value={pcn} />
                        </p>
                    </div>
                </div>
            </form>
        </div>
    )
}