import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'

export default function CommercialPharmacyInsuranceInformation() {

    const { enrollment_data, setenrollmentdata } = useOutletContext()

    const [noPharmacy, setnopharmacy] = useState(false)

    var existing_member = false
    if (window.localStorage.getItem('existing_member') == "true") {
        existing_member = true
    } else {
        existing_member = false
    }

    const [name, setname] = useState('')
    const [groupnumber, setgroupnumber] = useState('')
    const [membernumber, setmembernumber] = useState('')
    const [bin, setbin] = useState('')
    const [pcn, setpcn] = useState('')

    function updateName(e) {
        setname(e.target.value)
        enrollment_data.insurance.rx_insurance.name = e.target.value
        if (e.target.value == 'Other') {
            setname('')
            enrollment_data.insurance.rx_insurance.name = ''
        }
    }
    function updateGroupNumber(e) {
        setgroupnumber(e.target.value)
        enrollment_data.insurance.rx_insurance.group_number = e.target.value
    }
    function updateMemberNumber(e) {
        setmembernumber(e.target.value)
        enrollment_data.insurance.rx_insurance.member_number = e.target.value
    }
    function updateBin(e) {
        setbin(e.target.value)
        enrollment_data.insurance.rx_insurance.bin = e.target.value
    }
    function updatePcn(e) {
        setpcn(e.target.value)
        enrollment_data.insurance.rx_insurance.pcn = e.target.value
    }


    function handleCheckbox(e){
        if (document.getElementById("pharmacycheck").checked == true) {
            setname('')
            setgroupnumber('')
            setmembernumber('')
            setbin('')
            setpcn('')
            enrollment_data.insurance.rx_insurance.name = ''
            enrollment_data.insurance.rx_insurance.group_number = ''
            enrollment_data.insurance.rx_insurance.member_number = ''
            enrollment_data.insurance.rx_insurance.bin = ''
            enrollment_data.insurance.rx_insurance.pcn = ''
            setnopharmacy(true)
            window.localStorage.setItem('pharmacy-insurance-checked', 'yes')
        } else {
            window.localStorage.setItem('pharmacy-insurance-checked', 'no')
            setnopharmacy(false)
        }
    }

    useEffect(() => {
        if(window.localStorage.getItem('enrollment_data')){
            try {
                setenrollmentdata(JSON.parse(window.localStorage.getItem('enrollment_data')))
                setname(enrollment_data.insurance.rx_insurance.name)
                setgroupnumber(enrollment_data.insurance.rx_insurance.group_number)
                setmembernumber(enrollment_data.insurance.rx_insurance.member_number)
                setbin(enrollment_data.insurance.rx_insurance.bin)
                setpcn(enrollment_data.insurance.rx_insurance.pcn)
            } catch {

            }
            
        }
    }, [])

    return (
        <div className="section shadow-box">
            <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Commercial Pharmacy Insurance Information</h2>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field input-hide-2 text insurance-info ignore required-field">
                    <label htmlFor="RxinsuranceMemberNumber" className='smaller' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Rx company name<span className="required">*</span><span className="dots"></span></span></label>
                    <input readOnly={((existing_member == true) || (noPharmacy == true)) ? true : false} onChange={e => updateName(e)} type="text" name="insuranceMemberNumber" id="rxMemberNumber" value={enrollment_data.insurance.rx_insurance.name} />
                </p></div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field input-hide-2 text insurance-info ignore required-field">
                    <label htmlFor="RxinsuranceMemberNumber" className='smaller' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Rx member number<span className="required">*</span><span className="dots"></span></span></label>
                    <input readOnly={((existing_member == true) || (noPharmacy == true)) ? true : false} onChange={e => updateMemberNumber(e)} type="text" name="insuranceMemberNumber" id="rxMemberNumber" value={enrollment_data.insurance.rx_insurance.member_number} />
                </p></div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field input-hide-2 text insurance-info ignore required-field">
                    <label htmlFor="RxinsuranceGroupNumber" className='smaller' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Rx group number<span className="required">*</span><span class="dots"></span></span></label>
                    <input readOnly={((existing_member == true) || (noPharmacy == true)) ? true : false} onChange={e => updateGroupNumber(e)} type="text" name="insurancePharmacyGroupNumber" id="rxGroupNumber" value={enrollment_data.insurance.rx_insurance.group_number} />
                </p></div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field input-hide-2 text insurance-info ignore required-field">
                    <label htmlFor="RxinsuranceBINNumber" className='smaller' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Rx BIN<span className="required">*</span><span className="dots"></span></span></label>
                    <input readOnly={((existing_member == true) || (noPharmacy == true)) ? true : false} onChange={e => updateBin(e)} type="text" name="RxinsuranceBINNumber" id="RxinsuranceBINNumber" value={enrollment_data.insurance.rx_insurance.bin} />
                </p></div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p className="field input-hide-2 text insurance-info ignore required-field">
                    <label htmlFor="RxinsurancePCNNumber" className='smaller' style={{ fontSize: '1em', lineHeight: '1.49em' }}><span className="inner-label">Rx PCN<span className="required">*</span><span class="dots"></span></span></label>
                    <input readOnly={((existing_member == true) || (noPharmacy == true)) ? true : false} onChange={e => updatePcn(e)} type="text" name="RxinsurancePharmacyPCNNumber" id="RxinsurancePCNNumber" value={enrollment_data.insurance.rx_insurance.pcn} />
                </p></div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <p className="nothave-field-2">
                    <label className="checkbox" style={{ fontSize: '1em', lineHeight: '1.49em' }}><input onChange={e => handleCheckbox(e)} id='pharmacycheck' type="checkbox" name="nothave-2" value="yes" />&nbsp;Does not have a pharmacy insurance card<span className="checkmark "></span></label>
                </p></div>
        </div>
    )
}