import React from 'react'
import { useOutletContext } from 'react-router-dom'

export default function ReviewSiteOfCareInformation() {

    const { site_of_care, setsiteofcare } = useOutletContext()
    return (
        <div className="section shadow-box color-label">
            <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Site of Care Information</h2>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field">
                        <label htmlFor="practiceName" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Practice name</label>
                        {site_of_care.name}     </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field required-field text">
                        <label htmlFor="street" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Address</label>
                        {site_of_care.address.address1}    </p></div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
                <p className="field required-field text">
                    <label htmlFor="street" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Address 2</label>
                {site_of_care.address.address2} </p></div>
            <div className="col-sm-12 col-md-12 col-lg-4">
                <p className="field required-field text">
                    <label htmlFor="city" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>City</label>
                    {site_of_care.address.city}    </p></div>
            <div className="col-sm-12 col-md-12 col-lg-4">
                <p className="field required-field dropdown">
                    <label htmlFor="state" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>State</label><br />
                    {site_of_care.address.state}    </p></div>
            <div className="col-sm-12 col-md-12 col-lg-4">
                <p className="field required-field text zipcode">
                    <label htmlFor="zipCode" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>ZIP code</label>
                    {site_of_care.address.zip_code}    </p></div>
            <div className="col-sm-12 col-md-12 col-lg-4">
                <p className="field required-field text phone">
                    <label htmlFor="phone" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Phone number</label>
                    {site_of_care.phone_number}    </p></div>
            <div className="col-sm-12 col-md-12 col-lg-4">
                <p className="field required-field text phone">
                    <label htmlFor="phone" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>National Provider Identifier (NPI)</label>
                    {site_of_care.npi}    </p>
            </div>
        </div>
    )
}