import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $ from "jquery";
import { useLazyQuery } from "@apollo/client";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.min.css";
import EnrollDoctorInformationForm from "../components/EnrollDoctorInformationForm";
import { PROVIDER_FILL_HCP } from "../graphql/queries/Providers";

export default function EnrollDoctorInformationLap(props) {
  const { setTitle, setStep, provider_data, site_of_care } = useOutletContext();

  var existing_member = false;
  if (window.localStorage.getItem("existing_member") == "true") {
    existing_member = true;
  } else {
    existing_member = false;
  }
  const userType = props.type ? props.type : "patient";
  const [provider_id, setproviderid] = useState(0);

  var enrollment_data = window.localStorage.getItem("enrollment_data")
    ? window.localStorage.getItem("enrollment_data")
    : "";

  let navigate = useNavigate();

  useEffect(() => {
    if (enrollment_data == "") {
      navigate("../enroll");
    }
  }, [enrollment_data]);

  const [fillprovider, { loading, error, data }] = useLazyQuery(
    PROVIDER_FILL_HCP,
    {
      variables: { provider_id },
    }
  );

  const [provider_first, setproviderfirst] = useState("");
  const [provider_last, setproviderlast] = useState("");
  const [provider_state, setproviderstate] = useState("");
  const [provider_npi, setprovidernpi] = useState("");
  useEffect(() => {
    if (userType == "provider") {
      setproviderid(parseInt(window.localStorage.getItem("providerID")));
      fillprovider();
    }
    if (data) {
      window.localStorage.setItem("autofillProvider", JSON.stringify(data));
      if (data.providerfind != null) {
        if (data.providerfind.provider_npi) {
          setprovidernpi(data.providerfind.provider_npi);
        }
        if (data.providerfind.provider_data != null) {
          if (data.providerfind.provider_data.first_name) {
            setproviderfirst(data.providerfind.provider_data.first_name);
          }
          if (data.providerfind.provider_data.last_name) {
            setproviderlast(data.providerfind.provider_data.last_name);
          }
          if (data.providerfind.provider_data.address != null) {
            if (data.providerfind.provider_data.address.state) {
              setproviderstate(
                data.providerfind.provider_data.address.state.toUpperCase()
              );
            }
          }
        }
      }
    }
  }, [data]);

  useEffect(() => {
    $("#fill-info").removeClass("hide");
    $("#fill-info-care").removeClass("hide");

    $(function ($) {
      $("#search_care").on("click", function () {
        var d1 = $("#doc_firstName").val();
        var d2 = $("#doc_lastName").val();
        var d3 = $("#doc_npi").val();
        var d4 = $("#doc_address").val();
        var d5 = $("#doc_address2").val();
        var d6 = $("#doc_city").val();
        var d7 = $("#doc_state").val();
        var d8 = $("#doc_zipCode").val();
        var d9 = $("#doc_phone").val();

        $(".doc_firstName").val(d1);
        $(".doc_lastName").val(d2);
        $(".doc_npi").val(d3);
        $(".doc_address").val(d4);
        $(".doc_address2").val(d5);
        $(".doc_city").val(d6);
        $(".doc_state").val(d7).attr("selected", "selected");
        $(".doc_zipCode").val(d8);
        $(".doc_phone").val(d9);
        //$(".search-form-provider").submit();
        //$(".search-form-care").submit();
      });

      $("#next").on("click", function () {
        var d1 = $("#doc_firstName").val();
        var d2 = $("#doc_lastName").val();
        var d3 = $("#doc_npi").val();
        var d4 = $("#doc_address").val();
        var d5 = $("#doc_address2").val();
        var d6 = $("#doc_city").val();
        var d7 = $("#doc_state").val();
        var d8 = $("#doc_zipCode").val();
        var d9 = $("#doc_phone").val();

        $(".doc_firstName").val(d1);
        $(".doc_lastName").val(d2);
        $(".doc_npi").val(d3);
        $(".doc_address").val(d4);
        $(".doc_address2").val(d5);
        $(".doc_city").val(d6);
        $(".doc_state").val(d7).attr("selected", "selected");
        $(".doc_zipCode").val(d8);
        $(".doc_phone").val(d9);

        $(".search-form-provider").submit();
      });
      $("#next-care").on("click", function () {
        var d1 = $("#doc_firstName").val();
        var d2 = $("#doc_lastName").val();
        var d3 = $("#doc_npi").val();
        var d4 = $("#doc_address").val();
        var d5 = $("#doc_address2").val();
        var d6 = $("#doc_city").val();
        var d7 = $("#doc_state").val();
        var d8 = $("#doc_zipCode").val();
        var d9 = $("#doc_phone").val();

        $(".doc_firstName").val(d1);
        $(".doc_lastName").val(d2);
        $(".doc_npi").val(d3);
        $(".doc_address").val(d4);
        $(".doc_address2").val(d5);
        $(".doc_city").val(d6);
        $(".doc_state").val(d7).attr("selected", "selected");
        $(".doc_zipCode").val(d8);
        $(".doc_phone").val(d9);

        $(".search-form-care").submit();
      });
      $("#search").on("click", function () {
        //$(".search-div").removeClass( "hide" );
        //$(".actions-submit").removeClass( "hide" );
      });
      $("#search_care").on("click", function () {
        $(".search-div-care").removeClass("hide");
        $(".actions-submit").removeClass("hide");
      });
      $("input[name='result']").on("click", function () {
        var result = $("input[name='result']:checked").val();

        //alert(result);

        $(".select_provider").val(result);
        //if($(this).val=="1")
        if (result == 0) {
          //show fill $("#fill-info").removeClass("hide");
          $("#doc_firstName").val("");
          $("#doc_lastName").val("");
          $("#doc_npi").val("");
          $("#doc_address").val("");
          $("#doc_address2").val("");
          $("#doc_city").val("");
          $("#doc_state").val("").attr("selected", "selected");
          $("#doc_zipCode").val("");
          $("#doc_phone").val("");
        } else if (result == 1) {
          //show fill $("#fill-info").addClass("hide");
          $("#table tr.first").each(function () {
            var first_name = $(this).find("td").eq(0).html();
            $(".doc_firstName").val(first_name);
            var last_name = $(this).find("td").eq(1).html();
            $(".doc_lastName").val(last_name);
            var npi = $(this).find("td").eq(2).html();
            $("#doc_npi").val(npi);
            var address_1 = $(this).find("td").eq(3).html();
            $(".doc_address").val(address_1);
            var address_2 = $(this).find("td").eq(4).html();
            $(".doc_address2").val(address_2);
            var city = $(this).find("td").eq(5).html();
            $(".doc_city").val(city);
            var state = $(this).find("td").eq(6).html();
            //alert(state);
            $(".doc_state").val(state).attr("selected", "selected");
            var zip = $(this).find("td").eq(7).html();
            $(".doc_zipCode").val(zip);
            var phone_number = $(this).find("td").eq(8).html();
            $(".doc_phone").val(phone_number);
          });
        } else if (result == 2) {
          //show fill  $("#fill-info").addClass("hide");
          $("#table tr.second").each(function () {
            var first_name = $(this).find("td").eq(0).html();
            $(".doc_firstName").val(first_name);
            var last_name = $(this).find("td").eq(1).html();
            $(".doc_lastName").val(last_name);
            var npi = $(this).find("td").eq(2).html();
            $(".doc_npi").val(npi);
            var address_1 = $(this).find("td").eq(3).html();
            $(".doc_address").val(address_1);
            var address_2 = $(this).find("td").eq(4).html();
            $(".doc_address2").val(address_2);
            var city = $(this).find("td").eq(5).html();
            $(".doc_city").val(city);
            var state = $(this).find("td").eq(6).html();
            //alert(state);
            $(".doc_state").val(state).attr("selected", "selected");
            var zip = $(this).find("td").eq(7).html();
            $(".doc_zipCode").val(zip);
            var phone_number = $(this).find("td").eq(8).html();
            $(".doc_phone").val(phone_number);
          });
        }
      });

      $("input[name='result_care']").on("click", function () {
        var result = $("input[name='result_care']:checked").val();
        $(".select_care").val(result);
        //if($(this).val=="1")
        if (result == 0) {
          //$(".mandatory.field.text").removeClass( "field text" );
          //$(".mandatory.field.dropdown").removeClass( "field dropdown" );
          $(".mandatory").addClass("required-field");
          //$(".mandatory.field.dropdown").addClass( "field required-field dropdown" );
          //show fill  $("#fill-info-care").removeClass("hide");
          $("#sc_practiceName").val("");
          $("#sc_npi").val("");
          $("#sc_address").val("");
          $("#sc_address2").val("");
          $("#sc_city").val("");
          $("#sc_state").val("").attr("selected", "selected");
          $("#sc_zipCode").val("");
          $("#sc_phone").val("");
        } else if (result == 1) {
          ///show fill  $("#fill-info-care").addClass("hide");
          $(".mandatory.field.text").removeClass("field required-field text");
          $(".mandatory.field.dropdown").removeClass(
            "field required-field dropdown"
          );
          $(".mandatory.field.text").addClass("field text");
          $(".mandatory.field.dropdown").addClass("field dropdown");
          $("#table-care tr.first").each(function () {
            var first_name = $(this).find("td").eq(0).html();
            $("#sc_practiceName").val(first_name);
            var npi = $(this).find("td").eq(1).html();
            $("#sc_npi").val(npi);
            var address_1 = $(this).find("td").eq(2).html();
            $("#sc_address").val(address_1);
            var address_2 = $(this).find("td").eq(3).html();
            $("#sc_address2").val(address_2);
            var city = $(this).find("td").eq(4).html();
            $("#sc_city").val(city);
            var state = $(this).find("td").eq(5).html();
            //alert(state);
            $("#sc_state").val(state).attr("selected", "selected");
            var zip = $(this).find("td").eq(6).html();
            $("#sc_zipCode").val(zip);
            var phone_number = $(this).find("td").eq(7).html();
            $("#sc_phone").val(phone_number);
          });
        } else if (result == 2) {
          //show fill  $("#fill-info-care").addClass("hide");
          $(".mandatory.field.text").removeClass("field required-field text");
          $(".mandatory.field.dropdown").removeClass(
            "field required-field dropdown"
          );
          $(".mandatory.field.text").addClass("field text");
          $(".mandatory.field.dropdown").addClass("field dropdown");
          $("#table-care tr.second").each(function () {
            var first_name = $(this).find("td").eq(0).html();
            $("#sc_practiceName").val(first_name);
            var npi = $(this).find("td").eq(1).html();
            $("#sc_npi").val(npi);
            var address_1 = $(this).find("td").eq(2).html();
            $("#sc_address").val(address_1);
            var address_2 = $(this).find("td").eq(3).html();
            $("#sc_address2").val(address_2);
            var city = $(this).find("td").eq(4).html();
            $("#sc_city").val(city);
            var state = $(this).find("td").eq(5).html();
            //alert(state);
            $("#sc_state").val(state).attr("selected", "selected");
            var zip = $(this).find("td").eq(6).html();
            $("#sc_zipCode").val(zip);
            var phone_number = $(this).find("td").eq(7).html();
            $("#sc_phone").val(phone_number);
          });
        }
      });

      $.mask.definitions["~"] = "[+-]";
      $("#doc_zipCode").mask("99999");
      $("#search_zip").mask("99999");
      $("#doc_phone").mask("999-999-9999");
    });

    setTitle("Apply for the XOLAIR Co-pay Program");
    setStep("5");
  });

  function handleSubmit() {}

  function handleChange() {}

  function goBack() {
    navigate(-1);
  }

  function next() {
    window.localStorage.setItem("provider_data", JSON.stringify(provider_data));
    window.localStorage.setItem("site_of_care", JSON.stringify(site_of_care));
    if (existing_member == false) {
      if (
        provider_data.first_name == "" ||
        provider_data.last_name == "" ||
        provider_data.address.address1 == "" ||
        provider_data.address.city == "" ||
        provider_data.address.state == "" ||
        provider_data.address.zip_code == "" ||
        provider_data.phones.phone == ""
      ) {
        alert("missing provider info");
      } else {
        let path = `../enroll-form-review-lap`;
        navigate(path);
      }
    } else {
      let path = `../enroll-form-review-lap`;
      navigate(path);
    }
  }

  return (
    <>
      <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>
        Provider's Information
      </h2>
      <p>
        <span style={{ color: "red" }}> *Required field.</span>
      </p>
      <p>
        <font color="red"> </font>
      </p>
      <div className="section">
        <EnrollDoctorInformationForm />
        <div className="col-sm-12 col-md-12 col-lg-12 actions-submit">
          <p style={{ textAlign: "left" }}>
            <button type="reset" onClick={goBack}>
              Back
            </button>{" "}
            <button
              type="button"
              onClick={next}
              name="apply-step-two-submit"
              id="next-care"
            >
              Next
            </button>
            <span className="form-error"></span>
          </p>
        </div>

        <div className="clearfloat"></div>
      </div>
    </>
  );
}
