import React from 'react'
import hospitalCircle from '../images/Hospital-circle.png'
import injectionCircle from '../images/Injection-circle.png'
import circle from '../images/EOB-circle.png'
import checkCircle from '../images/Check-circle.png'
import moneyCircle from '../images/Money-circle.png'

export default function UsingTheCard() {
    return (
        <div className="container" id="content">
            <div id="container">
                <div className="using-the-program">
                    <h1 style={{ fontSize: '1.5em', lineHeight: '1.49em' }}>How to Use the Program</h1>
                    <p>The XOLAIR <span className="text-nowrap">Co-pay</span> Program can be used for your out-of-pocket costs for XOLAIR and XOLAIR injections. For both drug and injection assistance, you may use the program at: </p>
                    <ul>
                        <li>Your doctor's office</li>
                        <li>A hospital outpatient department (HOPD)</li>
                        <li>An alternate injection center (AIC)</li>
                        <li>A patient's address</li>
                        <li>For drug assistance only, you may also use the program with a specialty pharmacy</li>
                    </ul>
                    <div className="using-hcp-box arrow-bottom">
                        <div>
                            <table><tbody><tr>
                                <td className="first" style={{ fontSize: '1em', lineHeight: '1.49em' }}><img src={hospitalCircle} alt="How to use the program - Step 1" /></td>
                                <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>Tell your doctor's office, alternate injection center (AIC), hospital outpatient department (HOPD) or specialty pharmacy* you are using the XOLAIR  <span className="text-nowrap">Co-pay</span> Program. Pay as little as $0 for your drug <span className="text-nowrap">out-of-pocket</span> cost and/or as little as $0 for your injection <span className="text-nowrap">out-of-pocket</span> cost at the time of your visit.<br /></td>
                            </tr></tbody></table>
                        </div>
                    </div>
                    <div className="using-hcp-box arrow-bottom">
                        <table><tbody><tr>
                            <td className="first" style={{ fontSize: '1em', lineHeight: '1.49em' }}><img src={injectionCircle} alt="How to use the program - Step 2" /></td>
                            <td style={{ fontSize: '1em', lineHeight: '1.49em' }}><p style={{ textAlign: 'center', paddingTop: '10px' }}>Get your XOLAIR treatment.</p></td>
                        </tr></tbody></table>
                    </div>
                    <div className="using-hcp-box arrow-bottom">
                        <table><tbody><tr>
                            <td className="first" style={{ fontSize: '1em', lineHeight: '1.49em' }}><img src={circle} alt="How to use the program - Step 3" /></td>
                            <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>After your treatment, your health insurance plan will send an explanation of benefits (EOB) statement that says how much it will pay for your XOLAIR. Your doctor may ask you to pay your out-of-pocket payment upfront for the drug and/or injection service.</td>
                        </tr></tbody></table>
                    </div>
                    <div className="using-hcp-box arrow-bottom">
                        <table><tbody><tr>
                            <td className="first" style={{ fontSize: '1em', lineHeight: '1.49em' }}><img src={checkCircle} alt="How to use the program - Step 4" /></td>
                            <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>The XOLAIR <span className="text-nowrap">Co-pay</span> Program needs to review your EOB, check request form and a copy of your paid receipt. Your doctor's office or specialty pharmacy can send it to us on your behalf, or you can send it yourself by*:
                                <ul>
                                    <li style={{ backgroundPosition: '0px -337px' }}>Faxing it to <strong style={{ fontFamily: 'Avenir Next LT W01 Bold' }}>(866) 440-0599</strong> <br /> <strong style={{ fontFamily: 'Avenir Next LT W01 Bold' }}>OR</strong> </li>
                                    <li style={{ backgroundPosition: '0px -337px' }}>Uploading it after you sign in at <a href="http://XOLAIRcopay.com" target="_blank"><strong style={{ fontFamily: 'Avenir Next LT W01 Bold' }}>XOLAIRcopay.com</strong></a><br /><strong style={{ fontFamily: 'Avenir Next LT W01 Bold' }}>OR</strong></li>
                                    <li style={{ backgroundPosition: '0px -337px' }}>Mailing it to:<br /> The XOLAIR Co-pay Program<br /> P.O. Box 15160<br /> Newport Beach, CA 92659</li>
                                </ul>
                            </td>
                        </tr></tbody></table>
                    </div>
                    <div className="using-hcp-box">
                        <table><tbody><tr>
                            <td className="first" style={{ fontSize: '1em', lineHeight: '1.49em' }}><img src={moneyCircle} alt="How to use the program - Step 5" /></td>
                            <td style={{ fontSize: '1em', lineHeight: '1.49em' }}>You will pay as little as $0 for your drug out-of pocket cost and/or as little as $0 for your injection out-of-pocket costs. The final amount you owe may be as little as $0, but may vary depending on your health insurance plan. </td>
                        </tr></tbody></table>
                    </div>
                    <p>*EOBs must be submitted within 365 days of the date of service.</p>
                    <p><sup>†</sup>You can use the program with a specialty pharmacy for drug assistance only.</p>
                </div>
            </div>
        </div>
    )
}