import React from 'react'
import { useOutletContext } from 'react-router-dom'

export default function ReviewCommercialPharmacyInsurance() {

    const { enrollment_data, setenrollmentdata } = useOutletContext()

    return (
        <div className="section shadow-box color-label">
            <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Commercial Pharmacy Insurance Information</h2>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4"><p className=" text insurance-info ignore">
                    <label htmlFor="insuranceMemberNumber" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Rx company name</label>
                    {enrollment_data.insurance.rx_insurance.name}
                </p></div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4"><p className=" text insurance-info ignore">
                    <label htmlFor="insuranceMemberNumber" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Rx member number</label>
                    {enrollment_data.insurance.rx_insurance.member_number}		</p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className=" text insurance-info ignore">
                        <label htmlFor="insuranceGroupNumber" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Rx group number</label>
                        {enrollment_data.insurance.rx_insurance.group_number}		</p></div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field text insurance-info ignore required-field">
                        <label htmlFor="RxinsuranceBINNumber" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Rx BIN</label>
                        {enrollment_data.insurance.rx_insurance.bin}  </p></div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <p className="field text insurance-info ignore required-field">
                        <label htmlFor="RxinsurancePCNNumber" className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Rx PCN</label>
                        {enrollment_data.insurance.rx_insurance.pcn}  </p></div>
            </div>
        </div>
    )
}