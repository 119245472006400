import React, { useEffect } from 'react'

export default function RequestVirtualCardNumber() {

    useEffect(() => {
        if(window.location.toString().indexOf("enroll-form-review")){
            if(window.localStorage.getItem('request_card') == 'y'){
                document.getElementById("showmc").checked = true
            }
        }
    })
   
    function handleCheckbox(e) {
        if (document.getElementById("showmc").checked == true) {
            window.localStorage.setItem('request_card', 'y')
        } else if (document.getElementById("showmc").checked == false) {
            window.localStorage.setItem('request_card', 'n')
        }
    }

    return (
        <div className="section shadow-box">
            <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Request Virtual Card Number</h2>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <p className="field nothave-field">
                    <label className="checkbox" style={{ fontSize: '1em', lineHeight: '1.49em' }}>
                        <input onChange={e => handleCheckbox(e)} type="checkbox" name="showmc" id="showmc" value='yes' /> Click here if you require a 16-digit virtual card number to process the claim.<span className="checkmark"></span>
                    </label>
                </p>
            </div>
        </div>
    )
}