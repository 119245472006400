import { gql } from '@apollo/client';

export const PATIENT_NAME = gql`
query Patientinfo($patient_id: Int!) {
    patientinfo(patient_id: $patient_id) {
        enrollment_data {
            first_name
            last_name
        }
        credentials_id
    }
}
`;

export const PATIENT_INFO = gql`
query Patientinfo ($patient_id: Int!) {
    patientinfo(patient_id: $patient_id) {
        enrollment_data {
            first_name
            last_name
        }
        member_id
    }
}
`;

export const PATIENT_PROFILE_DETAILS = gql`
query Patientinfo($patient_id: Int!) {
    patientinfo(patient_id: $patient_id) {
        enrollment_data {
            first_name
            last_name
            date_of_birth
            phone
            phone_type
            email
            address {
                address_1
                address_2
                city
                state
                zip_code
            }
        }
    }
}
`;

export const MEMBERSHIP_INFORMATION = gql`
query Patientinfo($patient_id: Int!) {
    patientinfo(patient_id: $patient_id) {
        verification_data
        member_id
    }
}
`;

export const MEMBER_ID = gql`
query Patientinfo($patient_id: Int!) {
    patientinfo(patient_id: $patient_id) {
        member_id
    }
}
`;

export const COMMERCIAL_MEDICAL_INSURANCE_INFORMATION = gql`
query Patientinfo($patient_id: Int!) {
    patientinfo(patient_id: $patient_id) {
        enrollment_data {
            commercial_insurance {
                medical_insurance {
                    name
                    plan_type
                    group_number
                    member_number
                    effective_date
                    bin
                    pcn
                }
            }
        }
    }
}
`;

export const RX_INSURANCE_INFORMATION = gql`
query Patientinfo($patient_id: Int!) {
    patientinfo(patient_id: $patient_id) {
        enrollment_data {
            commercial_insurance {
                rx_insurance {
                    name
                    plan_type
                    group_number
                    member_number
                    bin
                    pcn
                }
            }
        }
    }
}
`;

export const PATIENT_ENROLLMENT_DETAILS = gql`
query Patientinfo($patient_id: Int!) {
    patientinfo(patient_id: $patient_id) {
        enrollment_status 
        drug_patient
        drug_admin_patient
        current_enrollment_date_drug
        current_enrollment_date_drug_admin
        benefits_amounts {
            current_period {
                total_amount_drug
                total_amount_drug_admin
                balance_amount_drug
                balance_amount_drug_admin
                used_amount_drug
                used_amount_drug_admin
                use_count_drug
                use_count_drug_admin
            }
        }
    }
}
`

export const CLAIM_HISTORY = gql`
query Patientclaims ($patient_id: Int!) {
    patientclaims(patient_id: $patient_id) {
        claim_id
        date_of_service
        claim_data {
            out_of_pocket_amount
            patient_responsibility_amount
            benefit_amount
            providers {
                provider {
                    first_name
                    last_name
                    practice_name
                    npi
                    address {
                        address1
                        city
                        state
                        zip_code
                    }
                }
            }
            patient_data {
                enrollment_data {
                    first_name
                    last_name
                    address {
                        city
                        address1
                        zip_code
                        state
                    }
                }
            }
        }
        claim_status
        payment_status
        payment_type
    }
}
`;

export const SEARCH_CLAIM_HISTORY = gql`
query Patientclaims ( $patient_id: Int!, $from_date_of_service: String, $claim_status: String, $payment_status: String ) {
    patientclaims(
        patient_id: $patient_id
        from_date_of_service: $from_date_of_service
        to_date_of_service: $from_date_of_service
        claim_status: $claim_status
        payment_status: $payment_status
    ) {
        claim_id
        program
        date_of_service
        claim_data {
            paid_to {
                first_name
                last_name
                address_1
                city
                state
                zip_code
            }
            shipping_info {
                first_name
                last_name
                address {
                    address_1
                    address_2
                    city
                    state
                    zip_code
                }
            }
            out_of_pocket_amount
            patient_responsibility_before_benefit_applied
            program_benefit
            providers {
                provider {
                    first_name
                    last_name
                    practice_name
                    npi
                    address {
                        address_1
                        city
                        state
                        zip_code
                    }
                }
            }
            patient_data {
                enrollment_data {
                    first_name
                    last_name
                    address {
                        city
                        address_1
                        zip_code
                        state
                    }
                }
            }
        }
        claim_status
        payment_status
        payment_type
    }
}

`;



export const GET_PATIENT_PROVIDER_NPI = gql`
query Patientinfo ($patient_id: Int!) {
    patientinfo(patient_id: $patient_id) {
        provider_data {
            primary {
                provider_npi
            }
        }
    }
}
`;

export const GET_SITE_OF_CARE_NPI = gql`
query Patientinfo ($patient_id: Int!) {
    patientinfo(patient_id: $patient_id) {
        provider_data {
            secondary {
                provider_npi
            }
        }
    }
}
`;

export const PROVIDER_INFORMATION = gql`
query Providernpi ($npi: Int!, $patient_id: Int!) {
    providernpi(npi: $npi, patient_id : $patient_id) {
      provider_id
      provider_type
      provider_data {
        provider_id
        provider_type
        first_name
        last_name
        practice_name
        phone_number
        address {
          address_1
          address_2
          city
          state
          zip_code
        }
        user_agreement
      }
      credentials_id
    }
  }  
`;

export const PATIENT_COMMUNICATIONS = gql`
query PatientCommunications ($patient_id: Int!, $document_sent_from_date: String, $document_sent_to_date: String, $document_types: String!){
    patientcommunications(patient_id: $patient_id, 
        document_sent_from_date: $document_sent_from_date,
        document_sent_to_date: $document_sent_to_date,
      document_types: $document_types) {
      document_id
      patient_id
      member_id
      document_type
      document_file_name
      document_received_date
      document_sent_date
      document_processed_date
      communication_data {
        file_location {
          s3_bucket
          s3_folder
          file_name
        }
      }
      related_document_id
    }
  }  
`;

export const VIRTUAL_CARD_INFO = gql`
query VirtualCard($member_id: String!) {
    patientcard(member_id:$member_id ) {
      result
      data {
        cardNum
        cvv
        cardId
        expDate
      }
    }
  }
`;

export const PARENT_CAREGIVER_INFO = gql`
query Patientinfo ($patient_id: Int!) {
    patientinfo(patient_id: $patient_id) {
        enrollment_data {
            lap {
                first_name
                last_name
                phone
                address {
                    address_1
                    address_2
                    city
                    state
                    zip_code
                }
            }
        }
    }
}
`;

export const PATIENT_INFO_DOC_UPLOAD = gql`
query Patientinfo($patient_id: Int!) {
    patientinfo(patient_id: $patient_id) {
        enrollment_data {
            first_name
            last_name
            member_id
        }
    }
}
`;

export const PROVIDER_SEARCH = gql`
query Providersearch($provider_first_name: String!, $provider_last_name: String!, $provider_state: String!) {
    providersearch(first_name: $provider_first_name, last_name: $provider_last_name, state: $provider_state) {
        provider_id
    }
}
`;

export const GET_PATIENT_ID = gql`
query Patientfindmember($member_id: String!, $dob: String!, $first_name: String!, $last_name: String!) {
    patientfindmember(member_id: $member_id, dob: $dob, first_name: $first_name, last_name: $last_name) {
        patient_id
    }
}
`;

export const ENROLL_SUCCESS = gql`
query Patientinfo($patient_id: Int!) {
    patientinfo(patient_id: $patient_id) {
        enrollment_data {
            first_name
            last_name
        }
        enrollment_status
        member_id
    }
}
`;

export const FIND_ENROLLED_PATIENT = gql`
query patientfindenrolled($first_name: String, $last_name: String, $zip_code: String, $dob: String){
    patientfindenrolled(first_name: $first_name ,last_name: $last_name, zip_code: $zip_code ,dob: $dob){
        patient_id
        drug_patient
        drug_admin_patient
        enrollment_data {
            first_name
            last_name
            date_of_birth
            gender
            address {
                address_1
                address_2
                city
                state
                zip_code
            }
            commercial_insurance {
                medical_insurance {
                    name
                    plan_type
                    member_number
                    group_number
                    bin
                    pcn
                    effective_date
                }
                rx_insurance {
                    name
                    plan_type
                    member_number
                    group_number
                    bin
                    pcn
                }
            }
            lap {
                first_name
                last_name
                address {
                    address_1
                    address_2
                    city
                    state
                    zip_code
                }
            }
        }
        patient_provider_data {
            primary {
                first_name
                last_name
                practice_name
                phone_number
                npi
                address {
                    address_1
                    address_2
                    city
                    state
                    zip_code
                }
            }
            secondary {
                first_name
                last_name
                practice_name
                phone_number
                npi
                address {
                    address_1
                    address_2
                    city
                    state
                    zip_code
                }
            }
        }
    }
  }`;

  export const FIND_ENROLLED_PATIENT_BY_MEMBERID = gql`
  query patientfindenrolled ($member_id: String, $dob: String){
    patientfindenrolled(member_id: $member_id ,dob: $dob){
        patient_id
        drug_patient
        drug_admin_patient
        enrollment_data {
            first_name
            last_name
            date_of_birth
            gender
            address {
                address_1
                address_2
                city
                state
                zip_code
            }
            commercial_insurance {
                medical_insurance {
                    name
                    plan_type
                    member_number
                    group_number
                    bin
                    pcn
                    effective_date
                }
                rx_insurance {
                    name
                    plan_type
                    member_number
                    group_number
                    bin
                    pcn
                }
            }
            lap {
                first_name
                last_name
                address {
                    address_1
                    address_2
                    city
                    state
                    zip_code
                }
            }
        }
        patient_provider_data {
            primary {
                first_name
                last_name
                practice_name
                phone_number
                npi
                address {
                    address_1
                    address_2
                    city
                    state
                    zip_code
                }
            }
            secondary {
                first_name
                last_name
                practice_name
                phone_number
                npi
                address {
                    address_1
                    address_2
                    city
                    state
                    zip_code
                }
            }
        }
    }
  }`;

  export const FILL_ENROLLED_DATA = gql`
  query Patientinfo ($patient_id: Int!) {
    patientinfo(patient_id: $patient_id) {
        enrollment_data {
            first_name
            last_name
            date_of_birth
            gender
            phone
            address {
                address_1
                address_2
                city
                state
                zip_code
            }
            commercial_insurance {
                medical_insurance {
                    name
                    plan_type
                    member_number
                    group_number
                    bin
                    pcn
                    effective_date
                }
                rx_insurance {
                    name
                    plan_type
                    member_number
                    group_number
                    bin
                    pcn
                }
            }
            lap {
                first_name
                last_name
                phone
                address {
                    address_1
                    address_2
                    city
                    state
                    zip_code
                }
            }
        }
    }
}
`

export const PATIENT_USER_PROFILE = gql`
query Patientinfo ($patient_id: Int!){
    patientinfo(patient_id: $patient_id) {
        patient_id
        member_id
        enrollment_data {
            first_name
            last_name
            email
        }
    }
}
`;

export const CHANGE_PASSWORD = gql`
query Patientpassword ($member_id: String!, $new_password_hash: String!){
    patientpassword(member_id: $member_id, new_password_hash: $new_password_hash) {
        result
        login_id
    }
}
`;