import React from 'react'
import specialPharmCircle from '../../images/SpecialtyPharm-circle.png'
import copayCardCircle from '../../images/CopayCard-circle.png'
import noCopayCardCircle from '../../images/NOCopayCard-circle.png'

export default function UsingTheCardAccount() {
    return (
        <div class="container" id="content" style={{fontSize: '1em'}}>
            <div id="container">
                <div class="using-hcp">
                    <h2 style={{color: 'rgb(0, 117, 159)' , fontSize: '1.25em', lineHeight: '1.49em'}}>How to Use the Program</h2>
                    <p> The XOLAIR Co-pay Program for drug or administration assistance may be used in a community practice, an alternate injection center (AIC), hospital outpatient department (HOPD), or patient's address. For drug assistance only, the program may also be used with specialty pharmacies. </p>
                    <p> Participating practices, AICs, HOPDs or specialty pharmacies must complete a one-time registration so they may process XOLAIR Co-pay Program payments. To register, please call <span class="text-nowrap"><strong>(855) 965-2472</strong></span>.</p>
                    <div class="clearfloat"></div>
                    <div class="using-hcp-box arrow-bottom">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="first" style={{fontSize: '1em', lineHeight: '1.49em'}}>
                                        <img src={specialPharmCircle} alt="How to use the program - Step 1"/>
                                    </td>
                                    <td style={{fontSize: '1em', lineHeight: '1.49em'}}>
                                        <div class="title strong">SPECIALTY PHARMACIES</div>
                                        <ul>
                                            <li style={{backgroundPosition: '0px -337px'}}> <span>Receive</span>  the patient's XOLAIR prescription and RxBIN, PCN, group number and Member ID </li>
                                            <li style={{backgroundPosition: '0px -337px'}}> Patient pays as little as $0* per XOLAIR drug out-of-pocket cost </li> 
                                            <li style={{backgroundPosition: '0px -337px'}}><span>Ship</span>  XOLAIR to the treatment location</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="using-hcp-box arrow-bottom">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="first" style={{fontSize: '1em', lineHeight: '1.49em'}}><img src={copayCardCircle} alt="How to use the program - Step 2"/>
                                    </td><td style={{fontSize: '1em', lineHeight: '1.49em'}}><div class="title strong"> DOCTOR'S OFFICES, HOPDs AND AICs WITH A CREDIT CARD TERMINAL </div>
                                        <ul> <li style={{backgroundPosition: '0px -337px'}}> Patient pays as little as $0* per XOLAIR drug out-of-pocket cost and/or as little as $0* per XOLAIR administration out-of-pocket cost</li>
                                            <li style={{backgroundPosition: '0px -337px'}}> <span>Upload</span> or <span>Fax</span> the patient's explanation of benefits (EOB)<sup>†</sup> to the XOLAIR Co-pay Program at <span class="text-nowrap">(866) 440-0599</span></li>
                                            <li style={{backgroundPosition: '0px -337px'}}> Within 2 business days, the office will receive an EOB Confirmation Fax indicating the status of the account. The EOB Confirmation Fax includes:<br />
                                                <ul>
                                                    <li style={{backgroundPosition: '0px -337px'}}>The amount added to the account <strong>OR</strong></li>
                                                    <li style={{backgroundPosition: '0px -337px'}}> Explanation of why the account cannot be used (eg, insufficient EOB information or program limit has been reached) </li>
                                                </ul>
                                            </li>
                                            <li style={{backgroundPosition: '0px -337px'}}> <span>Collect</span>  remaining amount of XOLAIR drug and/or administration out-of-pocket cost by keying in the<br /> 16-digit number </li>
                                            <li style={{backgroundPosition: '0px -337px'}}> The XOLAIR Co-pay Program verifies the patient's information and notifies your practice when the account is loaded with the proper amount </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> <div class="using-hcp-box">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="first" style={{fontSize: '1em', lineHeight: '1.49em'}}><img src={noCopayCardCircle} alt="How to use the program - Step 3"/></td>
                                    <td style={{fontSize: '1em', lineHeight: '1.49em'}}>
                                        <div class="title strong"> DOCTOR'S OFFICES, HOPDs AND AICs NOT USING A CREDIT CARD TERMINAL </div>
                                        <ul>
                                            <li style={{backgroundPosition: '0px -337px'}}>Patient pays as little as $0* per XOLAIR drug out-of-pocket cost and/or as little as $0* per XOLAIR administration out-of-pocket cost</li>
                                            <li style={{backgroundPosition: '0px -337px'}}>Complete the Reimbursement Request Form<sup>‡</sup></li>
                                            <li style={{backgroundPosition: '0px -337px'}}><span>Fax</span> the completed Reimbursement Request Form along with the patient's EOB and copy of paid receipt to the XOLAIR Co-pay Program Help Desk at <span class="noWrap">(866) 440-0599</span><br />
                                                <ul>
                                                    <li style={{backgroundPosition: '0px -337px'}}>Providers can only fax the patient's EOB information along with the completed Reimbursement Request Form</li>
                                                    <li style={{backgroundPosition: '0px -337px'}}>You may need to complete the Verification of Administration Form only if the EOB does not have  the drug name or J-code</li>
                                                </ul>
                                            </li>
                                            <li style={{backgroundPosition: '0px -337px'}}>A check will be sent to the recipient indicated on the Reimbursement Request Form</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>*<strong>Out-of-pocket costs for XOLAIR drug and/or administration may be as little as $0. The final amount owed by patients may be as little as $0, but may vary depending on the patient's health insurance plan. </strong></p>
                    <p><sup>†</sup>EOBs must be submitted within 365 days of the date of service.</p>
                    <p><sup>‡</sup>Providers may use the Reimbursement Request Form for drug assistance only.</p>
                </div>
            </div>
        </div>

    )
}