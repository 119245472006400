import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery, useApolloClient } from "@apollo/client";
import hcpBanner from "../../images/hcp-banner.jpeg";
import LoginError from "../../components/LoginError";
import { HCP_LOGIN } from "../../graphql/queries/Users";
import { PROVIDER_DATA } from "../../graphql/queries/Providers";
import $ from "jquery";

export default function HCPHome() {
    // function closeAnnouncement() {
    //     $(".home-page-announcement").css("display", "none");
    // }

    let navigate = useNavigate();
    var [incorrectLoginCount, setcount] = useState(0);
    let maxAttempts = 5;
    var [attemptsLeft, setAttemptsLeft] = useState(maxAttempts);
    const [login_id, setloginquery] = useState("");
    const [password_hash, setpasswordquery] = useState("");
    const [loginID, setLoginID] = useState("");
    const [password, setPassword] = useState("");
    var redirect_agree = false;
    var redirect_fax = false;
    const [provider_id, setproviderid] = useState(0);
    const [loggedIn, setLoggedIn] = useState(false);

    const [userlogin, { called, loading, data }] = useLazyQuery(HCP_LOGIN, {
        variables: { login_id, password_hash },
    });

    const [redirectPage, { data: providerdata }] = useLazyQuery(PROVIDER_DATA, {
        variables: { provider_id },
    });
    const client = useApolloClient();

    if (loginID != "") {
        $('label[for="username"]').removeClass("normal");
        $('label[for="username"]').addClass("smaller");
    }
    if (password != "") {
        $('label[for="password"]').removeClass("normal");
        $('label[for="password"]').addClass("smaller");
    }

    //keeps track of login data and determines if comes back as valid user
    useEffect(() => {
        if (data != null) {
            if (data.usersprovider != null) {
                if (data.usersprovider.user != null) {
                    if (data.usersprovider.user.result != null) {
                        if (data.usersprovider.user.result == 0) {
                            validUser(data);
                            // setLoggedIn(true);
                            async function asyncStateLoggedIn() {
                                await setLoggedIn(true);
                            }
                            asyncStateLoggedIn();
                        } else {
                            //manage incorrect credentials, show message and track count
                            // setcount(incorrectLoginCount + 1);
                            setAttemptsLeft(attemptsLeft - 1);
                            setLoginID("");
                            setPassword("");
                        }
                    }
                }
            } else {
                setcount(incorrectLoginCount + 1);
                // setAttemptsLeft(attemptsLeft - 1);
            }
        } else {
            if (loginID != "") {
                setcount(incorrectLoginCount + 1);
                // if (attemptsLeft > 0) setAttemptsLeft(attemptsLeft - 1);
                setLoginID("");
                setPassword("");
            }
        }
    }, [data]);

    useEffect(() => {
        setLoggedIn(window.localStorage.getItem("providerID") ? true : false);
        // console.log("Test5");
        let cachedLoggedIn = window.localStorage.getItem("providerID") ? true : false;
        let path = cachedLoggedIn ? "patient-list" : "";
        navigate(path);
    }, []);

    //if valid user, this checks provider data to determine which page to direct to on login
    useEffect(() => {
        if (providerdata != null) {
            if (providerdata.providerfind != null) {
                if (providerdata.providerfind.provider_data != null) {
                    if (
                        providerdata.providerfind.provider_data.fax_opt_in &&
                        (providerdata.providerfind.provider_data.fax_opt_in.toLowerCase() == "n" ||
                            providerdata.providerfind.provider_data.fax_opt_in.toLowerCase() == "y")
                    ) {
                        // redirect_agree = true;
                        redirect_fax = true;
                    }
                    if (providerdata.providerfind.provider_data.user_agreement) {
                        if (providerdata.providerfind.provider_data.user_agreement.toLowerCase() == "y") {
                            redirect_agree = true;
                        }
                    }
                    if (providerdata.providerfind.provider_data.opt_in_fax_numbers != null) {
                        if (
                            providerdata.providerfind.provider_data.opt_in_fax_numbers.fax1 &&
                            providerdata.providerfind.provider_data.opt_in_fax_numbers.fax2 &&
                            providerdata.providerfind.provider_data.opt_in_fax_numbers.fax3
                        ) {
                            if (
                                providerdata.providerfind.provider_data.opt_in_fax_numbers.fax1.toUpperCase() != "NULL" ||
                                providerdata.providerfind.provider_data.opt_in_fax_numbers.fax2.toUpperCase() != "NULL" ||
                                providerdata.providerfind.provider_data.opt_in_fax_numbers.fax3.toUpperCase() != "NULL"
                            ) {
                                redirect_fax = true;
                            }
                        }
                    }
                }
            }
        }
        if (loggedIn == true) {
            if (redirect_agree == true && redirect_fax == false) {
                let path = `/hcp/fax-consent`;
                navigate(path);
            } else if (redirect_agree == true && redirect_fax == true) {
                let path = `/hcp/patient-list`;
                navigate(path);
            } else {
                let path = `/hcp/agreement`;
                navigate(path);
            }
        }
    }, [providerdata]);

    //runs if user is valid, this sets token, id, etc
    function validUser(receivedData) {
        if (receivedData.usersprovider.token != null) {
            window.localStorage.setItem("token", data.usersprovider.token);
            if (receivedData.usersprovider.user.user_type != null) {
                if (data.usersprovider.user.user_type.toLowerCase() == "provider" || data.usersprovider.user.user_type.toLowerCase() == "administrator") {
                    if (data.usersprovider.user.user_type.toLowerCase() == "administrator") {
                        window.localStorage.setItem("credentials_id", JSON.stringify(data.usersprovider.user.credentials_id));
                    }
                    window.localStorage.setItem("providerID", JSON.stringify(data.usersprovider.user.xref_id));
                    window.localStorage.setItem("userID", JSON.stringify(data.usersprovider.user.user_id));
                    window.localStorage.setItem("userType", JSON.stringify(data.usersprovider.user.user_type));
                    window.localStorage.setItem("credentialsID", JSON.stringify(data.usersprovider.user.credentials_id));
                    setproviderid(data.usersprovider.user.xref_id);

                    //determine which page to navigate
                    redirectPage();
                } else {
                    //if invalid user type, treat like incorrect password
                    setcount(incorrectLoginCount + 1);
                    setAttemptsLeft(attemptsLeft - 1);
                    setLoginID("");
                    setPassword("");
                }
            }
        }
    }

    //check fields are not empty and run login query on form submission
    async function login(e) {
        e.preventDefault();
        localStorage.removeItem("token");

        if (loginID != "" && password != "" && attemptsLeft > 0) {
            setloginquery(loginID);
            setpasswordquery(password);
            async function asyncLogin() {
                await userlogin({ login_id, password_hash });
            }
            await asyncLogin();
            // }

            if (data != null) {
                if (data.usersprovider != null) {
                    if (data.usersprovider.user != null) {
                        if (data.usersprovider.user.result != null) {
                            if (data.usersprovider.user.result == 0) {
                                validUser(data);
                                setLoggedIn(true);
                            } else {
                                //manage incorrect credentials, show message and track count
                                setcount(incorrectLoginCount + 1);
                                setAttemptsLeft(attemptsLeft - 1);
                                setLoginID("");
                                setPassword("");
                            }
                        }
                    }
                } else {
                    setcount(incorrectLoginCount + 1);
                    if (attemptsLeft > 0) setAttemptsLeft(attemptsLeft - 1);
                }
            }
        }
    }

    return (
        <>
            {/* <div className="home-page-announcement">
                <div className="popup-box">
                    <h1>Program Update</h1>
                   
                    <p>
                        <button className="close-button" onClick={closeAnnouncement} type="button">
                            Close
                        </button>
                    </p>
                </div>
            </div> */}
            <div className="container" id="content" style={{ fontSize: "1em" }}>
                <div id="container">
                    <div id="home-container-patients">
                        <img src={hcpBanner} />
                        <p style={{ fontSize: "14px", marginTop: "10px" }}>
                            <strong>
                                The final amount owed by patients may be as little as $0, but may vary depending on the patient's health insurance plan.
                                Eligible commercially insured patients who are prescribed XOLAIR for an FDA-approved use can receive up to $15,000 in assistance
                                annually for drug costs and/or up to $1,500 in assistance annually for injection costs. For more information, see full program{" "}
                                <Link to="terms-and-conditions">Terms and Conditions</Link>.
                            </strong>
                        </p>
                    </div>
                    <h2 className="mt-4" style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>
                        Log in to Your Account
                    </h2>
                    <div className="account">
                        {incorrectLoginCount > 0 ? <LoginError count={attemptsLeft} /> : <></>}
                        <div className="small-login">
                            <form onSubmit={(e) => login(e)} className="form-validate" acceptCharset="utf-8">
                                <p className="field required-field text">
                                    <label htmlFor="username" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        Username<span className="required">*</span>:
                                    </label>
                                    <input onChange={(e) => setLoginID(e.target.value)} type="text" name="username" id="username" value={loginID} />
                                </p>
                                <p className="field required-field text">
                                    <label htmlFor="password" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        Password<span className="required">*</span>:
                                    </label>
                                    <input
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        type="password"
                                        name="password"
                                        id="pass"
                                        autoComplete="off"
                                    />
                                </p>
                                <p style={{ textAlign: "center" }}>
                                    <button type="submit" name="loginSubmit" autoComplete="off">
                                        Log in
                                    </button>
                                </p>
                            </form>
                        </div>
                        <div className="clear"></div>
                        <p style={{ marginBottom: "20px" }}>
                            If you forgot your username please call{" "}
                            <span className="text-nowrap">
                                <strong>(855) 965-2472</strong>
                            </span>{" "}
                            for assistance. If you forgot your password please <Link to="../../password-request">click here</Link>.
                        </p>
                        <p>
                            Due to privacy policies, health care professionals or specialty pharmacies who do not have an account must call{" "}
                            <span className="text-nowrap">
                                <strong>(855) 965-2472</strong>
                            </span>{" "}
                            to create one.
                        </p>
                    </div>
                    <div className="clearfloat"></div>
                </div>
            </div>
        </>
    );
}
