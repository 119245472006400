import React, { useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

export default function AdditionalPatientServices() {
    const { setTitle, setStep, enrollment_data, setenrollmentdata, email_opt_in, setemailopt } = useOutletContext()

    const [checkbox, setcheckbox] = useState('')
    const [email_address, setemail] = useState('')
   

    /*function updateEmail(e) {
        const newEmail = e.target.value;
         setemail(e.target.value)
         enrollment_data.patient_services_email = e.target.value
       const obj={
        ...enrollment_data,
        "patient_services_email":newEmail
       }
       window.localStorage.setItem('enrollment_data', JSON.stringify(obj))

    }*/
    function updateEmail(e) {
        const newEmail = e.target.value;
         setemail(e.target.value);
         console.log(newEmail);
         const emailoptin= window.localStorage.getItem('emailoptin');
         const enrolldata = JSON.parse(window.localStorage.getItem('enrollment_data') || '{}');

         if(emailoptin === 'Y' && newEmail === '' || enrolldata?.email === '' ){
            console.log("working")
            const obj={
                email_validations:"Please Enter Valid Email"
            }
            window.localStorage.setItem('email_validation', JSON.stringify(obj))

         }else{
            const obj={
                email_validations:""
            }
            window.localStorage.setItem('email_validation', JSON.stringify(obj))
         }
         enrollment_data.patient_services_email = e.target.value;
       const obj={
        ...enrollment_data,
        "patient_services_email":newEmail
       }
       window.localStorage.setItem('enrollment_data', JSON.stringify(obj))

    }
    function updateCheckbox(e) {
        setcheckbox(e.target.value)
        email_opt_in.opt = e.target.value
        window.localStorage.setItem('email_opt_in', JSON.stringify(email_opt_in))
        window.localStorage.setItem('emailoptin', e.target.value)
        const emailoptin= window.localStorage.getItem('emailoptin');
        if( emailoptin === 'N'){
         enrollment_data.patient_services_email === '';
         const obj={
            ...enrollment_data,
            "patient_services_email":""
         }
         window.localStorage.setItem('enrollment_data', JSON.stringify(obj))

        }else{
         const enrolldata = JSON.parse(window.localStorage.getItem('enrollment_data') || '{}');
         const obj={
            ...enrollment_data,
            "patient_services_email":email_address || enrolldata?.email
         }
         enrollment_data.patient_services_email === enrolldata?.email || email_address

         window.localStorage.setItem('enrollment_data', JSON.stringify(obj))
        }
    }

    const emailValidation = window.localStorage.getItem('email_validation');
    let EmailValidations = {};
    if (emailValidation) {
        try {
          EmailValidations = JSON.parse(emailValidation || '{}');
        } catch (error) {
          console.error('Error parsing email_validation:', error);
        }
      }

    return (
        <>
            <div className="section shadow-box">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <h2>Sign Up for Additional Patient Services</h2>
                    <p style={{minHeight: '30px'}}><span style={{ color: 'red' }}><sup>*</sup></span> Required field if you wish to sign up for this optional and free patient support program</p>
                    <p style={{minHeight: '30px'}}> In addition to the XOLAIR Co-pay Program, XOLAIR offers an optional and free patient support program. <mat-error style={{ color: 'red' }}><sup>*</sup></mat-error>You can get tools and resources to help stay motivated and in the know about what to expect next when you are on XOLAIR. </p>
                    <p style={{minHeight: '30px'}}>Would the patient like to enroll in the patient support program?<span style={{ color: 'red' }}><sup>*</sup></span> </p>
                    <p>
                        <label className="radio" ><input onChange={e => updateCheckbox(e)} type="radio" id="enroll-yes" name="enroll_email" value="Y" />   Yes <span className="checkmark"></span></label>
                        <label className="radio" ><input onChange={e => updateCheckbox(e)} type="radio" name="enroll_email" id="enroll-no" value="N" />  No <span className="checkmark"></span></label>
                    </p>
                    <div id="hide-yes" className="hide">
                        <div className={"col-sm-12 col-md-12" /* col-lg-3 */ }>
                            <p className="select-form ">
                                <label style={{ textAlign: 'left', width: 'auto', marginRight: '0'}}> Email address</label>:<span style={{ color: 'red' }}><sup>&dagger;</sup></span><br />
								<input onChange={e => updateEmail(e)} style={{ width: '100%' }} type="text" name="email_yes" id="email-yes" value={enrollment_data.patient_services_email} />                            
							</p>
                        	<p style={{color:'red'}}>{EmailValidations?.email_validations}</p>
                        </div>
                        <p className="checkbox-field"><label style={{ width: '100%' }} className="checkbox"><input id='terms-checkbox' type="checkbox" name="agree" value="yes" />By checking this box, I am requesting to receive optional disease education and other materials offered by Genentech and its affiliates. I understand that my personal information, some of which may be considered sensitive information, such as information about health conditions, will be used and disclosed as described in Genentech's <a href="http://www.gene.com/privacy-policy" target="_blank">Privacy Policy</a>, and that I may be contacted using the information I have provided. I understand providing this agreement is voluntary and plays no role in getting my medicine, and that I may opt out of receiving this information at any time by calling 877-436-3683. By submitting this form, I confirm that I am 18 years of age or older and that I agree to the <a href="https://www.gene.com/terms-conditions" target="_blank">Terms & Conditions</a>.<span className="checkmark "></span></label>
                        </p>
                        <p><span style={{ color: 'red' }}><sup>†</sup></span> <i>Your email address is required to participate in this optional and free patient support program.</i></p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </>

    )
}





/*
import React, { useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

export default function AdditionalPatientServices() {
    const { setTitle, setStep, enrollment_data, setenrollmentdata, email_opt_in, setemailopt } = useOutletContext()

    const [checkbox, setcheckbox] = useState('')
    const [email_address, setemail] = useState('')


    function updateEmail(e) {
        setemail(e.target.value)
        enrollment_data.email = e.target.value

    }
    function updateCheckbox(e) {
        setcheckbox(e.target.value)
        email_opt_in.opt = e.target.value
        window.localStorage.setItem('email_opt_in', JSON.stringify(email_opt_in))
    }

        return (
            <>
                <div className="section shadow-box">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Sign Up for Additional Patient Services</h2>
                        <p style={{minHeight: '30px'}}><span style={{ color: 'red' }}><sup>*</sup></span> Required field if you wish to sign up for this optional and free patient support program</p>
                        <p> In addition to the XOLAIR Co-pay Program, XOLAIR offers an optional and free patient support program. <mat-error style={{ color: 'red' }}><sup>*</sup></mat-error>You can get tools and resources to help stay motivated and in the know about what to expect next when you are on XOLAIR. </p>
                        <p style={{minHeight: '30px'}}>Would the patient like to enroll in the patient support program?<span style={{ color: 'red' }}><sup>*</sup></span> </p>
                        <p style={{minHeight: '30px'}}>
                            <label className="radio" style={{ fontSize: '1em', lineHeight: '1.49em' }}><input onChange={e => updateCheckbox(e)} type="radio" id="enroll-yes" name="enroll_email" value="Y" />   Yes <span className="checkmark"></span></label>
                            <label className="radio" style={{ fontSize: '1em', lineHeight: '1.49em' }}><input onChange={e => updateCheckbox(e)} type="radio" name="enroll_email" id="enroll-no" value="N" />  No <span className="checkmark"></span></label>
                        </p>
                        <div id="hide-yes" className="hide">
                            <div className={"col-sm-12 col-md-12" /* col-lg-3 * / }> 
                                <p className="select-form field required-field text ignore">
                                    <label style={{ textAlign: 'left', width: 'auto', fontSize: '1em', lineHeight: '1.49em', marginRight: '0' }}> Email address</label>:<span style={{ color: 'red' }}><sup>†</sup></span><br />
                                    <input onChange={e => updateEmail(e)} style={{ width: '100%' }} type="text" name="email_yes" id="email-yes" value={enrollment_data.email} />
                                </p>
                            </div>
                            <p>By submitting this form, I am requesting disease education and other materials from and on behalf of Genentech and its affiliates. This may include information about products and services, including co-pay assistance or other patient support, opportunities to participate in surveys or provide feedback, or other topics. I understand that my personal information, which may include sensitive information, will be used and disclosed as described in Genentech's <a href="http://www.gene.com/privacy-policy" target="_blank">Privacy Policy</a> and that I may be contacted using the information I have provided. I understand providing this agreement is voluntary and plays no role in getting my medicine, and that I may opt out of receiving this inforrnation at any time by calling 877-436-3683. By submitting this form, I confirm that I am 18 years of age or older and that I agree to the <a href="https://www.gene.com/terms-conditions" target="_blank">Terms &amp; Conditions</a>.
                            </p>
                            <p><span style={{ color: 'red' }}><sup>†</sup></span> <i>Your email address is required to participate in this optional and free patient support program.</i></p>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
            </>
        )
}
*/