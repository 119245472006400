import { gql } from '@apollo/client';

export const UPDATE_ALLERGY_PARTNER_PROFILE_SETTINGS = gql`
mutation Providerupdate ($provider_id: String!, $first_name: String, $last_name: String, $practice_name: String, $address1: String, $address2: String, $city: String, $state: String, $zip_code: String, $phone: String, $fax: String){
    providerupdate(
        input: {provider_id: $provider_id, 
            provider_data: {first_name: $first_name, last_name: $last_name, practice_name: $practice_name, address: {address_1: $address1, address_2: $address2, city: $city, state: $state, zip_code: $zip_code}, phone_number: $phone, fax_numbers: {fax_number: $fax}}}
    ) {
        provider_id
    }
}

`;

export const UPDATE_ACH = gql`
mutation Providerachupdate ($provider_id: String!, $bank: String!, $routing_number: String!, $account_number: String!, $request_date: String!, $bank_address: String!, $account_name: String!) {
    providerachupdate(
        input: {
            provider_id: $provider_id
            ach_info: {
                bank: $bank
                routing_number: $routing_number
                account_number: $account_number
                request_date: $request_date
                bank_address: $bank_address
                account_name: $account_name
            }
        }
    ) {
        message
        provider_id
    }
}`;

export const UPDATE_USER_AGREEMENT = gql`
mutation Providerupdate($provider_id: Int!, $practice_name: String!, $user_agreement: String!) {
    providerupdate(
        input: {
            provider_id: $provider_id
            provider_data: { practice_name: $practice_name, user_agreement: $user_agreement }
        }
    ) {
        provider_id
    }
}
`;

export const UPDATE_FAX_CONSENT = gql`
mutation Providerupdate($provider_id: Int!, $opt_in: String!, $fax1: String!) {
    providerupdate(
        input: {
            provider_id: $provider_id
            provider_data: { fax_opt_in: { opt_in: $opt_in, fax1: $fax1 } }
        }
    ) {
        provider_id
    }
}
`;

export const UPDATE_PROVIDER_DATA = gql`
mutation Providerupdate ($provider_id: String!, $email: String, $first_name: String!, $last_name: String!, $practice_name: String!, $phone_number: String!, $address_1: String!, $address_2: String!, $city: String!, $state: String!, $zip_code: String!, $fax_opt_in: String!, $fax1: String!, $fax2: String!, $fax3: String!, $user_agreement: String! ){
    providerupdate(
        input: {
            provider_id: $provider_id
            email: $email
            provider_data: {
                first_name: $first_name
                last_name: $last_name
                practice_name: $practice_name
                phone_number: $phone_number
                address: {
                    address_1: $address_1
                    address_2: $address_2
                    city: $city
                    state: $state
                    zip_code: $zip_code
                }
                fax_opt_in: $fax_opt_in
                opt_in_fax_numbers: { fax1: $fax1, fax2: $fax2, fax3: $fax3 }
                user_agreement: $user_agreement
            }
        }
    ) {
        provider_id
    }
}
`;
