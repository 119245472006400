import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
export default function ReviewAdditionalPatientServices() {

    var additionalPatientServices
    const { email_opt_in, setemailopt } = useOutletContext()


    if (window.localStorage.getItem('email_opt_in')) {
        additionalPatientServices = JSON.parse(window.localStorage.getItem('email_opt_in'))
    }

    useEffect(() => {
        if (window.localStorage.getItem('email_opt_in')) {
            setemailopt(additionalPatientServices)
        }
    }, [])
    return (
        <div className="section shadow-box color-label">
            <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Sign Up for Additional Patient Services</h2>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <p>
                    <label className="color" style={{ fontSize: '1em', lineHeight: '1.49em' }}>Would the patient like to enroll in the patient support program? </label>
                    {((email_opt_in.opt.toUpperCase() == 'Y') || (email_opt_in.opt.toUpperCase() == 'YES')) ? 'Yes' : 'No'}</p>
            </div>
        </div>
    )
}