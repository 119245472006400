
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useLazyQuery, gql } from '@apollo/client';
import { CREATE_ACCOUNT } from '../graphql/mutations/Patients'

export default function CreatePassword() {

    const [password, setpassword] = useState('')
    const [confirm_password, setconfirmpassword] = useState('')

    const [validpassword, setvalidpassword] = useState(true)
    const [validconfirmpassword, setvalidconfirmpassword] = useState(true)
    const [passwordMatch, setpasswordmatch] = useState(true)
    const [hasSpecialCharacter, setHasSpecialCharacter] = useState(true)

    const [userCreated, setusercreated] = useState(false)
    const [userExists, setuserexists] = useState(false)

    const [password_hash, setpasswordhash] = useState('')
    const xref_id = parseInt(window.localStorage.getItem('patientID'))
    const login_id = window.localStorage.getItem('memberID')

    const [createAccount, { called, loading, data }] = useLazyQuery(CREATE_ACCOUNT, {
        variables: { login_id, password_hash, xref_id }
    });

    /*
    query Createpatientuser($login_id: String!, $password_hash: String!, $xref_id: Int!) {
        createpatientuser(
            login_id: $login_id
            password_hash: $password_hash
            user_type: "patient"
            xref_id: $xref_id
        ) {
            message
        }
    }
    */


    useEffect(() => {
        if (xref_id) {
            if (data) {
                console.log(data)
                if (data.createpatientuser == null) {
                    setuserexists(true)
                } else {
                    if (data.createpatientuser.message) {
                        setusercreated(true)
                    }
                }
            } else {
            }
        } else {
        }
    }, [data])

    function createPassword(event) {
        event.preventDefault();
        if (password != confirm_password) {
            setpasswordmatch(false)
        } else {
            setpasswordmatch(true)
        }
        if ((password == '') || (password.length < 12)) {
            setvalidpassword(false)
        } else {
            setvalidpassword(true)
        }
        if ((confirm_password == '') || (confirm_password.length < 12)) {
            setvalidconfirmpassword(false)
        } else {
            setvalidconfirmpassword(true)
        }
        if(containsSpecialChars(password) == false ){
            setHasSpecialCharacter(false)
        } else {
            setHasSpecialCharacter(true)
        }
        if ((password != '') && (confirm_password != '') && (passwordMatch == true) && (validpassword == true) && (validconfirmpassword == true) && (hasSpecialCharacter == true)) {
            setpasswordhash(password)
            createAccount()
        }
    }

    function containsSpecialChars(str) {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str);
      }

    return (
        <div className="container" id="content" style={{ fontSize: '1em' }}>
            <div id="container">
            <div id="hcp_post_content">
                <div className="space"></div>
                <div className="shadow-box">
                    <h2 style={{ fontSize: '1.25em', lineHeight: '1.49em' }}>Choose New Password for User </h2>
                    <p className="required">*Required field.</p>
                    <p>Your new password must be</p>
                    <ul><li>At least 12 characters</li><li>At least 1 capital letter</li><li>At least 1 lowercase letter</li><li>At least 1 number</li><li>At least 1 special character (e.g., !@#$%^&amp;*()+|~-=`{}[]:";'&lt;&gt;?,./)</li><li>Cannot reuse the last 5 passwords</li></ul>
                    <div className="find_patient">
                        <form onSubmit={event => createPassword(event)} className="form-validate-create-password" >
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <p className="field required-field password">
                                    <label className="normal" style={{fontSize: '1em', lineHeight: '1.49em'}}><span className="inner-label">New Password<span className="required">*</span></span></label>
                                    {validpassword ? <input value={password} onChange={e => setpassword(e.target.value)} type="password" name="" id="pass_2" autoComplete="off" /> : <><input value={password} onChange={e => setpassword(e.target.value)} type="password" name="" id="pass_2" autoComplete="off" style={{ border: '1px solid red' }} />{password == '' ? <span class="prompt-error error">Please enter New Password*</span> : <></>}{((password != '') && (password.length <= 12)) ? <span class="prompt-error error">New Password* must be at least 12 characters long.</span> : <></>}</>}
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <p className="field required-field password password-retype">
                                    <label className="normal" style={{fontSize: '1em', lineHeight: '1.49em'}}><span className="inner-label">Confirm New Password<span className="required">*</span></span></label>
                                    {validconfirmpassword ? <input value={confirm_password} onChange={e => setconfirmpassword(e.target.value)} type="password" name="" id="pass_3" autoComplete="off" /> : <><input value={confirm_password} onChange={e => setconfirmpassword(e.target.value)} type="password" name="" id="pass_3" autoComplete="off" style={{ border: '1px solid red' }} />{confirm_password == '' ? <span class="prompt-error error">Please enter Confirm New Password*</span> : <></>}{((confirm_password != '') && (confirm_password.length <= 12)) ? <span class="prompt-error error">Confirm New Password* must be at least 12 characters long.</span> : <></>}</>}{passwordMatch ? <></> : <span class="prompt-error error">New Password and Confirm New Password do not match.</span>}
                                </p>
                            </div>
                            <p>
                                <br />
                                <button type='submit' name="loginSubmit" autoComplete="off">Update</button>
                            </p>
                            {userExists == true ? <p>Account already exists</p> : <></>}
                            {userCreated == true ? <p>Account created <Link to="../login">login</Link></p> : <></>}
                        </form>

                    </div>
                </div>
                <div className="clearfloat"></div>
            </div>
            </div>
        </div>
    )
}