import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import AccountNavbar from '../components/AccountNavbar'
import PatientProfileDetails from '../components/PatientProfileDetails'
import MembershipInformation from '../components/MembershipInformation'
import CommercialMedicalInsurance from '../components/CommercialMedicalInsurance'
import PharmacyInsuranceInformation from '../components/PharmacyInsuranceInformation'
import PatientEnrollmentDetails from '../components/PatientEnrollmentDetails'

export default function Profile() {

	useEffect(() => {
		const popup = window.localStorage.getItem('popup') ? window.localStorage.getItem('popup') : ''
		$(function () {
			if(popup == 'true'){
				$('.page-alert').css('display', 'none');
			} else {
				$('.page-alert').css('display', 'block');
				$('.page-alert .close-button').on('click', function (event) {
					event.preventDefault();
					window.localStorage.setItem('popup', 'true')
					$('.page-alert').css('display', 'none');
				});
				
			}
			
		});
	})
	return (
		<div className="container" id="content" style={{ fontSize: '1em' }}>
			<div className="page-alert" style={{ display: 'none' }}>
				<div className="popup-box" style={{ padding: '35px' }}>
				<p>We've recently made changes to your co-pay program patient account page. Please review your email address and phone type and update the information accordingly. By providing your email, you can easily reset your password online without having to contact the program for assistance. Email address and phone may be used for future co-pay related communications.<br /><br />For more information, please call the Help Desk at <span><strong>(855) 965-2472</strong></span>.</p>
					<button className="save close-button">Close</button>
				</div>
			</div>
			<div id="container" className="rtx_prof">
				<div id="hcp_post_content">
					<AccountNavbar page="profile" />
					<div className="clearfloat"></div>
					<div className="formBox">
						<PatientProfileDetails />
						<MembershipInformation />
					</div>
					<CommercialMedicalInsurance />
					<PharmacyInsuranceInformation />
					<PatientEnrollmentDetails />
					<div className="clearfloat"></div>
				</div>
			</div>
		</div>
	)
}